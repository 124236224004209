import React, { Component } from 'react';
import { connect } from 'react-redux';
import HybridLoginComponent from '../../components/customer/login/HybridLoginComponent';
import MaintenanceBar from '../../components/shared/MaintenanceBar';
import { getAdminLogin } from '../../actions/Auth';
import { getAlertNotifications } from '../../actions/AlertsNotifications';
import { translate } from '../../lang';
import { Redirect } from 'react-router-dom';

/** An active login component */
export class AdminLoginContainer extends Component {

	/**
	 * submit the form by dispatching the action
	 * @param  {Object} data This will dispatch the action.
	 */
	handleLogin = (data) => {
		console.log('handleLogin',data)
		data.module = 'ADMIN';
		this.props.getAdminLogin(data);
    }

	componentWillMount() {
		this.props.getAlertNotifications({
			data: {
				type: 1
			},
			numberOfRows: 200,
			startIndex: null,
			totalEventsFlag: null,
			sortingParams: null,
			smFilterParam : '?smFilterParam=SMARTASSIST SERVICE'
		})
	}

	render() {
		if (this.props.isAdminAuthenticated) {
			return <Redirect to="/service/dashboard" />
		} else {
			return (
				<div>
					<HybridLoginComponent />
					<MaintenanceBar maintainSysData={ this.props.maintainSysData }/>
				</div>
			);
		}

	}
}

/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 **/

function mapStateToProps(state) {
	let msg = 'User ID or Password is wrong';
	let isAdminAuthenticated = false;
	if (state.admin.data && state.admin.data.SET_ADMIN_LOGGED_IN_TOKEN) {
		isAdminAuthenticated = true
	}

	if (state.admin && state.admin.data && state.admin.data.error === false && state.admin.data.isLoggedIn) {
		isAdminAuthenticated = true;
	}

	if (state.admin && state.admin.data && state.admin.data.resMsg) {
		msg = state.admin.data.resMsg;
	}

	return {
		loggedInData: state.admin.data,
		isAdminAuthenticated: isAdminAuthenticated,
		msg,
		maintainSysData: state.alertsNotifications.data,
	}
}

/** @type { Object } Connect the action to be dispatched **/
const mapDispatchToProps = {
    getAdminLogin, getAlertNotifications
}


export default connect(mapStateToProps, mapDispatchToProps)(AdminLoginContainer);
