import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ForgetPassForm from '../../../forms/ForgetPassForm';
import './style.scss';

/** An dump login component */
class ForgotComponent extends Component {

	/**
	 * Dispacth the action to call the middleware and the API.
	 * @param  { Object } data Containing the user input
	 */
	submit = (data) => {
		this.props.submit(data)
	}

	render() {
		const { data, error, isSuccess, isSending, errorMsg, loader,translate } = this.props
		return (
			<ForgetPassForm 
				data = { data }
				isSuccess={ isSuccess }
				errorMsg={ errorMsg }
				isSending={ isSending } 
				error={ error }
				loader={ loader }
				forgetPass={ this.submit.bind(this) }
				translate={translate}
			/>
		);
	}
}

/** Required function and props with the proptypes */
ForgotComponent.propTypes = {
  	submit: PropTypes.func.isRequired
}

export default connect(null, null)(ForgotComponent);