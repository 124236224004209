import { SVPRURL } from '../../constants/Constants';

/** User Level */
export const __api_getEquipments = SVPRURL + "getEquipmentOperatingInfo";
export const __api_getEquipmentOperatingInfo = SVPRURL + "getEquipmentDailyWorkDetail";

export const __api_getCsvDownload = SVPRURL + "getCsvDownload";
export const __api_getServicingInfo = SVPRURL + "getEquipmentService";

export const __api_getSPRecentEvent = SVPRURL + "getSpEvent";
export const __api_getYearOrMonth = SVPRURL + "getYearOrMonth";

//Dashboard API
export const __api_getAllMachineDetail = SVPRURL + "getObjList";

//Location Page
export const __api_getAllMachinLocation = SVPRURL + "getLocation";

/** Contract Management */
export const __api_getContractDetails = SVPRURL + "getContractRenew";

export const __api_getStatusAndCompany = SVPRURL + "getStatusAndCompany";

export const __api_updateContract = SVPRURL + "renewContract";

export const __api_getContractListInfo = SVPRURL + "getContractListInfo";

/*Manage Email*/
export const __api_getCompanyEmail = SVPRURL + "getCompanyEmail";
export const __api_updateCompanyEmail = SVPRURL + "updateCompanyEmail";
export const __api_deleteCompanyEmail = SVPRURL + "deleteCompanyEmail";
export const __api_addCompanyEmail = SVPRURL + "addCompanyEmail";
export const __api_companySearch = SVPRURL + "companySearch";

/* Hourmeter offset management*/
export const __api_getHourMeterGap = SVPRURL + "getHourMeterGap";
export const __api_saveHourMeterOffsetValue = SVPRURL + "saveHourMeterOffsetValue";
export const __api_getHourMeterGapHistory = SVPRURL + "getHourMeterGapHistory";


