import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { UPDATE_SCHEDULE_SERVICE_ALERT_DATA, SENDING_UPDATE_SCHEDULE_SERVICE_ALERT_DATA, SET_UPDATE_SCHEDULE_SERVICE_ALERT_DATA, FETCH_DATA_FAIL } from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* updateServiceData(action) {
    const data = action.data;
    try {
        yield put({
            type: SENDING_UPDATE_SCHEDULE_SERVICE_ALERT_DATA,
            data: '',
            tokenExpired: '',
            invalidToken: '',
            resMsg: '',
            isSending: true
        })
        let response = yield call(api.admin.scheduleService.update, data)
        switch (response.statusCode) {
            case 200:
                yield put({
                    type: SET_UPDATE_SCHEDULE_SERVICE_ALERT_DATA,
                    data: response.data,
                    resMsg: response.message,
                    isSending: false,
                    error: false
                })
                break;
             default:  
                break;
        }
    } catch (e) {
        yield put({
            type: FETCH_DATA_FAIL,
            payload: {
                error: 'Error while connecting server'
            }
        })
    }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchUpdateScheduleServiceData() {
    yield takeLatest(UPDATE_SCHEDULE_SERVICE_ALERT_DATA, updateServiceData)
}
