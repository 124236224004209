import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { LIMIT } from "../../constants/Constants";

export default class Paginate extends Component{

    constructor(props){
        super(props);

        this.state = { page: 1 }
    }

    componentWillReceiveProps(newProps){
        if(newProps.activePage && (this.state.page !== newProps.activePage) && newProps.clearSelection){
            setTimeout(() => newProps.clearSelection(), 0);
        }

        if(newProps.activePage && newProps.activePage !== this.state.page){
            this.setState({ page: newProps.activePage});
        }
    }

    shouldComponentUpdate(nextProps){
        return nextProps.activePage === this.state.page ? true : false;
    }

    render(){
        let {activePage, total, limit, onChange, data} = this.props;

        if(data.constructor !== Array || data.length === 0) return null;

        if(total <= LIMIT) activePage = 1;

        let from = ((activePage-1)*limit)+1,
        to = data.length === limit ? activePage*limit : (activePage*limit - (limit - data.length));


        return(
            <div className='paginate'>
                <div className="float-left">Showing {from} to {to} of {total} entries</div>
                <div className="float-right">
                    {data.length && total>limit  && <Pagination
                        pageRangeDisplayed={6}
                        activePage={activePage}
                        itemsCountPerPage={limit}
                        totalItemsCount={total}
                        onChange={onChange}
                    />}
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
}