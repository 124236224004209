import { GET_PROFILE, SET_PROFILE } from '../../types';

/** @type { Object } default state */
let defaultState = {  };


/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function dailywork(state = defaultState, action = {}) {

    switch (action.type) {
        case GET_PROFILE:
            return { ...state, ...action.data };

        case SET_PROFILE:
            return { ...state, ...action.data };

        default: return state;
    }

}
