import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { GET_EQUIPMENTS_SINGLE_DATA, SENDING_EQUIPMENT_GET_DATA, SET_EQUIPMENT_SINGLE_DATA, FETCH_DATA_FAIL } from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* getEquipmentSingleData(action) {
  const data = action.data;
  try {
    yield put({ type: SENDING_EQUIPMENT_GET_DATA, 
                data: '', 
                resMsg: '',
                isSending: true
              })
   let response = yield call(api.equipmentData.getEquipmentById, data)   
   switch (response.statusCode) {
        case 200 :
          yield put({
             type: SET_EQUIPMENT_SINGLE_DATA,
             data: response.data,
             resMsg: response.message,
             isSending: false,
             error: false 
            })
            break;
          default:
            break;
    }
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: e.message
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchEquipmentSingleData() {
  yield takeLatest(GET_EQUIPMENTS_SINGLE_DATA, getEquipmentSingleData)
}
