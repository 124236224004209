import React, { Component, Fragment } from "react";
import { Field, reduxForm, reset } from "redux-form";
import Moment from "react-moment";
import {connect} from "react-redux";
import { required, onlyNumbers } from "../../shared/Validation";
import { renderTextField, FileInput  } from "../../shared/renderField";
import { Row, Col, Alert } from "reactstrap";
import {saveFota, deleteBrowseFota, getBrowseFota} from "../../../api/admin/fota";
import { setLoader, setToastr, setConfirmBox } from "../../../actions/Application";
import {FaPencilAlt, FaTrashAlt} from 'react-icons/fa'
import { LIMIT } from "../../../constants/Constants";
import Pagination from "../../shared/Pagination";
let form = "BrowseFotaForm";

class BrowseFotaComponent extends Component{

    constructor(props){
        super(props);

        this.state = {
            maps: [],
            activePage: 1,
            total: 0
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    getFotaData = (req) => {
        this.props.setLoader({ loader: {open:true}});

        getBrowseFota(req)
        .then(result => {
            this.props.setLoader({ loader: {open:false}});

            this.setState({
                maps: (result.data && result.data.fotaRecords) || [],
                activePage: this.state.activePage || 1,
                total: (result.data.countResult && result.data.countResult.totalCounts) ? result.data.countResult.totalCounts : this.state.total 
            });
        })
        .catch(err => this.props.setLoader({ loader: {open:false}}));
    }

    onPageChange = (page) => {
        this.setState({activePage: page || 1}, () => {
            this.getFotaData({page});
        });  
    }

    componentWillMount(){
        this.getFotaData({page:1});   
    }

    confirmnDelete = (user) => {
        this.props.setConfirmBox({ modal: { open: true, data: user, title: "Delete Record", content: `Do you really want to delete this record?`, done: this.deleteRecord }});
    }

    deleteRecord = (item) =>{
        /** show loader */
        this.props.setLoader({loader:{open: true}});

        return new Promise((resolve, reject) => {
            deleteBrowseFota(item.fotaId)
                .then(result => {
                    /** resolve promise */
                    /** close loader */
                    this.props.setLoader({ loader: { open: false } });
                    /** dispatch action to reload data */
                    this.getFotaData({page: this.state.activePage});
                    resolve({ toastr: { message: "Record has been deleted.", type: "success" } });
                })
                .catch(error => {
                    /** close loader */
                    this.props.setLoader({ loader: { open: false } });
                    setToastr({ toastr: { message: "This request couldn't be completed. Try again later.", type: "error" } });
                    /** reject promise */
                    reject(false);
                })
        });
    }

    handleKeyDown =(event) =>{
        if(event.key ==='Enter'){
            event.preventDefault();
        }
    }

    onSubmit = (values) =>{
        values.version = +values.version;
        this.props.setLoader({ loader: {open: true}});
        saveFota(values)
        .then(response => {
            if(response.data){
                this.props.setToastr({ toastr: { message:"Equipment list has been added.", type:"success" }});
                this.getFotaData({page: this.state.activePage});
                //this.props.setTab(1);
                this.props.dispatch(reset(form));
            }else{
                throw new Error();
            }
            this.props.setLoader({ loader: {open: false}});
        })
        .catch(error => {
            this.props.setToastr({toastr:{type: "error", message:error || "Something went wrong."}});
            this.props.setLoader({ loader: {open: false}});            
        })
    }

    render(){
        const { handleSubmit, submitting } = this.props, {maps, activePage, total} = this.state;
        return(
            <Fragment>
                <form name="BrowseFotaForm" id="BrowseFotaForm" onSubmit={handleSubmit(this.onSubmit)} autoComplete='off' onKeyDown={this.handleKeyDown}>
                    <Row className="members align-items-center fotaform">
                        <Col sm="6">

                            <Alert type="info">
                                <i>Note: Please upload csv file to import data in bulk.</i>
                            </Alert>

                            <div className="row profile-asset">
                                <div className="col-sm-6">Browse FOTA <span className='required'>*</span>
                                    <Field name="fota" component={FileInput} className='form-control' type="file" validate={[required]}/>
                                </div>
                            
                                <div className="col-sm-6">Version <span className='required'>*</span>
                                    <Field name="version" component={renderTextField} className='form-control' type="text" validate={[required, onlyNumbers]}/>
                                </div>
                            </div>


                            <div className="row profile-asset">
                                <div className="col-sm-12">
                                    <button type="submit" className="btn btn-primary" disabled={submitting}>Save</button> &nbsp;
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>

                <table className="tabledashboard table table-striped table-advance table-hover m-top-30">
                    <thead>
                        <tr>
                            <th>Version</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(maps) && maps.length>0 && maps.map((item,i) => (
                            <tr key={item.model+i+1}>
                                <td>{item.version || "NA"}</td>
                                <td><Moment format="DD MMM, YYYY HH:mm A">{item.fotaDate}</Moment></td>
                                <td>
                                    <FaTrashAlt onClick={() => this.confirmnDelete(item)}/>
                                </td>
                            </tr>
                        ))}

                        {!maps.length && <tr><td align="center" colSpan="6">No Data Found</td></tr>}
                        
                    </tbody>
                </table>

                <Pagination 
                    limit={LIMIT}
                    total={total}
                    activePage={activePage}
                    onChange={this.onPageChange}
                    data={maps || []}
                />
            </Fragment>
        );
    }
}


let formComponent = reduxForm({ form })(BrowseFotaComponent);
let mapDispatchToProps = { setLoader, setToastr, setConfirmBox };
function mapStateToProps(state) {
    let equipmentList = (state.sp && state.sp.equipmentList)
  
    
}
export default connect(mapStateToProps, mapDispatchToProps)(formComponent);