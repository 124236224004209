import React, { Component } from 'react';
import {/*FormGroup,*/ Button } from 'reactstrap'

/** It is common class that provide the button */
class ButtonField extends Component {

    handleClick = (e) => {
        this.props.handleClick(e)
    }

    render() {
         const{ type, name, className, buttonText, clickEvent, id, color, disabled } = this.props;
        return (
                <Button 
                        id={ id }
                        type={ type }
                        name={ name }
                        className={ className }
                        color={ color }
                        disabled={ disabled }
                        onClick={
                            (() => {
                                    if (clickEvent) {
                                        return this.handleClick
                                    }
                                }).call(this)}
                    >
                    { buttonText }
                    </Button>
        );
    }
}

export default ButtonField;
