import React, { Component } from 'react';
import './style.scss';

class FuelIndicator extends Component {

    render() {
        let { fuelRemaning } = this.props;
        let color = 'green';
        fuelRemaning = parseInt(fuelRemaning);
        fuelRemaning = (fuelRemaning > 100) ? 100 : fuelRemaning;

        if(fuelRemaning < 50){
            color = '#f3b704';
        }
        if(fuelRemaning < 25){
            color = 'red';
        }

        if(fuelRemaning < 0){
            color = '';
        }
        
        let style = {
            width: `${(fuelRemaning || 0)}%`,
            backgroundColor: color
        }
        return (
            <div className="fuelouter">
                <div className="fuelinner" style={style}></div>
            </div>
        );
    }

    componentWillUnmount() {

    }
}

export default FuelIndicator;
