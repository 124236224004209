import React, { Component } from 'react';
import { translate } from '../lang';
import AreaMeasurementComponent from '../components/customer/area-measurement/AreaMeasurementComponent';

export default class AreaMeasurementContainer extends Component {
    render() {
        return (
            <AreaMeasurementComponent translate={translate} />
        );
    }
}