import { SET_REPORTS_DATA, FETCH_DATA_FAIL, SENDING_REPORTS_DATA } from '../../types';

/** @type { Object } default state */
let defaultState = {
  data: {
		data: '',
 	}
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function reports(state=defaultState, action={}) {

	switch (action.type) {
		case SET_REPORTS_DATA:
			const data = {}
			data[action.type] = action.data;
			data['resMsg'] = action.resMsg;
			data['isSending'] = action.isSending;
			data['error'] = action.error;
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, data)
		      });
		case SENDING_REPORTS_DATA:
			const sendingRequest = {}
			sendingRequest[action.type] = action.data;
			sendingRequest['isSending'] = action.isSending;
			sendingRequest['error'] = action.error;
			sendingRequest['resMsg'] = action.resMsg;
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, sendingRequest)
		      })
		case FETCH_DATA_FAIL:
			const errors = {}
			errors[action.type] = action.payload.error;
			return Object.assign({}, state, errors)

		default: return state;
	}
}
