import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import MyAccountDetails from "./elements/MyAccountDetails";
import EditAccountDetails from "./elements/EditAccountDetails";
import ResetPassword from "./elements/ResetPassword";
import ChangePassword from "./elements/ChangePassword";
import { FaLock, FaUserCircle } from "react-icons/fa";
import './style.scss';
import classnames from 'classnames';
import { setToastr } from "../../../actions/Application";
import {translate} from "../../../lang"

class MyAccountComponent extends Component{
    constructor(props) {
        super(props);

        
        this.state = {
            activeTab: '1',
            isEditable: false
        };

        this.toggle = this.toggle.bind(this);
        this.getLanguage = this.getLanguage.bind(this);
        this.getTimezone = this.getTimezone.bind(this);
        this.getFullName = this.getFullName.bind(this);
        this.getAlert = this.getAlert.bind(this);
    }

    getFullName(profile){
        return [profile.firstName, profile.middleName, profile.lastName].join(" ");
    }

    getLanguage(code){
        let { language } = this.props;

        if (language.length){
            for (let lang of language){
                if (lang.languageCode.trim() === code){
                    return lang.language;
                }
            }
        }
    }

    getTimezone(code) {
        let { timezone } = this.props;
        if (timezone.length) {
            for (let tz of timezone) { 
                if (tz.timezoneId === parseInt(code)) {           
                    return "(" + tz.utcOffset.trim() + ") " + tz.timezone;
                }
            }
        }
    }

    getAlert(isMobile = true, isPC = true) {
        let alert = [];
        if (isMobile) alert.push(translate('sms'));
        if (isPC) alert.push(translate('email'));
        return alert.join(", ");
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }


    render(){
        let { profile } = this.props;
        let { isEditable } = this.state;

        return(
            <Fragment>
                <section className='reports'>
                        <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '1' }) }
                                        onClick={() => { this.toggle('1'); } }
                                        >
                                        <div>
                                            <FaUserCircle />
                                            <div className="tabheading">{translate('myProfileDetail')}</div>
                                        </div>
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '2' }) }
                                        onClick={() => { this.toggle('2'); } }
                                        >
                                        <div>
                                            <FaLock />
                                            <div className="tabheading">Reset Password</div>
                                        </div>
                                    </NavLink>
                                </NavItem> */}
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '3' }) }
                                        onClick={() => { this.toggle('3'); } }
                                        >
                                        <div>
                                            <FaLock />
                                            <div className="tabheading">{translate('changePassword')}</div>
                                        </div>
                                    </NavLink>
                                </NavItem>
                            </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <div className="tab-pane fade active in show" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div className="user-profile">

                                            {isEditable
                                                ? <EditAccountDetails profile={profile} cancelEdit={() => this.setState({ isEditable: !this.state.isEditable}) } translate={translate}/>
                                                : <MyAccountDetails profile={profile} getTimezone={this.getTimezone} getLanguage={this.getLanguage} getFullName={this.getFullName} getAlert={this.getAlert} translate={translate}/>
                                            }

                                            {!isEditable && <div className="row profile-asset">
                                                <div className="col-sm-2">&nbsp;</div>
                                                <div className="col-sm-10">
                                                    <button type="button" className="btn btn-primary" onClick={() => this.setState({ isEditable: !this.state.isEditable })}>{translate('edit')}</button>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col>       
                                    <ResetPassword profile={profile} translate={translate}/>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col>       
                                    <ChangePassword profile={profile} translate={translate}/>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>                
                </section>

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return ({ language: state.app.language || [], timezone: state.app.timezone || [] });
}

const mapDispatchToProps = {
    setToastr
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountComponent);