import http from "../../services/http";
import { LIMIT } from "../../constants/Constants";
import { __api_getMachineInfo, __api_addOwner, __api_listOwner, __api_getOwnerEquipments, __api_updateOwner,__api_checkOwner,__api_ResendEmailToRegisteredUser,__api_getOwnerDetail,__api_ownerDetailsUpdate } from "./admin.endpoints";

export const getMachineInfo = data => http.Request("GET", __api_getMachineInfo, data);
export const listOwners = (req) => {
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    let phone = req.phone != null && req.phone != undefined ? req.phone : false;
    let ownerId = req.ownerId != null && req.ownerId != undefined ? req.ownerId : false;
    let ownerName = req.ownerName != null && req.ownerName != undefined  ? req.ownerName : false;
    return http.Request("GET", [[__api_listOwner, offset, LIMIT, true,phone,ownerId,ownerName].join("/"), req.qs].join("?"), req.data);
};
export const addOwner = (data, qs='') => http.Request("POST", __api_addOwner+qs, data);
export const getOwnerEquipments = data => http.Request("GET", __api_getOwnerEquipments, data);
export const updateOwner = data => http.Request("POST", __api_updateOwner, data);
export const validateOwnerId = loginId => http.Request("GET", __api_checkOwner + "/" + loginId);
export const getSendEmail = loginId => http.Request("GET", __api_ResendEmailToRegisteredUser + "/" + loginId);
export const getOwnerDetail = machineSaleId => http.Request("GET", __api_getOwnerDetail + "/" + machineSaleId);
export const ownerDetailsUpdate = data => http.Request("POST",__api_ownerDetailsUpdate + "/" + data.machineSaleId, data);



