import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginComponent from '../components/customer/login/LoginComponent';
import MaintenanceBar from '../components/shared/MaintenanceBar';
import { getUserLogin } from '../actions/Auth';
import { getAlertNotifications } from '../actions/AlertsNotifications';
import { translate } from '../lang';
import { Redirect } from 'react-router-dom';

/** An active login component */
export class LoginContainer extends Component {

	/**
	 * submit the form by dispatching the action
	 * @param  {Object} data This will dispatch the action.
	 */
	handleLogin = (data) => {
		this.props.getUserLogin(data);
    }

	componentWillMount() {
		if (!this.props.isAuthenticated) {
			this.props.getAlertNotifications({
				data: {
					type: 1,
				},
				numberOfRows: 200,
				startIndex : null,
				totalEventsFlag : null,
				sortingParams : null,
				smFilterParam : '?smFilterParam=SMARTASSIST CUSTOMER'
			})
		}
	}
	render() {
		if (this.props.isAuthenticated) {
			return <Redirect to="/customer/equipment" />
		} else {
			return (
				<div>
					<LoginComponent
						login={ this.handleLogin.bind(this) }
						translate = { translate }
						msg = { this.props.msg }
						loggedInData = { this.props.loggedInData }
						/>
					<MaintenanceBar maintainSysData={ this.props.maintainSysData }/>
				</div>
			);
		}

	}
}

/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 **/

function mapStateToProps(state) {
	let isAuthenticated = false;
	if (state.user.data && state.user.data.SET_LOGGED_IN_TOKEN) {
		isAuthenticated = true
	}

	if (state.user && state.user.data && state.user.data.error === false && state.user.data.isLoggedIn) {
		isAuthenticated = true;
	}

	return {
		loggedInData: state.user.data,
		isAuthenticated: isAuthenticated,
		msg: 'User ID or Password is wrong',
		maintainSysData: state.alertsNotifications.data,
	}
}

/** @type { Object } Connect the action to be dispatched **/
const mapDispatchToProps = {
    getUserLogin, getAlertNotifications
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
