import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { MapKey } from '../../../../constants/Constants'
import Pagination from 'react-js-pagination';
import InputField from '../../../../components/shared/InputField';
import MapBox from '../../../../components/shared/MapBox';
import Image from '../../../../components/shared/Image';
import FuelIndicator from '../../../../components/shared/FuelIndicator';
import moment from 'moment';
import Validator from 'validator';
import { FaSortDown, FaSortUp, FaSort, FaTimesCircle } from 'react-icons/fa'
import sorting from "../../../../services/sorting";
import bellIcon from '../../../../assets/images/my-equipment-page-icons/bell-black.png';
import locationPointer from '../../../../assets/images/my-equipment-page-icons/location-pointer.png';
import maplocationPointer from '../../../../assets/images/my-equipment-page-icons/tractorlocation.png';
import warning from '../../../../assets/images/my-equipment-page-icons/warning.png';
import { getInstantReport } from '../../../../actions/Equipment';
import noimage from '../../../../assets/images/noimage.png';
import './style.scss';

import Moment from "react-moment";


/* A DataSheet component */
class DataSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            equipmentList: [],
            isMarkerShown: false,
            lat: 0,
            lon: 0,
            errorAPI: false,
            key: null,
            click: true,
            activePage: 1,
            perPage: 12
        };
        this.loadMap = this.loadMap.bind(this);
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.equipment !== nextProps.equipment) {
            let data = nextProps.equipment.map((val) => {
                delete val.sar.machineSaleId;
                Object.assign(val, { ...val.sar });
                let wan = val.equipmentEvents.filter((AlertVal) => {
                    return (AlertVal.eventType === "Technical Error" && AlertVal.currentStatus !== "Completed") ? true : false;
                })
                val.warn = wan.length;
                val.wan = wan;
                return val;
            })

            // Set all the data as a data
            this.setState({ equipmentList: data });
        }
        if (this.props.apiError !== nextProps.apiError) {
            if (nextProps.apiError !== false) {
                this.setState({
                    errorAPI: true,
                    showLoader: false
                })
            }
        }
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
    }

    loadMap(e, id) {
        e.preventDefault();
        let hbars = document.querySelectorAll(".hbar");
        if (hbars) {
            document.querySelectorAll(".hbar").forEach(i => i.classList.add("hidebar"));
        }

        let lat = parseFloat(e.target.parentElement.getAttribute('lat'));
        let lon = parseFloat(e.target.parentElement.getAttribute('lon'));
        
        this.setState({ lat: lat, lon: lon, isMarkerShown: true });
        // call for get data 
        let inDate = moment(new Date()).format("YYYY-MM-DD");
        this.props.getInstantReport({ machineSaleId: id, inDate});
      
       
    }

    HideMap() {
        this.setState({ isMarkerShown: false })
    }

    render() {
        const { translate } = this.props;
        let { instantReportData } = this.props;
        let sortType = (!this.state.click) ? <FaSortUp /> : <FaSortDown />,
            fromtop = (this.state.activePage - 1) * this.state.perPage,
            topend = ((this.state.activePage * this.state.perPage) > (this.state.equipmentList && this.state.equipmentList.length)) ? this.state.equipmentList.length : (this.state.activePage * this.state.perPage)
           
            return (
            <div>
                {
                    (this.state.isMarkerShown ) ?
                        <MapBox
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MapKey}`}
                            defaultZoom={15}
                            isMarkerShown
                            instantReportData={instantReportData}
                            lat={this.state.lat}
                            lon={this.state.lon}
                            icon={maplocationPointer}
                            showMap={this.HideMap.bind(this)}
                            containerElement={<div className="popupUI" />}
                            loadingElement={<div className="popupUI" />}
                            mapElement={<div style={{ height: `100%` }} />}
                        /> :
                        ''
                }
                <Table className="tabledashboard table-responsive table table-striped table-advance table-hover">
                    <thead>
                        <tr >
                            <th colSpan="4" className="myspecialth xs-show-table">{translate('latestEquipment')}</th>
                            <th></th>
                            <th onClick={() => this.sortBy('equipmentType')}>{translate('type')}
                                {(this.state.key === 'type') ? sortType : <FaSort />}
                            </th>
                            <th onClick={() => this.sortBy('model')}>{translate('model')}
                                {(this.state.key === 'model') ? sortType : <FaSort />}
                            </th>
                            <th onClick={() => this.sortByAlphaNumeric('machineNum')}>{translate('machineno')}
                                {(this.state.key === 'machineNum') ? sortType : <FaSort />}
                            </th>
                            <th onClick={() => this.sortByNumber('fuelRemaning')}>{translate('fuel')}
                                {(this.state.key === 'fuelRemaning') ? sortType : <FaSort />}
                            </th>
                            <th>{translate('alert')}</th>
                            <th>{translate('location')}</th>
                            <th onClick={() => this.sortBy('warn')}>{translate('error')}
                                {(this.state.key === 'warn') ? sortType : <FaSort />}
                            </th>
                            <th onClick={() => this.sortByNumber('hourMeter', 'sar')}>{translate('hourMeter')}
                                {(this.state.key === 'hourMeter') ? sortType : <FaSort />}
                            </th>
                            <th onClick={() => this.sortByDate('updatedOn', 'sar')}>{translate('updatedOn')}
                                {(this.state.key === 'updatedOn') ? sortType : <FaSort />}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getTableBody()}
                    </tbody>
                </Table>
                {
                    (this.state.equipmentList.length > this.state.perPage) ?
                        <Fragment>
                            <div className="float-left">
                                Showing {(fromtop + 1)} to {topend} of {this.state.equipmentList.length} entries
                        </div>
                            <div className="float-right">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.perPage}
                                    totalItemsCount={this.state.equipmentList.length}
                                    pageRangeDisplayed={6}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                        </Fragment>
                        :
                        ''
                }
                <div className="clearfix"></div>
            </div>
        );
    }

    sortBy(key) {
        let arrayCopy = [...this.state.equipmentList];
        arrayCopy.sort(sorting.compareByChar(key, this.state.click));
        this.setState({
            equipmentList: arrayCopy,
            key: key,
            click: !this.state.click
        });
    }

    sortByAlphaNumeric(key){
        let arrayCopy = [...this.state.equipmentList];
        arrayCopy.sort(sorting.compareAlpaNumeric(key, this.state.click));
        this.setState({
            equipmentList: arrayCopy,
            key: key,
            click: !this.state.click
        });

    }

    sortByNumber(key, nest = false) {
        let arrayCopy = [...this.state.equipmentList];
        arrayCopy.sort(sorting.compareByNumber(key, this.state.click, nest));
        this.setState({
            equipmentList: arrayCopy,
            key: key,
            click: !this.state.click
        });
    }

    sortByDate(key, nest = false) {
        let arrayCopy = [...this.state.equipmentList];
        arrayCopy.sort(sorting.compareByDate(key, this.state.click, nest));
        this.setState({
            equipmentList: arrayCopy,
            key: key,
            click: !this.state.click
        });
    }

    /**
     * get the table body as the category listing
     */
    getTableBody = () => {
        let equipmentData = '';
        if (this.state.equipmentList.length > 0) {
            equipmentData = this.state.equipmentList.map((value, index) => {
                let hidden = moment(new Date()).isAfter(value.contractExpiry)
                /* let style = {
                    width: `${value.sar.fuelRemaning}%`
                } */

                return (
                    <tr key={index} onClick={this.rowclick.bind(this, value.machineSaleId)}>
                        <td className="numberplate">
                            <div><Image className="tractor rounded-circle" src={value.logo || noimage} alt="logo" /></div>
                            {value.status && value.status=='TE' ?
                            (value.numberPlate ? value.numberPlate.toUpperCase() : " ")|| <InputField placeholder="ADD NO. PLATE" />:""}
                        </td>
                        <td>{value.equipmentType ? value.equipmentType.toUpperCase() : " "}</td>
                        <td>{value.model}</td>
                        <td>{value.machineNum}</td>
                        <td className="text-center">
                        {value.status && (value.status=='TE') ? 
                             <FuelIndicator fuelRemaning={value.sar.fuelRemaning} /> :"NA"}
                        </td>
                        <td className="alertbellcover text-center">
                            {this.prepareAlertRow(value)}
                        </td>
                        <td>
                            {
                                (value.sar.latitude !== null && value.sar.longitude !== null) &&
                                <a href="#" className="mappointer" onClick={(e) => this.loadMap(e, value.machineSaleId)} lat={value.sar.latitude} lon={value.sar.longitude}>
                                    <img src={locationPointer} className="poi" alt="POI" />
                                </a>
                            }
                        </td>
                        <td>{(value.warn > 0) ? <img src={warning} className="exclamationt" alt="exclamation" /> : ''}</td>
                        <td>{(value.sar && value.sar.hourMeter &&!hidden) ? parseFloat(value.sar.hourMeter).toFixed(1) : value.sar && value.sar.hourMeter ? " NA" : " "}</td>
                        <td>
                            {(value.sar.updatedOn &&
                                <Moment format="DD MMM YYYY HH:mm:ss" >
                                    {value.sar.updatedOn}
                                </Moment>) || " "}
                            {/* { (value.sar.updatedOn) ? moment(value.sar.updatedOn).format('D MMM YYYY HH:mm:ss HH:mm:ss') : 'N/A' } */}
                        </td>
                    </tr>
                );
            });
            equipmentData = this.paginateData(equipmentData);
        } else {
            if (this.props.equipment && this.props.equipment.length === 0) {
                equipmentData = <tr className="custom-no-data"><td className="text-center" colSpan={10}> No data Found </td></tr>;
            } else {
                equipmentData = <tr className="custom-no-data"><td className="text-center" colSpan={10}>{(this.state.errorAPI) ? 'Network Error' : 'Loading data..'}</td></tr>;
            }
        }
        return equipmentData
    }

    paginateData(equipmentData) {
        let fromtop = (this.state.activePage - 1) * this.state.perPage;
        let topend = this.state.activePage * this.state.perPage
        equipmentData = equipmentData.splice(fromtop, topend)
        equipmentData.length = this.state.perPage
        return equipmentData
    }

    closeBtn(instance) {
        instance.target.parentNode.parentNode.parentNode.className = "hidebar";
    }

    prepareAlertRow(value) {
        if (value.equipmentEvents.length > 0) {
            let showBellOrNot = value.equipmentEvents.filter((val) => {
                return (val.customerStatusFlag === false);
            })
            let popOption = value.equipmentEvents.map((val, key) => {
                let style = (val.customerStatusFlag) ? '' : ' notread';
                return (
                    <div key={key} className={`fonbox${style}`} onClick={this.markAsRead.bind(this, val.eventId, val.machineSaleId)}>
                        <div>{val.eventDescription}</div>
                        <span className="fns11 alert-primary float-right pl-2 pr-2 font-italic">

                            {(val.eventDate &&
                                <Moment format="D MMM YYYY HH:mm:ss" tz={this.props.timezone}>
                                    {val.eventDate}
                                </Moment>) || " "}

                            {/* {moment(val.eventDate).format('Do MMM YY')} */}
                        </span>
                        <div className="clearfix"></div>
                    </div>
                );
            })

            if (showBellOrNot.length > 0) {
                return <span>
                    <img src={bellIcon} className="alertbell" alt="bell" />
                    <div className="alerttext">{showBellOrNot.length > 99 ? "99+" : showBellOrNot.length}</div>
                    <div className="hidebar hbar">
                        <div className="position-absolute text-left alertpopup">
                            <div className="closeBtn" onClick={this.closeBtn.bind(this)}><FaTimesCircle /></div>
                            <div className="innerrowscroll">{popOption}</div>
                        </div>
                    </div>
                </span>
            } else {
                return <span>
                    <span className="badge-pill badge-secondary">{value.equipmentEvents.length}</span>
                    <div className="hidebar hbar">
                        <div className="position-absolute text-left alertpopup">
                            <div className="closeBtn" onClick={this.closeBtn.bind(this)}><FaTimesCircle /></div>
                            <div className="innerrowscroll">{popOption}</div>
                        </div>
                    </div>
                </span>
            }
        } else {
            return '';
        }
    }

    markAsRead(eVval, mSaleId, e) {
        let newList = this.state.equipmentList.map((val, index) => {
            if (mSaleId === val.machineSaleId) {
                let changeData = val.equipmentEvents.map((value, nnerindex) => {
                    if (value.eventId === eVval) {
                        return {
                            currentStatus: value.currentStatus,
                            customerStatusFlag: true,
                            eventDate: value.eventDate,
                            eventDescription: value.eventDescription,
                            eventId: value.eventId,
                            eventType: value.eventType,
                            eventUrgency: value.eventUrgency,
                            eventWorkAround: value.eventWorkAround,
                            fatalMsgId: value.fatalMsgId,
                            machineSaleId: value.machineSaleId
                        }
                    } else {
                        return value;
                    }
                })
                return {
                    ...this.state.equipmentList[index], equipmentEvents: changeData
                }
            } else {
                return val;
            }
        });
        this.setState({ equipmentList: newList })
        this.props.markAsRead(eVval);
    }

    rowclick(val, e) {
        if (e.target.tagName === "IMG" && e.target.getAttribute('class') === "poi") {
            this.loadMap(e, val);
            return true;
        }

        if (e.target.tagName === "svg"
            || e.target.parentElement.className === "p-2 position-absolute text-left alertpopup"
            || e.target.parentElement.className === "fonbox" || e.target.parentElement.className === "fonbox notread"
            || e.target.parentElement.className === "closeBtn" || e.target.parentElement.className === "hidebar"
            || e.target.className === "p-2 position-absolute text-left alertpopup"
        ) {
            return true;
        }

        if ((e.target.tagName === "IMG"
            && e.target.parentElement.parentElement.getAttribute('class') === "alertbellcover text-center")
            || e.target.getAttribute('class') === "alerttext"
            || e.target.parentElement.parentElement.getAttribute('class') === "alertbellcover text-center") {
            this.setState({ isMarkerShown: false })
            e.preventDefault();

            for (let d in document.getElementsByClassName('alertbellcover')) {
                let childElement = document.getElementsByClassName('alertbellcover')[d];
                if (childElement.childElementCount) {
                    if (childElement.lastElementChild.childElementCount > 0) {
                        childElement.lastElementChild.lastElementChild.className = "hidebar hbar";
                    }
                }
            }
            e.target.parentElement.lastChild.className = "hbar"

            let ele = e.target.parentElement.lastElementChild.lastChild.getBoundingClientRect()
            if (ele.height > window.innerHeight - (ele.height + ele.top)) {
                let value = `margin-bottom:${ele.height}px`;
                e.target.parentElement.lastElementChild.lastChild.setAttribute('style', value)
            }
            return true;
        }

        this.props.history.push(`/customer/equipment/equipmentDetail/${val}`)
    }

    componentDidMount() {
        let img = document.getElementsByTagName('img')
        for (let d in img) {
            if (!isNaN(d)) {
                img[d].addEventListener('error', function () {
                    this.src = noimage;
                })
            }
        }
    }
}

const mapDispatchToProps = { getInstantReport };
const mapStateToProps = state => {
    return ({
        instantReportData: (state.equipment && state.equipment.data && state.equipment.data.SET_INSTANT_REPORT_DATA) ? state.equipment.data.SET_INSTANT_REPORT_DATA : []

    });
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataSheet));
