import React, { Fragment } from "react";
import TopTabingBar from "../TopTabingBar";

const MyProfile = props => {
    let profile  = props.profile || {};
    
    return(
        <Fragment>

            <TopTabingBar active={3} />

            <div className="row profile-asset">
                <div className="col-sm-2">User ID</div>
                <div className="col-sm-10">{profile.userId || " "}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">Full Name</div>
                <div className="col-sm-10">{profile.name || " "}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">Email Address</div>
                <div className="col-sm-10">{profile.email || ' '}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">Company Name</div>
                <div className="col-sm-10">{profile.companyName || ' '}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">Language</div>
                <div className="col-sm-10">{profile.languageCode || profile.language || ' '}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">Timezone</div>
                <div className="col-sm-10">{profile.timeZone || ' '}</div>
            </div>
        </Fragment>
    );
}

export default MyProfile;