import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../../api/index';
import { GET_EQUIPMENTS_EVENTS_DATA, SENDING_EQUIPMENT_EVENTS_LIST_DATA, SET_EQUIPMENT_EVENTS_LIST_DATA, 
  FETCH_DATA_FAIL } from '../../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* getEquipmentEventsListData(action) {
  let response= null;
  try {
    yield put({
      type: SENDING_EQUIPMENT_EVENTS_LIST_DATA,
      data: '',
      resMsg: '',
      isSending: true,
      error: false
    })

    var lastPart = window.location.href.split('/').pop();
    if (action.data) {
      if (lastPart == 'dashboard' || localStorage.getItem('alertNotifications') == null) {
        response = yield call(api.admin.equipmentData.getRecentEvents);
        localStorage.setItem('alertNotifications', response);
      }
      else {
        response = localStorage.getItem('alertNotifications');
      }
    } else {
      if (lastPart == 'dashboard' || localStorage.getItem('alertSPNotifications') == null) {
        response = yield call(api.admin.equipmentData.getSPRecentEvents);
        localStorage.setItem('alertSPNotifications', response);
      }
      else {
        response = localStorage.getItem('alertSPNotifications');
      }
    }
  

    switch (response.statusCode) {
      case 200:
        yield put({
          type: SET_EQUIPMENT_EVENTS_LIST_DATA,
          data: response.data,
          resMsg: response.message,
          isSending: false,
          error: false
        })
       break;
      default:
       break;
    }
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: 'Error while connecting server'
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchEquipmentEventsListData() {
  yield takeLatest(GET_EQUIPMENTS_EVENTS_DATA, getEquipmentEventsListData)
}
