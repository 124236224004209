import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col, ButtonGroup, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { renderTextField } from "../../shared/renderField";
import { required, alphaNumeric } from "../../shared/Validation";
import {find} from 'lodash';
import { setLoader, setToastr } from "../../../actions/Application";
import { getModuleFeatures, setNewRole, updateRole } from "../../../api/admin/roles";
import "./style.scss";
import { getRole_list } from "../../../actions/admin/getRoles";
let form = "AddRole";

/* functions  */
const getItemCode = (item) => {
    return item.replace(/ /g, "_");
}, splitCaseStr = (str, styling='$1 $2') => {
    return str.replace(/([a-z])([A-Z])/g, styling);
}

class AddRoleComponent extends Component{

    constructor (props) {
        super(props);
        this.state = { 
            modules: {},
            access: [],
            permissions: {},
            relatedPermissions: {},
            isUpdate: false,
            reduxFlag: false,
            isUpdating: false
        };

        this.onPermissionSelect = this.onPermissionSelect.bind(this);
        this.selectRelatedPermissions = this.selectRelatedPermissions.bind(this);
        this.generateModuleMap = this.generateModuleMap.bind(this);
        this.addRole = this.addRole.bind(this);
        this.updateRole = this.updateRole.bind(this);
        this.onSelected = this.onSelected.bind(this);

    }

    onSelected (roles) {
        const roleName = Object.keys(roles);

        if ( Object.keys(roles).length ===1){

        let role_name = document.querySelector("input[name='roleName']").value;

        if(!role_name && !this.state.reduxFlag) {
            this.props.initialize({roleName: roleName});
            this.setState({ reduxFlag: true });
        }

        const fn = roles[roleName][0];
            if( fn && fn.admin && this.state.modules && this.state.modules.admin) {
                fn.admin.forEach( adninRole=> {
                    const module =  find(this.state.modules.admin, {function: adninRole});
                    if(module) this.onPermissionSelect(module.uuid+'@FULL', module.function, 'admin')
                });
            }

            if( fn && fn.serviceProvider && this.state.modules && this.state.modules.serviceProvider) {
                fn.serviceProvider.forEach( spRole=> {
                    const module =  find(this.state.modules.serviceProvider  , {function: spRole});
                    if(module) this.onPermissionSelect(module.uuid+'@FULL', module.function, 'serviceProvider')
                });
            }
        }
    }

    selectRelatedPermissions(){
        let { modules, relatedPermissions } = this.state;

        for(let m in modules){
            modules[m].map(perm => {
                if(perm.menu === false){
                    relatedPermissions[perm.uuid] = {
                        functionName: perm.function,
                        permission: "FULL",
                        module: m
                    }
                }
            })
        }

        this.setState({relatedPermissions});
    }

    componentWillReceiveProps(newProps) {
        if( newProps && newProps.roleList && newProps.roleList.roles) {
           if(!this.state.isUpdating){
                this.onSelected(newProps.roleList.roles);
           }    
        }
    }
    componentWillMount(){
        const { roleid } = (this.props.match &&  this.props.match.params ) || null;
        this.setState({isUpdate: (roleid ? true: false)});
        this.getFeatures(roleid);
    }

    getFeatures(roleid){
        this.props.setLoader({ loader: {open:true}});

        if( roleid) {
            this.props.getRole_list({page:1, data :{ roleCode: roleid}});
        }
        getModuleFeatures().then(result => {
            
            this.props.setLoader({ loader: {open:false}});

            this.setState({
                access: result.data.access || [],
                modules: result.data.modules || {}
            }, () => {
                if(this.props.roleList && this.props.roleList.roles) {
                   this.onSelected(this.props.roleList.roles);
                }else{
                    //this.selectRelatedPermissions();
                }
            });
        }).catch(err => this.props.setLoader({ loader: {open:false}}))
    }

    onPermissionSelect(permcode, functionName, module) {
        let [code, permission] = permcode.split("@"), { permissions } = this.state;

        if( permission === 'no') {
            // remove permision
            const keys =  Object.keys(permissions);
            if( keys && keys.length> 0) {
                const index = keys.indexOf(code);
                if( index >=0  ) {
                    delete permissions[code];
                }
            }
        }
        else {
            // add permission
            permissions[code] = {functionName, permission, module};
        }

        this.setState({ permissions });
    }

    updateRole(values){
        let { roleid } = this.props.match.params;
        
        let newRole = {
            "roles": { "roleName": ((values.roleName && values.roleName.constructor === Array && values.roleName[0].trim()) || values.roleName.trim()), "roleCode": +roleid },
            "functions": []
        }, {permissions} = this.state, {setToastr, setLoader} = this.props;

       
        if(Object.keys(permissions).length > 0){

            setLoader({ loader: {open:true}});
            for(let uuid in permissions){
                newRole.functions.push({
                    functionName: permissions[uuid].functionName,
                    access: permissions[uuid].permission,
                    module: (splitCaseStr(permissions[uuid].module)).toUpperCase()
                });
            }

            updateRole(newRole)
            .then(result => {
                

                if(result.data === true){
                    this.props.history.push('/service/roles');
                    setToastr({ toastr: {open:true, type:"success", message: "Role has been updated successfully."}});
                    setLoader({ loader: {open:false}});
                }else{
                    setToastr({ toastr: {open:true, type:"error", message: "Role can't be updated."}});
                    setLoader({ loader: {open:false}});
                }

                

            })
            .catch(err => {
                

                setToastr({ toastr: {open:true, type:"error", message: err || "This role can't be saved. Please try again later."}});
                setLoader({ loader: {open:false}});        
            });
        } else {
            setToastr({ toastr: {open:true, type:"info", message: "Please select atleast one module"}});
            
        }

    }

    addRole(values){
        let newRole = {
            "roles": { "roleName": values.roleName.trim() },
            "functions": []
        }, {permissions} = this.state, {setToastr, setLoader} = this.props;


        if(Object.keys(permissions).length){
            //let updatedPermissions = {...permissions, ...relatedPermissions};
            setLoader({ loader: {open:true}});
            for(let uuid in permissions){
                newRole.functions.push({
                    functionName: permissions[uuid].functionName,
                    access: permissions[uuid].permission,
                    module: (splitCaseStr(permissions[uuid].module)).toUpperCase()
                });
            }

            setNewRole(newRole)
            .then(result => {
                this.props.history.push('/service/roles');
                setToastr({ toastr: {open:true, type:"success", message: "New Role has been saved successfully."}});
                setLoader({ loader: {open:false}});

            })
            .catch(err => {
                setToastr({ toastr: {open:true, type:"error", message: err || "This role can't be saved. Please try again later."}});
                setLoader({ loader: {open:false}});        
            });
        } else {
            setToastr({ toastr: {open:true, type:"info", message: "Please select atleast one module"}});

        }

    }

    generateModuleMap(){
        let section = [], {modules, access, permissions} = this.state;
        console.log('access',access);
        console.log('modules',modules);
        console.log('permissions',permissions);
            for(let i in modules){
                section.push(<Table key={i} module={i} functions={modules[i]} access={access} permissions={permissions} onPermissionSelect={this.onPermissionSelect}/>);
            }
        return section.length ? section : "";
    }

    
    render(){   
        let { handleSubmit } = this.props, { roleid } = this.props.match.params;
        return(
            <Fragment>
                <form onSubmit={handleSubmit(roleid ? this.updateRole : this.addRole)} autoComplete='off' className='form-horizontal' id={form}>
      
                    <section className='member-personal-info margin-up'>
                    <Row className='no-gutters'>
    
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Role Name <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="roleName" component={renderTextField} className='form-control uprcase' type="text" validate={[alphaNumeric, required]} />
                                </Col>
                            </Col>

                            {this.generateModuleMap()}

                            <Col className='machine-button' md='12'>
                                <Col md='4' className='float-left'>
                                
                                
                                {roleid && <button type="submit" className="btn btn-primary ignore" onClick={() => this.setState({isUpdating: true})}>
                                    Update Role
                                </button>}

                                {!roleid && <button type="submit" className="btn btn-primary ignore">
                                    Add Role
                                </button>}


                                </Col>
                            </Col>
                        </Row>
                    </section>
                </form>
            </Fragment>   
        );
    }
}

const Table = ({module, functions, access, permissions, onPermissionSelect}) => {
    let customAccess = ["FULL"];
    return (
        <>
            <p className='sub-heading m-top-30'>{splitCaseStr(module)}</p>
            <table className="tabledashboard table table-striped table-advance table-hover">
                <tbody>
                    {functions.map((item, i) => {
                        let code = getItemCode(item.uuid);

                        if(!item.menu) return false;
                        
                        return <tr key={i} className="pointer-default">
                            <td width="17%">{item.function}</td>
                            <td>
                                <ButtonGroup>
                                    {customAccess.map((perm, index) => <div key = {index}>
                                  
                                    <Button className='role-title' color="primary" 
                                        onClick={() => onPermissionSelect(code+'@'+perm, item.function, module)} 
                                            active={permissions[code] && permissions[code].permission === perm}>
                                        Yes
                                    </Button> 
                                    &nbsp;&nbsp;
                                  
                                    <Button className='role-title' color="primary" 
                                      active={!(permissions[code] && permissions[code].permission)}   onClick={() => onPermissionSelect(code+'@no', item.function, module)}>
                                        No
                                    </Button> 
                                    </div>)}
                                </ButtonGroup>
                            </td>
                        </tr>;
                    })}
                </tbody>
            </table>
        </>
    );
}

const mapDispatchToProps = { setLoader, setToastr , getRole_list}
const mapStateToProps = state => {
    return({ roleList: state.RoleList });
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form})(withRouter(AddRoleComponent)));