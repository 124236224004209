import React, { Component } from 'react';
import { connect } from 'react-redux';
import SystemMaintenanceComponent from '../../components/admin/system-maintenance/SystemMaintenanceComponent';
import { translate } from '../../lang';
import { getAlertNotifications } from '../../actions/AlertsNotifications';
import { updateSystemMaintenance, unsetSystemMaintenance } from '../../actions/SysMaintenance';
import { setToastr } from "../../actions/Application";

class SystemMaintenanceContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            maintainSysData: [],
        };
    }

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount() {
        this.props.getAlertNotifications({
            data: {
                type: 1
            }
        })
    }

    handleSubmit(data) {
        this.props.updateSystemMaintenance(data)
    }

    handlePageChange(data) {
        this.props.getAlertNotifications({
            data: {
                type: 1,
            },
            numberOfRows: data.numberOfRows,
            startIndex: data.startIndex,
            totalEventsFlag: data.totalEventsFlag,
            sortingParams: data.sortingParams
        })
    }

    render() {
        return (
            <SystemMaintenanceComponent
                translate={translate}
                apiError = { this.props.apiError }
                setToastr = { this.props.setToastr }
                handleSubmit={this.handleSubmit.bind(this) }
                maintainSysData={ this.props.maintainSysData }
                handlePageChange={ this.handlePageChange.bind(this) }
                sysMaintenance={ this.props.sysMaintenance }
                />
        );
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    return {
        maintainSysData: state.alertsNotifications.data,
        apiError: state.alertsNotifications.FETCH_DATA_FAIL || state.sysMaintenance.FETCH_DATA_FAIL || false,
        sysMaintenance: state.sysMaintenance.data
    };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getAlertNotifications, updateSystemMaintenance, unsetSystemMaintenance, setToastr
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemMaintenanceContainer);