import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoginForm from '../../../forms/LoginForm';

/** An login component */
class LoginComponent extends Component {

	/**
	 * Get the pass the data to its parent component
	 * @param  { Object } data Containing the user input
	 */
	login = (data) => {
		this.props.login(data)
	}

	render() {
		const { translate, msg, loggedInData } = this.props;
		
		return (
			<LoginForm
				loginForm={ this.login.bind(this) }
				msg = { msg }
				loggedInData = { loggedInData }
				translate = { translate }
				/>
		);
	}
}

/** Required function and props with the proptypes */
LoginComponent.propTypes = {
	login: PropTypes.func.isRequired
}

export default LoginComponent;