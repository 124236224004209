import React, { Component } from "react";
import { connect } from "react-redux";
import { setLoader, setConfirmBox } from "../../../../actions/Application";
import { resetPassword } from "../../../../api/profile";
import { Button } from "reactstrap";

class ResetPassword extends Component{
    constructor(props){
        super(props);

        this.state = {
            disablePwdBtn: false
        }

        this.resetPwd = this.resetPwd.bind(this);
    }

    confirmnReset(user){
        let {translate} = this.props;
        this.props.setConfirmBox({ modal: { open: true, data: user, title: translate('resetPassword'), content: translate('doYouReallyWantToReset'), done: this.resetPwd }});
    }

    resetPwd() {
        let { profile, setLoader, translate } = this.props;

        setLoader({loader:{open: true}});
        
        return new Promise((resolve, reject) => {
            resetPassword(profile.id)
            .then(result => {
                setLoader({loader:{open: false}});
                /** disable button */
                this.setState({ disablePwdBtn: true });
                /** show success message */
                resolve({ toastr: { message: translate('passwordSentOnMobileNumber'), type: "success" } })
            })
            .catch(error => {
                setLoader({loader:{open: false}});
                reject(false);
            });
        });
    }

    render(){
        let { disablePwdBtn } = this.state, { profile,translate } = this.props;
        return (
            <div className="tab-pane fade active in show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div className="user-profile">
                    {translate('clickOnResetPassword')}
    
                <div className="reset-password-area">
                        <Button type="button" disabled={disablePwdBtn} className="btn btn-primary" onClick={() => this.confirmnReset(profile)}>{translate('resetPassword')}</Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    setLoader, setConfirmBox
}

export default connect(null, mapDispatchToProps)(ResetPassword);