import React, {Component, Fragment} from "react"
import Table from "./shared/Table";
import http from "../services/http";
import { Card, CardBody } from "reactstrap";
import profileImage from '../assets/images/logo.jpg';
import moment from "moment";

export default class DemoTerminalComponent extends Component{
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            packets: [],
            terminalId: undefined
        }
    }

    componentDidMount(){
        this.getTerminalData();
    }

    getTerminalData(terminalId=46764815393){
        this.setState({ loading: true })
        http.Request(
            "GET",
            "https://t4ufijutuj.execute-api.ap-south-1.amazonaws.com/Dev/getData/"+terminalId,
            null,
            true
        ).then(result => {
            this.setState({ loading: false, packets: (result.data && result.data.constructor === Array) ? result.data : [result.data], terminalId });
        }).catch(err => this.setState({ loading: false, terminalId }));
    }

    render(){
        let { packets, loading } = this.state;

        const columns = [
            { Header: 'Terminal Id', Cell: row => <span>{row.original.terminalId || " "}</span> },
            { Header: 'Latitude', Cell: row => <span>{row.original.latitude || " "}</span> },
            { Header: 'Longitude', Cell: row => <span>{row.original.longitude || " "}</span> },
            { Header: 'Updated On', Cell: row => <span>{row.original.updatedOn ? moment(new Date(parseInt(row.original.updatedOn))).format("DD MMM YYYY HH:mm A") : " "}</span> }];


        return(
            <Fragment>

                <div className="view loginView">
				<div className="box-loin">
                    
					<Card className="mb-3 form-card" style={{height: "600px"}}>
						<div className="topheader"><img src={ profileImage } alt="avatar"/></div>
						<CardBody>
                            
                        <section className='container demo-data' style={{padding: '20px'}}>
                        <p className='sub-heading u_l' style={{marginBottom: "30px"}}>Start Report Data</p>

                                <div style={{marginBottom: "10px"}}>
                                <span style={{marginRight: "20px", padding:"5px 0px", textTransform: "uppercase"}}> Ternimal Id: </span>

                                    <span style={{marginRight: "20px", backgroundColor: "rgba(0,0,0,0.7)", color: "#fff", padding:"5px 15px", cursor:"pointer"}} onClick={() => this.getTerminalData(46764815391)}>46764815391</span>
                                    
                                    <span style={{marginRight: "20px", backgroundColor: "rgba(0,0,0,0.7)", color: "#fff", padding:"5px 15px", cursor:"pointer"}} onClick={() => this.getTerminalData(46764815392)}>46764815392</span>

                                    <span style={{marginRight: "20px", backgroundColor: "rgba(0,0,0,0.7)", color: "#fff", padding:"5px 15px", cursor:"pointer"}} onClick={() => this.getTerminalData(46764815393)}>46764815393</span>

                                    <span style={{marginRight: "20px", backgroundColor: "rgba(0,0,0,0.7)", color: "#fff", padding:"5px 15px", cursor:"pointer"}} onClick={() => this.getTerminalData(8085470157)}>8085470157</span>
                                </div>

                                    <Table 
                                        columns={columns} 
                                        data={packets} 
                                        showPaginationBottom={false} 
                                        minRows={5} 
                                        loading={loading} />
                                </section>
						</CardBody>
					</Card>
				</div>
			</div>

            </Fragment>
        );
    }
}