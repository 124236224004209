import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Moment from 'react-moment'
import ServiceCheckComponent from '../../service-provider/equipments/ServiceCheckComponent';
import { LIMIT } from "../../../constants/Constants";
import Pagination from "../../shared/Pagination";

class ServiceHistoryDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            HistoryDetail: null,
            viewServiceList:[],
            readonly:true,
            itemList :[],
            activePage: 1,
            total: 0
        };
        this.toggle = this.toggle.bind(this);
        this.viewService = this.viewService.bind(this);
        this.getItemList =this.getItemList.bind(this);
    }
    
    viewService(rList) {
        
        this.toggle();
        this.setState({serviceCheckList:[]});
        let array = Array.from(JSON.parse(rList));
        this.getItemList(array);
        this.setState({
            viewServiceList: array,
            readonly: true
        })
    }
    getItemList(rList){
        let itemList=[];
        rList.map(r=>{
            itemList.push(r.item);
        });
        this.setState({itemList});

    }
     handlePageChange(page) {
         
        let { selectedEquipment } = this.props;
        this.setState({ activePage: page || 1 }, () => {
            this.props.getEquipmentServiceHistory(this.state.activePage);
        });
    }  
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }
    render() {
        let { serviceHistoryData,translate,selectedEquipment} = this.props;
        let historyData = serviceHistoryData ? serviceHistoryData.equipmentServiceHistory :[];
        console.log('historyData',historyData);
        let group = selectedEquipment.status;
        let {readonly,itemList,activePage} = this.state;
        return (
            <Fragment>
                {historyData && <table className="tabledashboard table table-striped table-advance table-hover m-top-30 eqService">
                    <thead>
                        <tr>
                            {/* <th>Sr. No</th> */}
                            <th>{translate('serviceDate')}</th>
                            <th>{translate('hourMeteratService')}</th>
                            <th>{translate('serviceDetail')}</th>
                            <th>{translate('otherServiceDetail')}</th>
                            <th>{translate('registeredBy')}</th>
                            <th>{translate('serviceCompany')}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {historyData.length > 0 && historyData.map((item, i) => (
                            <tr key={i + 1}>
                                <td><Moment format="DD MMM YYYY HH:mm:ss" tz="Asia/Kolkata">{item.serviceDate}</Moment></td>
                                <td>{item.hourMeter}</td>
                                <td>{JSON.parse(item.serviceDetail).length > 0 ? <a href="#" className="serviceDetail" onClick={() => this.viewService(item.serviceDetail)}  >
                                    <span> {translate('viewDetail')} </span>
                                </a> : ""}</td>
                                <td>{item.otherServiceDetail}</td>
                                <td>{item.serviceEngineerName}</td>
                                <td>{item.serviceCompanyName}</td>
                            </tr>
                        ))}

                        {!historyData.length && <tr><td align="center" colSpan="12">{translate('noServiceHistory')}</td></tr>}

                    </tbody>
                </table>}
                {historyData && <Pagination
                    limit={LIMIT}
                   total={serviceHistoryData.countResult.totalCounts}
                    activePage={activePage}
                    onChange={this.handlePageChange.bind(this)}
                    data={historyData || []}
                /> }
                 <ServiceCheckComponent readonly={readonly}  modal={this.state.modal} toggle={this.toggle} data={itemList}  serviceCheckList={this.state.viewServiceList}  translate={translate} group={group}></ServiceCheckComponent>
            </Fragment>
        );
    }
};


const mapStateToProps = state => {
    console.log('state',state)
    return ({
        serviceHistoryData: (state.serviceHistory && state.serviceHistory.data && state.serviceHistory.data.SET_EQUIPMENTS_SERVICE_HISTORY) ? state.serviceHistory.data.SET_EQUIPMENTS_SERVICE_HISTORY : []
    });
};

export default connect(mapStateToProps, null)(ServiceHistoryDetail);