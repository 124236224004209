import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { GET_EQUIPMENTS_LIST_DATA,SENDING_EQUIPMENT_DATA,SET_EQUIPMENT_DATA,SET_EQUIPMENT_SINGLE_DATA,FETCH_DATA_FAIL } from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */

export function* getEquipmentData(action) {
  const data = action.data;
  try {
   yield put({ type: SENDING_EQUIPMENT_DATA, data: '', tokenExpired: '', invalidToken: '', resMsg: '', isSending: true })
   yield put({type: SET_EQUIPMENT_SINGLE_DATA, data: '', resMsg: '', isSending: false, error: false })
   let response = yield call(api.equipmentData.latestEquipment, data)   
   switch (response.statusCode) {
        case 200 :
          yield put({ type: SET_EQUIPMENT_DATA, data: response.data, resMsg: response.message, isSending: false, error: false })
            let params = JSON.stringify({ 'equipmentData':  response.data});
            localStorage.setItem('equipmentData', params);
          break;
       default:  
        break;
    }
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: 'Error while connecting server'
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchEquipmentData() {
  yield takeLatest(GET_EQUIPMENTS_LIST_DATA, getEquipmentData)
}
