import { SET_FORGET_PASSWORD, SET_FORGET_PASSWORD_FAIL, SENDING_FORGET_PASSWORD_REQUEST } from '../../types';
let defaultState = {
  	data: {
		data: '',
 	},
 	isSuccess: false,
 	isSending: false,
 	resMsg: '',
 	error: false,
}
export default function forgetPassword(state=defaultState, action={}) {
	
	switch (action.type) {
		case SET_FORGET_PASSWORD:
			const forgetPassword = {}
			forgetPassword[action.type] = action.forgetPasswordData;
			forgetPassword['isSending'] = action.isSending;
			forgetPassword['isSuccess'] = action.isSuccess;
			forgetPassword['error'] = action.error;
			forgetPassword['resMsg'] = action.resMsg;

			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, forgetPassword)
		      })
		
		case SENDING_FORGET_PASSWORD_REQUEST:
			const sendingRequest = {}
			sendingRequest[action.type] = action.forgetPasswordData;
			sendingRequest['isSending'] = action.isSending;
			sendingRequest['isSuccess'] = action.isSuccess;
			sendingRequest['error'] = action.error;
			sendingRequest['resMsg'] = action.resMsg;
		
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, sendingRequest)
		      })

		case SET_FORGET_PASSWORD_FAIL:
			const errors = {}
			errors[action.type] = action.payload.error;
			errors['isSuccess'] = action.payload.isSuccess;
			errors['isSending'] = action.isSending;
			errors['error'] = action.payload.errorCheck;
			return Object.assign({}, state, errors)
		
		default: return state;
	}
}