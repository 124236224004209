import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../../api/index';
import { SAVE_EQUIPMENTS_SUPPORT_DATA, SENDING_EQUIPMENTS_SUPPORT_INFO_DATA, SET_EQUIPMENTS_SUPPORT_INFO_DATA, 
  FETCH_DATA_FAIL } from '../../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* saveEquipmentSupportData(action) {
  const data = action.data;
  try {
    yield put({
      type: SENDING_EQUIPMENTS_SUPPORT_INFO_DATA,
      data: '',
      resMsg: '',
      isSending: true,
      error: false
    })

    let response = yield call(api.serviceProvider.equipment.updateSupportInfo, data)
    switch (response.statusCode) {
      case 200:
        yield put({
          type: SET_EQUIPMENTS_SUPPORT_INFO_DATA,
          data: response.data,
          resMsg: response.message,
          isSending: false,
          error: false
        })
        break;
      default:
        break;
    }
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: 'Error while connecting server'
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchSaveEquipmentSupportData() {
  yield takeLatest(SAVE_EQUIPMENTS_SUPPORT_DATA, saveEquipmentSupportData)
}

