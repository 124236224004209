import watchLoginUser from './loginUser/loginUser';
import watchEquipmentData from './equipment/equipment';
import watchMembers from "./members/members";
import watchProfile from "./profile/profile";
import watchApplication from "./application/application";
import watchEquipmentUpdate from './equipment/equipmentUpdate';
import watchEquipmentGetAlertSetting from './equipment/equipmentAlert';
import watchAlertNotifications from './alerts/alertNotifications';
import watchSaveAlertNotification from './alerts/saveAlertNotification';
import watchSaveSchSysAlertNotification from './alerts/saveScheduleSysAlert';
import watchEquipmentSingleData from './equipment/equipmentSingleData';
import watchReportsData from './reports/reports';
import watchWorkReportsData from './reports/workReport';
import watchForgetPassword from './loginUser/forgetPassword';
import watchSaga from './watch.js';
import watchFIData from "./field-information/field-information";
import watchServiceEquipmentData from './serviceHistory/serviceHistory'
import watchEquipmentServiceHistory from './serviceHistory/serviceHistory'

/** Admin Watch Connections **/
import watchEquipmentAlertListData from "./equipment/adminEquipment/equipment";
import watchEquipmentEventsListData from './equipment/adminEquipment/equipmentEvents';
import watchLoginAdmin from './loginAdmin/loginAdmin';
import watchOwnerData from "./admin/owner";
import watchScheduleServiceData from './schedule-service/scheduleServiceAlerts';
import watchUpdateScheduleServiceData from './schedule-service/updateScheduleServiceAlert';
import watchAddSystemMaintenanceData from './sysMaintenance/addSysMaintenance';
import watchUpdateSystemMaintenanceData from './sysMaintenance/updateSysMaintenance';
import watchGetEquipmentSupportData from './equipment/adminEquipment/equipmentSupportInfo';
import watchEquipmentList from './admin/equipmentList';
/** Service Provider Watch Connections **/
import watchLoginSrvprov from './loginSrvprov/loginSrvprov';
import watchSaveEquipmentSupportData from './equipment/adminEquipment/saveSupportInfo';
import watchUpdateEquipmentStatus from './equipment/adminEquipment/equipmentStatus';
import watchSPEquipments from "./service-provider/equipment";
import watchSPMachine from "./service-provider/equipmentDetail";
import watchSPMachineLocation from "./service-provider/equipmentLocation";
import watchDC from "./admin/dataControl";
import watchDealerEquipment from "./admin/dealerManagement"
import watchRoles from "./admin/roles";
import watchInstantReportData from './equipment/getInstantReport';
import watchUpdateEquipmentService from "./service-provider/equipmentSaveService";
import watchContractDetails from './admin/contractManagement';
import watchHourmeterGap from './admin/hourmeterOffsetManagement';

/*
 * ALL your sagas must go into root saga so they can be combined back together. 
 * Don't forget to import them before adding them to JSON. 
 */
export default function* rootSaga() {
	yield [
		watchLoginUser(),
		watchEquipmentData(),
		watchEquipmentUpdate(),
		watchEquipmentGetAlertSetting(),
		watchAlertNotifications(),
		watchSaveAlertNotification(),
		watchSaveSchSysAlertNotification(),
		watchEquipmentSingleData(),
		watchReportsData(),
		watchWorkReportsData(),
		watchForgetPassword(),
		watchSaga(),
		watchMembers(),
		watchProfile(),
		watchFIData(),
		watchApplication(),
		watchServiceEquipmentData(),
		watchEquipmentServiceHistory(),
		/** Admin Watch */
		watchLoginAdmin(),
		watchEquipmentAlertListData(),
		watchEquipmentEventsListData(),
		watchOwnerData(),
		watchScheduleServiceData(),
		watchUpdateScheduleServiceData(),
		watchAddSystemMaintenanceData(),
		watchUpdateSystemMaintenanceData(),
		watchGetEquipmentSupportData(),
		watchEquipmentList(),
		/** Service Provider Watch */
		watchLoginSrvprov(),
		watchSaveEquipmentSupportData(),
		watchUpdateEquipmentStatus(),
		watchSPEquipments(),
		watchSPMachine(),
		watchSPMachineLocation(),
		watchDC(),
		watchDealerEquipment(),
		watchRoles(),
		watchInstantReportData(),
		watchUpdateEquipmentService(),
		watchContractDetails(),
		watchHourmeterGap()
	]
}
