import { takeLatest, call, put } from 'redux-saga/effects';
import { getHourMeterGap, getHourMeterGapHistory } from '../../api/admin/hourmeter-offset-management';
import { SET_TOASTR, SET_LOADER, SET_HOURMETER_GAP, GET_HOURMETER_GAP, SET_HOURMETER_GAP_HISTORY, GET_HOURMETER_GAP_HISTORY } from '../../types';


export function* getHourMeterGapWithOffset(action) {
    try {
        const machineNum = action.machineNum;
        const model = action.model;
        const hourMeterGapDetail = yield call(getHourMeterGap, machineNum, model);
        yield put({ type: SET_HOURMETER_GAP, data: hourMeterGapDetail });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.",
                    type: "info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}

export function* getHourMeterGapWithHistory(action) {
    try {
        const machineNum = action.machineNum;
        const model = action.model;
        const hourMeterGapHistory = yield call(getHourMeterGapHistory, model, machineNum);
        yield put({ type: SET_HOURMETER_GAP_HISTORY, data: hourMeterGapHistory });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.",
                    type: "info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchHourmeterGap() {
    yield takeLatest(GET_HOURMETER_GAP, getHourMeterGapWithOffset)
    yield takeLatest(GET_HOURMETER_GAP_HISTORY, getHourMeterGapWithHistory)
}


