import {GET_FOTA_EQUIPMENT_LIST,GET_ALL_FOTA_EQUIPMENT_LIST} from '../../types'

/**
* Dispatch the activity listing action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/


export const getFotaEquipmentList = (data) => {
	return {
		type : GET_FOTA_EQUIPMENT_LIST,
		data
	}
}
export const getAllFotaEquipmentList = (data) => {
	return {
		type : GET_ALL_FOTA_EQUIPMENT_LIST,
		data
	}
}

