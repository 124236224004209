import React, { Component } from 'react';
import { connect } from 'react-redux';
import EquipmentsDetails from '../components/customer/equipment/EquipmentDetails';
import { updateEquipmentData, getEquipmentAlertSettingData, getEquipmentSingleData, getInstantReport } from '../actions/Equipment';
import { getTimeZoneLanguage } from "../actions/Application"; 
import { translate } from '../lang';
import { setToastr } from "../actions/Application";

class EquipmentDetailsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            equipmentData: [],
            filtered:{}
        };
    }

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount() {
        this.getDataFromServer(this.props)
    }

    getDataFromServer(props){
        props.getEquipmentAlertSettingData({ value: props.match.params.equipmentid});
        props.getEquipmentSingleData({id:props.match.params.equipmentid});
        props.getTimeZoneLanguage()
    }
    /**
    * Get the new props and set into state if they are not same.
    * @param  { Object } nextProps Contains the next props
    */
    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.equipmentid !== nextProps.match.params.equipmentid) {
            this.getDataFromServer(nextProps)
        }
    }


    handleClick(data) {
        this.props.updateEquipmentData(data);
        
    }

    refresh(id){
        this.props.getEquipmentSingleData({id:id});
    }

    render() {
        return (
            <EquipmentsDetails 
                handleClick = { this.handleClick.bind(this) }
                {...this.props }
                refresh = { this.refresh.bind(this) }
                translate = { translate }
                timezone = {this.props.timezone}
                setToastr = {this.props.setToastr}
            />
        );
    }
}



/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    let alertSettings;
    if (state.equipment.data && state.equipment.data.SET_EQUIPMENT_ALERT_SETTINGS_DATA && state.equipment.data.SET_EQUIPMENT_ALERT_SETTINGS_DATA.geofence) {
        alertSettings = state.equipment.data.SET_EQUIPMENT_ALERT_SETTINGS_DATA.geofence[0];
    }

    return {
        alertSettings: alertSettings,
        updateSuccess: state.equipment.data.SET_EQUIPMENT_UPDATE_DATA,
        integrationData : state.equipment.data,
        apifailed: state.equipment.FETCH_DATA_FAIL,
        app : state.app,
        timezone: (state.profile && state.profile.timezone) || "Asia/Kolkata"
    }
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    setToastr, updateEquipmentData, getEquipmentSingleData, getEquipmentAlertSettingData, getTimeZoneLanguage 
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentDetailsContainer);