import { SET_EQUIPMENT_DAILYWORK_DETAILS, SET_EQUIPMENT_OPERATINGINFO_DETAILS } from '../types'

/**
* Dispatch the activity listing action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/

export const setEquipmentDailywork = data => {
    return { type: SET_EQUIPMENT_DAILYWORK_DETAILS, data }
}

export const setEquipmentOperatingInfo = data => {
    return { type: SET_EQUIPMENT_OPERATINGINFO_DETAILS, data }
}
