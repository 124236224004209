import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditOwnerDetailsComponent from '../../components/admin/owners/EditOwnerDetailsComponent';
import { Link, withRouter } from "react-router-dom";
import { getOwnerEquipments, resetOwnerDetails } from "../../actions/admin/Owner";

class EditOwnerDetailContainer extends Component {

    componentDidMount() {
        let { userId } = this.props.match.params;
        if(userId) this.props.getOwnerEquipments({ userId })
        
    }

    componentWillUnmount(){
        this.props.resetOwnerDetails();
    }

    render(){
        return <EditOwnerDetailsComponent />;
    }
    /*render (){
        return(
            <EditOwnerDetailsComponent/>
        );
    }*/
}
const mapStateToProps = state => {  
    return {};
}


/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
     getOwnerEquipments, resetOwnerDetails
}
export default connect(mapStateToProps,mapDispatchToProps)(EditOwnerDetailContainer);