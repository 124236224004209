/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React from 'react';
import PropTypes from 'prop-types';
const InlineError =({ text }) => (
        <span style={{ color: "#bc1e05" }}>{text}</span>
        );


InlineError.propTypes = {
    text: PropTypes.string.isRequired
}; 
export default InlineError;

