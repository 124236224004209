import { SET_MEMBERS_DATA,SET_MEMBERS_LIST } from '../../types';

/** @type { Object } default state */
let defaultState = {
    users: undefined,
    memberFunctions:undefined
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function equipment(state = defaultState, action = {}) {

    switch (action.type) {
        case SET_MEMBERS_DATA:
            return { ...state, users: action.data };
        case SET_MEMBERS_LIST:
            return { ...state, memberFunctions: action.data };
        default: return state;
    }
}
