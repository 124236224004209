/**
List of types for the equipment
**/
export const GET_EQUIPMENTS_LIST_DATA = 'GET_EQUIPMENTS_LIST_DATA';
export const GET_INSTANT_REPORT_DATA = 'GET_INSTANT_REPORT_DATA';
export const SET_INSTANT_REPORT_DATA = 'SET_INSTANT_REPORT_DATA';
export const INVALID_DATA = 'INVALID_DATA';
export const SENDING_EQUIPMENT_DATA = 'SENDING_EQUIPMENT_DATA';
export const SET_EQUIPMENT_DATA = 'SET_EQUIPMENT_DATA';
export const FETCH_DATA_FAIL = 'FETCH_DATA_FAIL';
export const UPDATE_EQUIPMENTS_DATA = 'UPDATE_EQUIPMENTS_DATA';
export const SET_EQUIPMENT_UPDATE_DATA = 'SET_EQUIPMENT_UPDATE_DATA';
export const SENDING_EQUIPMENT_UPDATE_DATA = 'SENDING_EQUIPMENT_UPDATE_DATA';
export const GET_EQUIPMENTS_SETTINGS_ALERT = 'GET_EQUIPMENTS_SETTINGS_ALERT';
export const SENDING_EQUIPMENT_ALERT_SETTINGS_REQUEST = 'SENDING_EQUIPMENT_ALERT_SETTINGS_REQUEST';
export const SET_EQUIPMENT_ALERT_SETTINGS_DATA = 'SET_EQUIPMENT_ALERT_SETTINGS_DATA';
export const GET_EQUIPMENTS_SINGLE_DATA = 'GET_EQUIPMENTS_SINGLE_DATA';
export const SET_EQUIPMENT_SINGLE_DATA = 'SET_EQUIPMENT_SINGLE_DATA';
export const SENDING_EQUIPMENT_GET_DATA = 'SENDING_EQUIPMENT_GET_DATA';
export const GET_EQUIPMENTS_SERVICE_DATA = 'GET_EQUIPMENTS_SERVICE_DATA';
export const SET_EQUIPMENTS_SERVICE_DATA = 'SET_EQUIPMENTS_SERVICE_DATA';
export const SET_EQUIPMENTS_SERVICE_HISTORY = 'SET_EQUIPMENTS_SERVICE_HISTORY';
export const GET_EQUIPMENTS_SERVICE_HISTORY = 'GET_EQUIPMENTS_SERVICE_HISTORY';
/**
List of types for the login
**/

export const SET_USER_LOGGED_IN = 'SET_USER_LOGGED_IN';
export const INVALID_LOGIN = 'INVALID_LOGIN';
export const USER_FETCH_DATA_FAIL = 'USER_FETCH_DATA_FAIL';
export const SENDING_REQUEST = 'SENDING_REQUEST';
export const SET_LOGGED_IN_TOKEN = 'SET_LOGGED_IN_TOKEN';
export const GET_USER_LOGGED_IN = 'GET_USER_LOGGED_IN';
export const SET_AUTHENTICATE_CODE = 'SET_AUTHENTICATE_CODE';
export const GET_USER_LOGOUT = 'GET_USER_LOGOUT';

/** For the forget password */
export const GET_FORGET_PASSWORD = 'GET_FORGET_PASSWORD';
export const SET_FORGET_PASSWORD = 'SET_FORGET_PASSWORD';
export const SET_FORGET_PASSWORD_FAIL = 'SET_FORGET_PASSWORD_FAIL';
export const SENDING_FORGET_PASSWORD_REQUEST = 'SENDING_FORGET_PASSWORD_REQUEST';

/**
 * List of types for the Reports
 */

export const GET_REPORTS_DATA = 'GET_REPORTS_DATA';
export const SENDING_REPORTS_DATA = 'SENDING_REPORTS_DATA';
export const SET_REPORTS_DATA = 'SET_REPORTS_DATA';
export const GET_WORK_REPORTS_DATA = 'GET_WORK_REPORTS_DATA';

/** Daily Work */
export const SET_EQUIPMENT_DAILYWORK_DETAILS = 'SET_EQUIPMENT_DAILYWORK_DETAILS';
export const SET_EQUIPMENT_OPERATINGINFO_DETAILS = 'SET_EQUIPMENT_OPERATINGINFO_DETAILS';

/** App Level Types */
export const SET_LOADER = "SET_LOADER";
export const SET_TOASTR = "SET_TOASTR";
export const SET_CONFIRM_BOX = "SET_CONFIRM_BOX";
export const GET_TZ_LN = "GET_TZ_LN";
export const SET_TZ_LN = "SET_TZ_LN";
export const SET_BREADCRUMBS = "SET_BREADCRUMBS";

/** Members */
export const GET_MEMBERS = "GET_MEMBERS";
export const SET_MEMBERS_DATA = "SET_MEMBERS_DATA";
export const GET_MEMBERS_LIST = "GET_MEMBER_LIST";
export const SET_MEMBERS_LIST = "SET_MEMBER_LIST";

/** My Account */
export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';

/** Alert Notificatons */
export const GET_ALERTS_NOTIFICATIONS = 'GET_ALERTS_NOTIFICATIONS';
export const SENDING_ALERT_NOTIFICATION_DATA = 'SENDING_ALERT_NOTIFICATION_DATA';
export const SET_ALERT_NOTIFICATION_DATA = 'SET_ALERT_NOTIFICATION_DATA';

/* Field Information */
export const GET_FIELDINFORMATION_DATA = 'GET_FIELDINFORMATION_DATA';
export const SET_MAP_DATA = 'SET_MAP_DATA';
export const AREA_DATA = 'AREA_DATA';
export const VERTICES_DATA = 'VERTICES_DATA';
export const AGRICULTURAL_DATA = 'AGRICULTURAL_DATA';

/* Alert Setting */
export const GET_ALERT_DATA = 'GET_ALERT_DATA';
export const SAVE_ALERT_DATA = 'SAVE_ALERT_DATA';
export const DISPLAY_ALERT_DATA = 'DISPLAY_ALERT_DATA';
export const SET_SAR_OBJ = 'SET_SAR_OBJ';
export const SET_ALERT_DATA = 'SET_ALERT_DATA';

/* Location Information */
export const GET_LOCATION_DATA = 'GET_LOCATION_DATA';
export const GET_BOUND_DATA = 'GET_BOUND_DATA';
export const RESET_BOUND = 'RESET_BOUND';
export const UPDATE_ALERT_READ_STATUS = 'UPDATE_ALERT_READ_STATUS';
export const UPDATE_SCHEDULESYS_ALERT_READ_STATUS = 'UPDATE_SCHEDULESYS_ALERT_READ_STATUS';

/* Equipments Alert Data */
export const SENDING_EQUIPMENT_ALERT_LIST_DATA = 'SENDING_EQUIPMENT_ALERT_LIST_DATA';
export const GET_EQUIPMENTS_ALERT_DATA = 'GET_EQUIPMENTS_ALERT_DATA';
export const SET_EQUIPMENT_ALERT_LIST_DATA = 'SET_EQUIPMENT_ALERT_LIST_DATA';
export const SENDING_EQUIPMENT_EVENTS_LIST_DATA = 'SENDING_EQUIPMENT_EVENTS_LIST_DATA';
export const GET_EQUIPMENTS_EVENTS_DATA = 'GET_EQUIPMENTS_EVENTS_DATA';
export const SET_EQUIPMENT_EVENTS_LIST_DATA = 'SET_EQUIPMENT_EVENTS_LIST_DATA';
export const GET_INFO_BOX_DATA = 'GET_INFO_BOX_DATA';


/**
 * ////// ADMIN MODULE TYPES ///////
 */
/** LOGIN */
export const GET_ADMIN_LOGGED_IN = 'GET_ADMIN_LOGGED_IN';
export const SET_ADMIN_LOGGED_IN = 'SET_ADMIN_LOGGED_IN';
export const SET_ADMIN_LOGGED_IN_TOKEN = 'SET_ADMIN_LOGGED_IN_TOKEN';

/* Recent Event */

export const GET_EVENT_TOKEN_DATA = 'GET_EVENT_TOKEN_DATA';

/** Owner Module */
export const GET_OWNERS = 'GET_OWNERS';
export const SET_OWNERS = 'SET_OWNERS';
export const GET_OWNER_EQUIPMENTS = 'GET_OWNER_EQUIPMENTS';
export const SET_OWNER_EQUIPMENTS = 'SET_OWNER_EQUIPMENTS';
export const RESET_OWNER_DETAILS = 'RESET_OWNER_DETAILS';
export const GET_OWNER_DETAIL = 'GET_OWNER_DETAIL';
export const SET_OWNER_DETAIL = 'SET_OWNER_DETAIL';

export const SET_OWNER_DATA_ORDER = 'SET_OWNER_DATA_ORDER';
/** Schedule Service*/
export const GET_SCHEDULE_SERVICE_ALERT_DATA = 'GET_SCHEDULE_SERVICE_ALERT_DATA';
export const SET_SCHEDULE_SERVICE_ALERT_DATA = 'SET_SCHEDULE_SERVICE_ALERT_DATA';
export const SENDING_SCHEDULE_SERVICE_ALERT_DATA = 'SENDING_SCHEDULE_SERVICE_ALERT_DATA';
export const UPDATE_SCHEDULE_SERVICE_ALERT_DATA = 'UPDATE_SCHEDULE_SERVICE_ALERT_DATA';
export const SENDING_UPDATE_SCHEDULE_SERVICE_ALERT_DATA = 'SENDING_UPDATE_SCHEDULE_SERVICE_ALERT_DATA';
export const SET_UPDATE_SCHEDULE_SERVICE_ALERT_DATA = 'SET_UPDATE_SCHEDULE_SERVICE_ALERT_DATA';

/** System Maintainance */
export const SENDING_ADD_MAINTAIN_DATA = 'SENDING_ADD_MAINTAIN_DATA';
export const SET_ADD_MAINTAIN_DATA = 'SET_ADD_MAINTAIN_DATA';
export const UNSET_ADD_MAINTAIN_DATA = 'UNSET_ADD_MAINTAIN_DATA';
export const ADD_SYS_MAINTAIN_DATA = 'ADD_SYS_MAINTAIN_DATA';
export const UPDATE_SYS_MAINTAIN_DATA = 'UPDATE_SYS_MAINTAIN_DATA';
export const SENDING_UPDATE_MAINTAIN_DATA = 'SENDING_UPDATE_MAINTAIN_DATA';
export const SET_UPDATE_MAINTAIN_DATA = 'SET_UPDATE_MAINTAIN_DATA';

/** System Maintainance support data */
export const GET_EQUIPMENTS_SUPPORT_DATA = 'GET_EQUIPMENTS_SUPPORT_DATA';
export const SET_EQUIPMENTS_SUPPORT_DATA = 'SET_EQUIPMENTS_SUPPORT_DATA';
export const SENDING_EQUIPMENTS_SUPPORT_DATA = 'SENDING_EQUIPMENTS_SUPPORT_DATA';
export const SHOW_REALTIME_ALERT = 'SHOW_REALTIME_ALERT';
export const BELL_TOGGLE = 'BELL_TOGGLE';

/** Manage Service Data */
export const GET_MANAGE_SERVICE_DATA = 'GET_MANAGE_SERVICE_DATA';
export const GET_MANAGE_SERVICE_EQUIPMENT_LIST = 'GET_MANAGE_SERVICE_EQUIPMENT_LIST';
export const SET_MANAGE_SERVICE_EQUIPMENT_LIST = 'SET_MANAGE_SERVICE_EQUIPMENT_LIST';

/** Data Control */
export const GET_DC = 'GET_DC';
export const SET_DC = 'SET_DC';

/** Forgot Password */
export const GET_ADMIN_FORGET_PASSWORD = 'GET_ADMIN_FORGET_PASSWORD';

/* Roles */
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export const GET_ROLES = 'GET_ROLES';
export const SET_ROLES = 'SET_ROLES';
export const SET_ROLES_LIST = 'SET_ROLES_LIST';

/** Service provider types */
export const GET_SP_EQUIPMENTS = 'GET_SP_EQUIPMENTS';
export const SET_SP_EQUIPMENTS = 'SET_SP_EQUIPMENTS';
export const SET_SP_PROFILE = 'SET_SP_PROFILE';
export const GET_ALL_EQUIPMENTS = 'GET_ALL_EQUIPMENTS';
export const SET_ALL_EQUIPMENTS = 'SET_ALL_EQUIPMENTS';
export const GET_ALL_EQUIPMENTS_LOCATION = 'GET_ALL_EQUIPMENTS_LOCATION';
export const SET_ALL_EQUIPMENTS_LOCATION = 'SET_ALL_EQUIPMENTS_LOCATION';

/** Login */
export const GET_SRVPROV_LOGGED_IN = 'GET_SRVPROV_LOGGED_IN';
export const SET_SRVPROV_LOGGED_IN_TOKEN = 'SET_SRVPROV_LOGGED_IN_TOKEN';

export const SAVE_EQUIPMENTS_SUPPORT_DATA = 'SAVE_EQUIPMENTS_SUPPORT_DATA';
export const SENDING_EQUIPMENTS_SUPPORT_INFO_DATA = 'SENDING_EQUIPMENTS_SUPPORT_INFO_DATA';
export const SET_EQUIPMENTS_SUPPORT_INFO_DATA = 'SET_EQUIPMENTS_SUPPORT_INFO_DATA';
export const UPDATE_EQUIPMENTS_STATUS_DATA = 'UPDATE_EQUIPMENTS_STATUS_DATA';

/** Dealer Management */
export const GET_DEALER_EQUIPMENT_LIST = 'GET_DEALER_EQUIPMENT_LIST';
export const SET_DEALER_EQUIPMENT_LIST = 'SET_DEALER_EQUIPMENT_LIST';
export const GET_DEALERS = 'GET_DEALERS';
export const SET_DEALERS = 'SET_DEALERS';

/** Service Info */
export const UPDATE_EQUIPMENTS_SERVICE_DATA = 'UPDATE_EQUIPMENTS_SERVICE_DATA';
export const SET_EQUIPMENT_SERVICE_INFO = 'SET_EQUIPMENT_SERVICE_INFO';

/** FOTA */
export const SET_FOTA_EQUIPMENT_LIST = 'SET_FOTA_EQUIPMENT_LIST';
export const GET_FOTA_EQUIPMENT_LIST = 'GET_FOTA_EQUIPMENT_LIST';
export const GET_ALL_FOTA_EQUIPMENT_LIST = 'GET_ALL_FOTA_EQUIPMENT_LIST';
export const SET_ALL_FOTA_EQUIPMENT_LIST = 'SET_ALL_FOTA_EQUIPMENT_LIST';
/** Custom Map */
export const SET_CUSTOM_MAP_EQUIPMENT_LIST = 'SET_CUSTOM_MAP_EQUIPMENT_LIST';
export const GET_CUSTOM_MAP_EQUIPMENT_LIST = 'GET_CUSTOM_MAP_EQUIPMENT_LIST';
export const GET_SAVED_CUSTOM_MAP_EQUIPMENT_LIST = 'GET_SAVED_CUSTOM_MAP_EQUIPMENT_LIST';
export const SET_SAVED_CUSTOM_MAP_EQUIPMENT_LIST = 'SET_SAVED_CUSTOM_MAP_EQUIPMENT_LIST';

/** Contract Management */
export const GET_CONTRACT_DETAILS = "GET_CONTRACT_DETAILS";
export const SET_CONTRACT_DETAILS = "SET_CONTRACT_DETAILS";
export const GET_RECENT_CONTRACT = "GET_RECENT_CONTRACT";
export const SET_RECENT_CONTRACT = "SET_RECENT_CONTRACT";
export const SET_COMPANY_STATUS_LIST = "SET_COMPANY_STATUS_LIST";
export const GET_COMPANY_STATUS_LIST = "GET_COMPANY_STATUS_LIST";

/*Hourmeter Offset Management*/
export const SET_HOURMETER_GAP = "SET_HOURMETER_GAP";
export const GET_HOURMETER_GAP = "GET_HOURMETER_GAP";

export const SET_HOURMETER_GAP_HISTORY = "SET_HOURMETER_GAP_HISTORY";
export const GET_HOURMETER_GAP_HISTORY = "GET_HOURMETER_GAP_HISTORY";
