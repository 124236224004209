import React from 'react';
import { connect } from "react-redux";
import Profile from '../../components/service-provider/profile/Profile';
const MyProfileContainer = ({profile}) => {
    return <Profile profile={profile} />;
}

const mapStateToProps = state => {
    return({ profile: state.spProfile.profile});
}

export default connect(mapStateToProps)(MyProfileContainer);