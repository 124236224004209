import React from "react";
import { CustomInput } from "reactstrap";
import DatePicker from 'react-datepicker';
import moment from "moment";

export const renderTextField = ({
    input,
    label,
    meta: { touched, error, warning, asyncValidating },
    ...custom
}) => (
        <div>
            <input
                className={asyncValidating ? 'form-control async-validating' : 'form-control'}
                {...input}
                {...custom}
            />
            {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span className="warning">{warning}</span>))}
        </div>
    );


const adaptPickerEventToValue = delegate => async e => {
    if(e){
        return delegate(moment(e).format("YYYY-MM-DD"));
    }
};
    

export const renderDatepicker = ({
    input,
    label,
    meta: { touched, error, warning },
    ...custom
}) => {
    return(
        <div>
            <DatePicker {...input} {...custom} onChange={adaptPickerEventToValue(input.onChange)} />
            {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span className="warning">{warning}</span>))}
        </div>
    )
};

        
export const renderSelectField = ({ input, id, meta: { touched, error, warning }, children, ...custom }) => (
    <div>
        <select {...input} {...custom} id={id || "selectbox"} className='form-control no-padded'>
            {children}
        </select>
        {touched &&
            ((error && <span className="error">{error}</span>) ||
                (warning && <span className="warning">{warning}</span>))}
    </div>
    )

export const renderCustomField = ({
        input,
        label,
        meta: { touched, error, warning, asyncValidating },
        ...custom
    }) => (
            <div>
                <CustomInput
                    className='form-control'
                    {...input}
                    {...custom}
                />
                {touched &&
                    ((error && <span className="error">{error}</span>) ||
                        (warning && <span className="warning">{warning}</span>))}
            </div>
        );


const adaptFileEventToValue = delegate => async e => {
    let i = await getBase64(e.target.files[0]);
    if (i) return delegate(i);
};

export const getBase64 = (file, cb) => {
    return new Promise((resolve, reject) => {
        if (!file) reject();
        
        /** resovle image */
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = error => reject('Error: ', error);
    });
}

export const FileInput = ({
    input: { value: omitValue, onChange, onBlur, ...inputProps },
    meta: omitMeta,
    ...props
}) => {
    return (
        <input
            onChange={adaptFileEventToValue(onChange)}
            /* onBlur={adaptFileEventToValue(onBlur)} */
            type="file"
            {...props.input}
            {...props}
        />
    );
};