import { SET_FOTA_EQUIPMENT_LIST,SET_ALL_FOTA_EQUIPMENT_LIST } from '../../types';

/** @type { Object } default state */
let defaultState = {
    data: {
    }
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function fota(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_FOTA_EQUIPMENT_LIST:
            console.log("++++++++++++ SET_DEALER_EQUIPMENT_LIST  +++++")
            return { ...state, SearchCustomMapEqList:action.data};
        case SET_ALL_FOTA_EQUIPMENT_LIST:
            console.log("++++++++++++ SET_DEALER_EQUIPMENT_LIST  +++++")
            return { ...state, newCustomMapEqList:action.data}; 
        default: return state;
    }
}
