import { GET_MEMBERS,GET_MEMBERS_LIST } from '../types';

/**
 * Dispatch the login action to the middleware
 * @param  { Object } data Date to be processed
 * @return { Object }      Dispatch the action type
 */
export const getMembers = data => {
    return {
        type: GET_MEMBERS,
        data
    }
}
export const getMemberList = data => {
        return {
            type: GET_MEMBERS_LIST,
            data
        }
}