import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, change } from "redux-form";
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import "./style.scss";
import { translate } from '../../../lang';

class ServiceCheckComponent_constrcution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceItems: [],
            checkedService: []

        }
        this.processData = this.processData.bind(this);
        this.closeModal = this.closeModal.bind(this)
        this.genearteTableHeading= this.genearteTableHeading.bind(this);
        this.genearteTableBody = this.genearteTableBody.bind(this);
    }

    saveModalData() {
        let serviceItems = this.state.serviceItems;
        let saveData = Array.from(serviceItems);
        this.setState({ serviceItems: []})
        this.props.saveData(saveData);
    }
    clickCheckBox(e) {
        const item = e.target.name;
        const service = e.target.id;
        const checked = e.target.checked;
        let  serviceItems  = this.props.serviceCheckList;
        console.log('serviceItems',serviceItems);
        let { data } = this.props;
        let obj;
        let serviceList = [];
        const elementsIndex = serviceItems.findIndex(element => element.item == item)
        if (elementsIndex > -1) {
            if (checked) {
                serviceItems[elementsIndex].service.push(service);
            }
            else {
                let index = serviceItems[elementsIndex].service.findIndex(obj => obj === service);
                serviceItems[elementsIndex].service.splice(index, 1);
            }

        } else {
            serviceList.push(service);
            obj = {
                item: item,
                service: (serviceList)
            }
            serviceItems.push(obj);
        }
        this.setState({serviceItems});
       this.processData(data, serviceItems);
    }
    processData(data, CheckList) {

        let checkedService = [];
        if (CheckList != null && CheckList.length > 0) {
            data.map(el => {
                const elementsIndex = CheckList.findIndex(element => element.item == el)
                if (elementsIndex > -1) {
                    // item is present in service list
                    checkedService.push(CheckList[elementsIndex].service);
                } else {
                    checkedService.push('');
                }

            });
            this.setState({ checkedService });

        } else {
            this.setState({ checkedService: [] });
        }
        console.log("processing end   " + JSON.stringify(this.props.serviceCheckList))
    }
    closeModal() {
        if(this.props.readonly)
        this.setState({ serviceItems: []})
        else
        this.setState({ serviceItems: this.props.serviceCheckList})
        this.props.toggle();
    }
    genearteTableBody(group){
        let { data,readonly} = this.props;
        let {checkedService } = this.state;
        let checkbox1 ='Replace'
        let checkbox2 = group == 'CE'?'Supply':group == 'CH'?'Rotation':'Clean';
        let checkbox3 = group == 'CE'?'Adjust(Clean)':'Check';
        let checkbox4 = group == 'CE'?'Oil and Grease':'Adjust';
        
                const body = data.length > 0 && data.map((item, i) => (
            <tr key={i + 1}>
                <td className='firstRow'>{item}</td>
                <td>
                    <FormGroup className="error-field">
                        <div className="checkbox-group">
                            <label>
                                <input type="checkbox"  disabled = {readonly} onChange={this.clickCheckBox.bind(this)} name={item} id={checkbox1} checked={checkedService.length > 0 && checkedService[i].indexOf(checkbox1) > -1} />
                                <span className='checkmark'></span></label>
                        </div>
                    </FormGroup>
                </td>
                <td><FormGroup className="error-field ">
                    <div className="checkbox-group">
                        <label>
                            <input type="checkbox"  disabled = {readonly} onChange={this.clickCheckBox.bind(this)} name={item} id={checkbox2} checked={checkedService.length > 0 && checkedService[i].indexOf(checkbox2) > -1} />
                            <span className='checkmark'></span></label>
                    </div>
                </FormGroup></td>
                <td>
                    <FormGroup className="error-field">
                        <div className="checkbox-group">
                            <label>
                                <input type="checkbox" disabled = {readonly} onChange={this.clickCheckBox.bind(this)} name={item} id={checkbox3} checked={checkedService.length > 0 && checkedService[i].indexOf(checkbox3) > -1} />
                                <span className='checkmark'></span></label>
                        </div>
                    </FormGroup>
                </td>
                <td>
                    <FormGroup className="error-field">
                        <div className="checkbox-group">
                            <label>
                                <input type="checkbox"  disabled = {readonly} onChange={this.clickCheckBox.bind(this)} name={item} id={checkbox4} checked={checkedService.length > 0 && checkedService[i].indexOf(checkbox4) > -1} />
                                <span className='checkmark'></span></label>
                        </div>
                    </FormGroup>
                </td>
            </tr>
        ))

        return body;
    }

    genearteTableHeading(group){
        let header;
        if(group== 'CE'){
             header = <Fragment><th className='firstRow'>{translate('item')}</th>
            <th>{translate('replace')}</th>
            <th>{translate('supply')}</th>
            <th>{translate('adjustClean')}</th>
            <th>{translate('oilAndGrease')}</th></Fragment>
        }else if(group== 'CH'){
             header = <Fragment><th className='firstRow'>{translate('item')}</th>
            <th>{translate('replace')}</th>
            <th>{translate('rotation')}</th>
            <th>{translate('check')}</th>
            <th>{translate('adjust')}</th></Fragment>
        }else{
             header = <Fragment><th className='firstRow'>{translate('item')}</th>
            <th>{translate('replace')}</th>
            <th>{translate('clean')}</th>
            <th>{translate('check')}</th>
            <th>{translate('adjust')}</th></Fragment>
        }
        return header;
    }
    render() {
        let { data, serviceCheckList,readonly} = this.props;
        let {checkedService } = this.state;

        
        return (
            <div >
                <Modal size='lg' isOpen={this.props.modal} toggle={this.props.toggle} className='modalClass'>
                    <ModalHeader toggle={this.closeModal}>{translate('serviceCheckList')}</ModalHeader>
                    <ModalBody >
                        <form name="ChecklistForm" id="ChecklistForm"  className='equipmentInfo'/* autoComplete='off'  *//* onSubmit={handleSubmit(this.onSubmit)} */>
                            {data && <div class="tableFixHead"><table className="tabledashboard table table-striped table-advance table-hover " >
                                <thead>
                                    <tr>
                                         {this.genearteTableHeading(this.props.group)}
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.genearteTableBody(this.props.group)}
                                    

                                    {!(data).length && <tr><td align="center" colSpan="12">No Service Item Found</td></tr>}

                                </tbody>
                            </table>
                            </div>}

                        </form>
                    </ModalBody>
                    <ModalFooter>
                      { (!readonly) && <Button color="primary"   onClick={this.saveModalData.bind(this)}>Save</Button>}
                        <Button color="secondary" onClick={this.closeModal}>{translate('close')}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.serviceCheckList!=this.props.serviceCheckList) {
            this.processData(nextProps.data, nextProps.serviceCheckList);
        }
    }
}
export default withRouter(ServiceCheckComponent_constrcution);
