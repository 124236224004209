import { GET_ALERTS_NOTIFICATIONS, UPDATE_ALERT_READ_STATUS, UPDATE_SCHEDULESYS_ALERT_READ_STATUS } from '../types';

/**
 * Dispatch the Alerts Notifications actions to the middleware
 * @param  { Object } data Date to be processed
 * @return { Object }      Dispatch the action type
 */
export const getAlertNotifications = (data) => {
	return {
		type: GET_ALERTS_NOTIFICATIONS,
		data
	}
}

export const saveAlertReadStatus = (data) => {
	return {
		type : UPDATE_ALERT_READ_STATUS,
		data
	}
}

export const saveScheduleSysAlert = (data) => {
	return {
		type : UPDATE_SCHEDULESYS_ALERT_READ_STATUS,
		data
	}
}