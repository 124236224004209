import React, { Component } from 'react';
import {/*FormGroup*/ Input } from 'reactstrap'

class SelectField extends Component {
    
    /**
     * Get the changed input and provide to parent.
     * @param  { Object } e Get the events
     */
    handleChange(e) {
	    this.props.handleChange(e)
	} 

    render() {
        const{ type, name, placeholder, id, value, options, divClass, 
                defaultOption, disabled, className, readOnly } = this.props;
		/** Check the default option */
		if ( defaultOption !== null && defaultOption !== '' ){
				var getDefaultOption = <option value="null"> { defaultOption } </option> 
		}
        return (
                <div className={ divClass }>
                        <Input 
                        	value={ value }
                        	id={ id }
                        	type={ type }
                        	name={ name }
                            disabled={ disabled }
                            readOnly={ readOnly }
                        	placeholder={ placeholder }
                            className={ className }
                        	onChange={ this.handleChange.bind(this) } >
							{ getDefaultOption }
                            { options }
                        </Input>
                    </div>
        );
    }
}

export default SelectField;
