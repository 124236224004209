import http from "../services/http";
import { __api_getProfile, __api_updateProfile, __api_resetPassword, __api_changePassword } from "./endpoints";

export const getProfile = () => {
    return http.Request("GET", __api_getProfile);
}

export const updateProfile = data => {
    return http.Request("POST", __api_updateProfile, data);
}

export const resetPassword = id => {
    return http.Request("GET", __api_resetPassword + "/" + id);
}

export const changePassword = data => {
    return http.Request("POST", __api_changePassword, data);
}
