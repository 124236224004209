import { takeLatest, call, put } from 'redux-saga/effects';
import { getContractDetail,getcontractCount,getCompanyAndStatus} from '../../api/admin/contract-management';

import { SET_TOASTR, SET_LOADER, GET_CONTRACT_DETAILS,SET_CONTRACT_DETAILS,SET_RECENT_CONTRACT,GET_RECENT_CONTRACT,SET_COMPANY_STATUS_LIST,GET_COMPANY_STATUS_LIST } from '../../types';



export function* getContractDetails(action) {

    const data = action.data && action.data.machineId;

    try {
        const contractDetail = yield call(getContractDetail, data);

        yield put({ type: SET_CONTRACT_DETAILS, data: contractDetail });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}
export function* getRecenetContract(acton) {
    try {
        const contractCountDetail = yield call(getcontractCount);
        yield put({ type: SET_RECENT_CONTRACT, data: contractCountDetail });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}

export function* getCompanyAndStatusList(acton) {
    try {
        const CompanyAndStatusDetail = yield call(getCompanyAndStatus);
        yield put({ type: SET_COMPANY_STATUS_LIST, data: CompanyAndStatusDetail });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchContractDetails() {
    yield takeLatest(GET_CONTRACT_DETAILS, getContractDetails)
    yield takeLatest(GET_RECENT_CONTRACT,getRecenetContract)
    yield takeLatest(GET_COMPANY_STATUS_LIST,getCompanyAndStatusList)


}


