import store from '../store/config';
import cookie from 'react-cookies';
import {SET_LOGGED_IN_TOKEN, SET_ADMIN_LOGGED_IN_TOKEN, SET_SRVPROV_LOGGED_IN_TOKEN, GET_EQUIPMENTS_EVENTS_DATA } from '../types';
import { RealTimeAlertCall } from "../constants/Constants";
import session from "../services/session";

export const storageTokenLogin = ()=>{
    if (localStorage.getItem('TOKEN')) {
        const token = { token: localStorage.getItem('TOKEN') }
        store.dispatch({
            type: SET_LOGGED_IN_TOKEN,
            token: token,
            isAutheticated: true
        })
    }

    if (session.get('ADMINTOKEN', 'cookie')) {
        const token = { token: session.get('ADMINTOKEN', 'cookie') }
        store.dispatch({
            type: SET_ADMIN_LOGGED_IN_TOKEN,
            token: token,
            isAdminAutheticated: true
        })
        if(window.location.pathname.split('/')[1] === 'admin'){
            setInterval(() => store.dispatch({ type: GET_EQUIPMENTS_EVENTS_DATA }),  RealTimeAlertCall);
        }
    }

    if (session.get('SERVPROVTOKEN', 'cookie')) {
        const token = { token: session.get('SERVPROVTOKEN', 'cookie') }
        store.dispatch({
            type: SET_SRVPROV_LOGGED_IN_TOKEN,
            token: token,
            isSrvprovAuthenticated: true
        })
    }
}



export const cookieLogin = ()=>{
    if (cookie.load('user_activity') - 0) {
        let date = new Date();
        date.setTime(date.getTime() + (30 * 60 * 1000));
        cookie.save('user_activity', 1, { path: '/',  expires: date})
    } else if (cookie.load('admin_activity') - 0) {
        let date = new Date();
        date.setTime(date.getTime() + (30 * 60 * 1000));
        cookie.save('admin_activity', 1, { path: '/',  expires: date})
    } else if (cookie.load('serv_prov_activity') - 0) {
        let date = new Date();
        date.setTime(date.getTime() + (30 * 60 * 1000));
        cookie.save('serv_prov_activity', 1, { path: '/',  expires: date})
    } else {
        localStorage.clear();
    }
}