import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import { Modal, ModalBody } from "reactstrap";
import ReactLoading from "react-loading";
import './style.scss';

//import loadr from "../../assets/images/loadr.gif";
/** A dump loader message component */
class Loader extends Component {

    render() {
        const { open, loadingText, color, className } = this.props.loader;

        //console.log(this.props, open);
        return (
            <Fragment>
                {/* <div className='overlayer'>
                    <div>
                        <img src={loadr} className='auto-center'/> 
                        <span className='auto-center text-center'>{loadingText || ""}</span>
                    </div>
                </div> */}

                <Modal isOpen={open} className="loader-process" >
                    <ModalBody>
                        <div className="processing">
                            <ReactLoading
                                type={`spinningBubbles`}
                                color={color || '#08a5e1'}
                                className={className || 'loading-contents'}
                            />
                            <h5>{loadingText || 'Please Wait...'}</h5>
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return ({
        loader: state.app.loader
    });
}

export default connect(mapStateToProps)(Loader);
