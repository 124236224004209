import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router'
import { Provider } from 'react-redux';
import store from './store/config';
import Routes from './routes/route';
import './style.scss';
import registerServiceWorker from './registerServiceWorker';
import {storageTokenLogin,cookieLogin} from './utility';

import 'react-app-polyfill/ie9';

cookieLogin();
storageTokenLogin();

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <Route component={ Routes }/>
        </Provider>
    </BrowserRouter>, 
document.getElementById('root'));
registerServiceWorker();
