import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContractRenew from '../../components/service-provider/equipments/ContractRenew';
import {getContractDetail} from '../../actions/admin/contractManagement';

 class ContractRenewContainer extends Component {

    componentWillMount() {
        let { machineId } = this.props.match.params;

        if(machineId) this.props.getContractDetail({ machineId })
     
    }


    render() {
        let {contractDetails} = this.props;
        return(
            <ContractRenew contractDetails={contractDetails}/>

        );
        
}
}
const mapStateToProps = state => {  
    let contractDetails = state.contractManagement && state.contractManagement.contractDetails && state.contractManagement.contractDetails.data;
    return { contractDetails};
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getContractDetail
}
export default connect(mapStateToProps, mapDispatchToProps)(ContractRenewContainer);