import { GET_BOUND_DATA, GET_INFO_BOX_DATA, RESET_BOUND } from '../../types';

/** @type { Object } default state */
let defaultState = {
    boundData: undefined,
    infoBox: undefined,
}
/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function locationInformation(state = defaultState, action = {}) {
    switch (action.type) {
        case GET_BOUND_DATA:
            return { ...state, boundData: action.data };
        
        case GET_INFO_BOX_DATA:
            return { ...state, infoBox: action.data };

        case RESET_BOUND:
            return defaultState;

        default: return state;
    }
}
