import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { UPDATE_SCHEDULESYS_ALERT_READ_STATUS, FETCH_DATA_FAIL } from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* saveSchSysAlertNotiRead(action) {
  const data = action.data;
  try {
    yield call(api.alertNotify.saveScheduleSysAlertStatus, data);
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: e.message
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchSaveSchSysAlertNotification() {
  yield takeLatest(UPDATE_SCHEDULESYS_ALERT_READ_STATUS, saveSchSysAlertNotiRead)
}
