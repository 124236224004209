import { GET_SCHEDULE_SERVICE_ALERT_DATA, UPDATE_SCHEDULE_SERVICE_ALERT_DATA } from '../types'

/**
* Dispatch the Schedule Service action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/

export const getScheduleServiceAlert = (data) => {
	return {
		type : GET_SCHEDULE_SERVICE_ALERT_DATA,
		data
	}
}

export const updateScheduleServiceAlert = (data) => {
	return {
		type : UPDATE_SCHEDULE_SERVICE_ALERT_DATA,
		data
	}
}