import React, { Component, Fragment } from 'react';
import { Card, CardBody, Alert} from 'reactstrap';
import {withRouter} from "react-router-dom";
import loader from '../../../assets/images/wait.gif';
import { SSO } from "../../../constants/Constants";
import SearchParams from 'url-search-params';
/** An login component */
class HybridLoginComponent extends Component {

	constructor(props){
		super(props);
console.log("***********HybridLoginComponent*******");
		this.state = {
			action: "login"
		}

		this.processLoginRequest = this.processLoginRequest.bind(this);
	}

	componentWillMount(){
		let {search} = this.props.location;
		const params = new SearchParams(search); 
		
		const action = params.get('action');
		
		if(action) this.setState({action});
	}

	componentDidMount(){
		this.processLoginRequest();
	}

	processLoginRequest(){
		let {action} = this.state;
		
		if(action==='login'){
			let module = "ADMIN";
			if(this.props.location.pathname && this.props.location.pathname.toLowerCase().indexOf("service-provider") >= 0){
				module = "SERVICE PROVIDER";
			}
			if(this.props.location.pathname && this.props.location.pathname.toLowerCase().indexOf("service") >= 0){
				module = "SERVICE";
			}
			console.log('module',module);
			
			window.location.assign(`${SSO}&module=${module}`);
		}else{
			if(!["fail", "logout", "login"].includes(action)){
				this.setState({action: "fail"});
			}
		}
	}

	render() {
		const {action} = this.state;

		return (
                	<div className="view loginView">
				<div className="box-login">
					<Card className="mb-3 form-card">
						<CardBody>
							
								{action === 'login' &&  
									<Fragment>
										<center>
											<img src={loader} width="150" alt='loader' />
											<p>Please wait! While we're redirecting you...</p>
										</center>
									</Fragment>
								}

								{
									action === 'logout' && 
									<Fragment>
										<Alert> You've been logout successfully. </Alert>
										<center>
											<span className='pointer login-link' onClick={() => {this.setState({action: "login"}, () => this.processLoginRequest()); }}>Login with same account!</span>
											

											
										</center>
									</Fragment>
								}

{
									action === 'fail' && 
									<Fragment>
										<Alert color="danger"> You don't have sufficient permission to login into dashboard. </Alert>
										<center>
											<span className='pointer login-link' onClick={() => {this.setState({action: "login"}, () => this.processLoginRequest()); }}>Click here to login!</span>
										</center>
									</Fragment>
								}

						</CardBody>
					</Card>
				</div>
			</div>
		);
	}
}

export default withRouter(HybridLoginComponent);