import { takeLatest, call, put } from 'redux-saga/effects';
import { getSettings } from '../../api/application';
import { GET_TZ_LN, SET_TZ_LN } from '../../types';


export function* getSettingsCall(action) {
    try {
        const settings = yield call(getSettings, action);
        yield put({ type: SET_TZ_LN, data: settings.data || undefined });
    }
    catch (e) {
        //action.error(e);
    }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchProfileData() {
    yield takeLatest(GET_TZ_LN, getSettingsCall)
}
