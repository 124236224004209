import React, { Component } from 'react';
import DataSheet from './datasheet/DataSheet';
import './style.scss';
import { Col, Table } from "reactstrap";
import {Link} from 'react-router-dom';
import {FaChartArea } from 'react-icons/fa'
import chart from "../../../assets/images/chart.jpg"
import timeico from "../../../assets/images/timeico.png"
import mapmarker from "../../../assets/images/mapmarker.png"

/* A Dashboard component */
class DashboardComponent extends Component {
    render() {
        const { translate } = this.props;
       // console.log(JSON.stringify(translate) + "translate")
        return (

            <div>
                <Col sm="8" className='eq-holder'>
                    <p className='view-all'><Link to="/customer/equipment">view all</Link></p>
                    <div className='bodded'>    
                        <DataSheet timezone={this.props.timezone} equipment={this.props.dashdata} apiError = { this.props.apiError } markAsRead = {this.props.markAsRead} translate = { translate } />
                    </div>
                </Col>

                <Col sm="4" className='eq-holder holdright'>
                    <div className='left-box'>
                        <h3 class="header-text" id="header-text">Manage Reports</h3>
                        <div className='manage-reports'>
                            <Col className='float-left' sm="4">
                                <img src={chart} />
                            </Col>

                            <Col className='float-left' sm="8">
                                <ul className='reports-name'>
                                    <li>Work Report</li>
                                    <li>Utilization Report</li>
                                    <li>Error Report</li>
                                </ul>
                            </Col>
                        </div>
                    </div>


                    <div className='left-box'>
                        <h3 class="header-text" id="header-text">Alert Settings</h3>
                        <div className='setting-box'>
                            <Col className='float-left' sm="3">
                                <img src={mapmarker} alt="map-marker" />
                            </Col>

                            <Col className='float-left pad-0' sm="9">
                                <h3 class="header-text" id="header-text">GEO-Fencing</h3>
                                <small><em>create and manage geographic fencing</em></small>
                            </Col>
                        </div>

                        <div className='setting-box'>
                            <Col className='float-left' sm="3">
                                <img src={timeico} alt='time-ico' />
                            </Col>

                            <Col className='float-left pad-0' sm="9">
                                <h3 class="header-text" id="header-text">Operating Time</h3>
                                <small><em>manage equipment operating hours</em></small>
                            </Col>
                        </div>
                    </div>


                    <div className='left-box'>
                        <h3 class="header-text" id="header-text">
                            My Members

                            <span className='h3-links'>
                                <Link to={"/customer/members/add"}>add new member</Link> | <Link to={"/customer/members"}>view all</Link>
                            </span>

                        </h3>
                        <div className='setting-box pad-0'>
                            <Col className='float-left pad-0' sm="12">
                                {this.props.members && this.props.members.length > 0 && 
                                <Table className="tabledashboard table-responsive table table-striped table-advance table-hover">
                                    {this.props.members.slice(0,5).map(member => {
                                        return <tr>
                                            <td>{`${member.firstName} ${member.lastName}`}</td>
                                            <td>ID: {member.loginId}</td>
                                            <td>{member.phone}</td>
                                        </tr>
                                    })}
                                </Table>}

                                {(!this.props.members || this.props.members.length === 0) && <p className='text-center'>No Member Found</p>}
                            </Col>
                        </div>
                    </div>
                </Col>
            </div>
        );
    }
}

export default DashboardComponent;
