import { SENDING_UPDATE_MAINTAIN_DATA, SET_UPDATE_MAINTAIN_DATA, FETCH_DATA_FAIL } from '../../types';

/** @type { Object } default state */
let defaultState = {
  	data: {
		data: '',
	}
}

/**
 * dispatched the schedule service data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function sysMaintenance(state=defaultState, action={}) {

	switch (action.type) {
		case SET_UPDATE_MAINTAIN_DATA:
			const data = {}
			data[action.type] = action.data;
			data['resMsg'] = action.resMsg;
			data['isSending'] = action.isSending;
			data['error'] = action.error;
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, data)
		      });
		case SENDING_UPDATE_MAINTAIN_DATA:
			const sendUpdateReq = {}
			sendUpdateReq[action.type] = action.data;
			sendUpdateReq['isSending'] = action.isSending;
			sendUpdateReq['error'] = action.error;
			sendUpdateReq['resMsg'] = action.resMsg;
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, sendUpdateReq)
			  })

		case FETCH_DATA_FAIL:
			const errors = {}
			errors[action.type] = action.payload.error;
			return Object.assign({}, state, errors)

		default: return state;
	}
}
