import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddMemberComponent from '../components/customer/members/AddMemberComponent';
import { getTimeZoneLanguage } from "../actions/Application";
import { translate } from '../lang';

class AddMemberContainer extends Component {
    componentDidMount(){
        this.props.getTimeZoneLanguage();
    }

    render() {
        let { states } = this.props;
        return <AddMemberComponent states={states} translate={translate}/>;
    }
}

const mapStateToProps = state => {
    return({states: state.app.state || []});
}

const mapDispatchToProps = {
    getTimeZoneLanguage
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMemberContainer);