import React, { Component } from 'react';
import ResetPasswordForm from '../../../forms/ResetPasswordForm';

class ResetPasswordComponent extends Component {
	/**
	 * Get the pass the data to its parent component
	 * @param  { Object } data Containing the user input
	 */
	resetPassword = (token, data) => {
		this.props.resetPassword(token, data);
	}

	render() {
		const { translate, msg, loader, error, success } = this.props
		return (
			<ResetPasswordForm
				resetPassword={ this.resetPassword.bind(this) }
				msg = { msg }
				loader = { loader }
				translate = { translate }
				error = {error}
				success = {success}
				/>
		);
	}
}

export default ResetPasswordComponent;