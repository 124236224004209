import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Table } from 'reactstrap';
import SelectField from '../../../../components/shared/SelectField';
import {FaSort, FaSortUp, FaSortDown, FaExclamation, FaPencilAlt} from 'react-icons/fa'
import ButtonField  from '../../../../components/shared/ButtonField';
import Pagination from '../../../shared/Pagination';
// import './style.scss';

/* A DataSheet component */
class DataSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            equipmentList: [],
            equipmentTotal: 0,
            activePage : 1,
            key: null,
            click: true,
            sortingParams: null
        };
    }
    componentWillMount() {
        this.setState({ equipmentList: this.props.equipment });
    }

    componentWillReceiveProps(nextProps) {
        
        if (this.props.equipment !== nextProps.equipment || this.props.sendData !== nextProps.sendData) {
            this.setState({ 
                equipmentList: nextProps.equipment,
                equipmentTotal : (nextProps.totalData === 0 && this.state.equipmentTotal !== null) ? this.state.equipmentTotal : nextProps.totalData,
                roles: nextProps.roles
            });
        }
    }

     handlePageChange(page) {
        this.setState({ activePage: page });
        if (page < 2) {
            page = 0;
        } else {
            page = page - 1;
            page = (15 * page);
        }
        this.props.handlePageChange({ page: page, totalCounts: 'false',sortingParams: this.state.sortingParams });
    }

    sortBy(key) {
        let page = this.state.activePage;
        if (page < 2) {
            page = 0;
        } else {
            page = page - 1;
            page = (15 * page);
        }
        let sortingParams = `?fieldName=${key}&order=${((this.state.click === true) ? 'ASC&' : 'DESC&')}`
        this.props.handlePageChange({ page: page, totalCounts: 'false', sortingParams: sortingParams });
        this.setState({
            key: key,
            click: !this.state.click,
            sortingParams: sortingParams
        });
    }

    
    rolesDropDown () {
        let options = [];
        for (let i=0; i<this.state.roles.length; i++){
            options.push(<option key={i} data={this.state.roles[i]['roleName']} name={this.state.roles[i]['roleName']} value={this.state.roles[i]['roleCode'].trim()}>{this.state.roles[i]['roleName']}</option>)
        }
        return options;
    }

    /**
     * get the table body as the category listing
     */

    getTableBody = () => {
        let equipmentData = '';
        if (this.state.equipmentList.length > 0) {
            equipmentData = this.state.equipmentList.map((value, index) => {
                return (
                    <tr key={ index }>
                        <td>{value.roleCode}</td>
                        <td className="uppercase">
                            {(this.props.showCtrl.index === index)? <select onChange = { this.props.handleChange } name={index}>
                                <option>Please Select</option>
                                { this.state.roles.map((data, i) => {
                                    return <option key={i} data={data['roleName']} name={data['roleName']} value={data['roleCode'].trim()} selected={value.roleName == data['roleName'] ? 'selected' : ''}>{data['roleName']}</option>
                                })}
                            </select>
                            :value.roleName}
                        </td>
                        <td>{value.departmentCode}</td>
                        <td>{value.departmentName}</td>
                        <td>{value.companyName}</td>
                        <td className="extrawidth">
                        {(this.props.showCtrl.index === index)? '':<button className="edititnow" onClick={(event) => this.props.handleInlineEditClick(index, event) } ><FaPencilAlt/></button>}
                        {(this.props.showCtrl.index === index) ?
                        <Fragment>
                            <ButtonField
                                className={'btn-primary '}
                                buttonText={'Save'}
                                type="submit"
                                name = "saveRole"
                                clickEvent={true}
                                handleClick={() => this.props.saveCompanyData(index, value) }
                            />
                            <ButtonField
                                className={'btn-danger'}
                                buttonText={'Cancel'}
                                type="submit"
                                name = "cancel"
                                clickEvent={true}
                                handleClick={(event) => this.props.handleInlineEditClick(null, event) }
                            />
                        </Fragment>: null}
                        </td>
                    </tr>
                );
            });
        } else {
            equipmentData = <tr className="custom-no-data"><td className="text-center" colSpan={8}>No data..</td></tr>;
        }
        return equipmentData
    }

    render() {
        const { translate, roles, handleChange } = this.props;
        let sortType = (!this.state.click) ? <FaSortUp/> : <FaSortDown/>
        return (
            <div>
                <Table className="tabledashboard table table-striped table-advance table-hover">
                    <thead>
                        <tr >
                            <th className="xs-hidden" onClick={() => this.sortBy('rolecode')}>{translate('RoleCode') }
                                 {(this.state.key === 'rolecode') ? sortType : <FaSort/>}
                            </th>
                            <th className="xs-hidden" onClick={() => this.sortBy('rolename')}>{translate('RoleName') }
                                {(this.state.key === 'rolename') ? sortType : <FaSort/>}
                            </th>
                            <th className="xs-hidden" onClick={() => this.sortBy('departmentCode')}>{translate('DivisionCode') } 
                                {(this.state.key === 'departmentCode') ? sortType : <FaSort/>}
                            </th>
                            <th className="xs-hidden" onClick={() => this.sortBy('departmentName')}>{translate('DivisionName') } 
                                {(this.state.key === 'departmentName') ? sortType : <FaSort/>}
                            </th>
                            <th className="xs-hidden" onClick={() => this.sortBy('companyName')}>{translate('Company') }
                                {(this.state.key === 'companyName') ? sortType : <FaSort/>}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.getTableBody() }
                    </tbody>
                </Table>
                <div>
                     <Pagination
                        limit={15}
                        total={this.state.equipmentTotal}
                        activePage={this.state.activePage}
                        onChange={this.handlePageChange.bind(this) }
                        data={this.state.equipmentList}
                        />
                </div>
            </div>
        );
    }
}

export default withRouter(DataSheet);