import { SET_SCHEDULE_SERVICE_ALERT_DATA, SENDING_SCHEDULE_SERVICE_ALERT_DATA, 
	SET_UPDATE_SCHEDULE_SERVICE_ALERT_DATA, SENDING_UPDATE_SCHEDULE_SERVICE_ALERT_DATA,
	FETCH_DATA_FAIL } from '../../types';

/** @type { Object } default state */
let defaultState = {
  data: {
		data: '',
 	}
}

/**
 * dispatched the schedule service data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function scheduleService(state=defaultState, action={}) {

	switch (action.type) {
		case SET_SCHEDULE_SERVICE_ALERT_DATA:
			const data = {}
			data[action.type] = action.data;
			data['resMsg'] = action.resMsg;
			data['isSending'] = action.isSending;
			data['error'] = action.error;
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, data)
		      });
		case SENDING_SCHEDULE_SERVICE_ALERT_DATA:
			const sendingRequest = {}
			sendingRequest[action.type] = action.data;
			sendingRequest['isSending'] = action.isSending;
			sendingRequest['error'] = action.error;
			sendingRequest['resMsg'] = action.resMsg;
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, sendingRequest)
		      })
		case SET_UPDATE_SCHEDULE_SERVICE_ALERT_DATA:
			const updateData = {}
			updateData[action.type] = action.data;
			updateData['resMsg'] = action.resMsg;
			updateData['isSending'] = action.isSending;
			updateData['error'] = action.error;
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, updateData)
		      });
		case SENDING_UPDATE_SCHEDULE_SERVICE_ALERT_DATA:
			const sendingUpdateReq = {}
			sendingUpdateReq[action.type] = action.data;
			sendingUpdateReq['isSending'] = action.isSending;
			sendingUpdateReq['error'] = action.error;
			sendingUpdateReq['resMsg'] = action.resMsg;
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, sendingUpdateReq)
		      })
		case FETCH_DATA_FAIL:
			const errors = {}
			errors[action.type] = action.payload.error;
			return Object.assign({}, state, errors)

		default: return state;
	}
}
