import React from "react";
import DatePicker, {  setDefaultLocale }from "react-datepicker"
import moment from 'moment';/* 
import { fi} from "date-fns/locale/fi/index.js";
 *//* registerLocale("fi", fi); */
import 'react-datepicker/dist/react-datepicker.css';

export default class DatePickerComp extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            date: moment(),
            minYear: ''
        };
        /** Binding Events */
        this.changeDate = this.changeDate.bind(this);
        this.changeMonth = this.changeMonth.bind(this);
        this.changeYear = this.changeYear.bind(this);
    }

    componentDidMount() {
        let years = this.props.years;
        if (Array.isArray(years) && years.length > 0) {
            let minYear = Math.min(...years);
            this.setState({ minYear });
        }
        this.setState({ date: moment() })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.equipment && nextProps.equipment && this.props.equipment.machineSaleId !== nextProps.equipment.machineSaleId) {
            this.setState({ date: moment() });
        }
        if (this.props.openToDate != nextProps.openToDate && nextProps.openToDate > 0) {
            this.setState({ date: nextProps.openToDate });
        }
        let years = nextProps.years;
        if (Array.isArray(years) && years.length > 0) {
            let minYear = Math.min(...years);
            this.setState({ minYear });
        }

    }

    changeDate(date) {
        /** Set date into State */
        this.setState({ date: date });
        /** let other component know about seleceted date */
        if (this.props.getDate) this.props.getDate(date);
    }

    changeMonth(date) {
        if(moment(date).isAfter(new Date())){
            date = moment(new Date());
        }
        /** Set date into State */
        this.setState({ date });
        /** let other component know about seleceted date */
        if (this.props.getDate) this.props.getDate(date);
    }

    changeYear(date) {
        if(moment(date).isAfter(new Date())){
            date = moment(new Date());
        }
        /** Set date into State */
        this.setState({ date });
        /** let other component know about seleceted date */
        if (this.props.getDate) this.props.getDate(date);
    }

    render(){
        let {date, minYear} = this.state, {highlighted,maxDate,openToDate,years} = this.props;
        let minDate = minYear ? new Date(minYear,1,1):null;
        let highlightDates = highlighted.map(date => moment(date));
        let formattedMinDate = moment(minDate).format('DD MM YYYY')
      
        return(
            <DatePicker
                fixedHeight
                disabledKeyboardNavigation
                selectsStart
                highlightDates={highlightDates}
                inline
                selected={maxDate && !maxDate.isAfter(moment(date))?maxDate: date}
                openToDate={openToDate > 0 ? openToDate :(maxDate && !maxDate.isAfter(moment(date))?maxDate:date)}
                onChange={this.changeDate}
                maxDate={maxDate && !maxDate.isAfter(moment())?maxDate:moment().add(0, "days")}
                onMonthChange={this.changeMonth}
                onYearChange={this.changeYear}
                locale="fi"
                showYearDropdown
                showMonthDropdown
                minDate={years && moment(formattedMinDate)}   
                scrollableMonthYearDropdown
                scrollableYearDropdown
            />
        );
    }
}