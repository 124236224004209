import React, { Component, Fragment } from "react";
import { Field, reduxForm, reset } from "redux-form";
import {connect} from "react-redux";
import { required } from "../../shared/Validation";
import { FileInput  } from "../../shared/renderField";
import { Row, Col, Alert } from "reactstrap";
import {saveBulkFota} from "../../../api/admin/fota";
import { setLoader, setToastr } from "../../../actions/Application";

let form = "BulkFotaForm";

class BulkFotaComponent extends Component{
    state = {
        mapRecords: []
    };

    onSubmit = (values) =>{
        this.props.setLoader({ loader: {open: true}});
        saveBulkFota(values)
        .then(response => {
            if(response.data){

                if(response.data.listFotaRecords && Array.isArray(response.data.listFotaRecords)){
                    this.setState({mapRecords: response.data.listFotaRecords}); 
                }

                this.props.setToastr({ toastr: { message:"Equipment list has been added.", type:"success" }});
                //this.props.setTab(1);
                this.props.dispatch(reset(form));
            }else{
                throw new Error();
            }
            this.props.setLoader({ loader: {open: false}});
        })
        .catch(error => {
            this.props.setToastr({toastr:{type: "error", message:error || "Something went wrong."}});
            this.props.setLoader({ loader: {open: false}});            
        })
    }

    render(){
        const { handleSubmit, submitting } = this.props, { mapRecords } = this.state;
        return(
            <Fragment>
                <form name="BrowseFotaForm" id="BrowseFotaForm" onSubmit={handleSubmit(this.onSubmit)} autoComplete='off'>
                    <Row className="members align-items-center fotaform">
                        <Col sm="6">
                            <Alert type="info">
                                <i>Note: Please upload csv file to import data in bulk.</i>
                            </Alert>
                            <div className="row profile-asset">
                                <div className="col-sm-6">Browse FOTA <span className='required'>*</span>
                                    <Field name="file" component={FileInput} className='form-control' type="file" validate={[required]}/>
                                </div>
                            </div>


                            <div className="row profile-asset">
                                <div className="col-sm-12">
                                    <button type="submit" className="btn btn-primary" disabled={submitting}>Save</button> &nbsp;
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>

                {mapRecords.length > 0 && <table className="tabledashboard table table-striped table-advance table-hover m-top-30">
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>Machine Number</th>
                            <th>Version</th>
                            {/* <th>Map2 ID</th> */}
                            <th>Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(mapRecords) && mapRecords.length>0 && mapRecords.map((item,i) => (
                            <tr key={item.model+i+1}>
                                <td className={item.errorMessage ? "errbg": "sucbg"}>{item.model || "NA"}</td>
                                <td className={item.errorMessage ? "errbg": "sucbg"}>{item.machineNum || "NA"}</td>
                                <td className={item.errorMessage ? "errbg": "sucbg"}>{item.version || "NA"}</td>
                                {/* <td className={item.errorMessage ? "errbg": "sucbg"}>{item.map1Id || "NA"}</td> */}
                                <td className={item.errorMessage ? "errbg": "sucbg"}>{item.errorMessage || "NA"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </Fragment>

        );
    }
}


let formComponent = reduxForm({ form })(BulkFotaComponent);
let mapDispatchToProps = { setLoader, setToastr };

export default connect(null, mapDispatchToProps)(formComponent);