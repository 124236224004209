import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import {FETCH_DATA_FAIL ,UPDATE_EQUIPMENTS_SERVICE_DATA,SET_EQUIPMENT_SERVICE_INFO,SET_TOASTR} from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* updateEquipmentService(action) {
  const data = action.data;
  try {
    
   let response = yield call(api.serviceProvider.equipment.updateServiceInfo, data);

  switch (response.statusCode) {
    case 200:
        yield put({
          type: SET_TOASTR,
          data: {
              toastr: {
                  message: "Equipment Service Info saved successfully", 
                  type:"success"
              }
          }
        })
        yield put({ type: SET_EQUIPMENT_SERVICE_INFO, data: response, resMsg: response, isSending: false, error: false })
   
        break;
    default:  
        break;
}
 
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: 'Error while connecting server'
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchUpdateEquipmentService() {
  yield takeLatest(UPDATE_EQUIPMENTS_SERVICE_DATA, updateEquipmentService)
}
