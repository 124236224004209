import React, { Component, Fragment } from "react";
import { renderTextField, renderSelectField } from "../../shared/renderField";
import { required, email } from "../../shared/Validation";
import { Card, CardBody } from "reactstrap";
import { Row, Col, CustomInput, FormGroup } from "reactstrap";
import { Field, reduxForm, change, reset } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FaTrash, FaPencilAlt } from 'react-icons/fa'
import ButtonField from '../../shared/ButtonField';
import { Button } from "reactstrap";
import { setLoader, setToastr, setConfirmBox } from "../../../actions/Application";
import ReactDOM from "react-dom";
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'
import Pagination from "../../shared/Pagination";
import { LIMIT } from "../../../constants/Constants";
import "./style.scss"
import { getCompanyEmail, updateCompanyEmail, deleteCompanyEmail, getCompanyEmailByComapnyCode, companySearch, addCompanyEmail } from "../../../api/service-provider/manageEmail";
let form = "SearchForm";
/*global google*/

/* A Contract Management component */
class EmailInformationComponent extends Component {
    constructor(props) {
        super(props);
        this.getEmailListByCompany = this.getEmailListByCompany.bind(this);
        this.getCompanySearchList = this.getCompanySearchList.bind(this);
        this.getCompanyEmailList = this.getCompanyEmailList.bind(this);
        this.getCompanyEmailBySelectedCompanyCode = this.getCompanyEmailBySelectedCompanyCode.bind(this);
        this.handleCancelAddEmail = this.handleCancelAddEmail.bind(this);
        this.saveEditedCompanyEmail = this.saveEditedCompanyEmail.bind(this);
        this.sortBy = this.sortBy.bind(this)
        this.state = {
            companyDropdownList: [],
            initialCompanyList: [],
            showCtrl: { 0: false },
            filteredCompanyList: [],
            useFiltered: false,
            selectedCompanyCode: '',
            adminFlag: false,
            newEmail: '',
            newChecked: false,
            selectedCompanyName: '',
            email: {},
            checkbox: {},
            ccEmailFlags: [],
            click: true,
            total: 0,
            activePage: 1,
            key:null,
            sortingOrder : false,
            setFlagCounts :0
        }
    }

    componentDidMount() {
        this.getCompanySearchList();
        this.getCompanyEmailList(null,false);
    }

    /* method to get company dropdown list*/
    getCompanySearchList() {
        this.props.setLoader({ loader: { open: true } });
        companySearch()
            .then(result => {
                if (result.data) {
                    this.setState({ companyDropdownList: result.data });
                }
                this.props.setLoader({ loader: { open: false } });
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    /* method to get list of email on the page*/
    getCompanyEmailList(sortKey,sortOrder,page) {
        this.props.setLoader({ loader: { open: true } });
        let activePage = page ? page:this.state.activePage;
        let sortByEmail = sortKey == 'email'; 
        let sortByCompanyName = sortKey == 'companyName';
        let offset = (activePage && activePage > 1) ? ((activePage - 1) * LIMIT) : 0;
        getCompanyEmail(offset, LIMIT, true, sortOrder, sortByEmail, sortByCompanyName)
            .then(result => {
                if (result.data) {
                    //filtering the blank emails from list
                    let allCOmpanyListWithEmails = result.data.companyEmail.length > 0 ? result.data.companyEmail.filter(a => a.email !== "") : [];
                    let checkbox = {};
                    for (let i = 0; i < allCOmpanyListWithEmails.length; i++) {
                        checkbox = {
                            ...checkbox, [i]: allCOmpanyListWithEmails[i].ccEmailFlag
                        }
                    }
                    this.setState({ initialCompanyList: allCOmpanyListWithEmails, adminFlag: result.data.adminFlag, checkbox: checkbox, total: result.data.countResult.totalCounts });
                    if (this.state.useFiltered) {
                        this.getCompanyEmailBySelectedCompanyCode(this.state.selectedCompanyCode, this.state.selectedCompanyName,sortKey,sortOrder)
                    }
                }
                this.props.setLoader({ loader: { open: false } });
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    /*method to get email list on the basis of search */
    getEmailListByCompany(e) {
        this.props.setLoader({ loader: { open: true } });
        this.props.dispatch(change(form, 'newEmail', ''));
        this.setState({ activePage: 1, newChecked: false, newEmail: '', showCtrl: { 0: false } ,email: {},checkbox: {}});
        let selectedCompanyCode = e.target.value;
        if (selectedCompanyCode == null || selectedCompanyCode == '') {
            this.setState({ filteredCompanyList: [], useFiltered: false, selectedCompanyCode: '', selectedCompanyName: '' });
            this.getCompanyEmailList(this.state.key,this.state.sortingOrder,1);
            return;
        }
        let selectedCompanyName = this.state.companyDropdownList.filter(a => a.companyCode == selectedCompanyCode)[0].companyName;

        this.getCompanyEmailBySelectedCompanyCode(selectedCompanyCode, selectedCompanyName,this.state.key,this.state.sortingOrder,1);
    }

    /* Api call to get result via company code*/
    getCompanyEmailBySelectedCompanyCode(selectedCompanyCode, selectedCompanyName,sortKey,sortOrder,page) {
        var filtered = [];
        let activePage = page ? page:this.state.activePage;
        let sortByEmail = sortKey == 'email'; 
        let sortByCompanyName = sortKey == 'companyName';
        let offset = (activePage && activePage > 1) ? ((activePage - 1) * LIMIT) : 0;
        getCompanyEmailByComapnyCode(selectedCompanyCode,offset,LIMIT,true,sortOrder,sortByEmail,sortByCompanyName)
            .then(result => {
                if (result.data) {
                    filtered = result.data.companyEmail && result.data.companyEmail.length > 0 ? result.data.companyEmail.filter(a => a.email !== "") : [];
                    //filtered.sort((a, b) => a.email.localeCompare(b.email));
                    let checkbox = {};
                    for (let i = 0; i < filtered.length; i++) {
                        checkbox = {
                            ...checkbox, [i]: filtered[i].ccEmailFlag
                        }
                    }
                    this.setState({ filteredCompanyList: filtered, useFiltered: true, selectedCompanyCode: selectedCompanyCode, selectedCompanyName: selectedCompanyName, checkbox: checkbox ,total: result.data.countResult.totalCounts, setFlagCounts:result.data.countResult.ccEmailCount});
                }
                this.props.setLoader({ loader: { open: false } });
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    /* Method to get new email*/
    handleAddEmail = (data) => {
        this.setState(
            {
                newEmail: data.target.value
            });
    }

    /*Methoid to handle checkbox while adding new email*/
    handleAddCheck = (e) => {
        /*condition to check not adding email with cc check if already 10 cc are checked*/ 
        /*let {setFlagCounts } = this.state;
        if (setFlagCounts >= 10) {
            this.props.setLoader({ loader: { open: false } });
            this.props.setToastr({ toastr: { message: "Maximum 10 users of a company can receive copy of  registration email.", type: "error" } });
            return
        }*/
        this.setState(
            {
                newChecked: e.target.checked,
            });

    }

    /* Method for adding new email */
    handleAddNewEmail(e) {
        e.preventDefault();
        let { newEmail, newChecked, selectedCompanyCode, filteredCompanyList, selectedCompanyName } = this.state;

        if (newEmail == null || newEmail == '') {
            this.props.setToastr({ toastr: { message: "Please enter email to proceed", type: "error" } });
            return;
        }
        this.props.setLoader({ loader: { open: true } });
        var emailList = filteredCompanyList.map(i => i.email);
        let sendData = {};
        this.props.setLoader({ loader: { open: true } });

        sendData = {
            'email': newEmail,
            'companyCode': selectedCompanyCode.trim(),
            'ccEmailFlag': newChecked,
        }
        let data = JSON.stringify(sendData);

        if (emailList.includes(newEmail)) {
            this.props.setLoader({ loader: { open: false } });
            this.props.setToastr({ toastr: { message: "This email already exists", type: "info" } });
            return
        }
        /*if (newEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail)) {
            this.props.setLoader({ loader: { open: false } });
            this.props.setToastr({ toastr: { message: "Could not save invalid Email", type: "info" } });
            return
        }*/

        addCompanyEmail(data)
            .then(result => {
                if (result.data) {
                    this.props.setToastr({ toastr: { message: "Email is added successfully", type: "success" } });
                    this.getCompanyEmailList(this.state.key,this.state.sortingOrder);
                    this.props.dispatch(change(form, 'newEmail', ''));
                    this.props.setLoader({ loader: { open: false } });
                    this.setState({ newChecked: false, newEmail: '' });

                } else {
                    this.props.setToastr({ toastr: { message: "Email is not added successfully", type: "error" } });
                    this.props.setLoader({ loader: { open: false } });
                }
            })

            .catch(error => {
                this.props.setToastr({ toastr: { message: error, type: "error" } });
                if(error="Maximum 10 users of a company can receive copy of  registration email."){
                    this.setState({ newChecked: false});
                }
                this.props.setLoader({ loader: { open: false } });
            })
    }

    /* method to cancel add new email*/
    handleCancelAddEmail = (event) => {
        event.preventDefault();
        this.props.dispatch(change(form, 'newEmail', ''));
        this.setState({ newChecked: false, newEmail: '' });
    }

    handleInlineEditClick = (index, event) => {
        event.preventDefault();
        let allCOmpanyListWithEmails = this.state.useFiltered ? this.state.filteredCompanyList : this.state.initialCompanyList;
        let checkbox = {};
        /*for loop to verfiy checkebox if move to other index after edit without save/cancel */
        for (let i = 0; i < allCOmpanyListWithEmails.length; i++) {
            checkbox = {
                ...checkbox, [i]: allCOmpanyListWithEmails[i].ccEmailFlag
            }
        }
        this.setState({
            showCtrl: {
                ...this.state.showCtrl, ['index']: index
            },
            checkbox: checkbox
        })
        if (index == null) {
            if (this.state.useFiltered) {
                this.getCompanyEmailBySelectedCompanyCode(this.state.selectedCompanyCode, this.state.selectedCompanyName, this.state.key, this.state.sortingOrder)
            }
            else {
                this.getCompanyEmailList(this.state.key, this.state.sortingOrder);
            }
        }
    }

    handleChange = (type, data) => {
        if (data === undefined) {
            data = type.target.value;
            type = type.target.name;
        }
        this.setState(
            {
                email: {
                    ...this.state.email, [type]: data,
                }
            });
    }

    clickCheckBox = (type, data) => {
        if (data === undefined) {
            data = type.target.checked;
            type = type.target.name;
        }
        this.setState(
            {
                checkbox: {
                    ...this.state.checkbox, [type]: data,
                }
            });
    }

    /* method to save edited email*/
    saveEditedCompanyEmail(index, item, event) {
        event.preventDefault();
        let { initialCompanyList, useFiltered } = this.state;
        let email = this.state.email[index];
        let checkbox = this.state.checkbox[index];
        var emailList = initialCompanyList.map(i => i.email);

        let sendData = {};
        this.props.setLoader({ loader: { open: true } });
        sendData = {
            [index]: {
                'companyCode': item.companyCode.trim(),
                'email': email ? email : item.email,
                'ccEmailFlag': checkbox != undefined ? checkbox : item.ccEmailFlag,
            }
        }
        let data = JSON.stringify(sendData[index]);

        if (emailList.includes(email)) {
            this.props.setLoader({ loader: { open: false } });
            this.props.setToastr({ toastr: { message: "This email already exists", type: "info" } });
            return
        }
        {/*if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            this.props.setLoader({ loader: { open: false } });
            this.props.setToastr({ toastr: { message: "Could not save invalid Email", type: "info" } });
            return
        }*/}

        updateCompanyEmail(item.email, data)
            .then(result => {
                if (result.data && result.data !== 'false') {
                    this.props.setToastr({ toastr: { message: "Email is updated successfully", type: "success" } });
                    this.getCompanyEmailList(this.state.key,this.state.sortingOrder);
                    this.props.setLoader({ loader: { open: false } });
                    this.setState({
                        showCtrl: { 0: false },
                        email: {},
                        checkbox: {}
                    })
                } else {
                    this.props.setToastr({ toastr: { message: "Email is not updated successfully", type: "error" } });
                    this.props.setLoader({ loader: { open: false } });
                }
            })
            .catch(error => {
                this.props.setToastr({ toastr: { message: error, type: "error" } });
                if(error="Maximum 10 users of a company can receive copy of  registration email."){
                    this.getCompanyEmailList(this.state.key,this.state.sortingOrder); 
                }
                this.props.setLoader({ loader: { open: false } });
            })

    }

    /* method to delete email*/
    confirmnDelete = (item) => {
        this.props.setConfirmBox({ modal: { open: true, data: item, title: "Delete Record", content: `Do you really want to delete this record?`, done: this.deleteDataConfirmation } });
    }

    deleteDataConfirmation = (item) => {
        let { useFiltered, selectedCompanyCode, selectedCompanyName } = this.state;
        this.props.setLoader({ loader: { open: true } });

        return new Promise((resolve, reject) => {
            deleteCompanyEmail(item.email)
                .then(result => {
                    if (result.data) {
                        this.props.setToastr({ toastr: { message: "Email has been deleted successfully", type: "success" } });
                        this.getCompanyEmailList(this.state.key,this.state.sortingOrder);
                        //this.props.dispatch(reset(form));
                        this.props.setLoader({ loader: { open: false } });
                        resolve({ toastr: { message: "Record has been deleted.", type: "success" } });
                    } else {
                        this.props.setToastr({ toastr: { message: "Email can't be deleted", type: "error" } });
                        this.props.setLoader({ loader: { open: false } });
                        reject(false);
                    }
                })
                .catch(error => {
                    this.props.setToastr({ toastr: { message: "Email can't be deleted", type: "error" } });
                    this.props.setLoader({ loader: { open: false } });
                })
        });
    }

    sortBy(key) {
        /*let page = this.state.activePage;
        if (page < 2) {
            page = 0;
        } else {
            page = page - 1;
            page = (15 * page);
        }
        let sortingParams =`eventField=${key}&order=${((this.state.click === true) ? 'ASC' : 'DESC')}`
        this.handlePageChange(page, sortingParams);*/
        let sortingOrder = !this.state.click;
        this.setState({
            key: key,
            click: !this.state.click,
            sortingOrder : sortingOrder
        });
        this.getCompanyEmailList(key,sortingOrder);
    }

    handlePageChange(page) {
        this.setState({ activePage: page || 1 }, () => {
            this.getCompanyEmailList(this.state.key,this.state.sortingOrder);
        });
    }

    render() {
        let { companyDropdownList, initialCompanyList, showCtrl, useFiltered, filteredCompanyList, adminFlag, selectedCompanyName, checkbox, activePage, total } = this.state;
        let companyListToShow = useFiltered ? filteredCompanyList : initialCompanyList;
        let index = 1;
        let sortType = (!this.state.click) ? <FaSortUp /> : <FaSortDown />


        return (
            <Fragment>
                <form className="equipmentInfo" name="SearchForm" id="SearchForm" autoComplete='off'>
                    {adminFlag && <Row className="members align-items-center form-srch">
                        <Col sm="12">

                            <div className="row profile-asset">
                                <div className="col-sm-6">
                                    <div style={{ marginBottom: '2px' }}>
                                    <span style={{ marginLeft: '138px' }}>To add new email,please select company</span>
                                    </div>
                                    <div style={{ display: 'flex' }}>Service Company
                                        <Field name="companyCode" id="companyCode" component={renderSelectField} className='form-control' type="text" onChange={this.getEmailListByCompany} style={{ marginLeft: '20px' }}>
                                            <option value="">All companies</option>
                                            {companyDropdownList && companyDropdownList.map((company, index) => (<option key={index} value={company.companyCode}>{company.companyName}</option>))}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>}

                    <table className="tabledashboard table table-striped table-advance table-hover m-top-30" style={{ marginBottom: '0px' }}>

                        <thead>
                            <tr>
                                <th className="col-sm-3" onClick={() => this.sortBy('email')}>Email Address {(this.state.key === 'email') ? sortType : <FaSort />}</th>
                                <th className="col-sm-2" onClick={() => this.sortBy('companyName')}>Company Name {(this.state.key === 'companyName') ? sortType : <FaSort />}</th>
                                <th className="col-sm-3">Registration email copy required?</th>
                                <th className="col-sm-3">Actions</th>
                            </tr>
                        </thead>

                        <tbody >

                            {companyListToShow && companyListToShow.length > 0 && companyListToShow.map((item, index) => (

                                <tr key={index}>
                                    <td className="col-sm-3">{(showCtrl.index === index) ? <input defaultValue={item.email} onChange={this.handleChange} name={index} validate={[email]}></input> : item.email}</td>
                                    <td className="col-sm-2">{item.companyName}</td>
                                    {/* #changenumber-00020389 -- changes for CC email functionality start*/}
                                    <td className="col-sm-3">
                                        <div className="checkbox-group">
                                            <label>
                                                <input type="checkbox" onChange={event => this.clickCheckBox(event)} name={index} id={index} checked={checkbox[index]} disabled={(showCtrl.index !== index)} />
                                                <span className='checkmark' style={{ left: '60px' }}></span>
                                            </label>
                                        </div>
                                    </td>
                                    {/* #changenumber -- changes for CC email functionality end*/}
                                    <td className="col-sm-3">
                                        <div className="space-v" style={{ marginLeft: "-12%" }}>
                                            {(showCtrl.index === index) ? '' : <Button style={{ marginTop: '0px' }} onClick={() => this.confirmnDelete(item)}> <FaTrash /> </Button>} &nbsp;
                                            {(showCtrl.index === index) ? '' : <Button style={{ marginTop: '0px' }} className="edititnow" onClick={(event) => this.handleInlineEditClick(index, event)} > <FaPencilAlt /> </Button>}
                                            {(showCtrl.index === index) ?
                                                <Fragment>
                                                    <ButtonField
                                                        className={'btn-success button-left'}
                                                        buttonText={'Save'}
                                                        type="submit"
                                                        name="saveEmail"
                                                        clickEvent={true}
                                                        handleClick={(event) => this.saveEditedCompanyEmail(index, item, event)}
                                                    />
                                                    <ButtonField
                                                        className={'btn-danger'}
                                                        buttonText={'Cancel'}
                                                        type="submit"
                                                        name="cancel"
                                                        clickEvent={true}
                                                        handleClick={(event) => this.handleInlineEditClick(null, event)}
                                                    />
                                                </Fragment> : null}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {(companyListToShow == undefined || companyListToShow.length == 0) && <tr><td align="center" colSpan="12">No Emails Found</td></tr>}
                        </tbody>
                    </table>

                    {useFiltered && <table className="tabledashboard table table-striped table-advance table-hover">
                        <tbody>
                            <tr>
                                <td className="col-sm-3">
                                    <Field name="newEmail" placeholder="Add New Email Address" component={renderTextField} className='form-control' type="text" validate={[email]} onChange={this.handleAddEmail.bind(this)} style={{ width: '55%' }} />
                                </td>
                                <td className="col-sm-2">{selectedCompanyName}</td>
                                <td className="col-sm-3">
                                    <div className="checkbox-group">
                                        <label>
                                            <input type="checkbox" onChange={this.handleAddCheck.bind(this)} checked={this.state.newChecked} />
                                            <span className='checkmark' style={{ left: '60px' }}></span></label>
                                    </div>
                                </td>
                                <td className="col-sm-3">
                                    <div className="detailcovercustom">
                                        <div className="float-left" >

                                            <ButtonField
                                                className="btn-success float-left button-left"
                                                buttonText="Save"
                                                handleClick={this.handleAddNewEmail.bind(this)}
                                                clickEvent={true}
                                            />
                                            <ButtonField
                                                className="btn-danger float-left "
                                                buttonText="Cancel"
                                                type="submit"
                                                name="cancelAdd"
                                                id="cancelAdd"
                                                clickEvent={true}
                                                handleClick={(event) => this.handleCancelAddEmail(event)}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>}
                    <Pagination
                        limit={LIMIT}
                        total={total}
                        activePage={activePage}
                        onChange={this.handlePageChange.bind(this)}
                        data={companyListToShow}
                    />
                </form>
            </Fragment>
        );
    }

}

function mapStateToProps(state) {
    return;
}
let formComponent = reduxForm({ form: "SearchForm" })(EmailInformationComponent);
let mapDispatchToProps = { setLoader, setToastr, setConfirmBox };
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(formComponent));
