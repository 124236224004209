import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import InputField from '../components/shared/InputField';
import ButtonField from '../components/shared/ButtonField';
import Validator from 'validator';
import { Alert, Form, FormGroup, Card, CardBody, Label } from 'reactstrap';
import InlineError from '../messages/InlineErrors/Inline';
import logoImage from '../assets/images/logo.jpg';

/** An forget Password component */
class LoginForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
		    data: {
		    	loginId: '',
				email: '',
				mobileNum:''
		    },
		    isValidEmail: false,
			isValidLoginId: false,
			isValidMobileNum:false,
		    wrongDetail: false,
		    isSuccess: false,
		    errors: {},
		    msg: '',
			type : ''
		};
	}

	/**
	 * Set the state of user input on change
	 * @param  { Object } e Contains the user data
	 */
	handleChange(e) {
		this.setState({ 
	    	data: {
	    		...this.state.data, [e.target.name]: e.target.value
	    	} 
	    });
	}

	componentDidMount(){
		//console.log(this.props)
		this.props.loader({loader:{open:true}});
	}

    /**
     * Get the new props and set into state if they are not same.
     * @param  { Object } nextProps Contains the next props
     */
    componentWillReceiveProps(nextProps) {
    	if(nextProps.data.SET_FORGET_PASSWORD && nextProps.data.SET_FORGET_PASSWORD === true){
			if(!nextProps.data.isSending) this.setState({ wrongDetail : true, msg : 'Details sent to your Email ID and registered Mobile Number', type : "success" })
		}else{
			if(!nextProps.data.isSending) this.setState({ wrongDetail : true, msg : 'Provide correct details', type : "danger" })
		}

		//console.log()

		if(nextProps.data.isSending){
			// show loader
			this.props.loader({loader:{open:true}});
		}else{
			// hide loader
			this.props.loader({loader:{open:false}});
		}
    }

    /**
     * Get and validate the user inputs pass to the parent components
     * @param  { Object } e Contains the user data
     */
	handleSubmit(e) {
		e.preventDefault();
		const errors = this.validate(this.state.data);
	    this.setState({errors});
	    if (Object.keys(errors).length === 0) {
	    	this.props.forgetPass(this.state.data)
	    }
	}

	/**
	 * Validate the user inputs
	 * @param  { Object } data User inputs
	 */
	validate(data) {
		const errors = {};
		
		if(Validator.isEmpty(data.loginId)){
			errors.loginId = 'Provide a valid User ID'
			this.setState({isValidLoginId: true })
		} else {
			this.setState({isValidLoginId: false})
		}
		if (data.email && !Validator.isEmail(data.email)) {
			errors.email = 'Invalid email'
			this.setState({isValidEmail: true })
		} else {
			this.setState({isValidEmail: false })
		}
		if (data.mobileNum && !Validator.isNumeric(data.mobileNum)) {
			errors.mobileNum = 'Invalid Mobile Number'
			this.setState({isValidMobileNum: true })
		}else if(Validator.isEmpty(data.mobileNum)){
			errors.mobileNum = 'Please provide Mobile Number'
			this.setState({ isValidMobileNum : true })
			}
		else {
			this.setState({isValidMobileNum: false })
		}

		
		
		return errors;
	}

	/**
	 * Provide the header part of the page
	 * @return { Object } Header part of the page.
	 */
	getHeader = () => {
		return (
				<div className="topheader">
		            <img src={ logoImage } alt="Yanmar" />
		        </div>
            );
	}

	/**
	 * Hide the error pop up
	 */
	hidePopUp = () => {
      this.setState({wrongDetail: false, msg : 'Please Wait..', type : "info"})
    }

	render() {
		let popMsg = ''
		let popUp = false
		const { error, isSuccess, isSending, errorMsg ,translate} = this.props;
		const { data, errors } = this.state;
  		
  		if (this.state.isValidEmail) {
  			popUp = true
  			popMsg = errors.email
  		}
		
		if (error || isSuccess) {
  			popMsg = errorMsg
  		}

  		return (
			<div className="view loginView">
				<div className="box-login view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
					<Card className="mb-3 form-card">
						{ this.getHeader() }
                        <CardBody>
                            <p className="mt-4 small">{translate("enterUserNameAndMobileNumber")}</p>
							{ this.state.wrongDetail && <Alert color={ this.state.type } isOpen={true} toggle={this.hidePopUp.bind(this)}>{ this.state.msg }</Alert> }
							<Form onSubmit={this.handleSubmit.bind(this)}>
                    			<FormGroup>
                    				<Label>{translate("userID")}</Label><span className='required'>*</span>
	                				<InputField
	                                	name="loginId"
							            type="text"
							            id="loginId"
							            value={data.loginId}
							            handleChange={ this.handleChange.bind(this) }
							        />
                                    { this.state.isValidLoginId && <InlineError text={ this.state.errors.loginId || '' }/> }
							    </FormGroup>
								<FormGroup>
                    				<Label>{translate("mobileNumber")}</Label><span className='required'>*</span>
	                				<InputField
	                                	name="mobileNum"
							            type="text"
							            id="mobileNum"
							            value={data.mobileNum}
							            handleChange={ this.handleChange.bind(this) }
							        />
                                    { this.state.isValidMobileNum && <InlineError text={ this.state.errors.mobileNum || '' }/> }
							    </FormGroup>
								<FormGroup>
                    				<Label>{translate("email")}</Label>
	                				<InputField
	                                	name="email"
							            type="email" 
							            id="email"
							            value={data.email}
							            handleChange={ this.handleChange.bind(this) }
							        />
                                    { this.state.isValidEmail && <InlineError text={ this.state.errors.email || '' }/> }
							    </FormGroup>
							    <FormGroup className="text-right">
	                                <ButtonField 
							            	type="submit" 
							            	id="reset" 
							            	name={ 'reset' }
                                            className = "btn-danger"
					                        disabled={ isSending ? true : false }
							            	buttonText={isSending ? 'Sending...' : 'Reset Password'}
							            />
	                            </FormGroup>
              				</Form>
              				<p>{translate("alreadyHavePassword")} <Link to="/">{translate("signIn")}</Link></p>
					    </CardBody>
				    </Card>
				    
	            </div>
	        </div>
	    );
  	}
}

export default withRouter(LoginForm);
