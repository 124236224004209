import React, { Component } from 'react';
import { connect } from "react-redux";
import { getMemberList } from "../actions/Member";
import MemberMangementComponent from '../components/customer/members/MemberMangementComponent';
import { translate } from '../lang';


class MemberManagementContainer extends Component {

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount(){
       
        this.props.getMemberList();
    }
    render() {
        let {memberList} = this.props;
        console.log('Memb',memberList)
        return (
            
           <MemberMangementComponent translate={translate} memberList={memberList}/>
        );
    }
   
}
const mapDispatchToProps = {
    getMemberList
    
}
const mapStateToProps = state => {
    return({
        memberList: (state.members && state.members.memberFunctions ) ? state.members.memberFunctions.allMemberList : []
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberManagementContainer);

