import React, { Component } from 'react';
import Highcharts from 'highcharts';
import {
  HighchartsChart, Chart, withHighcharts, XAxis, YAxis, Title, Legend, ColumnSeries, SplineSeries
} from 'react-jsx-highcharts';


const YAx = ({ item, data, n }) => {
    return(
          <YAxis opposite={n%2==0? true: false}>
              <YAxis.Title>{item.replace(/_/g, " ")}</YAxis.Title>
              {item.indexOf("AVERAGE")>=0 ? <ColumnSeries name={item.replace(/_/g, " ")} data={data} /> :
              <SplineSeries name={item.replace(/_/g, " ")} data={data} />}
          </YAxis>
    )
}

class Graphs extends Component {

  constructor(props){
      super(props);
      this.state = {
        xAxisData: [],
        graphData: []
      }
  }

  componentWillReceiveProps(nextProps){
    let graphData = [];
    console.log("componentWillReceiveProps  "+JSON.stringify(nextProps.xAxisData));
		if(this.props !== nextProps){
      if(this.props.xAxisData !== nextProps.xAxisData){
         // this.setState({xAxisData : nextProps.xAxisData})

        /*  

           this.setState(
            {  xAxisData: nextProps.xAxisData },
            () => {
              console.log("should update "+ this.state.xAxisData)
              this.setState(this.state.xAxisData);
              this.forceUpdate();
            }
          );  */

         this.setState((state) => {
            return {xAxisData: nextProps.xAxisData};
          }); 
          console.log("after changing state nextProps "+ JSON.stringify(nextProps.xAxisData))
          console.log("after changing state "+ JSON.stringify(this.state.xAxisData))
          
      }
    }
   

    //if(nextProps.graphData && Object.keys(nextProps.graphData).length){
      for(let i in nextProps.graphData){
        graphData.push({
            key: i,
            value: nextProps.graphData[i]
        });
      }

      this.setState({ graphData });
  //}
  }

  componentWillMount(){
    this.setState({xAxisData : this.props.xAxisData})

   let graphData = [];

    if(this.props.graphData && Object.keys(this.props.graphData).length){
        for(let i in this.props.graphData){
          graphData.push({
              key: i,
              value: this.props.graphData[i]
          });
        }

        this.setState({ graphData });
    }
  }

  plotgraph(graphData){
    
      let g='';

      for(let k in graphData){
          if(graphData[k] && graphData[k].length) g += <YAx item={k} data={graphData[k]} />;
      }
      return g;
  }

  roundOff(num){
    num = num.toFixed(4);
    return parseFloat(num);
  }

  render() {
    console.log("inside render graphdata ++ "+JSON.stringify(this.state.graphData))
    var xAxis = {
        categories: this.state.xAxisData
    }, {graphData} = this.state;
    console.log("inside render ++ "+JSON.stringify(xAxis))
    return (
      <div className="app">
        <HighchartsChart /* xAxis={{ categories:[this.state.xAxisData]}} */ tooltip={{ shared: true }}>
          <Chart />
          {/* <Legend /> */}
          
          <XAxis categories={this.state.xAxisData} lable = {this.state.xAxisData}>
        <XAxis.Title >Time</XAxis.Title>
      </XAxis>
          
{/*             <div dangerouslySetInnerHTML={{__html: this.plotgraph(this.props.graphData)}}></div> */}

            {graphData && graphData.map((gData, i) => {
              const result = gData.value.filter(val => !isNaN(val));
              const roundedOffResult = result.map(this.roundOff);
                return(
                  (result && result.length) ? <YAx key={i} n={i} item={gData.key} data={roundedOffResult} /> : ''
                )
            })}

            {/* {
              (this.props.graphData.AVERAGE_FUEL && this.props.graphData.AVERAGE_FUEL.length > 0)?
              <YAx item={"AVERAGE_FUEL"} data={this.props.graphData.AVERAGE_FUEL} />  
                :
                ''
            } */}
            {/* {
              (this.props.graphData.averageEngineLoadFactor && this.props.graphData.averageEngineLoadFactor.length > 0)?
                <YAxis>
                  <YAxis.Title>Average Engine Load Factor</YAxis.Title>
                  <SplineSeries name="Average Engine Load Factor" data={this.props.graphData.averageEngineLoadFactor} />
                </YAxis>
                :
                ''
            }
            {
              (this.props.graphData.averageDiffIntermediateTemp && this.props.graphData.averageDiffIntermediateTemp.length > 0)?
                <YAxis categories={['C']} type="category">
                  <YAxis.Title>DPF Intermediate Temp</YAxis.Title>
                  <SplineSeries name="DPF Intermediate Temp" data={this.props.graphData.averageDiffIntermediateTemp} />
                </YAxis>
                :
                ''
            }
            {
              (this.props.graphData.totalDistanceTravelled && this.props.graphData.totalDistanceTravelled.length > 0)?
              <YAxis opposite>
                <YAxis.Title>Distance total</YAxis.Title>
                <ColumnSeries name="Distance total" data={this.props.graphData.totalDistanceTravelled} />
              </YAxis>
                :
                ''
            }
            {
              (this.props.graphData.averageCoolantTemp && this.props.graphData.averageCoolantTemp.length > 0)?
              <YAxis opposite>
                <YAxis.Title>Coolant Temp</YAxis.Title>
                <SplineSeries name="Coolant Temp" data={this.props.graphData.averageCoolantTemp} />
              </YAxis>
                :
                ''
            }
            {
              (this.props.graphData.averageActualEngineSpeed && this.props.graphData.averageActualEngineSpeed.length > 0)?
                <YAxis opposite>
                  <YAxis.Title>Actual Engine Speed</YAxis.Title>
                  <SplineSeries name="Actual Engine Speed" data={this.props.graphData.averageActualEngineSpeed} />
                </YAxis>
                :
                ''
            } */}
            
        </HighchartsChart>
      </div>
    );
  }
}

export default withHighcharts(Graphs, Highcharts);