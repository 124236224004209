import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '../lang';
import ReportsComponent from '../components/customer/reports/ReportsComponent';
import { getReports, getWorkReports } from '../actions/Reports';

class EquipmentContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reportsData: [],
        };
    }

    handleChange( e, type = 0 ) {
        let data = new Object();
        let index;
        const DateKey = ['fromDate', 'toDate'];
        for (var key in e) {
            index = DateKey.indexOf(key);
            data[key] = (index === -1) ? e[key] : e[key].format('YYYY-MM-DD');
        }
        if(type === 1){
            this.props.getWorkReports(data);
        }else{
            this.props.getReports(data);
        }
    }
    

    render() {
        return (
            <ReportsComponent 
                handleChange={this.handleChange.bind(this) } 
                translate={ translate } 
                {...this.props} 
            />
        );
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    let data;
    if (state.reports.data && state.reports.data.SET_REPORTS_DATA) {
        data = state.reports.data.SET_REPORTS_DATA
    }
    return {
        data: data,
        equipment : state.equipment
    };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getReports, getWorkReports
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentContainer);