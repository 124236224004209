import React from 'react';
import Equipment from '../../components/service-provider/equipments/Equipment';
import EquipmentDetails from '../../components/service-provider/equipments/EquipmentDetails';
import { translate } from '../../lang';

const EquipmentOperatingInfoContainer = ({match}) => {
    let { params } = match;
    console.log('EquipmentOperatingInfoContainer');
    return params.machineId ? <EquipmentDetails translate={translate} /> : <Equipment translate={translate} />;
}

export default EquipmentOperatingInfoContainer;