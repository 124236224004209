import {GET_FIELDINFORMATION_DATA, AREA_DATA, VERTICES_DATA, AGRICULTURAL_DATA} from '../types'

/**
* Dispatch the activity listing action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/

export const getFieldInformation = (data) => {
	return {
		type : GET_FIELDINFORMATION_DATA,
		data
	}
}


export const getAreaData = (data) => {
	return {
		type : AREA_DATA,
		data
	}
}

export const getVerticesData = (data) => {
	return {
		type : VERTICES_DATA,
		data
	}
}

export const saveAgriculturalData = (data) => {
	return {
		type : AGRICULTURAL_DATA,
		data
	}
}