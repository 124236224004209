import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { GET_EQUIPMENTS_SETTINGS_ALERT, SENDING_EQUIPMENT_ALERT_SETTINGS_REQUEST, SET_EQUIPMENT_ALERT_SETTINGS_DATA, FETCH_DATA_FAIL } from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* getEquipmentAlertSettings(action) {
  const data = action.data;
  try {
    yield put({
      type: SENDING_EQUIPMENT_ALERT_SETTINGS_REQUEST,
      data: '',
      resMsg: '',
      isSending: true,
      isSuccess: false,
      error: false
    })
    let response = yield call(api.equipmentData.getEquipmentAlertSettings, data)
    switch (response.statusCode) {
        case 200 :
          yield put({ type: SET_EQUIPMENT_ALERT_SETTINGS_DATA, data: response.data, resMsg: response.message, isSending: false, error: false })
          break;
        default:
          break;
    }
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: 'Error while connecting server'
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchEquipmentGetAlertSetting() {
  yield takeLatest(GET_EQUIPMENTS_SETTINGS_ALERT, getEquipmentAlertSettings)
}
