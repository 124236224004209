import { SET_ALERT_NOTIFICATION_DATA,
	FETCH_DATA_FAIL, SENDING_ALERT_NOTIFICATION_DATA,
	SENDING_ADD_MAINTAIN_DATA, SET_ADD_MAINTAIN_DATA } from '../../types';

/** @type { Object } default state */
let defaultState = {
  data: {
		data: '',
 	}
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function alertsNotifications(state=defaultState, action={}) {

	switch (action.type) {
		case SET_ALERT_NOTIFICATION_DATA:
			const data = {}
			data[action.type] = action.data;
			data['resMsg'] = action.resMsg;
			data['isSending'] = action.isSending;
			data['error'] = action.error;
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, data)
		      });
		case SENDING_ALERT_NOTIFICATION_DATA:
			const sendingRequest = {}
			sendingRequest[action.type] = action.data;
			sendingRequest['isSending'] = action.isSending;
			sendingRequest['error'] = action.error;
			sendingRequest['resMsg'] = action.resMsg;
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, sendingRequest)
		      });
		case SENDING_ADD_MAINTAIN_DATA:
			const sendingAddRequest = {}
			sendingAddRequest[action.type] = action.data;
			sendingAddRequest['isSending'] = action.isSending;
			sendingAddRequest['error'] = action.error;
			sendingAddRequest['resMsg'] = action.resMsg;
			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, sendingAddRequest)
		      });
		case SET_ADD_MAINTAIN_DATA:
			const addData = {}
			addData[action.type] = action.data;
			addData['resMsg'] = action.resMsg;
			addData['isSending'] = action.isSending;
			addData['error'] = action.error;


			return Object.assign({}, state,{
		        data: Object.assign({}, state.data, addData)
		      });
		case FETCH_DATA_FAIL:
			const errors = {}
			errors[action.type] = action.payload.error;
			return Object.assign({}, state, errors);

		default: return state;
	}
}
