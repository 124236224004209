import React from 'react';
import {connect} from 'react-redux';
import { FaTimesCircle } from "react-icons/fa";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setConfirmBox, setToastr, setLoader } from "../../actions/Application";
import {translate} from "../../lang"
import Moment from "react-moment";

class ConfirmBox extends React.Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.done = this.done.bind(this);
    }

    close(options) {
        this.props.setConfirmBox({modal:{...options}});
    }

    done(data=null){
        let { done } = this.props.modal;
        let { setToastr, setLoader } = this.props;
        
        setLoader({ loader: {open: true }})

        done(data).then(success => {
            /** display toast message */
            if (success.toastr){
                /** dispatch action */
                setToastr(success);
            }
            setLoader({ loader: {open: false}});

            this.close();
        })
        .catch(error => {
            this.close();

            setLoader({ loader: {open: false}});

            setToastr({ toastr: {type: "error", message: error || "This request couldn't be completed. Please try again later."}});
        });
    }

    render() {
        let { title, content, open, data, footer, header, done, size, style, className,isServiceCall } = this.props.modal;
        let selectedDate = this.props.modal.data;

        if(typeof header === 'undefined') header = true;
        if(typeof footer === 'undefined') footer = true;

        return (
            <div>
                <Modal isOpen={open} toggle={this.close} className={className + ' ' + (size || '')}>
                    {header && 
                    <ModalHeader>
                        {isServiceCall &&<div>
                            <span style={{fontWeight:'500'}}>Operating Date -</span>&nbsp;
                            <span ><Moment format="D MMM YYYY">
                                {selectedDate}
                            </Moment></span>
                        </div>}
                        <FaTimesCircle className='modal-close' onClick={() => this.close({...this.props.modal, open:false})} size='1em' />
                        {title}
                    </ModalHeader>}
                    <ModalBody style={style}>
                        {content}
                    </ModalBody>
                    {footer && <ModalFooter>
                        {done && <Button color="success" onClick={() => this.done(data)}>{translate('yes')}</Button>}
                        <Button color="danger" onClick={() => this.close({...this.props.modal, open:false})}>{translate('cancel')}</Button>
                    </ModalFooter>}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return ({
        modal: state.app.modal || undefined
    });
}

const mapDispatchToProps = {
    setConfirmBox, setToastr, setLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBox);
