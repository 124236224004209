import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _find from "lodash/find";
/** importing sub-components */
import { getRecentEvent, getAllMachineDetail, getSPRecentEvent } from "../../../api/admin/home";


import { setToastr } from "../../../actions/Application";
import warning from '../../../assets/images/my-equipment-page-icons/warning.png';
import mscompany from '../../../assets/images/admin/home/mscompany.svg';
import userProfile from '../../../assets/images/admin/home/userProfile.svg';
import equipmentInfo from '../../../assets/images/admin/home/equipmentInfo.svg'
import eqServiceInfo from '../../../assets/images/admin/home/eqServiceInfo.svg'
import hourmeter from '../../../assets/images/admin/home/HourMeterIcon.svg'
import contractManagement from '../../../assets/images/admin/home/contractManagement.png'
import email from '../../../assets/images/admin/home/Email2.svg'
import fota from '../../../assets/images/admin/home/fota.svg'
import customMap from '../../../assets/images/admin/home/customMap.svg'
import dealer from '../../../assets/images/admin/home/dealer.svg'
import location from '../../../assets/images/admin/home/Location2.svg'
import maintenance from '../../../assets/images/admin/home/maintenance.svg';
import owner from '../../../assets/images/admin/home/owner.svg';
import ssalert from '../../../assets/images/admin/home/ssalert.svg';
import Newregistration from '../../../assets/images/admin/home/Newregistration.svg';
import theft from '../../../assets/images/admin/home/theft.svg';
import equipmentalert from '../../../assets/images/admin/home/equipmentalert.svg';
import rolesicon from '../../../assets/images/menu/group.svg';
import servericon from '../../../assets/images/menu/server.svg';
import session from "../../../services/session";

class HomeComponent extends Component {

    constructor(props) {
        console.log("Home Component");
        super(props);
        this.state = {
            technicalError: 0,
            theft: 0,
            expired: 0,
            underContract: 0,
            dueExpire: 0,
            permissions: {},
            routeList: (session && session.get("prev", "cookie")) || []
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.permissions && !Object.keys(this.state.permissions).length) {
            this.setState({ permissions: newProps.permissions });
        }
    }

    componentDidMount() {
        let { routeList } = this.state;
        let isService = false;

        let hasEquipmentAlerts = _find(routeList, { uuid: '/service/equipment-alerts' });

        getRecentEvent(hasEquipmentAlerts).then(result => {
            let data = result.data;
            this.setState({
                technicalError: this.search("Technical Error", data) ? this.search("Technical Error", data).length : 0,
                theft: this.search("THEFT", data) ? this.search("THEFT", data).length : 0,
                expired: this.search("EXPIRED", data) ? this.search("EXPIRED", data).length : 0,
                dueExpire: this.search("DUE EXPIRE", data) ? this.search("DUE EXPIRE", data).length : 0,
                underContract: this.search("Under Contract", data) ? this.search("Under Contract", data).length : 0

            })
        }).catch(error => {
            this.props.setToastr({ toastr: { message: "Error Occured", type: "error" } });
        })

    }

    search(nameKey, myArray) {
        let newArr = [];
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].eventType.toLowerCase() === nameKey.toLowerCase()) {
                newArr.push(myArray[i])
            }
        }
        return newArr;
    }

    render() {

        let { routeList } = this.state;
        let { contractDetails } = this.props;
        let expiredCount = 0;
        let underContractCount = 0;
        let dueToExpire = 0;

        if (contractDetails) {
            contractDetails.map((contractDetail) => {
                if (contractDetail.status === 'Due to Expire')
                    dueToExpire++;
                if (contractDetail.status === 'Expired')
                    expiredCount++;
                if (contractDetail.status === 'Under Contract')
                    underContractCount++
            })
        }
        let modules = {
            serviceCompanies: "/service/manage-service-companies",
            serviceAlerts: "/service/schedule-service-alert",
            owners: "/service/owners",
            systemMaintenance: "/service/system-maintenance",
            dataControl: "/service/data-control",
            newRegistration: "/service/owners/add",
            equipmentAlert: "/service/equipment-alerts",
            spEquipmentAlert: "/service/sp_equipment-alerts",
            roles: "/service/roles",
            equipmentOperatingInfo: "/service/equipment-operating-info",
            SPProfile: "/service/profile",
            SPEquipmentServiceInfo: "/service/equipment-service-info",
            fota: "/service/fota",
            customMap: "/service/custom-map",
            dealerMangement: "/service/dealer-management",
            equipmentLocationInfo: "/service/equipment-location-info",
            emailInformation: "/service/email-information",
            contractManagement: "/service/contract-management",
            contractManagementUnderContract: "/service/contract-management/Under Contract",
            contractManagementDueToExpire: "/service/contract-management/DueToExpire",
            contractManagementExpired: "/service/contract-management/Expired",
            hourmeterOffsetManagement: "/service/hourmeter-offset-management"
        };

        let hasEquipmentAlerts = _find(routeList, { uuid: modules.equipmentAlert }),
            hasSpEquipmentAlerts = _find(routeList, { uuid: modules.spEquipmentAlert }),
            hasServiceComapnies = _find(routeList, { uuid: modules.serviceCompanies }),
            hasServiceAlerts = _find(routeList, { uuid: modules.serviceAlerts }),
            hasOwners = _find(routeList, { uuid: modules.owners }),
            hasSystemMaintenance = _find(routeList, { uuid: modules.systemMaintenance }),
            hasDataControl = _find(routeList, { uuid: modules.dataControl }),
            hasNewReg = _find(routeList, { uuid: modules.newRegistration }),
            hasRoles = _find(routeList, { uuid: modules.roles }),
            hasEquipmentOperatingInfo = _find(routeList, { uuid: modules.equipmentOperatingInfo }),
            hasUserDetail = _find(routeList, { uuid: modules.SPProfile }),
            hasEquipmentServiceInfo = _find(routeList, { uuid: modules.SPEquipmentServiceInfo }),
            hasFota = _find(routeList, { uuid: modules.fota }),
            hasCustomMap = _find(routeList, { uuid: modules.customMap }),
            hasDealerMangement = _find(routeList, { uuid: modules.dealerMangement }),
            hasEquipmentLocationInfo = _find(routeList, { uuid: modules.equipmentLocationInfo }),
            hasEmailInformation = _find(routeList, { uuid: modules.emailInformation }),
            hasContractManagement = _find(routeList, { uuid: modules.contractManagement }),
            hasHourmeterOffsetManagement = _find(routeList, { uuid: modules.hourmeterOffsetManagement });


        /*  let hasEquipmentAlerts = true,
         hasSpEquipmentAlerts = true,
        hasServiceComapnies = true,
        hasServiceAlerts = true,
        hasOwners =true,
        hasSystemMaintenance = true,
        hasDataControl = true,
        hasNewReg =true,
        hasRoles =true,
        hasEquipmentOperatingInfo = true,
        hasUserDetail =  true,
        hasEquipmentServiceInfo = true,
        hasFota = true,
        hasCustomMap =  true,
        hasDealerMangement = true;  */


        return (
            <Row className='c-admin-wrapper'>
                {(hasServiceComapnies || hasServiceAlerts || hasOwners || hasSystemMaintenance || hasDataControl || hasRoles || hasEquipmentOperatingInfo || hasUserDetail || hasEquipmentServiceInfo || hasEquipmentLocationInfo || hasEmailInformation || hasContractManagement || hasHourmeterOffsetManagement) && <Col lg='9' md='7' xs='12' className='c-admin-wrapper-left'>
                    <div className='list'>
                        <ul >
                            {hasServiceComapnies && <li >
                                <span className='icon'><img src={mscompany} /></span>
                                <div>
                                    <h5>Manage Service Companies</h5>
                                    <p>Manage all service companies or dealers that help in selling products. View all dealers and define their roles.</p>
                                    <Link to={modules.serviceCompanies}>View all companies</Link>
                                </div>
                            </li>}
                            {hasServiceAlerts && <li>
                                <span className='icon'><img src={ssalert} /></span>
                                <div>
                                    <h5>Schedule Service Alerts</h5>
                                    <p>Schedule and manage service alerts for different types of equipments.</p>
                                    <Link to={modules.serviceAlerts}>Schedule alerts</Link>
                                </div>
                            </li>}
                            {hasOwners && <li>
                                <span className='icon'><img src={owner} /></span>
                                <div>
                                    <h5>Owner Management</h5>
                                    <p>It helps you to manage authorization, which enables to assign different roles to user.</p>
                                    <Link to={modules.owners}>View all owners</Link>
                                </div>
                            </li>}
                            {hasSystemMaintenance && <li>
                                <span className='icon'><img src={maintenance} /></span>
                                <div>
                                    <h5>System Maintenance</h5>
                                    <p>It allows you to schedule maintenance for different applications.</p>
                                    <Link to={modules.systemMaintenance}>View all system maintenance</Link>
                                </div>
                            </li>}
                            {hasDataControl && <li>
                                <span className='icon'><img src={servericon} /></span>
                                <div>
                                    <h5>Data Control</h5>
                                    <p>Helps in creating and managing parent child relationship between service companies.</p>
                                    <Link to={modules.dataControl}>View all data control</Link>
                                </div>
                            </li>}
                            {hasRoles && <li>
                                <span className='icon'><img src={rolesicon} /></span>
                                <div>
                                    <h5>Manage Roles</h5>
                                    <p>It helps you to create new roles which provides specific permission to users of the application.</p>
                                    <Link to={modules.roles}>View all roles</Link>
                                </div>
                            </li>}
                            {hasCustomMap && <li>
                                <span className='icon'><img src={customMap} /></span>
                                <div>
                                    <h5>Custom Map</h5>
                                    <p>It is used to define a different map for the device .</p>
                                    <Link to={modules.customMap}>View all custom map</Link>
                                </div>
                            </li>}
                            {hasFota && <li>
                                <span className='icon'><img src={fota} /></span>
                                <div>
                                    <h5>Fota</h5>
                                    <p>Firmware Over-the-Air (FOTA) is sent to device after the machine is stopped.</p>
                                    <Link to={modules.fota}>Click Here</Link>
                                </div>
                            </li>}
                            {hasDealerMangement && <li>
                                <span className='icon'><img src={dealer} /></span>
                                <div>
                                    <h5>Dealer Management</h5>
                                    <p>Any number of equipment of one dealer can be transferred to other dealer. </p>
                                    <Link to={modules.dealerMangement}>Click Here</Link>
                                </div>
                            </li>}
                            {hasEquipmentLocationInfo && <li>
                                <span className='icon'><img src={location} /></span>
                                <div>
                                    <h5>Equipment Location Info</h5>
                                    <p>All the information about equipment location can be seen.</p>
                                    <Link to={modules.equipmentLocationInfo}>View Location</Link>
                                </div>
                            </li>}
                            {hasEmailInformation && <li>
                                <span className='icon'><img src={email} /></span>
                                <div>
                                    <h5>Manage Email Address</h5>
                                    <p>Add,Delete and Save Email ID of the companies.</p>
                                    <Link to={modules.emailInformation}>View Email</Link>
                                </div>
                            </li>}
                            {hasEquipmentOperatingInfo && <li>
                                <span className='icon'><img src={equipmentInfo} /></span>
                                <div>
                                    <h5>EQUIPMENT OPERATING INFO</h5>
                                    <p>All the information about equipment operation can be seen.</p>
                                    <Link to={modules.equipmentOperatingInfo}>View all Operating Info</Link>
                                </div>
                            </li>}
                            {hasUserDetail && <li>
                                <span className='icon'><img src={userProfile} /></span>
                                <div>
                                    <h5>USER DETAILS </h5>
                                    <p>User can see his details like id, name, email, company name and language.</p>
                                    <Link to={modules.SPProfile}>View User Detail</Link>
                                </div>
                            </li>}
                            {hasEquipmentServiceInfo && <li>
                                <span className='icon'><img src={eqServiceInfo} /></span>
                                <div>
                                    <h5>EQUIPMENT SERVICE INFO</h5>
                                    <p>User can update the service performed on equipment and view previous service history.</p>
                                    <Link to={modules.SPEquipmentServiceInfo}>View Equipment Service Info</Link>
                                </div>
                            </li>}
                            {hasHourmeterOffsetManagement && <li>
                                <span className='icon'><img src={hourmeter} /></span>
                                <div>
                                    <h5>MANAGE HOURMETER OFFSET</h5>
                                    <p>User can update the hourmeter offset value of equipment and view related history.</p>
                                    <Link to={modules.hourmeterOffsetManagement}>View Hourmeter Offset Info</Link>
                                </div>
                            </li>}
                        </ul>
                    </div>
                </Col>}
                <Col lg='3' md='5' xs='12' className='c-admin-wrapper-right'>
                    {(hasEquipmentAlerts || hasSpEquipmentAlerts) && <section className='equipment-alert'>
                        <section className='top'>
                            <span className='icon'><img src={equipmentalert} width='75' height='75' /></span>
                            <h5>EQUIPMENT ALERTS</h5>
                            <p>Recent alert and thefts that occurred in last 72 hours</p>
                        </section>
                        <section className='bottom'>
                            <div className='error-count'>
                                <img src={warning} />
                                <div>ERROR</div>
                                <span>{this.state.technicalError}</span>
                            </div>
                            <div className='theft-count'>
                                <img src={theft} />
                                <div>THEFT</div>
                                <span>{this.state.theft}</span>
                            </div>
                            {<Link to={modules.equipmentAlert} className='btn-register'>View all Alerts</Link>}
                        </section>
                    </section>}
                    {hasContractManagement && <section className='contractManagement'>
                        <section className='top'>
                            <span className='icon'><img src={contractManagement} width='100' height='100' /></span>
                            <h5>CONTRACT MANAGEMENT</h5>
                            <p>It helps to check the status of contract agreement and renew contract</p>
                        </section>
                        <section className='bottom'>
                            <Link to={modules.contractManagementExpired} >
                                <div className='contract-count'>
                                    <div>Expired</div>
                                    <span>{expiredCount}</span>
                                </div></Link>
                            <Link to={modules.contractManagementDueToExpire} >
                                <div className='contract-count'>
                                    <div>Due to Expire</div>
                                    <span>{dueToExpire}</span>
                                </div></Link>
                            <Link to={modules.contractManagementUnderContract} >
                                <div className='contract-count'>
                                    <div>Under Contract
                                    </div>
                                    <span>{underContractCount}</span>
                                </div></Link>
                            {<Link to={modules.contractManagement} className='btn-register'>View all Contracts</Link>}
                        </section>
                    </section>}
                    {hasNewReg && <section className='new-registration'>
                        <section className='top'>
                            <span className='icon'><img src={Newregistration} width='75' height='75' /></span>
                            <h5>NEW REGISTRATION</h5>
                            <p>Register equipment owner details</p>
                        </section>
                        <section className='bottom'>
                            <p>Customer registration is required for all new equipment buyers to use services of this application.</p>
                            <Link to={modules.newRegistration} className='btn-register'>REGISTER</Link>
                        </section>
                    </section>}

                </Col>
            </Row>

        );
    }
}
const mapStateToProps = state => {

    return {
        permissions: state.app.permissions

    };
}

const mapDispatchToProps = { setToastr }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeComponent));