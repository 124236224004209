import React, { Component, Fragment } from "react";
import { Map, GoogleApiWrapper, Polygon, InfoWindow, Polyline, Marker } from 'google-maps-react';
import _compact from 'lodash/compact';
import { MapKey } from "../../constants/Constants";
import Loadr from "../../assets/images/download.gif";
import Moment from 'react-moment';
import { startAsyncValidation } from "redux-form";
import start from "../../assets/images/map/location_pin3.png";
import offline from "../../assets/images/map/location_pin4.png";
const LoadingContainer = (props) => (
    <div style={{ top: 0, bottom: 0, left: 0, right: 0 }} className='d-flex justify-content-center align-items-center maploadr'><img src={Loadr} alt='loader' /></div>
)

const colors = ["blue", "orange", "red", "green", "black", "brown", "purple", "gray", "yellow", "white", "violet", "sienna", "black", "brown", "purple", "gray"];

export class MapContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lines: [],
            points: [],
            selectedPlace: null,
            activeMarker: null,
            showingInfoWindow: false,
        }

        this.setMapCoords = this.setMapCoords.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
    }

    getBoundedPoints(coords) {
        let points = [];

        if (coords && coords.length) {
            /** if coordinates*/
            coords.forEach(coord => {
                if (coord.bootStartLattitude && coord.bootStartLongitude) {
                    points.push({ lat: parseFloat(coord.bootStartLattitude), lng: parseFloat(coord.bootStartLongitude) });
                }

                if (coord.bootStopLattitude && coord.bootStopLongitude) {
                    points.push({ lat: parseFloat(coord.bootStopLattitude), lng: parseFloat(coord.bootStopLongitude) });
                }
            });
            if (!_compact(points).length) {
                points = []
            }
        }


        if (!points.length) {
            /** if no coordinates then set Japan Corrdinates as bounded coord*/
            points = [{ lat: 23.63936, lng: 68.14712 }, { lat: 28.20453, lng: 97.34466 }]
        }

        return points;
    }

    getDriverBoundedPoints(coords) {
        let points = [];

        if (coords && coords.length) {
            /** if coordinates*/
            coords.forEach(marker => {
                for (let i in marker) {
                    if (marker[i] && marker[i].length > 0) {
                        marker[i].forEach(point => points.push({ lat: parseFloat(point.latitude), lng: parseFloat(point.longitude) }));
                    }
                }
            });
        } else {
            /** if no coordinates then set India Corrdinates as bounded coord*/
            points = [{ lat: 23.63936, lng: 68.14712 }, { lat: 28.20453, lng: 97.34466 }]
        }

        return points;
    }

    getLines(linecords) {
        let lines = [];

        if (linecords && linecords.length) {
            linecords.forEach(marker => {
                lines.push([
                    { lat: parseFloat(marker.bootStartLattitude), lng: parseFloat(marker.bootStartLongitude) },
                    { lat: parseFloat(marker.bootStopLattitude), lng: parseFloat(marker.bootStopLongitude) }
                ]);
            });
        }

        return lines;
    }

    getDriverLines(linecords,group) {
        let obj = {};
        let lines = [];

        if (linecords && linecords.length) {
            linecords.forEach((marker, i) => {
                let routes = [];
                let routesLine = [];
                let insideLine = [];
                for (let i in marker) {
                    if (marker[i] && marker[i].length >= 2) {
                        marker[i].forEach(point => {

                            let drivingStatus = null;

                            if ((point.frameCode) == 1) {
                                routesLine = [];
                                obj = {
                                    ...obj,
                                    info: {
                                        group:group,
                                        framecode: point.frameCode,
                                        drivingStatus: 'Start Point',
                                        eventTimeStamp: point.eventTimeStamp
                                    },
                                    lat: parseFloat(point.latitude),
                                    lng: parseFloat(point.longitude)
                                };
                            } else if ((point.frameCode) == 2) {
                                obj = {
                                    ...obj,
                                    info: {
                                        framecode: point.frameCode,
                                        drivingStatus: 'Stop Point',
                                        eventTimeStamp: point.eventTimeStamp
                                    },
                                    lat: parseFloat(point.latitude),
                                    lng: parseFloat(point.longitude)
                                };
                            } else {
                                switch (point.drivingStatus) {
                                    case 'EngIdel':
                                        drivingStatus = 'Idling';
                                        break;
                                    case 'Driving':
                                        drivingStatus = 'Running';
                                        break;
                                    case 'HighSpeed':
                                        drivingStatus = 'High Speed Running';
                                        break;
                                    case 'PTO':
                                        drivingStatus = 'PTO Running';
                                        break;
                                }
                                obj = {
                                    info: {
                                        drivingStatus: drivingStatus,
                                        color: point.color,
                                        framecode: point.frameCode,
                                        trendAnalogValue: point.trendAnalogValue,
                                        eventTimeStamp: point.eventTimeStamp,
                                    },
                                    lat: parseFloat(point.latitude),
                                    lng: parseFloat(point.longitude)
                                }
                            }



                            if (point.latitude !== null && point.longitude !== null)
                               { routes.push(
                                    obj
                                );
                            routesLine.push(
                                obj
                            );}
                        });

                        insideLine.push(routesLine);
                    }

                }
                lines.push(insideLine);
            });
        }
        console.log("line",lines[0])
        return lines[0];
    }
    setMapCoords(props) {
        let { drivercoords, linecords,group } = props, points,
        lines = drivercoords ? this.getDriverLines(drivercoords,group) : this.getLines(linecords);
        points = drivercoords ? this.getDriverBoundedPoints(drivercoords) : this.getBoundedPoints(linecords);
        this.setState({ lines, points });
    }

    componentDidMount() {
        this.setMapCoords(this.props);
    }

    componentWillReceiveProps(newProps) {
        this.setMapCoords(newProps);
    }
    onMarkerClick(props, marker, e) {

        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    }


    render() {
        let { width, height, isMarker, isRouteMap,group, showLegend } = this.props,
            { lines, points, activeMarker, selectedPlace, showingInfoWindow } = this.state;

        let bounds = new this.props.google.maps.LatLngBounds();

        if (points && !points.length) {
            points = [{ lat: 23.63936, lng: 68.14712 }, { lat: 28.20453, lng: 97.34466 }]
        }

        for (let i = 0; i < points.length; i++) {
            if (points[i] && points[i].lat && points[i].lng) {
                bounds.extend(points[i]);
            }
        }

        if (this.props.isRouteMap && this.props.isRouteMap === 'yes') {
            let routeMapStatus = false;
            if (lines && lines.length) {
                lines.map(line => {
                    if (line.length > 2) {
                        routeMapStatus = true;
                    }
                })
            }

            if (routeMapStatus === false) {
                bounds = new this.props.google.maps.LatLngBounds();
                let pts = [{ lat: 23.63936, lng: 68.14712 }, { lat: 28.20453, lng: 97.34466 }]
                for (let i = 0; i < pts.length; i++) {
                    if (pts[i] && pts[i].lat && pts[i].lng) {
                        bounds.extend(pts[i]);
                    }
                }
            }
        }

        let props = {
            google: this.props.google,
            style: { width: width || '100%', height: height || '100%', position: 'relative' },
            className: 'map',
            zoom: 15,
            bounds
        }

        let markers = [];

        // if (lines && lines.length > 0) {
        //     let line = lines[0];
        //     if (line && line.length > 0) {
        //         markers = [...line];

        //     }
        // }
        if (lines && lines.length > 0) {
            for (let i in lines) {
                for (let x in lines[i]) {
                    if(lines[i][x].info){
                        markers.push(lines[i][x])
                    }else{
                    let obj = {};
                    obj = {
                        ...obj,
                        info: {
                            framecode: x== 0 ? 1 : 2 ,
                            drivingStatus : x == 0 ? 'Start Point' : 'Stop Point'
                        },
                        lat: lines[i][x].lat,
                        lng: lines[i][x].lng
                    };
                    markers.push(obj);
                }
                }
            }
        }
        
        const markerIcon = (e) => {
            if (e.info.framecode == 1) return start;
            if (e.info.framecode == 2) return offline;
            
            var goldStar = {
                // size 15 X 15
                path: 'M 14.5625 2.226562 L 0.4375 2.226562 C 0.195312 2.226562 0 2.421875 0 2.664062 L 0 12.335938 C 0 12.578125 0.195312 12.773438 0.4375 12.773438 L 14.5625 12.773438 C 14.804688 12.773438 15 12.578125 15 12.335938 L 15 2.664062 C 15 2.421875 14.804688 2.226562 14.5625 2.226562 Z M 14.5625 2.226562',
                fillColor: (e.info ? e.info.color : null),
                fillOpacity: 0.8,
                strokeWeight: 1,
                scale: 1,
                strokeColor: '#000099',
                
            };

            if(this.props.group == "CE" || this.props.group == "CH"){
                goldStar.fillOpacity = 0.0;
                goldStar.strokeWeight=0
            }
            return goldStar
        }

        let mapLegend = [];
        markers.forEach(e => {
            if (e && e.info) {
                if (showLegend!=false && e.info.drivingStatus) {
                    mapLegend.push({ color: e.info.color, drivingStatus: e.info.drivingStatus, framecode: e.info.framecode });
                }
            }
        });

        function removeDuplicates(myArr, prop) {
            return myArr.filter((obj, pos, arr) => {
                return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
            });
        }

        if (mapLegend && mapLegend.length > 0) {
            mapLegend = removeDuplicates(mapLegend, 'drivingStatus');
        }
        return (
            <Fragment>
                {mapLegend && mapLegend.length > 0 &&
                    <div className="map-legend">
                        {mapLegend.map((e, i) => (<div key={i}>
                            {e.framecode == 1 && <span><img src={start} /></span>}
                            {e.framecode == 2 && <span><img src={offline} /></span>}
                            {!e.framecode && <span style={{ backgroundColor: e.color }}></span>}

                            <small>{e.drivingStatus}</small>
                        </div>))}
                    </div>
                }

                <Map {...props} google={this.props.google} zoom={14}>

                    {(isMarker && markers && markers.map((e, i) => (

                        <Marker
                            key={i}
                            data={e.info}
                            name={'Marker-' + (i + 1)}
                            onClick={this.onMarkerClick}
                            icon={markerIcon(e)}
                            position={{ lat: e.lat, lng: e.lng }} />


                    )))
                    }


                    <InfoWindow
                        marker={activeMarker}
                        visible={showingInfoWindow}>
                        <div>
                            {(selectedPlace && selectedPlace.data) &&
                                <div>
                                    <div> <strong className="driving-status"> {selectedPlace.data.drivingStatus}</strong> </div>
                                    <div className="info-windows"> <span>DATE TIME</span> :<strong>{<Moment format='DD-MMM-YYYY HH:mm:ss'>{selectedPlace.data.eventTimeStamp}</Moment>}</strong> </div>
                                    <div>
                                        {selectedPlace.data && !selectedPlace.data.framecode && Object.keys(selectedPlace.data.trendAnalogValue).map((key, i) => (
                                            <Fragment key={i}>
                                                <div className="info-windows"> <span>{key}</span> : <strong>{parseFloat(selectedPlace.data.trendAnalogValue[key])>0?selectedPlace.data.trendAnalogValue[key]:0}</strong> </div>
                                            </Fragment>
                                        ))}
                                    </div>
                                </div>
                            }

                        </div>
                    </InfoWindow>

                    {/* Draw lines here */}
                    {!isRouteMap && lines.length && lines.map((line, i) => {
                        return (
                            <Polygon key={i}
                                paths={line}
                                strokeColor={colors[i]}
                                strokeOpacity={1}
                                strokeWeight={2}
                            />
                        );
                    }
                        //isMarker

                    )}
                    {isRouteMap && lines.length && lines.map((line, i) => {
                        return (
                            <Polyline
                                path={line}
                                strokeColor="#000099"
                                strokeOpacity={0.8}
                                strokeWeight={3}
                            />
                        );
                    }
                        //isMarker

                    )}
                </Map>

            </Fragment>);
    }
}

export default GoogleApiWrapper({ apiKey: (MapKey), LoadingContainer })(MapContainer);