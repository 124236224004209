import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { UPDATE_EQUIPMENTS_DATA,SENDING_EQUIPMENT_UPDATE_DATA,SET_EQUIPMENT_UPDATE_DATA,FETCH_DATA_FAIL } from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* updateEquipmentData(action) {
  const data = action.data;
  try {
    yield put({ type: SENDING_EQUIPMENT_UPDATE_DATA, 
                data: '', 
                resMsg: '',
                isSending: true,
                error: false
              })
    let response = yield call(api.equipmentData.updateEquipment, data)  
    yield put({ type: SET_EQUIPMENT_UPDATE_DATA, data: response, resMsg: response, isSending: false, error: false })
    /*switch (response.statusCode) {
        case 200 :
          yield put({ type: SET_DASHBOARD_DATA, data: response, resMsg: response.message, isSending: false, error: false })
          break;
    }*/
  } catch (e) {
    yield put({
      type: SET_EQUIPMENT_UPDATE_DATA,data: e, isSending: false, error: true
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchEquipmentUpdate() {
  yield takeLatest(UPDATE_EQUIPMENTS_DATA, updateEquipmentData)
}
