import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import 'react-table/react-table.css'


export default class Table extends Component {
    
    render(){
        let { data, columns, loading, noDataText, defaultPageSize, minRows, showPagination, showPaginationBottom, fetchData, pages, sortable, getTrProps, className } = this.props;
        return(
            <Fragment>
                <ReactTable 
                    className={className || ""}
                    data={data} 
                    columns={columns}
                    pages={pages || 0}
                    loading={loading || false}
                    showPageSizeOptions={false}
                    showPagination={showPagination || true}
                    showPaginationBottom={showPaginationBottom || false}
                    defaultPageSize= {defaultPageSize || 15}
                    sortable= {sortable || false}
                    resizable={false}
                    minRows={minRows || 8}
                    manual
                    onFetchData={fetchData}
                    getTrProps={getTrProps}
                    noDataText={noDataText || 'No rows found'}
                />
            </Fragment>
        );
    }
}
