import React, { Component } from "react";
import ReactHighcharts from "react-highcharts";  // Expects that Highcharts was loaded in the code.
import HighchartsMore from 'highcharts/highcharts-more';
import moment from "moment";
import { connect } from "react-redux";
import Moment from "react-moment";

HighchartsMore(ReactHighcharts.Highcharts);

const intoSeconds = (time) => {
    let hms = time;   // your input string
    let a = hms.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    return (seconds / 3600).toFixed(2);
}

/*Convert Minutes into Hours*/
const convertMinute = (time) => {
    if (time) {
        let m1 = time.split(":");
        let h = +m1[0], m = +m1[1], s = +m1[2];
        let d = h + (m / 60) + (s / 3600);
        return +(d.toFixed(2));
    }
}

class Chart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartConfig: null,
            isRender: false
        }
        // this.onChangeLegend= this.onChangeLegend.bind(this);
    }

    render() {

        let { operatingInfo, translate, group, operatingInformation, isService } = this.props;
        console.log("group", group)
        let operatingHours = [];
        let PTORunningTime = [];
        let IdleingHours = [];
        let RunningHours = [];
        let HighSpeedHours = [];
        let WorkDetailsMemo = [];
        let OilingQty = [];
        let WorkingWidth = [];
        let AcreageReport = [];
        let MemberDetails = [];
        let coolingWaterTemps = [];
        let hydraulicTempsAvg = [];
        let coolingWaterTempsAvg = [];
        let hydraulicTempsMin = [];
        let hydraulicTemps = [];
        let alarmInfos = [];




        let finalOperatingInfo = operatingInfo;
        if (isService && operatingInformation) {
            finalOperatingInfo = operatingInformation.operatingInfoResponse;
        }

        finalOperatingInfo.forEach(e => {
            let operatingTime = convertMinute(e.operatingHour);
            operatingHours.push(operatingTime || 0);

            PTORunningTime.push(e.ptoHr || 0);
            IdleingHours.push(e.idleHr || 0);

            let runningsTime = e.runningTime;
            RunningHours.push(runningsTime || 0);

            let hightspeed = e.highSpeed;
            HighSpeedHours.push(hightspeed || 0);

            WorkDetailsMemo.push(e.memo || 0);
            OilingQty.push(e.oilQuantity || 0);
            WorkingWidth.push(e.workingWidth || 0);
            AcreageReport.push(e.acreageReport || 0);
            MemberDetails.push(e.member || 0);
            coolingWaterTemps.push(e.coolingTemperatureMax && e.coolingTemperatureMin ? [e.coolingTemperatureMin, e.coolingTemperatureMax] : 0);
            coolingWaterTempsAvg.push(e.coolingTemperatureAvg || 0);
            hydraulicTemps.push(e.hydraulicOilMin && e.hydraulicOilMax ? [e.hydraulicOilMin, e.hydraulicOilMax] : 0);

            hydraulicTempsAvg.push(e.hydraulicOilAvg || 0);
            alarmInfos.push(e.waterTempAlarm || 0);
        });

        let categories = [];

        RunningHours.forEach((e => categories.push([])));

        let config = {
            chart: {

                type: 'column'
            },

            title: {
                text: translate('operatingHourAndDyanmicInfo')
            },

            xAxis: {
                categories: [
                    ...categories
                ],
                crosshair: true,

            },

            yAxis: [{
                min: 0,
                title: {
                    text: ''/* translate('refuelQuantity')    */
                },
                //stacked: true,
                labels: {
                    useHTML: true,
                    formatter: function () {
                        if (group == 'TE')
                            return `
                    <span class ="left-info">
                        ${this.value} <span>L</span> &nbsp; &nbsp; 
                        ${this.value} <span>M</span>
                    </span>`
                        else
                            return `
                    <span class ="left-info">
                        ${this.value} <span>°C</span>
                    </span>`
                    }
                }
            }, {
               
                title: {
                    text: ''/* translate('operationalHour') */
                },
                labels: {
                    useHTML: true,
                    formatter: function () {
                        if (group == 'TE')
                            return `
                                <span class ="right-info"> 
                                ${this.value} <span>Hrs</span> &nbsp; &nbsp; 
                                ${this.value} <span>Ha</span>
                                </span>`
                        else {
                            return `
                                <span class ="right-info"> 
                                ${this.value} <span>Hrs</span>
                                </span>`
                        }
                    }
                },
                opposite: true
            }],

            plotOptions: {
                column: {
                    pointPadding: 0.25,
                    borderWidth: 0,
                    pointPlacement: 0.5
                   // 
                  // pointWidth: 20
                },
                columnrange: {
                    pointPadding: 0.25,
                   // pointWidth: 20
                },

                series: {
                    //pointWidth: isService ? '50':'10',
                    events: {
                        legendItemClick: function (e) {
                        }
                    }
                }
            },

            colors: ['#72a955', '#ff0000', '#27aad4', '#f4b27e', '#0022ff', '#f905fd', '#f0ff03', '#46005d', '#d5ce43'],

            credits: {
                enabled: false
            },

            series: [
                {
                    name: translate('operatingHourChart'),
                    type: "column",
                    data: operatingHours,
                    yAxis:1,
                    visible: true,
                    tooltip: {
                        valueSuffix: 'Hrs'
                    },
                }
            ]
        };
        if (group == 'TE') {
            let tractorSeries = [{
                name: translate('ptoRunningHour'),
                type: "column",
                data: PTORunningTime,
                yAxis:1,
                visible: true,
                tooltip: {
                    valueDecimals: 4,
                    valueSuffix: 'Hrs'
                }
            },
            {
                name: translate('idlingHour'),
                type: "column",
                data: IdleingHours,
                yAxis:1,
                visible: true,
                tooltip: {
                    valueDecimals: 4,
                    valueSuffix: 'Hrs'
                }
            },
            {
                name: translate('runningTime'),
                type: "column",
                data: RunningHours,
                yAxis:1,
                visible: true,
                tooltip: {
                    valueDecimals: 4,
                    valueSuffix: 'Hrs'
                }
            },
            {
                name: translate('highSpeed'),
                type: "column",
                data: HighSpeedHours,
                yAxis:1,
                visible: true,
                tooltip: {
                    valueDecimals: 4,
                    valueSuffix: 'Hrs'
                }
            },
            {
                name: translate('refuelQty'),
                type: "column",
                data: OilingQty,
                yAxis:0,
                visible: true,
                tooltip: {
                    valueDecimals: 4,
                    valueSuffix: 'L'
                }
            },
            {
                name: translate('workingWidth'),
                type: "column",
                data: WorkingWidth,
                yAxis:0,
                visible: true,
                tooltip: {
                    valueDecimals: 4,
                    valueSuffix: 'm'
                }
            },
            {
                name: translate('acreageArea'),
                type: "column",
                data: AcreageReport,
                yAxis:1,
                visible: true,
                tooltip: {
                    valueDecimals: 4,
                    valueSuffix: 'Ha'
                }
            }];
            config.series.push(...tractorSeries);
        }
        else if (group == 'CE') {

            let constructionSeries = [
                {
                    type: isService ? "columnrange" : "column",
                    id: 'hydraulicTemp',
                    name: translate('hydraulicTemp'),
                    data: isService ? hydraulicTemps : hydraulicTempsAvg,
                    yAxis:0,
                    // min:10,
                    // stack: 0,
                    visible: true,
                    tooltip: {
                        valueDecimals: 4,
                        valueSuffix: '°C'
                    }
                }, {
                    name: translate('coolingTemp'),
                    id: 'coolingTemp',
                    data: isService ? coolingWaterTemps : coolingWaterTempsAvg,
                    type: isService ? "columnrange" : "column",
                    yAxis:0,
                    //data: coolingWaterTemps,
                    visible: true,
                    tooltip: {
                        valueDecimals: 4,
                        valueSuffix: '°C'
                    }
                }
            ];

            let lineSeries = [{
                name: translate('hydraulicTempAvg'),
                linkedTo: 'hydraulicTemp',
                type: "scatter" ,
                yAxis:0,
                data: hydraulicTempsAvg,
                visible: true,
                pointPlacement:-0.15,
                tooltip: {
                    valueDecimals: 4,
                     //valueSuffix: '°C'
                     pointFormat: '{point.y} °C'
                }
            },
            {
                name: translate('coolingTempAvg'),
                yAxis:0,
                type: "scatter",
                linkedTo: 'coolingTemp',
                data: coolingWaterTempsAvg,
                visible: true,
                tooltip: {
                    valueDecimals: 4,
                     //valueSuffix: '°C'
                     pointFormat: '{point.y} °C'
                },
                pointPlacement:  0.15
            }];
            if(isService){
                constructionSeries.push(...lineSeries);
            }
            config.series.push(...constructionSeries);
        }
        else if (group == 'CH') {
            let combinedEquipmentSeries = [{
                name: translate('alarmInfo'),
                data: alarmInfos,
                yAxis:1,
                visible: true,
                tooltip: {
                    valueDecimals: 4,
                    valueSuffix: ''
                }
            }
            ];
            config.series.push(...combinedEquipmentSeries);
        }


        return <> <ReactHighcharts config={config}></ReactHighcharts></>;
    }

}



const mapStateToProps = state => {
    return ({
        operatingInfo: state.dailywork.operatingInfo || {},
        operatingInformation: state.dailywork.equipOperatingInfo || {},
        timezone: state.profile.timezone || "Asia/Kolkata",
        group: state.dailywork.status && state.dailywork.status.toUpperCase() || {}
    });
};
/* ReactHighcharts.Highcharts.wrap(ReactHighcharts.Highcharts.seriesTypes.columnrange.prototype, 'drawPoints', function (proceed) {
    // Draw Points as normal   
    proceed.apply(this);

    var renderer = this.chart.renderer;

    // Loop over the data points
    ReactHighcharts.Highcharts.each(this.points, function (point) {
        // Retrieve the dimensions of the column
        var shapeArgs = point.shapeArgs,
            x = shapeArgs.x + (shapeArgs.width / 2) - 5,
            y = shapeArgs.y + (shapeArgs.height / 2) - 5,
            averageMarker = point.averageMarker;

        if (averageMarker) {
            // If the marker already exists, move it to a new positions
            point.averageMarker.animate({
                x: x,
                y: y
            });
        } else {
            // Otherwise create it and attach it to the point
            point.averageMarker = point.graphic.renderer.symbol('circle', x, y,7, 7).attr({
                fill: 'black'
            }).add(point.graphic.parentGroup);
        }
    });
}); */
export default connect(mapStateToProps)(Chart);