import { members, add_member, edit_member, daily_work,service_history, my_account, equipments, equipments_detail, work_report, location_information, alert_settings, alert_settings_byid, filed_information,
    equipmentAlerts, equipmentAlertDetails, scheduleServiceAlert, systemMaintenance, addSystemMaintenance, owners, addOwner, ownerDetails, editOwnerDetails,dataControl, SVEquipemtAlert,
    SVEquipemtAlertDetail,SPEquipmentOperatingInfo, SPEquipmentDetailOperatingInfo,SPEquipmentDetailOperatingInfoContractRenew , SPEquipmentServiceInfo,home, manageServiceCompany, roles, addRole, editRole, SPProfile, dashboard, CustomMap, FOTA,dealerMangement, member_management,teamMembers,compareRoles,area_measurement,equipmentLocationInfo,contractManagement,SPContractManagementContractRenew,contractManagementStatus,emailInformation, hourmeterOffsetManagement, hourmeterOffsetDetail,editDetailsContractRenew } from "./Startpoints";
import { translate } from '../lang';

const Breadcrumbs = {
    [dashboard]: {
        tagline: "Dashboard",
        links: [
            { title: translate('Dashboard'), href: "/customer/dashboard" }
        ]
    },
    [equipments]: {
        tagline: translate('myEquipment'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('myEquipment') },
        ]
    },
    [equipments_detail]: {
        tagline: translate('equipment'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('myEquipment'), href: "/customer/equipment" },
            { title: translate('equipmentDetails') },
        ]
    },
    [work_report]: {
        tagline: translate('manageReports'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('manageReports') },
        ]
    },
    [service_history]: {
        tagline: translate('equipmentServiceHistory'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('serviceHistory') },
        ]
    },

    [daily_work]: {
        tagline: translate('manageDailyWork'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('manageDailyWork') },
        ]
    },
    [members]: {
        tagline: translate('myMember'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('myMember') },
        ]
    },
    [teamMembers]: {
        tagline: translate('teamMember'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('teamMember') },
        ]
    },
    [filed_information]: {
        tagline: "Field Information",
        links: [
            { title: "Dashboard", href: "/customer" },
            { title: "Field Information" },
        ]
    },
    [location_information]: {
        tagline: translate('locationInformation'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('locationInformation') },
        ]
    },
    [alert_settings]: {
        tagline: translate('alertSettings'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('alertSettings') },
        ]
    },
    [area_measurement]: {
        tagline: translate('areaMeasurement'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('areaMeasurement') },
        ]
    },
    [alert_settings_byid]: {
        tagline: translate('alertSettings'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: "My Equipments", href: "/customer/equipment" },
            { title: translate('equipmentDetails'), href: "/customer/equipment/equipmentDetail/:machineSaleId" },
            { title: "Edit Settings" },
        ]
    },
    [add_member]: {
        tagline: translate('addNewMember'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('myMember'), href: "/customer/members" },
            { title: translate('addNewMember') }
        ]
    },
    [member_management]: {
        tagline: translate('memberManagement'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('memberManagement') },
        ]
    },
    [edit_member]: {
        tagline: translate('editMember'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('myMember'), href: "/customer/members" },
            { title: translate('editMember') }
        ]
    },
    [my_account]: {
        tagline: translate('myAccount'),
        links: [
            { title: translate('Dashboard'), href: "/customer" },
            { title: translate('myAccount') }
        ]
    },
    [equipmentAlerts]: {
        tagline: "Equipment Alerts",
        caption: "Manage alerts occurred on equipments",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Equipment Alerts" }
        ]
    },
    [equipmentAlertDetails]: {
        tagline: "Alert Details",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Equipment Alerts", href: "/service/equipment-alerts" },
            { title: "Alert Details" }
        ]
    },
    [systemMaintenance]: {
        tagline: "System Maintenance",
        caption: "Manage maintenance of different applications",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "System Maintenance", href: "/service/system-maintenance" },
        ]
    },
    [addSystemMaintenance]: {
        tagline: "Add Maintenance",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "System Maintenance", href: "/service/system-maintenance" },
            { title: "Add Maintenance" }
        ]
    },
    [scheduleServiceAlert]: {
        tagline: "Schedule Service Alerts",
        caption: "Manage and Schedule service alerts of all Equipments",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Schedule Service Alerts", href: "/service/schedule-service-alert" },
        ]
    },
    [manageServiceCompany]: {
        tagline: "Manage Service Companies",
        caption: "Assign role to the companies",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Manage Service Companies", href: "/service/manage-service-companies" },
        ]
    },
    [owners]: {
        tagline: "Owner Management",
        caption: "Manage all Equipment registered owners",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Owner Management" }
        ]
    },
    [addOwner]: {
        tagline: "Add New Owner",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Owner Management", href: owners },
            { title: "Add New Owner" }
        ]
    },
    [ownerDetails]: {
        tagline: "Owner Details",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Owner Management", href: owners },
            { title: "Owner Details" }
        ]
    },
    [editOwnerDetails]: {
        tagline: "Edit Details",
        caption: "Edit machine details and renew the contract details",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Owner Management", href: owners },
            { title: "Owner Details", href: "/service/owners/detail/:userId" },
            { title: "Edit Details" }
        ]
    },
    [dataControl]: {
        tagline: "Data Control",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Data Control" }]
    },
    [dealerMangement]: {
        tagline: "Dealer Management",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Dealer Management" }]
    },
    [home]: {
        tagline: "Service",
        links: [
            { title: "", href: "" }
        ]
    },
    [CustomMap]: {
        tagline: "Custom Map",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Custom Map", href: "" }
        ]
    },
    [FOTA]: {
        tagline: "FOTA",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "FOTA", href: "" }
        ]
    },
    [roles]: {
        tagline: "Manage Roles",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Manage Roles" }
        ]
    },
    [compareRoles]: {
        tagline: "Compare Roles",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Manage Roles", href: roles },
            { title: "Compare Roles" }
        ]
    },
    [addRole]: {
        tagline: "Add New Role",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Manage Roles", href: roles },
            { title: "Add New Role" }
        ]
    },
    [editRole]: {
        tagline: "Edit Role",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Manage Roles", href: roles },
            { title: "Edit Role" }
        ]
    },
    [SVEquipemtAlert]: {
        tagline: "Equipment Alerts",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Equipment Alerts" }
        ]
    },
    [SVEquipemtAlertDetail]: {
        tagline: "Equipment Alert - Preview",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Equipment Alerts", href: "/service/equipment-alerts" },
            { title: "Alert Details" }
        ]
    },

    [SPEquipmentOperatingInfo]: {
        tagline: "Equipment Operating Info",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Equipment Operating Info" }
        ]
    },
    [SPEquipmentDetailOperatingInfo]: {
        tagline: "Equipment Operating Info",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Equipment Operating Info", href: "/service/equipment-operating-info" },
            { title: "Equipment Details" }
        ]
    },
    [SPEquipmentDetailOperatingInfoContractRenew]: {
        tagline: "Contract Renew",
        caption: "Renew the contract details",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Equipment Operating Info", href: "/service/equipment-operating-info" },
            { title: "Contract Renew" },

        ]
    },
    [SPContractManagementContractRenew]: {
        tagline: "Contract Renew",
        caption: "Renew the contract details",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Contract Management", href: "/service/contract-management" },
            { title: "Contract Renew" },

        ]
    },

    [editDetailsContractRenew]: {
        tagline: "Edit Details",
        caption: "Edit manchine details and renew the contract details",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Contract Management", href: "/service/contract-management" },
            { title: "Contract Renew" },

        ]
    },
    
    [equipmentLocationInfo]: {
        tagline: "Equipment Location Info",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Equipment Location Info", href: "/service/equipment-location-info" },
        ]
    },
    [emailInformation]: {
        tagline: "Manage Email Address",
        caption: "Assign Emails to the companies",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Manage Email Address", href: "/service/email-information" },
        ]
    },

    [hourmeterOffsetManagement]: {
        tagline: "Hourmeter Offset Management",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Hourmeter Offset Management", href: "/service/hourmeter-offset-management" },
        ]
    },

    [hourmeterOffsetDetail]: {
        tagline: "Hourmeter Offset Detail",
        caption: "Add the offset value for hourmeter",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Hourmeter Offset Management", href: "/service/hourmeter-offset-management" },
            { title: "Hourmeter Offset Detail" },

        ]
    },

    [contractManagement]: {
        tagline: "Contract Management",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Contract Management", href: "/service/contract-management" },
        ]
    },
    [contractManagementStatus]: {
        tagline: "Contract Management",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Contract Management", href: "/service/contract-management/:status" },
        ]
    },
    [SPProfile]: {
        tagline: "User Details",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "User Details" }
        ]
    },
    [SPEquipmentServiceInfo]: {
        tagline: "Equipment Service Info",
        links: [
            { title: "Dashboard", href: "/service" },
            { title: "Equipment Service Info" }
        ]
    }
}

export default Breadcrumbs;