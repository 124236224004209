import React, { Component } from 'react';
import './style.scss';

export class Footer extends Component {
    
    render() {
        return (
            <div className="footer">
                Copyright(C) {new Date().getFullYear()},Yanmar Holdings Co., Ltd. All rights reserved
            </div>
        );
    }
}


export default Footer;
