import React, { Component, Fragment } from 'react';
import comImage from '../../assets/images/logo.jpg';
import noimage from '../../assets/images/noimage.png';
import defaultImg from "../../assets/avatar/avatar.png";
import { FaBars, FaWrench, FaUser, FaAngleDown, FaGlobe, FaInfoCircle, FaCheckSquare, FaLock } from 'react-icons/fa'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { webURL } from '../../constants/Constants';
import Moment from "react-moment";
import './style.scss';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Button } from 'reactstrap';
import CryptoJS from 'crypto-js';
import { hashKey } from '../../constants/Constants';
import Image from "../../components/shared/Image";
//Icons and logo
import notification from '../../assets/images/my-equipment-page-icons/Asset7xhdpi.png';
import alert from '../../assets/images/my-equipment-page-icons/Asset6xhdpi.png';
//Actions
import { getAlertNotifications, saveAlertReadStatus, saveScheduleSysAlert } from '../../actions/AlertsNotifications';
import { getProfile } from "../../actions/Profile";
import { getEquipmentAlertData } from '../../actions/Equipment';
import { showRealTimeAlert } from "../../actions/Application";
import RealTimeAlert from "./RealTimeAlert";
import session from "../../services/session";
import mtz from 'moment-timezone';
import { locale1, translate } from '../../lang';
import moment from "moment";

import { getAlerts,getScheduleSysAlert,getHeaderNotificationCount } from "../../api/header.js";
import { setLoader, setToastr } from "../../actions/Application";
import api from '../../api/index';

export class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            equipmentServiceAlert: [],
            alertList: [],
            sysMentainInfo: [],
            equipmentAlertCount: 0,
            selectedLanguage: 'English',
            serviceDueAlertCount:'',
            systemMaintenanceCount:'',
            RecentAlertCount:'',
            data : {
                startIndex : 0,
                numberOfRows: 15,
                totalEventsFlag: true,
                data : {
                    module : "SMARTASSIST CUSTOMER"
                }
            }
        }
        this.handleServiceDueAlertsClick = this.handleServiceDueAlertsClick.bind(this);
        this.handleRecentAlertsClick = this.handleRecentAlertsClick.bind(this);
        this.handleSystemMaintenanceClick = this.handleSystemMaintenanceClick.bind(this);   
    }

    componentWillMount() {
        if (
            (this.props.notification.SET_ALERT_NOTIFICATION_DATA === undefined) ||
            (this.props.notification.SET_ALERT_NOTIFICATION_DATA.eventsCount === undefined) ||
            (this.props.notification.SET_ALERT_NOTIFICATION_DATA.eventsCount.totalCounts === undefined) || (this.props.notification && this.props.notification.SET_ALERT_NOTIFICATION_DATA && this.props.notification.SET_ALERT_NOTIFICATION_DATA.eventsCount && this.props.notification.SET_ALERT_NOTIFICATION_DATA.eventsCount.totalCounts === 0)) {
            const isCustomer = (this.props.isService || this.props.isServiceProvider) ? false : true;
            /*if (isCustomer) {
                this.props.getAlertNotifications({
                    data: {
                        module: "SMARTASSIST CUSTOMER",
                        type: 0
                    }
                });
            } else {
                this.props.getAlertNotifications({
                    data: {
                        module: "SMARTASSIST SERVICE",
                        type: 1
                    }
                });
            }*/

            if(!isCustomer){
                this.props.getAlertNotifications({
                    data: {
                        module: "SMARTASSIST SERVICE",
                        type: 1
                    }
                });
            }
        }

        if (localStorage.getItem('user_data')) {
            let byteUserData = CryptoJS.AES.decrypt(localStorage.getItem('user_data').toString(), hashKey);
            let userData = JSON.parse(byteUserData.toString(CryptoJS.enc.Utf8));
            this.setState({ userData: userData })
        }
        if (localStorage.getItem('locale') === 'hin') {
            this.setState({ selectedLanguage: 'हिन्दी' })
        } else {
            this.setState({ selectedLanguage: 'English' })
        }
    }


    componentWillReceiveProps(nextProps) {
        const isCustomer = (this.props.isService || this.props.isServiceProvider) ? false : true;
        if (this.props.notification.SET_ALERT_NOTIFICATION_DATA !== nextProps.notification.SET_ALERT_NOTIFICATION_DATA) {
            if (nextProps.notification.SET_ALERT_NOTIFICATION_DATA) {
                /*if (isCustomer) {
                    this.setState(
                        {
                            alertList: this.state.alertList,
                            equipmentServiceAlert: this.state.equipmentServiceAlert,
                            sysMentainInfo: nextProps.notification.SET_ALERT_NOTIFICATION_DATA[2] && nextProps.notification.SET_ALERT_NOTIFICATION_DATA[2].systemMaintenance,
                        }
                    )
                } else {
                    this.setState(
                        {
                            sysMentainInfo: nextProps.notification.SET_ALERT_NOTIFICATION_DATA && nextProps.notification.SET_ALERT_NOTIFICATION_DATA.systemMaintenance
                        })

                }*/
                if (!isCustomer) {
                    this.setState(
                        {
                            sysMentainInfo: nextProps.notification.SET_ALERT_NOTIFICATION_DATA && nextProps.notification.SET_ALERT_NOTIFICATION_DATA.systemMaintenance
                        })
                }

            }
        } else {
            if (this.state.equipmentAlertCount === 0 && nextProps.equipmentAlertCount > 0) {
                this.setState({ equipmentAlertCount: nextProps.equipmentAlertCount });
            } else if (nextProps.equipmentAlertCount > this.state.equipmentAlertCount && nextProps.equipmentEvents.length) {


                let pickData = nextProps.equipmentAlertCount - this.state.equipmentAlertCount, newData = nextProps.equipmentEvents.splice(this.state.equipmentAlertCount, pickData);
                if (this.props.isService) {
                    /** dispatch another props for real time alert */
                    this.props.showRealTimeAlert({ type: "list", title: "Error Alert", className: "error-alert-modal modal-lg", footer: null, content: <RealTimeAlert push={this.props.history.push} events={newData} /> });
                }
                this.setState({ equipmentAlertCount: nextProps.equipmentAlertCount });

            } else {
                this.setState({ equipmentAlertCount: nextProps.equipmentAlertCount });
            }
        }
    }

    /*Hide the pop up onclick Ok button*/
    handleClick = () => {
        this.props.handleClick()
    }

    changeLanguage = (e) => {
        var lang = e.currentTarget.name;
        this.setState({ selectedLanguage: lang })
        if (lang === 'Hindi') {

            localStorage.setItem('locale', 'hin');
            locale1("hin");
        } else {
            localStorage.setItem('locale', 'en');
            locale1("en");
        }

        //setTimeout(3000);
        window.location.reload();
    }

    /*this functon is used to logout the user and remove the localStorage*/
    handleLogout = () => {
        if (this.props.isService) {
            session.clear('ADMINTOKEN', "cookie");
            session.clear('admin_activity', "cookie");
            session.clear('adminname', "cookie");
            session.clear("prev", "cookie");

            session.clr('ADMINTOKEN');
            session.clr('admin_activity');
            session.clr('adminname');
            session.clr("prev");
            session.clear('spprofile', "cookie");
            session.clear('module', "cookie");
            session.clr('spprofile');
            session.clr("module");
            setTimeout(() => window.location = `${webURL}/service?action=logout`, 200)

        } else if (this.props.isServiceProvider) {
            session.clear('SERVPROVTOKEN', "cookie");
            session.clear('spprofile', "cookie");
            session.clear('spname', "cookie");
            session.clear("spprev", "cookie");

            session.clr('SERVPROVTOKEN');
            session.clr('spprofile');
            session.clr('spname');
            session.clr("spprev");

            setTimeout(() => window.location = `${webURL}/service-provider?action=logout`, 200)
        } else {
            localStorage.removeItem('TOKEN');
            window.location = `${webURL}/customer`;
        }
    }

    
    /* Prepare dropdata for Equipment service alert */
    getEquipServAlert = () => {
        if (!this.state.equipmentServiceAlert) return false;

        return [
            this.state.equipmentServiceAlert.map((value, index) => {
                let eleClass = (value.customerReadFlag !== true) ? "unread" : "";
                return <div key={index} className={`listLineeq ${eleClass} text-left`}><FaWrench size="10" />{value.message} for equipment <b>{value.numberPlate}</b>
                    {
                        (value.customerReadFlag !== true) ?
                            <div className="expand btn btn-sm btn-success float-right read-check" servicealertid={value.serviceAlertId} onClick={this.markScheduleSysAlertAsRead.bind(this, index)}><FaCheckSquare />Mark as read</div>
                            : ''
                    }
                    <div className="clearfix"></div>
                </div>
            })
            ,
            this.state.equipmentServiceAlert.filter((value, index) => {
                return (value.customerReadFlag !== true)
            })
        ]
    }

    /* Prepare dropdata for All alert */
    getAllAlert = () => {
        if (!this.state.alertList) return false;

        return [
            this.state.alertList.map((value, index) => {
                let type = (value.eventType === "Technical Error") ? "ERROR" : value.eventType;
                let eleClass = (value.customerStatusFlag !== true) ? "unread" : "";
                return <div key={index} className={`listLineeq ${eleClass} text-left`}>
                    <div className="float-left">
                        <span className={`alerttype ${type ? type.toUpperCase() : ""}`}>{type ? type.toUpperCase() : ""} </span> <span className="btn-dark pl-1 pr-1">{value.numberPlate}</span>
                    </div>
                    <div className="float-left w-16rem">
                        <div className="float-left">Machine No. {value.machineNum}</div>
                        <div className="float-right timesml">
                            {(value.eventDate &&
                                <Moment format="D MMM YYYY HH:mm:ss" tz={this.props.timezone}>
                                    {value.eventDate}
                                </Moment>) || " "}
                            {/* { moment(value.eventDate).format('D MMM YY hh:mm:ss') } */}
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="text-secondary">{value.eventDescription}</div>
                    {/* <div className="float-left font-weight-bold pl-1 pr-1 tabledashboard text-uppercase">Urgency : {value.eventUrgency}</div> */}
                    {
                        (value.customerStatusFlag !== true) ?
                            <div className="expand btn btn-sm btn-success float-right read-check" eventid={value.eventId} onClick={this.markAsRead.bind(this, index)}><FaCheckSquare />Mark as read</div>
                            : ''
                    }
                    <div className="clearfix"></div>
                </div>
            })
            ,
            this.state.alertList.filter((value, index) => {
                return (value.customerStatusFlag !== true)
            })
        ]
    }

    markAsRead = (index, e) => {
        let newList = this.state.alertList.map((val, mapindex) => {
            if (mapindex === index) {
                return {
                    ...this.state.alertList[index], customerStatusFlag: true
                }
            } else {
                return val;
            }
        });
        this.props.saveAlertReadStatus({ alertId: e.target.getAttribute('eventid') });
        this.setState({ alertList: newList })
    }

    markScheduleSysAlertAsRead = (index, e) => {
        let newList = this.state.equipmentServiceAlert.map((val, mapindex) => {
            if (mapindex === index) {
                return {
                    ...this.state.equipmentServiceAlert[index], customerReadFlag: true
                }
            } else {
                return val;
            }
        });
        this.props.saveScheduleSysAlert({ serviceAlertId: e.target.getAttribute('servicealertid') });
        this.setState({ equipmentServiceAlert: newList })
    }

    /*Prepare dropdata for All service alert */
    getServAlert = () => {
        let sysmain = this.state.sysMentainInfo || undefined;
        let sysMentainInfo;
        if (!sysmain) return false;
        if (this.props.isService) {
            sysMentainInfo = this.state.sysMentainInfo.filter((value, index) => {
             
                return (value.application.trim() === 'SMARTASSIST SERVICE' && moment(value.endDate).isAfter(new Date())) ? true : false
            });
        } else {
            sysMentainInfo = this.state.sysMentainInfo.filter((value, index) => {
                return (value.application.trim() === 'SMARTASSIST CUSTOMER') ? true : false
            });
        }
        return sysMentainInfo.map((value, index) => {
            
            return <div key={index} className="listLineeq text-left"><FaInfoCircle size="10" />

                {value.title}
                &nbsp;on&nbsp;
                {(value.startDate &&
                    <Moment format="D MMM YYYY HH:mm:ss">
                        {value.startDate}
                    </Moment>) || " "}

                &nbsp;to {(value.endDate &&
                    <Moment format="D MMM YYYY HH:mm:ss">
                        {value.endDate}
                    </Moment>) || " "} </div>
        });
    }

    componentDidMount() {
        const isCustomer = (this.props.isService || this.props.isServiceProvider) ? false : true;

        if (isCustomer) {
            getHeaderNotificationCount()
                .then(result => {
                    this.props.setLoader({ loader: { open: true } });
                    if (result.data) {
                        this.setState({
                            serviceDueAlertCount: result.data.scheduleServiceAlertCount,
                            systemMaintenanceCount: result.data.systemMaintenanceCount,
                            RecentAlertCount: result.dataalertNotificationCount
                        });
                    }
                    this.props.setLoader({ loader: { open: false } });

                })
                .catch(error => {
                    this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                    this.props.setLoader({ loader: { open: false } });
                })
                /** get profile data */
                this.props.getProfile();
        }
        else{
            if (this.props.notification.SET_ALERT_NOTIFICATION_DATA) {
                this.setState(
                    {
                        alertList: this.props.notification.SET_ALERT_NOTIFICATION_DATA[0],
                        equipmentServiceAlert: this.props.notification.SET_ALERT_NOTIFICATION_DATA[1],
                        sysMentainInfo: this.props.notification.SET_ALERT_NOTIFICATION_DATA[2] && this.props.notification.SET_ALERT_NOTIFICATION_DATA[2].systemMaintenance,
                    }
                )
            }
        }
        
        let img = document.getElementsByTagName('img')
        for (let d in img) {
            if (!isNaN(d)) {
                img[d].addEventListener('error', function () {
                    this.src = noimage;
                })
            }
        }


        if (this.props.isService && (localStorage && !localStorage.equipmentAlertListData)) this.props.getEquipmentAlertData({ isService: true, data: false })



        ///testing realtime notification
        /* let newData = [{
            numberPlate: "RJ45SA6651",
            mode: "bajaj",
            machineNum: "WTEYRUTKTYEF",
            owner: "Bhupen",
            eventDescription: "Test Desc",
            eventUrgency: 1,
            eventId: 14329
        }]
        this.props.showRealTimeAlert({type:"list", title: "Error Alert", className: "error-alert-modal modal-lg", footer: null, content: <RealTimeAlert push={this.props.history.push} events={newData}/>}); */

    }

    /*API call to get service alert due dropdown data*/ 
    handleServiceDueAlertsClick(){
        this.props.setLoader({ loader: { open: true } });
        getScheduleSysAlert()
            .then(result => {
                if(result.data ){
                    this.setState({equipmentServiceAlert:result.data})
                }
                this.props.setLoader({ loader: { open: false } });
                
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    /*API call to get all alerts dropdown data*/ 
    handleRecentAlertsClick(){
        this.props.setLoader({ loader: { open: true } });
        getAlerts()
            .then(result => {
                if(result.data ){
                    this.setState({alertList:result.data})
                }
                this.props.setLoader({ loader: { open: false } });
                
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }

    /*API call to get system maintanenace dropdown data*/ 
    handleSystemMaintenanceClick(){
        const isCustomer = (this.props.isService || this.props.isServiceProvider) ? false : true;
        if(isCustomer){
            this.props.setLoader({ loader: { open: true } });
            api.alertNotify.getSysMentainInfo(this.state.data)
            .then(result => {
                if(result.data && result.data.systemMaintenance ){
                    this.setState({sysMentainInfo:result.data.systemMaintenance})
                }
                this.props.setLoader({ loader: { open: false } });
                
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            }) ;
        }
          
    }

    render() {
        let spid = session.get("spname", "cookie"), adminid = session.get("adminname", "cookie");
        const isServiceProvider = this.props.isServiceProvider;
        const isCustomer = (this.props.isService || isServiceProvider) ? false : true;

        let getAllAlert = this.getAllAlert();
        let getEquipServAlert = this.getEquipServAlert();
        let getServAlert = this.getServAlert();
        const cls = isCustomer ? "float-right app-settings-links-customer" : "float-right app-settings-links";
        return (
            <div className="header appheader">
                {
                    (this.props.isService) ?
                        <div className="smmenubtnicon  xs-hidden adminbtn" onClick={this.handleClick} ><FaBars className="navicon" /></div>
                        :
                        ''
                }
                <div className="inner-header xs-hidden">
                    <img src={comImage} alt="avatar" className="float-left" />
                    <div className="wrap profile float-right">
                        <div>
                            <UncontrolledDropdown className={cls}>
                                <div className="notification-wrapper">
                                    <DropdownToggle tag="div" className="float-right">
                                        <FaAngleDown className="dropdowntoogle" />
                                    </DropdownToggle>
                                    <span className="hover-text">{translate('profile')}</span>
                                    {
                                        (isCustomer) ?
                                            <Fragment>
                                                <div className="float-right username">{(this.state.userData && this.state.userData.firstName) ? this.state.userData.firstName : ""}</div>
                                                <div className="float-right lg-screen-proimg"><Image className='rounded-circle' src={this.state.userData && this.state.userData.photo} /></div>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <div className="float-right username">{(this.props.isService) ? (adminid || 'Admin') : (spid || 'SP User')}</div>
                                                <div className="float-right lg-screen-proimg ie-proimg"><img className='rounded-circle' src={defaultImg} alt="image" /></div>
                                            </Fragment>
                                    }
                                    <div className="clearfix" />
                                </div>
                                <DropdownMenu right className={'marginRight'}>
                                    {
                                        (isCustomer && <Link to="/customer/my-account" className="dropdown-item">
                                            <FaUser size="16" />&emsp;{translate('profile')}</Link>)
                                    }
                                    {
                                        (this.props.isService && <Fragment>{/* <div className="dropdown-item">
                                        <FaUser size="16"/>&emsp;Profile</div> */}<a href="https://gidms.yanmar.co.jp/idm_out/ForUserPassWord/PassWordChange" target="_blank" className="dropdown-item left-align">
                                                <FaLock size="16" />&emsp;{translate('changePassword')}</a></Fragment>)
                                    }
                                    <div className="text-right ml-3 mr-3 mt-2">
                                        <Button block color="danger" size="sm" onClick={this.handleLogout.bind(this)}>
                                            {translate('logout')}</Button>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            {
                                <Fragment>
                                    <UncontrolledDropdown className="noticover">
                                        <div className="notification-wrapper">
                                            <DropdownToggle tag="div" className="float-right" onClick={this.handleSystemMaintenanceClick}>
                                                <img src={notification} className="imageNoti" alt="image" />
                                                <div className="badge">{this.state.systemMaintenanceCount && this.state.systemMaintenanceCount > 99 ? "99+" : (this.state.systemMaintenanceCount) || 0}</div>
                                                {/*<div className="badge">{getServAlert && getServAlert.length > 99 ? "99+" : (getServAlert.length || 0)}</div>*/}
                                            </DropdownToggle>
                                            <span className="hover-text">{translate('systemMaintenanceAlerts')}</span>
                                        </div>
                                        <DropdownMenu style={{ width: '25rem' }} className="maintainanceCover maintainanceCoverd">
                                            <span className="up-caret"></span>
                                            <div className="header-box">
                                                {translate('systemMaintenanceAlerts')}
                                            </div>
                                            <div className="scrollBox">{
                                                (getServAlert && getServAlert.length > 0) ?
                                                    getServAlert
                                                    :
                                                    <div className={`text-center`}> {translate('noDataFound')} </div>
                                            }</div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {(isCustomer) && <UncontrolledDropdown className="alertcover">
                                        <div className="filtertype notification-wrapper">
                                            <DropdownToggle tag="div" className="float-right" onClick={this.handleRecentAlertsClick}>
                                                <img src={alert} className="imageNoti" alt="image" />
                                                <div className="badge">{this.state.RecentAlertCount && this.state.RecentAlertCount > 99 ? "99+" : (this.state.RecentAlertCount) || 0}</div>
                                                {/*<div className="badge">{getAllAlert && getAllAlert[1] && getAllAlert[1].length > 99 ? "99+" : (getAllAlert && getAllAlert[1] && getAllAlert[1].length) || 0}</div>*/}
                                            </DropdownToggle>
                                            <span className="hover-text">{translate('recentAlerts')}</span>
                                        </div>
                                        <DropdownMenu style={{ width: '27rem' }} className="maintainanceCover">
                                            <span className="up-caret"></span>
                                            <div className="header-box">
                                                {translate('recentAlerts')}
                                                <small className='last-evnt'>{translate('occuredInLast72Hour')}</small>
                                            </div>
                                            <div className="scrollBox">
                                                {
                                                    (getAllAlert && getAllAlert[0] && getAllAlert[0].length > 0) ?
                                                        getAllAlert[0]
                                                        :
                                                        <div className={`text-center`}>{translate('noDataFound')}</div>
                                                }
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>}
                                    {(isCustomer) && <UncontrolledDropdown className="alertcover wrinchtcover">
                                        <div className="notification-wrapper">
                                            <DropdownToggle tag="div" className="float-right" onClick={this.handleServiceDueAlertsClick}>
                                                <FaWrench size="16" />
                                                {/*<div className="badge">{getEquipServAlert && getEquipServAlert[1] && getEquipServAlert[1].length > 99 ? "99+" : (getEquipServAlert && getEquipServAlert[1] && getEquipServAlert[1].length) || 0}</div>*/}
                                                <div className="badge">{this.state.serviceDueAlertCount && this.state.serviceDueAlertCount > 99 ? "99+" : (this.state.serviceDueAlertCount) || 0}</div>
                                            </DropdownToggle>
                                            <span className="hover-text">{translate('serviceDueAlerts')}</span>
                                        </div>
                                        <DropdownMenu style={{ width: '22rem' }} className="maintainanceCover">
                                            <span className="up-caret"></span>
                                            <div className="header-box">
                                                {translate('serviceDueAlerts')}
                                            </div>
                                            <div className="scrollBox">
                                                {
                                                    (getEquipServAlert && getEquipServAlert[1] && getEquipServAlert[0].length) ?
                                                        getEquipServAlert[0]
                                                        :
                                                        <div className={`text-center`}> {translate('noDataFound')}</div>
                                                }</div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>} 
                                    <UncontrolledDropdown className={cls}>
                                        <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })} >
                                            <div className="notification-wrapper">
                                                <DropdownToggle tag="div" className="float-right">
                                                    <FaAngleDown className="dropdowntoogle" />
                                                </DropdownToggle>
                                                <div className="float-right username">{this.state.selectedLanguage}</div>
                                                <div className="float-right lg-screen-proimg"><FaGlobe /></div>
                                                <div className="clearfix" />
                                                <span className="hover-text">{translate('selectLanguage')}</span>
                                            </div>
                                            <DropdownMenu right>
                                                <DropdownItem className="Header" onClick={this.changeLanguage} name="English">English</DropdownItem>
                                                <DropdownItem className="Header" onClick={this.changeLanguage} name="Hindi">हिन्दी</DropdownItem>{/* 
                                                <DropdownItem divider/> */}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </UncontrolledDropdown>
                                </Fragment>
                            }    
                        </div>
                    </div>
                </div>
                <div className="sm-inner-header">
                    {(!isServiceProvider && <div className="smmenubtnicon" onClick={this.handleClick} ><FaBars className="navicon" /></div>)}
                    <div className="comptext">Yanmar</div>

                    <div className="ctrls">
                        <UncontrolledDropdown className="float-right">
                            <div>
                                <DropdownToggle tag="div">
                                    <FaUser className="ctrlfonts" />
                                </DropdownToggle>
                            </div>
                            <DropdownMenu right className="sm-dropdn" style={{ width: '2rem' }}>
                                <DropdownItem header>
                                    {(() => {
                                        return 'Welcome'
                                    }).call(this)}
                                </DropdownItem>
                                <DropdownItem divider />
                                {
                                    (isCustomer && <Link to="/profile" className="dropdown-item">
                                        <FaUser size="16" />&emsp; {translate('profile')}</Link>)
                                }
                                {
                                    (!isCustomer && <div className="dropdown-item">
                                        <FaUser size="16" />&emsp;Profile</div>)
                                }
                                <div className="text-right ml-3 mr-3 mt-2">
                                    <Button block color="danger" size="sm" onClick={this.handleLogout.bind(this)}>
                                        {translate('logout')}</Button>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {
                            (isCustomer) ?
                                <Fragment>
                                    <UncontrolledDropdown className="noticover">
                                        <div>
                                            <DropdownToggle tag="div" className="float-right">
                                                <img src={notification} className="imageNoti" alt="image" />
                                                <div className="badge">{(getServAlert && getServAlert.length) || 0}</div>
                                            </DropdownToggle>
                                        </div>
                                        <DropdownMenu style={{ width: '17rem' }} className="maintainanceCover">
                                            <span className="up-caret-sm"></span>
                                            <div className="scrollBox">{
                                                (getServAlert && getServAlert.length > 0) ?
                                                    getServAlert
                                                    :
                                                    <div className={`text-center`}> {translate('noDataFound')}</div>
                                            }</div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <UncontrolledDropdown className="alertcover">
                                        <div>
                                            <DropdownToggle tag="div" className="float-right">
                                                <img src={alert} className="imageNoti" />
                                                <div className="badge">{(getServAlert && getServAlert[1] && getServAlert[1].length) || 0}</div>
                                            </DropdownToggle>
                                        </div>
                                        <DropdownMenu style={{ width: '22rem' }} className="maintainanceCover allalert">
                                            <span className="up-caret-sm"></span>
                                            <div className="header-box"> {translate('recentAlerts')}</div>
                                            <div className="scrollBox">
                                                {
                                                    (getAllAlert && getAllAlert[0] && getAllAlert[0].length > 0) ?
                                                        getAllAlert[0]
                                                        :
                                                        <div className={`text-center`}>{translate('noDataFound')}</div>
                                                }
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <UncontrolledDropdown className="alertcover wrinchtcover">
                                        <div>
                                            <DropdownToggle tag="div" className="float-right">
                                                <FaWrench size="16" />
                                                <div className="badge">{(getEquipServAlert && getEquipServAlert[1] && getEquipServAlert[1].length) || 0}</div>
                                            </DropdownToggle>
                                        </div>
                                        <DropdownMenu style={{ width: '17rem' }} className="maintainanceCover">
                                            <span className="up-caret-sm"></span>
                                            <div className="scrollBox">
                                                {
                                                    (getEquipServAlert && getEquipServAlert[0] && getEquipServAlert[0].length > 0) ?
                                                        getEquipServAlert[0]
                                                        :
                                                        <div className={`text-center`}>{translate('noDataFound')}</div>
                                                }
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Fragment>
                                : ''
                        }
                    </div>

                </div>
            </div>
        );
    }
}

/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    return {
        equipmentEvents: (state.equipment.data && state.equipment.data.SET_EQUIPMENT_EVENTS_LIST_DATA) ? state.equipment.data.SET_EQUIPMENT_EVENTS_LIST_DATA : [],
        equipmentAlertCount: state.equipment.equipmentAlertCount || 0,
        notification: state.alertsNotifications.data,
        profile: state.profile || undefined,
        timezone: state.profile.timezone || "Asia/Kolkata"
    }
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getAlertNotifications, saveAlertReadStatus, saveScheduleSysAlert, getProfile, showRealTimeAlert, getEquipmentAlertData,setLoader, setToastr 
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
