import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddSystemMaintenanceComponent from '../../components/admin/system-maintenance/AddSystemMaintenanceComponent';
import { translate } from '../../lang';
import { addSystemMaintenance } from '../../actions/SysMaintenance';

class AddSystemMaintenanceContainer extends Component {


    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount(){

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.maintainSysData !== nextProps.maintainSysData) {
            if(nextProps.maintainSysData && nextProps.maintainSysData.SET_ALERT_NOTIFICATION_DATA){
                this.setState({
                    systemMaintainList: nextProps.maintainSysData.SET_ALERT_NOTIFICATION_DATA,
                    showLoader : nextProps.maintainSysData.isSending,
                    updated: false
                });
            }
        }
    }

    addMaintenance(data) {
        this.props.addSystemMaintenance(data);
    }
    render() {        
        return (
            <AddSystemMaintenanceComponent translate={translate} addSysMaintainData= { this.props.addSysMaintainData }  addMaintenance={ this.addMaintenance.bind(this) } />
        );
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    return {
        addSysMaintainData: state.alertsNotifications.data,
    };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    addSystemMaintenance
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSystemMaintenanceContainer);