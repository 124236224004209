import { GET_PROFILE, SET_PROFILE } from '../types';

/**
 * Dispatch the login action to the middleware
 * @param  { Object } data Date to be processed
 * @return { Object }      Dispatch the action type
 */
export const getProfile = data => {
    return {
        type: GET_PROFILE,
        data
    }
}

export const setProfile = data => {
    return {
        type: SET_PROFILE,
        data
    }
}