import React, { Component } from 'react';
import { connect } from 'react-redux';
import HourmeterOffsetManagementComponent from '../../components/admin/hourmeter-offset-manage/HourmeterOffsetManagementComponent';
//import {getHourMeterGap} from '../../actions/admin/hourmeterOffsetManagement';

class HourmeterOffsetManagementContainer extends Component {

    render() {
        return (
            <HourmeterOffsetManagementComponent />

        );

    }
}
export default (HourmeterOffsetManagementContainer);