import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import session from "../services/session";
import AdminRouteContainer from '../containers/AdminRouteContainer';
import _find from "lodash/find";
import ServiceEquipmentAlertsContainer from '../containers/admin/EquipmentAlertsContainer';
import EquipmentAlertDetailAleridContainer from '../containers/admin/EquipmentAlertDetailContainer';
import ServiceSystemMaintenanceContainer from '../containers/admin/SystemMaintenanceContainer';
import ServiceScheduleServiceAlertContainer from '../containers/admin/ScheduleServiceAlertContainer';
import ServiceLoginContainer from '../containers/admin/AdminLoginContainer';
import ServiceDashboardContainer from '../containers/admin/HomeContainer';
import ServiceCustomMapContainer from '../containers/admin/CustomMapContainer';
import ServiceDealerManagementContainer from '../containers/admin/DealerManagementContainer';
import ServiceFotaContainer from '../containers/admin/FotaContainer';
import ServiceManageServiceCompaniesContainer from '../containers/admin/ManageServiceCompanyContainer';
import ServiceOwnersContainer from "../containers/admin/OwnersContainer";
import ServiceOwnersAddContainer from "../containers/admin/NewOwnerContainer";
import ServiceOwnersDetailUseridContainer from "../containers/admin/EditOwnerContainer";
import ServiceEditOwnerDetailContainer from "../containers/admin/EditOwnerDetailContainer";
import ServiceDataControlContainer from "../containers/admin/DataControlContainer";
import ForgetPassContainer from "../containers/admin/ForgetPassContainer";
import ServiceAddSystemMaintenanceContainer from '../containers/admin/AddSystemMaintenanceContainer';
import ServiceRolesContainer from '../containers/admin/RoleContainer';
import ServiceRolesCompareContainer from '../containers/admin/CompareRoleContainer'
import ServiceRolesAddContainer from '../containers/admin/AddRoleContainer';
import ServiceProviderEquipmentAlertsContainer from '../containers/service-provider/EquipmentAlertsContainer';
import ServiceProviderEquipmentAlertDetailAleridContainer from '../containers/service-provider/EquipmentAlertDetailContainer';
import ServiceEquipmentServiceInfoContainer from '../components/service-provider/equipments/EquipmentServiceInfo';
import ServiceEquipmentOperatingInfoContainer from '../containers/service-provider/EquipmentOperatingInfoContainer';
import ServiceContractRenewContainer from '../containers/admin/ContractRenewContainer';

import ServiceProfileContainer from "../containers/service-provider/MyProfileContainer";
import ServiceEquipmentLocationInfoContainer from '../containers/admin/EquipmentLocationInfoContainer';
import ServiceContractManagementContainer from '../containers/admin/ContractManagementContainer';
import ServiceHourmeterOffsetManagementContainer from '../containers/admin/HourmeterOffsetManagementContainer';
import ServiceHourmeterOffsetDetailContainer from '../containers/admin/HourmeterOffsetDetailContainer';
import ServiceEmailInformationContainer from '../containers/admin/EmailInformationContainer';

const pascalConverter = str => (str.replace(/(\w)(\w*)/g,
    function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); })).replace(/[^a-zA-Z ]/g, "") + "Container";

const ContainerList = {
   ServiceDataControlContainer,
   ServiceOwnersAddContainer,
   ServiceSystemMaintenanceContainer,
   ServiceScheduleServiceAlertContainer,
   ServiceDashboardContainer,
   ServiceManageServiceCompaniesContainer,
   ServiceOwnersContainer,
   ServiceRolesContainer,
   ServiceDealerManagementContainer,
   ServiceCustomMapContainer,
   ServiceFotaContainer,
   ServiceEquipmentServiceInfoContainer,
   ServiceEquipmentOperatingInfoContainer,
   ServiceProfileContainer,
   ServiceEquipmentLocationInfoContainer,
   ServiceContractManagementContainer,
   ServiceContractRenewContainer,
   ServiceEmailInformationContainer,
   ServiceHourmeterOffsetManagementContainer,
   ServiceEditOwnerDetailContainer

};

class AdminRoutes extends Component {
    constructor(props) {
        super(props);
        this.state = { routeList: (session && session.get("prev", "cookie")) || [] };
    }

    render() {

        const { location, translate, match } = this.props;
        //const { routeList } = this.state;

        let routeList = (session && session.get("prev", "cookie")) || [];

        let hasRole = _find(routeList, { uuid: "/service/roles" }),
            hasOwner = _find(routeList, { uuid: "/service/owners" }),
            hasEqAlerts = _find(routeList, { uuid: "/service/equipment-alerts" }),
            sphasEqAlerts = _find(routeList, { uuid: "/service/sp_equipment-alerts" }),
            hasSysMaint = _find(routeList, { uuid: "/service/system-maintenance" }),
            hasEqInfo = _find(routeList, { uuid: "/service/equipment-operating-info" }),
            hasHourInfo= _find(routeList, { uuid: "/service/hourmeter-offset-management" });
        console.log('sphasEqAlerts ', sphasEqAlerts)
        console.log('hasEqAlerts ', hasEqAlerts)
        return (
            <>
                <Route location={location} translate={translate} path={`${match.url}/`} exact component={ServiceLoginContainer} />

                {/*  <Route location={ location } translate={ translate } match={`${match.url}`} path={`${match.url}/forget-password`} exact component= { ForgetPassContainer } />*/}
                {sphasEqAlerts && <AdminRouteContainer location={location} translate={translate} path={`${match.url}/equipment-alerts`} exact component={ServiceProviderEquipmentAlertsContainer} />}

                {!sphasEqAlerts && <AdminRouteContainer location={location} translate={translate} path={`${match.url}/equipment-alerts`} exact component={ServiceEquipmentAlertsContainer} />}
                {routeList && routeList.map((route, i) => {
                    let comp = pascalConverter(route.uuid);
                    if (ContainerList[comp]) {
                        return <AdminRouteContainer key={route.uuid} location={location} translate={translate} path={route.uuid} exact component={ContainerList[comp]} />
                    } else {
                        return false;
                    }
                })}

                {/*<AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/contract-management`} exact component={ ServiceContractManagementContainer } />*/}
                <AdminRouteContainer location={location} translate={translate} path={`${match.url}/contract-management/:status`} exact component={ServiceContractManagementContainer} />
                {hasRole && <AdminRouteContainer location={location} translate={translate} path={`${match.url}/roles/add`} exact component={ServiceRolesAddContainer} />}
                {hasRole && <AdminRouteContainer location={location} translate={translate} path={`${match.url}/roles/compareRoles`} exact component={ServiceRolesCompareContainer} />}

                {hasRole && <AdminRouteContainer location={location} translate={translate} path={`${match.url}/roles/edit/:roleid`} exact component={ServiceRolesAddContainer} />}

                {/* {hasOwner && <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/owners/add`} exact component={ AdminOwnersAddContainer } />} */}
                {hasOwner && <AdminRouteContainer location={location} translate={translate} path={`${match.url}/owners/detail/:userId`} exact component={ServiceOwnersDetailUseridContainer} />}

                {sphasEqAlerts && <AdminRouteContainer location={location} translate={translate} path={`${match.url}/sp_equipment-alert-detail/:alerId`} exact component={ServiceProviderEquipmentAlertDetailAleridContainer} />}

                <AdminRouteContainer location={location} translate={translate} path={`${match.url}/add-system-maintenance`} exact component={ServiceAddSystemMaintenanceContainer} />
                {/* <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/roles`} exact component={ AdminRolesContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/roles/add`} exact component={ AdminRolesAddContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/roles/edit/:roleid`} exact component={ AdminRolesAddContainer } /> 
 */}

                <AdminRouteContainer location={location} translate={translate} path={`${match.url}/dashboard`} exact component={ServiceDashboardContainer} />

                {hasEqInfo && <AdminRouteContainer location={location} translate={translate} path={`${match.url}/equipment-detail/:userId/:machineId`} exact component={ServiceEquipmentOperatingInfoContainer} />}
                {hasEqInfo && <AdminRouteContainer location={location} translate={translate} path={`${match.url}/contract-renew/:userId/:machineId`} exact component={ServiceContractRenewContainer} />}
                {hasEqInfo && <AdminRouteContainer location={location} translate={translate} path={`${match.url}/contract-renew/:machineId`} exact component={ServiceContractRenewContainer} />}
                {hasHourInfo &&<AdminRouteContainer location={location} translate={translate} path={`${match.url}/hourmeter-offset-detail`} exact component={ServiceHourmeterOffsetDetailContainer} />}
                {hasEqAlerts && <AdminRouteContainer location={location} translate={translate} path={`${match.url}/equipment-alert-detail/:alerId`} exact component={EquipmentAlertDetailAleridContainer} />}
                <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/edit-owner-details/:userId/:machineSaleId`} exact component={ServiceEditOwnerDetailContainer } />
                <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/edit-owner-details/:userId/:machineSaleId/true`} exact component={ServiceEditOwnerDetailContainer } />
                {/* <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/roles`} exact component={ AdminRolesContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/roles/add`} exact component={ AdminRolesAddContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/roles/edit/:roleid`} exact component={ AdminRolesAddContainer } /> */}


                {/* <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/dashboard`} exact component={AdminDashboardContainer} />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/equipment-alerts`} exact component={AdminEquipmentAlertsContainer} />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/system-maintenance`} exact component={AdminSystemMaintenanceContainer} />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/schedule-service-alert`} exact component={ AdminScheduleServiceAlertContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/owners`} exact component={ AdminOwnersContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/owners/add`} exact component={ AdminNewOwnerContainer } />  */}

                {/* <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/owners/edit/:memberId`} exact component={ NewOwnerContainer } /> */}

                {/* <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/manage-service-companies`} exact component={ AdminManageServiceCompaniesContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/owners/detail/:userId`} exact component={ AdminEditOwnerContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/data-control`} exact component={ AdminDataControlContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/add-system-maintenance`} exact component={ AdminAddSystemMaintenanceContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/roles`} exact component={ AdminRolesContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/roles/add`} exact component={ AdminAddRolesContainer } />

            <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/roles/edit/:roleid`} exact component={ AdminAddRolesContainer } />  */}

                {/* <AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/roles/edit/:roleid`} exact component={ AdminAddRolesContainer } /> 

<AdminRouteContainer location={ location } translate={ translate } path={`${match.url}/roles/add`} exact component={ AdminAddRolesContainer } /> */}
            </>);
    }

}


export default AdminRoutes;

