import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { GET_REPORTS_DATA, SENDING_REPORTS_DATA, SET_REPORTS_DATA, FETCH_DATA_FAIL } from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* getReportsData(action) {
    const data = action.data;
    try {
        yield put({
            type: SENDING_REPORTS_DATA,
            data: '',
            tokenExpired: '',
            invalidToken: '',
            resMsg: '',
            isSending: true
        })
        let response = yield call(api.reportsData.latest, data)
        switch (response.statusCode) {
            case 200:
                yield put({ type: SET_REPORTS_DATA, data: response.data, resMsg: response.message, isSending: false, error: false })
                let params = JSON.stringify({ 'reportsData': response.data });
                localStorage.setItem('reportsData', params);
                break;
              default:
                break;
        }
    } catch (e) {
        yield put({
            type: FETCH_DATA_FAIL,
            payload: {
                error: e.message
            }
        })
    }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchReportsData() {
    yield takeLatest(GET_REPORTS_DATA, getReportsData)
}
