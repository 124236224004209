import React, { Component } from 'react';
import InputField from '../components/shared/InputField';
import SelectField from '../components/shared/SelectField';
import DatePicker from 'react-datepicker';
import { FormGroup, Label } from 'reactstrap';
import { FaCalendarAlt } from 'react-icons/fa'
import './style.scss';
import 'react-datepicker/dist/react-datepicker.css';
import moment, { now } from "moment";

/* A Search Report Form component */
class SearchReportForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            optionType: '1',
            selectedOptionExpiryDate:null,
            toDate:moment(),
            fromDate:moment()
        };
    }


    handleChange(type, data) {
        console.log("handle Change Called")
        const { numberPlateOption} = this.props;
        let equipmentData = numberPlateOption[2].equipmentData;
        let contractExpiryDate;
        console.log('data--->'+JSON.stringify(data))
        if (type.target && type.target.name === "radio1") {
            this.setState({ optionType: type.target.value });
            
        } else {
        
            if(data === undefined ){
                console.log("For undefined");
                equipmentData && equipmentData.map(function (item, index) {
                    if(item.machineSaleId == type.target.value ){
                        contractExpiryDate = item.contractExpiry ? moment(item.contractExpiry) : null;
                    }
                });
                console.log(contractExpiryDate,"contractExpiryDate")
                let endDate= moment(new Date()).isAfter(contractExpiryDate) ? contractExpiryDate :moment( new Date());
                let startDate= moment(new Date()).isAfter(contractExpiryDate) ? contractExpiryDate :moment( new Date());
                console.log('contractExpiryDate-->',endDate);
                this.setState({selectedOptionExpiryDate: contractExpiryDate, toDate:endDate, fromDate:startDate});
                
            } else {
                if (type == 'toDate')
                    this.setState({ toDate: data })
                else
                    if (type == 'fromDate') {
                        this.setState({ fromDate: data })
                    }

            }
            
            this.props.handleChange(type, data);
        }
    }


    render() {
        const { numberPlateOption, report, selectError, alertSettingVal, machineSaleId, module, translate } = this.props;
        const {toDate} = this.state;
        const {fromDate}=this.state;
        let opt = (this.state.optionType === '1') ? false : true;
        let equipmentData = numberPlateOption[2].equipmentData;
        
        const containsAgriEquip = equipmentData && !!equipmentData.find(equipment => {
            return (equipment.status == 'TE' || equipment.status == 'CH')
        })

        return (
            <div>
                <FormGroup tag="fieldset">
                    {!machineSaleId && <FormGroup>
                        <Label className="inputLabel float-left">{translate('selectEquipment')}</Label>
                        <div className="float-left" style={{marginLeft:'20px'}}>
                            <div className="radioButton-group">
                                <Label className="inputboxLabel">
                                    <InputField type="radio" name="radio1" value={1} checked={(!opt)} handleChange={this.handleChange.bind(this)}/>
                                    <span className="checkmark"></span>
                                    {translate('byModelMachineNo')}
                                </Label>
                            </div>
                            {containsAgriEquip &&
                                <div className="radioButton-group">
                                    <Label className="inputboxLabel">
                                        <InputField type="radio" name="radio1" value={0} checked={(opt)} handleChange={this.handleChange.bind(this)} />
                                        <span className="checkmark"></span>
                                        {translate('byNumberPlate')}
                                    </Label>
                                </div>
                            }
                        </div>
                        <div className="clearfix"></div>
                    </FormGroup>}
                    <FormGroup>
                        {
                            (opt) ?
                                <Label for="exampleSelect" className="inputLabel" style={{marginRight:'20px'}}> {translate('numberPlate')}</Label>
                                :
                                <Label for="exampleSelect" className="inputLabel" style={{marginRight:'20px'}} >{translate('modelMachine')}</Label>
                        }


                        {(alertSettingVal) ?
                            <SelectField 
                                divClass="selectwrapper"
                                handleChange={this.handleChange.bind(this)}
                                options={numberPlateOption[this.state.optionType]}
                                type="select"
                                value={alertSettingVal || ''}
                                disabled={machineSaleId ? true : false}
                                name="machineSaleId" 
                                className={(selectError) ? 'border-danger' : '' }
                                defaultOption={(opt) ? "---" + translate('selectNumberPlate') + "---" : "---" + translate('selectModelMachineNum') + "---"}
                            />
                            :
                            <SelectField 
                                divClass="selectwrapper" 
                                handleChange={this.handleChange.bind(this)}
                                options={numberPlateOption[this.state.optionType]}
                                disabled={machineSaleId ? true : false}
                                type="select"
                                name="machineSaleId" 
                                className={(selectError) ? 'border-danger' : ''}
                                defaultOption={(opt) ? "---" + translate('selectNumberPlate') + "---" : "---" + translate('selectModelMachineNum') + "---"}
                            />
                        }
                    </FormGroup>
                    {report ?
                        <FormGroup>
                            <Label className="inputLabel float-left" style={{marginTop:'7px'}}>{translate('dateRange')}</Label>
                            
                            <div className="float-left mr-5 position-relative" style={{marginLeft:'20px'}}>
                                <DatePicker
                                    selected={fromDate}
                                    maxDate={(module === 'work'|| module==='utilization') ? (this.state.selectedOptionExpiryDate && moment(new Date()).isAfter(this.state.selectedOptionExpiryDate) ? this.state.selectedOptionExpiryDate : toDate) : moment().subtract(1, "d")}
                                    //maxDate={module === 'work' ? new Date() : moment().subtract(1, "d")}
                                    ref={(r) => {
                                        this.startcomponent = r;
                                    }}
                                    autoComplete='off'
                                    onChange={this.handleChange.bind(this, 'fromDate')}
                                    dateFormat="DD MMM YYYY"
                                    id="fromDate"
                                    className="form-control pointer-event"
                                />
                                <button className="calLogo" onClick={() => this.startDate()}><FaCalendarAlt /></button>
                            </div>
                            <Label className="inputLabel float-left" style={{marginTop:'7px', marginLeft:'-27px'}}>{translate('to')}</Label>
                            <div className="float-left position-relative" style={{marginLeft:'-155px'}}>
                                <DatePicker
                                    selected={toDate}//{this.state.selectedOptionExpiryDate && moment(new Date()).isAfter(this.state.selectedOptionExpiryDate) ? this.state.selectedOptionExpiryDate : toDate}
                                    maxDate={(module === 'work'|| module==='utilization') ? (this.state.selectedOptionExpiryDate && moment(new Date()).isAfter(this.state.selectedOptionExpiryDate) ? this.state.selectedOptionExpiryDate : toDate) : moment().subtract(1, "d")}
                                    ref={(r) => {
                                        this.endcomponent = r;
                                    }}
                                    autoComplete='off'
                                    onChange={this.handleChange.bind(this, 'toDate')}
                                    dateFormat="DD MMM YYYY"
                                    id="toDate"
                                    className="form-control pointer-event"
                                />
                                <button className="calLogo" onClick={() => this.endDate()}><FaCalendarAlt /></button>
                            </div>
                        </FormGroup>
                        : null
                    }
                </FormGroup>
            </div>
        );
    }

    startDate() {
        this.startcomponent.setOpen(true);
    }

    endDate() {
        this.endcomponent.setOpen(true);
    }

    componentDidMount() {
        this.setState()
    }
}

export default SearchReportForm;
