import React, { Component } from 'react';
import { connect } from 'react-redux';
import AlertSettingComponent from '../components/customer/alert-setting/AlertSettingComponent';
import { getAlertData } from '../actions/AlertSetting';

class AlertSettingContainer extends Component {

    constructor(props) {
        super(props);
        this.state={

        };
    }

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount(){
        let token = "";
        if(localStorage.getItem('TOKEN')){
            token = JSON.parse(localStorage.getItem('TOKEN'));
        }
        this.props.getAlertData({token:token.token});
    }


    render() {        
        return (
            <AlertSettingComponent alertSettingVal = {this.props.match.params.machineSaleId || ''}/>
        );
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    return {};
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getAlertData
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertSettingContainer);