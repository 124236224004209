import React, { Component } from 'react';
import { connect } from 'react-redux';
import EquipmentComponent from '../components/customer/equipment/EquipmentComponent';
import { getEquipmentList } from '../actions/Equipment';
import { saveAlertReadStatus } from '../actions/AlertsNotifications';
import { translate } from '../lang';

class EquipmentContainer extends Component {

    constructor(props) {
        super(props);
        this.state={
            dashboardData : [],
        };
    }

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount(){
        let token = "";
        if(localStorage.getItem('TOKEN')){
            token = JSON.parse(localStorage.getItem('TOKEN'));
        }
        this.props.getEquipmentList({token:token.token});
    }

    markAsRead(data) {
        this.props.saveAlertReadStatus( { alertId : data } );
    }
    render() {        
        return (
            <EquipmentComponent 
                markAsRead = { this.markAsRead.bind(this) }
                translate={translate} 
                apiError = { this.props.apiError }
                timezone = {this.props.timezone}
                dashdata={ this.props.equipmentData.SET_EQUIPMENT_DATA }/>
        );
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */


function mapStateToProps(state) {
    return {
        equipmentData: state.equipment.data,
        apiError : state.equipment.FETCH_DATA_FAIL || false,
        timezone: (state.profile && state.profile.timezone) || "Asia/Kolkata"
      };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getEquipmentList, saveAlertReadStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentContainer);