import http from "../../services/http";
import { LIMIT } from "../../constants/Constants";
import { __api_getFeatures, __api_setNewRole , __api_get_role_list, __api_delete_role,__api_get_roles, __api_updateNewRole} from "./admin.endpoints";

export const getModuleFeatures = data => http.Request("GET", __api_getFeatures, data);
export const setNewRole = data => http.Request("POST", __api_setNewRole, data);
export const updateRole = data => http.Request("POST", __api_updateNewRole, data);

export const getRoles = (req) => {
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0, uri = [__api_get_role_list, offset, LIMIT, true].join("/");
    return http.Request("GET", uri+"?t="+new Date().getTime(), req.data);
};

export const getRolesList =() => {
    return http.Request("GET", __api_get_roles);
};

export const deleteRole = data => http.Request("DELETE", `${__api_delete_role}?roleCode=${data.roleCode}`);