import React, { Component } from 'react';
import DealerManagementComponent from '../../components/admin/dealer-management/DealerManagementComponent';
import { getDealersList } from '../../actions/admin/dealerManagement';
import { connect } from 'react-redux';

class DealerManagementContainer extends Component {

    constructor(props) {
        super(props);

    }
    render() {
        return <DealerManagementComponent 
        dealerList = {this.props.dealerList}/>;
    }
    componentWillMount() {

        this.props.getDealersList();
    }
   


}


const mapDispatchToProps = {getDealersList }

const mapStateToProps = state => {
    return ({
        dealerList: state.dealerManagement ? state.dealerManagement.dealerList : []

    });
}


export default connect(mapStateToProps, mapDispatchToProps)(DealerManagementContainer);
