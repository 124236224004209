import {GET_ALERT_DATA, SAVE_ALERT_DATA, DISPLAY_ALERT_DATA, SET_SAR_OBJ} from '../types'

/**
* Dispatch the activity listing action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/

export const getAlertData = (data) => {
	return {
		type : GET_ALERT_DATA,
		data
	}
}

export const displayAlertSettingData = (data) => {
	return {
		type : DISPLAY_ALERT_DATA,
		data
	}
}

export const setSarObj = (data) => {
	return {
		type : SET_SAR_OBJ,
		data
	}
}

export const saveAlertData = (data) => {
	return {
		type : SAVE_ALERT_DATA,
		data
	}
}