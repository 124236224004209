import { SET_HOURMETER_GAP, SET_HOURMETER_GAP_HISTORY } from '../../types';

/** @type { Object } default state */
let defaultState = {
    data: {
    }
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function hourmeterOffsetManagement(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_HOURMETER_GAP:
            return { ...state, hourMeterGapDetail: action.data };
        default: return state;
    }
}

export function hourmeterOffsetHistory(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_HOURMETER_GAP_HISTORY:
        default: return state;
    }
}
