import { SENDING_REQUEST, SET_ADMIN_LOGGED_IN_TOKEN, INVALID_LOGIN, USER_FETCH_DATA_FAIL } from '../../types';

/** @type { Object } default state */
let defaultState = {

}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function admin(state = defaultState, action = {}) {

	switch (action.type) {
		case SET_ADMIN_LOGGED_IN_TOKEN:
			const token = {}
			token[action.type] = action.token;
			token['isSending'] = action.isSending;
			token['isLoggedIn'] = action.isLoggedIn;
			token['error'] = action.error;
			token['resMsg'] = action.resMsg;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, token)
			})

		case SENDING_REQUEST:
			const sendingRequest = {}
			sendingRequest[action.type] = action.customerData;
			sendingRequest['isSending'] = action.isSending;
			sendingRequest['isLoggedIn'] = action.isLoggedIn;
			sendingRequest['error'] = action.error;
			sendingRequest['resMsg'] = action.resMsg;

			return Object.assign({}, state, {
				data: Object.assign({}, state.data, sendingRequest)
			})

		case INVALID_LOGIN:
			const invalidLogin = {}
			invalidLogin[action.type] = action.customerData;
			invalidLogin['isLoggedIn'] = action.isLoggedIn;
			invalidLogin['isSending'] = action.isSending;
			invalidLogin['error'] = action.error;
			invalidLogin['resMsg'] = action.resMsg;

			return Object.assign({}, state, {
				data: Object.assign({}, state.data, invalidLogin)
			})

		case USER_FETCH_DATA_FAIL:
			const errors = {}
			errors[action.type] = action.payload.error;
			errors['isLoggedIn'] = action.payload.isLoggedIn;
			errors['isSending'] = action.payload.isSending;
			errors['error'] = action.payload.errorCheck;
			errors['resMsg'] = action.payload.resMsg;

			return Object.assign({}, state, {
				data: Object.assign({}, state.data, errors)
			})

		default: return state;
	}
}