import React from 'react';
import E404 from "../assets/images/404.jpg";

export default class Error404 extends React.Component{
    render(){
        return(
            <center>
                <img alt='404 error' src={E404} />
                <p><span className='e404' style={{cursor: 'pointer', textDecoration: 'underline', textTransform: 'uppercase', fontWeight: "bold"}} onClick={() => this.props.history.goBack()}>Back to Previous Page</span></p>
            </center>
        )
    }
}
