import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { UPDATE_SYS_MAINTAIN_DATA, SENDING_UPDATE_MAINTAIN_DATA, SET_UPDATE_MAINTAIN_DATA, FETCH_DATA_FAIL } from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* updateSysMaintainData(action) {
    const data = action.data;
    try {
        yield put({
            type: SENDING_UPDATE_MAINTAIN_DATA,
            data: '',
            tokenExpired: '',
            invalidToken: '',
            resMsg: '',
            isSending: true
        })
        let response = yield call(api.admin.sysMaintenance.update, data)
        switch (response.statusCode) {
            case 200:
                yield put({
                    type: SET_UPDATE_MAINTAIN_DATA,
                    data: response.data,
                    resMsg: response.message,
                    isSending: false,
                    error: false
                })
                break;
            default:  
            break;
        }
    } catch (e) {
        yield put({
            type: SET_UPDATE_MAINTAIN_DATA,
            data: [],
            resMsg: e,
            isSending: false,
            error: true
        })
    }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchUpdateSystemMaintenanceData() {
    yield takeLatest(UPDATE_SYS_MAINTAIN_DATA, updateSysMaintainData)
}
