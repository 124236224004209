import { takeLatest, call, put } from 'redux-saga/effects';
import { getAllMachineDetail } from '../../api/service-provider/equipments';
import { SET_LOADER, SET_TOASTR, SET_ALL_EQUIPMENTS,GET_ALL_EQUIPMENTS } from '../../types';




export function* getSPMachineDetail(action) {

    try {

        const equipments = yield call(getAllMachineDetail, action.data);
        
        yield put({ type: SET_ALL_EQUIPMENTS, data: equipments.data||[]});
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}
/**
 * map the dispatched action to the above function (Returning with pagination)
 */
export default function* watchSPMachine() {
    yield takeLatest(GET_ALL_EQUIPMENTS, getSPMachineDetail) // without pagination
}

   
