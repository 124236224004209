import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { GET_ALERTS_NOTIFICATIONS, SENDING_ALERT_NOTIFICATION_DATA, SET_ALERT_NOTIFICATION_DATA, FETCH_DATA_FAIL } from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* getAlertNotificationData(action) {
  const data = action.data;

  try {
    yield put({
      type: SENDING_ALERT_NOTIFICATION_DATA,
      data: '',
      tokenExpired: '',
      invalidToken: '',
      resMsg: '',
      isSending: true
    })
    
    let sysResponse, response, sysAlertResponse = [];

    if(data.data.type === 1){
      sysResponse = yield call(api.alertNotify.getSysMentainInfo, data);
    }/*else{
      sysResponse = yield call(api.alertNotify.getSysMentainInfo, data);
      response = yield call(api.alertNotify.getAlerts, data);
      sysAlertResponse = yield call(api.alertNotify.getScheduleSysAlert, data);
    }*/
  
    switch (sysResponse.statusCode) {
      case 200:
        if(data.data.type === 1){
          yield put({ type: SET_ALERT_NOTIFICATION_DATA, data: sysResponse.data, resMsg: sysResponse.message, isSending: false, error: false })
        }/*else{
          yield put({ type: SET_ALERT_NOTIFICATION_DATA, data:[response.data, sysAlertResponse.data, sysResponse.data], resMsg: response.message, isSending: false, error: false })
        }*/
        break;
      default:
      break;
    }
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: 'Error while connecting server'
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchAlertNotifications() {
  yield takeLatest(GET_ALERTS_NOTIFICATIONS, getAlertNotificationData)
}
