import { SET_ROLES,SET_ROLES_LIST } from '../../types';


/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function RoleList(state = {}, action = {}) {
    switch (action.type) {
        case SET_ROLES:
            return { ...state, ...action.data};
        case SET_ROLES_LIST:
            return { ...state, ...action.data};
        // case RESET_OWNER_DETAILS:
        //     return { ...state, user: undefined, equipments: []};
        
        // case SET_OWNERS:
        //     return { ...state, ...action.data};
        
        // case SET_OWNER_DATA_ORDER:
        //     return { ...state, ...action.data};

        default: return state;
    }
}
