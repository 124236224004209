import React, { Component } from 'react';
import { connect } from 'react-redux';
import HomeComponent from '../../components/admin/home/HomeComponent';
import { getEventTokenData } from '../../actions/RecentEvents';
import {getRecentContract} from '../../actions/admin/contractManagement'
import session from "../../services/session"

class HomeContainer extends Component {

    /**
     * Dispatch the action to get the members data
     */
    componentWillMount(){
        let token = '';
        if(session.get('ADMINTOKEN', 'cookie')){
            token = session.get('ADMINTOKEN', 'cookie');
        }
        this.props.getEventTokenData({token:token.token})
        this.props.getRecentContract()

    }
    componentDidMount() {
        //this.props.getMembers();
    }

    render() {
        return <HomeComponent contractDetails={this.props.contractDetails}/>;
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
const mapStateToProps = state => {
    let contractDetails = state.contractManagement && state.contractManagement.contractCountDetail && state.contractManagement.contractCountDetail.data;
    return { contractDetails};

}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getEventTokenData,
    getRecentContract
}

export default connect(mapStateToProps, mapDispatchToProps) (HomeContainer);