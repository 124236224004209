import React, { Component } from 'react';
import { connect } from 'react-redux';
import ForgetPassComponent from '../../components/admin/forget/ForgetPassComponent';
import { handleAdminForgetPassword } from '../../actions/Auth';

/** An active Forget Password component */
class ForgetPassContainer extends Component {


	/**
	 * Submit the data of the forget password form
	 * @param  { Object } data Object of the user inputs
	 */
	handleForgetPass = (data) => {
		this.props.handleAdminForgetPassword(data)
	}

	render() {

		return (
				<ForgetPassComponent
					data = { this.props.forgetPassword }
					submit={ this.handleForgetPass.bind(this) }
				/>	
			);
	}
}

/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
	console.log(state);
	return {
			forgetPassword: state.forgetPassword && state.forgetPassword.data,
		}
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
	handleAdminForgetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassContainer);