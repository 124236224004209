import http from "../../services/http";
import { __api_getContractDetails,__api_getStatusAndCompany,__api_updateContract,__api_getContractListInfo} from "../service-provider/sp.endpoints";
import {api_getContractCountDetail} from "../admin/admin.endpoints";
import { LIMIT } from "../../constants/Constants";



export const getContractDetail = (machineSaleId) => http.Request("GET", __api_getContractDetails+"/"+machineSaleId);
export const getcontractCount = ()=>http.Request("GET",api_getContractCountDetail);
export const updateContract = data => {
    return http.Request("POST", __api_updateContract, data);
}
export const getCompanyAndStatus=()=>http.Request("GET",__api_getStatusAndCompany)

export const getContractListInfo = (req) => {
    if(req.qs){ req.data = req.qs};
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    let status = req.isStatus;
    let company = req.isCompanyName;
    return http.Request("GET", [[__api_getContractListInfo,offset,LIMIT,true,company,status].join("/"),req.data].join("?"));
    
};