import React, { Component } from 'react';
import { connect } from 'react-redux';
import EquipmentLocationInfoComponent from '../../components/admin/equipment-location-info/EquipmentLocationInfoComponent';
import { getSPMachineLocation } from "../../actions/service-provider/equipment";

class EquipmentLocationInfoContainer extends Component {

    componentWillMount() {
        this.props.getSPMachineLocation();
    }

    render() {
        const test =
        {

            "locationEquipmentR-esponse": [
                {
                    "id": 1120,
                    "machineSaleId": 79060,
                    "equipmentType": "tractor",
                    "operatingStatus": "RUNNING",
                    "latitude": "29.542482",
                    "longitude": "75.053515",
                    "runningStatus": "Active",
                    "errorFlag": true,
                    "machineNum": "00021",
                    "model": "YM348A-IN-P",
                    "numberPlate": "trial2"
                },
                {
                    "id": 1120,
                    "machineSaleId": 63809,
                    "equipmentType": "EXCAVATOR",
                    "machineNum": "61206",
                    "model": "VIO20-6",
                    "numberPlate": null,
                    "operatingStatus": "STOPPED",
                    "latitude": "12.092025",
                    "longitude": "45.821753333333334",
                    "runningStatus": "Offline",
                    "errorFlag": false
                },
                {
                    "id": 1284,
                    "machineSaleId": 99995,
                    "equipmentType": "tractor",
                    "operatingStatus": "RUNNING",
                    "latitude": "36.076888333333336",
                    "longitude": "126.69427166666667",
                    "runningStatus": "Active",
                    "errorFlag": true
                }]
        };

        console.log("test ********", test)
        return (
            <EquipmentLocationInfoComponent equipmentList={this.props.equipmentList} />
        );
    }
}
function mapStateToProps(state) {
    let equipmentResponse = (state.sp && state.sp.data && state.sp.data.SET_ALL_EQUIPMENTS_LOCATION)
    let equipmentList = equipmentResponse && equipmentResponse.locationEquipmentResponse

    return { equipmentList: equipmentList }

}

const mapDispatchToProps = {
    getSPMachineLocation
}
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentLocationInfoContainer);