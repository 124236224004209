import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { SET_SRVPROV_LOGGED_IN_TOKEN, GET_SRVPROV_LOGGED_IN, SENDING_REQUEST, INVALID_LOGIN, USER_FETCH_DATA_FAIL } from '../../types';
import CryptoJS from 'crypto-js';
import { hashKey } from '../../constants/Constants';
import cookie from 'react-cookies';

import session from "../../services/session";
/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* loginSrvprov(action) {
console.log("loginSrvprov")
  const data = action.data;
  try {
    yield put(
      {
        type: SENDING_REQUEST,
        customerData: '',
        resMsg: '',
        isSending: true,
        isLoggedIn: false,
        error: false
      }
    )
    const response = yield call(api.serviceProvider.user.login, data);
    if (response.statusCode === 200 && response.data && response.data.token && response.data.functions && response.data.functions.length) {
      let params = { 'token': response.data.token };

        /* if(params && window['JSReceiver']){
          let o = {
            "token": params,
            "profile": response.data.userProfile || {},
            "functions": response.data.functions || []
          }
          window['JSReceiver'].getParams(JSON.stringify(o));
        } */
    
        session.set('SERVPROVTOKEN', params, "cookie");
        session.set("spname", data.username, "cookie");
        session.set("spprofile", response.data.userProfile, "cookie");
        session.set('spprev', response.data.functions,'cookie');

        let date = new Date();
        date.setTime(date.getTime() + (30 * 60 * 1000));
        cookie.save('serv_prov_activity', 1, { path: '/', expires: date });
        localStorage.setItem('serv_prov_authenticated', CryptoJS.AES.encrypt(data.username, hashKey));
        yield put({ type: "SET_SP_PROFILE", data: response.data.userProfile });

        yield put(
          {
            type: SET_SRVPROV_LOGGED_IN_TOKEN,
            token: response,
            resMsg: '',
            isSending: false,
            isLoggedIn: true,
            error: false,
          }
        );
        
      
    } else {
      yield put(
        {
          type: INVALID_LOGIN,
          customerData: '',
          resMsg: "You don't have sufficient permissions to login into dashboard.",
          isSending: false,
          isLoggedIn: false,
          error: true,
        }
      );
    }
  } catch (e) {
    yield put({
      type: USER_FETCH_DATA_FAIL,
      payload: {
        error: (e && e.message), isLoggedIn: false, errorCheck: true, resMsg: (e && e.message), isSending: false
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchLoginSrvprov() {
  yield takeLatest(GET_SRVPROV_LOGGED_IN, loginSrvprov)
}
