import {GET_LOCATION_DATA, GET_BOUND_DATA, GET_INFO_BOX_DATA, RESET_BOUND} from '../types'

/**
* Dispatch the activity listing action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/

export const getLocationData = (data) => {
	return {
		type : GET_LOCATION_DATA,
		data
	}
}

export const getBoundData = (data) => {
	return {
		type : GET_BOUND_DATA,
		data
	}
}

export const getInfoBoxData = (data) => {
	console.log(data);
	return {
		type: GET_INFO_BOX_DATA,
		data
	}
}

export const resetBound = (data) => {
	return {
		type: RESET_BOUND,
		data
	}
}