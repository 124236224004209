import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Table } from 'reactstrap';
import ButtonField  from '../../../../components/shared/ButtonField';
// import Pagination from 'react-js-pagination';
import Pagination from '../../../shared/Pagination';
import session from "../../../../services/session";
import _find from "lodash/find";

import {FaSort, FaSortUp, FaSortDown, FaExclamation} from 'react-icons/fa'
import circle from '../../../../assets/images/admin/circle.png';
import exclamation from '../../../../assets/images/admin/exclamation.jpg';
import iconGreen from '../../../../assets/images/admin/icon-green.png';
import completedGreen from '../../../../assets/images/admin/completed-green.svg';
import inProgressGren from '../../../../assets/images/admin/in-progress.svg';
import './style.scss';
import Moment from "react-moment";

/* A DataSheet component */
class DataSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            equipmentList: [],
            equipmentTotal: 0,
            activePage: 1,
            errorAPI: false,
            key: null,
            click: true,
            sortingParams: null
        };

    }
    componentWillMount() {
        if (this.props.equipment && this.props.equipment) {
            this.setState({ equipmentList: this.props.equipment.eventList });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.equipment !== nextProps.equipment) {
            this.setState({
                equipmentList: nextProps.equipment.eventList,
                equipmentTotal: (nextProps.equipment.eventsCount.totalCounts === 0 && this.state.equipmentTotal !== null) ? this.state.equipmentTotal : nextProps.equipment.eventsCount.totalCounts
            });
        }
        if (this.props.apiError !== nextProps.apiError) {
            if (nextProps.apiError !== false) {
                this.setState({
                    errorAPI: true
                })
            }
        }
        if(nextProps.search && nextProps.paginate === false){
            this.setState({
                activePage : 1,
                key: null,
                click: true,
                sortingParams: null
            })
        }
    }

    render() {
        
        const { translate,isService } = this.props;
        let sortType = (!this.state.click) ? <FaSortUp/> : <FaSortDown/>;

        let routeList = (session && session.get( "prev", "cookie")) || [];
        return (
            <div className="equipmentalertlist">
                <Table className="tabledashboard table table-responsive table-striped table-advance table-hover m-top-30">
                    <thead>
                        <tr >
                            <th width="15%" onClick={() => this.sortBy('alertDate') }>{translate('alertdate') } {(this.state.key === 'alertDate') ? sortType : <FaSort/>}</th>
                            <th width="10%" onClick={() => this.sortBy('type') }>{translate('type') } {(this.state.key === 'type') ? sortType : <FaSort/>}</th>
                            <th width="10%" onClick={() => this.sortBy('urgency') }>
                                <div className="float-left">{translate('urgency') } </div>
                                <div> {(this.state.key === 'urgency') ? sortType : <FaSort/>}</div>
                            </th>
                            <th width="10%" onClick={() => this.sortBy('numberPlate') }>{translate('numberPlate') } {(this.state.key === 'numberPlate') ? sortType : <FaSort/>}</th>
                            <th width="10%" onClick={() => this.sortBy('machineNum') }>{translate('machineno') } {(this.state.key === 'machineNum') ? sortType : <FaSort/>}</th>
                            <th width="10%" onClick={() => this.sortBy('owner') }>{translate('owner') } {(this.state.key === 'owner') ? sortType : <FaSort/>}</th>
                            <th onClick={() => this.sortBy('emergencyContact')}>{translate('emergnecyConatct') } {(this.state.key === 'emergencyContact') ? sortType : <FaSort/>}</th>
                            <th>{translate('description') }</th>
                            <th onClick={() => this.sortBy('status') }>{translate('status') } {(this.state.key === 'status') ? sortType : <FaSort/>}</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.getTableBody(routeList) }
                    </tbody>
                </Table>
                <div className="mb-2">
                    <img className="completed" src={completedGreen} alt="Completed"/> Completed
                    <img className="inprogress" src={inProgressGren} alt="In Progress"/> In Progress
                    <img className="callScheduled" src={ iconGreen } alt="Call Scheduled"/> Call Scheduled
                    <img className="notstart" src={exclamation} alt="Technical Error"/> Technical Error
                    <img className="notstart" src={circle} alt="Started"/> Started
                    <FaExclamation className={`urgencyColor1`}/> High
                    <FaExclamation className={`urgencyColor2`}/> Medium
                    <FaExclamation className={`urgencyColor3`}/> Low
                </div>


                <Pagination
                    limit={15}
                    total={this.state.equipmentTotal}
                    activePage={this.state.activePage}
                    onChange={this.handlePageChange.bind(this) }
                    data={this.state.equipmentList}
                    />

            </div>
        );
    }

    handlePageChange(page) {
        this.setState({ activePage: page });
        if (page < 2) {
            page = 0;
        } else {
            page = page - 1;
            page = (15 * page);
        }
        this.props.handlePageChange({ page: page, totalEventsFlag: 'false',sortingParams: this.state.sortingParams });
    }

    sortBy(key) {
        let page = this.state.activePage;
        if (page < 2) {
            page = 0;
        } else {
            page = page - 1;
            page = (15 * page);
        }
        let sortingParams = `?eventField=${key}&order=${((this.state.click === true) ? 'ASC' : 'DESC')}`
        this.props.handlePageChange({ page: page, totalEventsFlag: 'false', sortingParams: sortingParams });
        this.setState({
            key: key,
            click: !this.state.click,
            sortingParams: sortingParams
        });
    }

    getStatusIcon() {
        return {
            completed: completedGreen,
            inprogress: inProgressGren,
            callscheduled: iconGreen,
            technicalerror: exclamation,
            notstarted: circle,
            started: circle
        }
    }

    /**
     * get the table body as the alert listing
     */
    getTableBody = (routeList) => {
        let equipmentData = '';

        if (this.state.equipmentList.length > 0) {
            equipmentData = this.state.equipmentList.map((val, index) => {
                console.log('val',JSON.stringify(val))
                let imgs = "";
                /*if (val.currentStatus !== null && val.currentStatus !== 'New') {
                    imgs = <img alt="status" className="statuse" src={ this.getStatusIcon()[val.currentStatus !== null && (val.currentStatus).replace(/\s/g, '').toLowerCase()]}/>
                }*/
                return (
                    <tr key={ index } eid={ val.eventId } onClick={this.rowclick.bind(this, val.eventId, val.currentStatus, routeList) }>
                        
                        <td>

                            {/* {val.eventDate || "NA"} */}
                             {val.eventDate ? 
                            <Moment tz="Asia/Kolkata" format="DD MMM YYYY HH:mm:ss">{val.eventDate}</Moment>
                                : " "
                            } 
                        </td>
                        <td>{val.eventType}</td>
                        <td> { val.eventUrgency !== null && <FaExclamation className={`urgencyColor${val.eventUrgency}`} disabled/> }</td>
                        {
                             val.status == 'TE' ?
                                 <td>{(val.numberPlate ? val.numberPlate.toUpperCase() : " ")}</td> : <td></td>
                         }
                        <td>{val.machineNum}</td>
                        <td>{val.owner}</td>
                        <td>{val.emergencyContactNumber || ' '}</td>
                        <td>{val.eventDescription}</td>
                        <td>
                            <div className="widthmore">
                                {(val.currentStatus !== null && val.currentStatus!== 'New' && <img alt="status" className="statuse" src={ this.getStatusIcon()[val.currentStatus !== null && (val.currentStatus).replace(/\s/g, '').toLowerCase()]}/>) }
                                {val.currentStatus }
                            </div>
                        </td>
                    </tr>
                );
            });
        } else {
            if (this.props.equipment && this.props.equipment.eventList.length === 0) {
                equipmentData = <tr className="custom-no-data"><td className="text-center" colSpan={10}> No data Found </td></tr>;
            } else {
                equipmentData = <tr className="custom-no-data"><td className="text-center" colSpan={10}> {(this.state.errorAPI) ? 'Network Error' : 'Loading data..'}</td></tr>;
            }
        }
        return equipmentData;
    }


    rowclick(val, status, routeList, e) {
        if (status === null && !this.props.isService) {
            this.props.handleStatusChange(
                {
                    "eventId": val,
                    "currentStatus": "Started",
                    "supportResult": "Status changed NEW to Started"
                }
            );
        }
       
        let hasAdminAlertDetails = _find(routeList,{uuid:`/service/equipment-alerts`}),
        hasSpAlertDetails = _find(routeList, {uuid: `/service/sp_equipment-alerts`});
        console.log('hasSpAlertDetails',hasSpAlertDetails,'hasAdminAlertDetails',hasAdminAlertDetails);
        if (hasSpAlertDetails){
            hasAdminAlertDetails = false;
        }


       
            if(hasAdminAlertDetails){
                console.log('equipment-alert-detail');
                this.props.history.push(`/service/equipment-alert-detail/${val}`)
            }else if(hasSpAlertDetails){
                console.log('sp_equipment-alert-detail');
                this.props.history.push(`/service/sp_equipment-alert-detail/${val}`)
            }else{
                return false;
            }                
        } 
    

}

export default withRouter(DataSheet);
