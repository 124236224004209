import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import HeaderText from "../shared/HeaderText";
import { withRouter } from "react-router-dom";
import Bcrumbs from "../../constants/Breadcrumbs";


const Breadcrumbs = ({breadcrumbs, history}) => {
    
    document.title = `${(breadcrumbs && breadcrumbs.tagline)} - Yanmar SmartAssist | Web`
    return(
        <Col sm='8' className='breadcrumb-box'>
            <span>
                {breadcrumbs && breadcrumbs.links.map((link, index) =>  (
                    <Fragment key={index}>
                        {(index+1) < breadcrumbs.links.length ? <span className='pointer blu' id={`u${index}`} data-url={link.href} onClick={(e) => history.push(e.target.dataset.url)}>{link.title}</span> : link.title} 
                        {(index+1) < breadcrumbs.links.length && ` > `}
                    </Fragment>
                ))}
            </span>
            <HeaderText headerText={breadcrumbs && breadcrumbs.tagline} caption={breadcrumbs && breadcrumbs.caption}/>
        </Col>
    );
}

const mapStateToProps = state => {
    return ({
        breadcrumbs: Bcrumbs[state.app.breadcrumbs]
    });
}

export default connect(mapStateToProps)(withRouter(Breadcrumbs));