import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../../api/index';
import { UPDATE_EQUIPMENTS_STATUS_DATA, FETCH_DATA_FAIL } from '../../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* updateEquipmentStatus(action) {
  const data = action.data;
  try {
    
    yield call(api.serviceProvider.equipment.updateSupportInfo, data);

  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: 'Error while connecting server'
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchUpdateEquipmentStatus() {
  yield takeLatest(UPDATE_EQUIPMENTS_STATUS_DATA, updateEquipmentStatus)
}
