import { SET_TOASTR, GET_TZ_LN, SET_CONFIRM_BOX, SET_LOADER, SET_BREADCRUMBS, SHOW_REALTIME_ALERT, BELL_TOGGLE } from '../types';

/**
 * Dispatch the login action to the middleware
 * @param  { Object } data Date to be processed
 * @return { Object }      Dispatch the action type
 */
export const setToastr = data => {
    return { type: SET_TOASTR, data }
}

export const setLoader = data => {
    return { type: SET_LOADER, data }
}

export const getTimeZoneLanguage = data => {
    return { type: GET_TZ_LN, data }
}

export const setConfirmBox = data => {
    return { type: SET_CONFIRM_BOX, data }
}

export const setBreadCrumbs = data => {
    return { type: SET_BREADCRUMBS, data }
}

export const showRealTimeAlert = data => {
    return { type: SHOW_REALTIME_ALERT, data }
}

export const bellToggle = data => {
    return { type: BELL_TOGGLE, data }
}