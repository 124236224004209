import { SET_MAP_DATA, AREA_DATA, VERTICES_DATA } from '../../types';

/** @type { Object } default state */
let defaultState = {
    mapData: undefined,
    areaData: undefined,
    verticesData: undefined
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function fieldInformation(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_MAP_DATA:
            return { ...state, mapData: action.data };
        
        case AREA_DATA:
            return { ...state, areaData: action.data };

        
        case VERTICES_DATA:
            return { ...state, verticesData: action.data };

        default: return state;
    }
}
