import React, { Component, Fragment } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { FaSortUp, FaSortDown } from 'react-icons/fa'
import { LIMIT } from "../../../constants/Constants";
import Pagination from "../../shared/Pagination";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import { Link } from 'react-router-dom';
//import "./style.scss"

class HourmeterOffsetManagementDetailComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activePage: 1,
            total: 0,
            searchField: '',
            key: null,
            click: true,
        }
    }
    handlePageChange(page) {
        this.setState({ activePage: page || 1 }, () => {
            //this.props.getHourMeterGapInfo(page);
            this.props.parentCallback(page);
        });
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ activePage: nextProps.activePage })
    }
    
    render() {
        let { data } = this.props; 
        let { push } = this.props.history;
        let { activePage } = this.state;
        let sortType = (!this.state.click) ? <FaSortUp /> : <FaSortDown />

        return (
            <Fragment>
                <table className="tabledashboard table table-striped table-advance table-hover m-top-30">


                    <thead>
                        <tr>
                            {/* <th>Sr. No</th> */}
                            <th >Model</th>
                            <th >Machine No.</th>
                            <th > Offset Value(h)</th>
                            <th >Updated By</th>
                            <th >Updated On</th>
                        </tr>
                    </thead>

                    {data != undefined &&
                        <tbody >
                            {data && data.hourMeterGapWithOffSet.length > 0 ? data.hourMeterGapWithOffSet.map((item, i) => <Tr item={item} key={i} push={push} />) : <tr><td align="center" colSpan="12">No Details Found</td></tr> }
                        </tbody>}

                </table>

                <Pagination
                    limit={LIMIT}
                    total={data && data.countResult && data.countResult.totalCounts}
                    activePage={activePage}
                    onChange={this.handlePageChange.bind(this)}
                    data={data != undefined && data.hourMeterGapWithOffSet || []}
                />
            </Fragment>
        );
    }
}
const Tr = ({ item, push }) => <tr onClick={() => push(`/service/hourmeter-offset-detail?model=${item.model}&machineNum=${item.machineNum}`)}>
    <td>{item.model}</td>
    <td>{item.machineNum}</td>
    <td>{item.hourmeterOffset}</td>
    <td>{item.updateBy}</td>
    <td>{item.serviceDate ? (<Moment format="DD MMM YYYY HH:mm:ss" tz="Asia/Kolkata">
        {item.serviceDate}
    </Moment>) : " "}</td>
    <td><button className="btn btn-primary srch-btn" style={{marginTop:'0px'}}><Link to={`/service/hourmeter-offset-detail?model=${item.model}&machineNum=${item.machineNum}`} className='btn-register' style={{color:'white'}}>DETAIL</Link></button></td>
</tr>


export default (withRouter(HourmeterOffsetManagementDetailComponent));