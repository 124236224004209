import { GET_DEALER_EQUIPMENT_LIST, GET_DEALERS } from '../../types';

export const getDealerEquipmentList = (data) => {
    console.log('inside action ');
    return { type: GET_DEALER_EQUIPMENT_LIST, data }
}

export const getDealersList = (data) => {
    console.log('inside getDealers ');
    return { type: GET_DEALERS, data }
}

