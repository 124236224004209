export default class Sorting {
	
	static compareByNumber(key, click, nest=false) {
        
		if(!click){
            return function (a, b) {
                if(nest){
                    if ((parseInt(a[nest][key] || 0)) > (parseInt(b[nest][key]) || 0)) return -1;
                    if ((parseInt(a[nest][key] || 0)) < (parseInt(b[nest][key]) || 0)) return 1;
                    return 0;
                }else{
                    if (parseInt(a[key]) > parseInt(b[key])) return -1;
                    if (parseInt(a[key]) < parseInt(b[key])) return 1;
                    return 0;
                }
            };    
        }
        return function (a, b) {
            if(nest){
                if ((parseInt(a[nest][key] || 0)) < (parseInt(b[nest][key]) || 0)) return -1;
                if ((parseInt(a[nest][key] || 0)) > (parseInt(b[nest][key]) || 0)) return 1;
                if (parseInt(a[nest][key]) < parseInt(b[nest][key])) return -1;
                if (parseInt(a[nest][key]) > parseInt(b[nest][key])) return 1;
                return 0;
            }else{
                if (parseInt(a[key]) < parseInt(b[key])) return -1;
                if (parseInt(a[key]) > parseInt(b[key])) return 1;
                return 0;
            }
        };
    }

    static compareByDate(key, click, nest=false) {
        
		if(!click){
            return function (a, b) {
                if(nest){
                    if ((parseInt(new Date(a[nest][key]).getTime() || 0)) > (parseInt(new Date(b[nest][key]).getTime()) || 0)) return -1;
                    if ((parseInt(new Date(a[nest][key]).getTime() || 0)) < (parseInt(new Date(b[nest][key]).getTime()) || 0)) return 1;
                    return 0;
                }else{
                    if (parseInt(new Date(a[key]).getTime()) > parseInt(new Date(b[key]).getTime())) return -1;
                    if (parseInt(new Date(a[key]).getTime()) < parseInt(new Date(b[key]).getTime())) return 1;
                    return 0;
                }
            };    
        }
        return function (a, b) {
            if(nest){
                if ((parseInt(new Date(a[nest][key]).getTime() || 0)) < (parseInt(new Date(b[nest][key]).getTime()) || 0)) return -1;
                if ((parseInt(new Date(a[nest][key]).getTime() || 0)) > (parseInt(new Date(b[nest][key]).getTime()) || 0)) return 1;
                if (parseInt(new Date(a[nest][key]).getTime()) < parseInt(new Date(b[nest][key]).getTime())) return -1;
                if (parseInt(new Date(a[nest][key]).getTime()) > parseInt(new Date(b[nest][key]).getTime())) return 1;
                return 0;
            }else{
                if (parseInt(new Date(a[key]).getTime()) < parseInt(new Date(b[key]).getTime())) return -1;
                if (parseInt(new Date(a[key]).getTime()) > parseInt(new Date(b[key]).getTime())) return 1;
                return 0;
            }
        };
    }

    static compareByChar(key, click) {
		if(!click){
            return function (a, b) {
                if (a[key] > b[key]) return -1;
                if (a[key] < b[key]) return 1;
                return 0;
            };    
        }
        return function (a, b) {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        };
    }

    static compareAlpaNumeric(key, click) {
       
        if (!click) {
            return function (a, b) {
                var regExAlphabets = /[^a-zA-Z]/g;
                var regExNumbers = /[^0-9]/g;
                var aA = a[key].replace(regExAlphabets, "");
                var bA = b[key].replace(regExAlphabets, "");        
                if (aA === bA) {
                    var aN = parseInt(a[key].replace(regExNumbers, ""), 10);
                    var bN = parseInt(b[key].replace(regExNumbers, ""), 10);
                    return aN === bN ? 0 : aN > bN ? -1 : 1;
                } else {
                    return aA > bA ? -1 : 1;
                }
            }

        }
        return function (a, b) {
            var regExAlphabets = /[^a-zA-Z]/g;
            var regExNumbers = /[^0-9]/g;
            var aA = a[key].replace(regExAlphabets, "");
            var bA = b[key].replace(regExAlphabets, "");
            if (aA === bA) {
                var aN = parseInt(a[key].replace(regExNumbers, ""), 10);
                var bN = parseInt(b[key].replace(regExNumbers, ""), 10);
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        }
        
    }
}