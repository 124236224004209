import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResetPasswordComponent from "../components/customer/reset-password/ResetPasswordComponent";
import Loader from "../messages/loaders/Loader";
import { translate } from '../lang';
import { Redirect } from 'react-router-dom';
import { resetPassword } from "../api/user";
import { setLoader, setToastr } from "../actions/Application";

/** An active login component */
export class ResetPasswordContainer extends Component {

	constructor(props){
		super(props);

		/** function binding */
		this.userResetPassword = this.userResetPassword.bind(this);

		this.state = {
			error: "",
			success: ""
		};
	}

    componentDidMount(){
		let {token} = this.props.match.params;

		if(token){
			/** validate token available*/
			this.userResetPassword(token, null);
		}else{
			/** show error page */
		}

		this.props.setLoader({loader:{open: true, khol:"do"}});
	}
	
	userResetPassword(token, data=null){
		let { setLoader } = this.props;
		/** show loader */
		setLoader({loader:{open:true}});

		resetPassword(token, data)
		.then(result => {
			if(data && result.data === 'false'){
				/** maybe - wrong login id */
				this.setState({success: "", error: "You've entered invalid User Id" });
			}else if(result.data === 'false'){
				/** link is expired */
				this.setState({success: "", error: "This link has been expired" });
			}else if(result.data){
				if(data){
					/** */
					this.setState({error: "", success: "Your password has been reset successfully." });
				}
			}
			/** off loader */
			setLoader({loader:{open:false}});
		})
		.catch(error => {
			this.setState({ success: "", error: error || "We couldn't proceed with this request. Please try again later."});
			setLoader({loader:{open:false}});
		})
	}

	render() {
		if (this.props.isAuthenticated) {
			return <Redirect to="/customer/equipment" />
		} else {
			return (
				<div>
					<ResetPasswordComponent
						translate = { translate } 
						msg = { this.props.msg }
						loader = { this.props.loader }
						resetPassword = { this.userResetPassword }
						error = {this.state.error}
						success = {this.state.success}
						/>
					<Loader />
				</div>
			);
		}

	}
}

/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 **/

function mapStateToProps(state) {
	let isAuthenticated = false;
	if (state.user.data && state.user.data.SET_LOGGED_IN_TOKEN) {
		isAuthenticated = true
	}
	
	if (state.user && state.user.data && state.user.data.error === false && state.user.data.isLoggedIn) {
		isAuthenticated = true;
	}
	return {
		isAuthenticated: isAuthenticated
	}
}

const mapDispatchToProps = {setLoader, setToastr}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);