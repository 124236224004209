import React from "react";
import profileImage from '../../assets/avatar/avatar.png';

const Image = (props) => {
    let i;
    if(props && props.src && props.src.length > 150){
        i = "data:image/jpg;base64," + props.src;
    }else{
        if(props.src){
            i = [props.src, "?t=", new Date().getTime()].join("");
        }else{
            i = profileImage;
        }
    }
    return <img {...props} src={i} alt='profile' />;
}

export default Image;