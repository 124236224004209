import React, { Component } from 'react';
import WorkReportComponent  from './ReportTabComponent/WorkReportComponent';
import UtilizationReportComponent  from './ReportTabComponent/UtilizationReportComponent';
import { Col, Row, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {FaClock, FaChartArea } from 'react-icons/fa'
import classnames from 'classnames';
import './style.scss';

/* A Reports component */
class ReportsComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
            },
            activeTab: '1',
            equipment : '',
            equipmentData:[],
            loader : false
        };
        this.toggle = this.toggle.bind(this);
    }

    componentWillMount(){
        let equipmentData = JSON.parse(localStorage.getItem('equipmentData'));
        this.getNumberPlate(equipmentData);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
 
    getNumberPlate(equipmentData) {
        let data = [];
        if(equipmentData){
            //Number Plate Option
            let numberPlate = equipmentData.equipmentData.map(function (item, index) {
                if(item.status=='TE')
                return <option key={index} value={item.machineSaleId}> {(item.numberPlate ? item.numberPlate.toUpperCase() : " ")} </option>;
            });
            //Model Machine Number
            let modelMachine = equipmentData.equipmentData.map(function (item, index) {
                return <option key={index} value={item.machineSaleId}> {item.model}/{item.machineNum} </option>;
            });
            data =  [numberPlate,modelMachine,equipmentData];
        }
        this.setState({ equipmentData : data})        
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            if(nextProps.equipment.data){
                this.setState({loader:nextProps.equipment.data.isSending})
            }
            if(nextProps.equipment.data.SET_EQUIPMENT_DATA){
                this.getNumberPlate({ equipmentData : nextProps.equipment.data.SET_EQUIPMENT_DATA});
            }
        }
    }

    handleChange(data,type) {
        this.props.handleChange(data,type);
    }

    render() {
        const { translate } = this.props;
        return (
            <div className="colorwhite reports">
                <Row className="toprow">
                    <Col  lg="12">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '1' }) }
                                    onClick={() => { this.toggle('1'); } }
                                    >
                                    <div>
                                        <FaChartArea/>
                                        <div className="tabheading">{translate('workReport') }</div>
                                        <div>{translate('daysMonthly') }</div>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' }) }
                                    onClick={() => { this.toggle('2'); } }
                                    >
                                    <div>
                                        <FaClock/>
                                        <div className="tabheading">{translate('utilizationReport') }</div>
                                        <div>{translate('daysMonthly') }</div>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col lg={12}>
                                        { this.state.activeTab === '1' && 
                                        <WorkReportComponent 
                                            handleChange={ this.handleChange.bind(this) } 
                                            langtranslate={ translate }
                                            showLoader = { this.state.loader }
                                            numberPlateOption = { this.state.equipmentData }
                                                {...this.props}
                                                translate={translate}
                                        />
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col lg={12}>
                                        { this.state.activeTab === '2' && 
                                        <UtilizationReportComponent
                                            handleChange={ this.handleChange.bind(this) } 
                                            langtranslate={ translate }
                                            showLoader = { this.state.loader }
                                            numberPlateOption = { this.state.equipmentData }
                                            equipments = { this.state.equipmentData[2] }
                                            { ...this.props }
                                            
                                        />
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ReportsComponent;
