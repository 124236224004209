import { translate } from "../../lang";

export const required = value => (value || typeof value === 'number' ? (typeof value === 'string' && value.trim() === "" ? translate('spacesNotAllowed') : undefined) : translate('requiredField'));

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? translate('invalidEmail')
        : undefined

const maxLength = max => value =>
    value && value.length > max ? translate('mustBe') + `${max}` + translate('characters') : undefined;
export const max10 = maxLength(10);
export const max6 = maxLength(6);
export const max12 = maxLength(12);
export const max5 = maxLength(5);

export const minLength = min => value =>
    value && value.length < min ? translate('mustBe') + `${min}` + translate('characters') : undefined
export const min7 = minLength(7);
export const min3 = minLength(3);
export const min6 = minLength(6);
export const min4 = minLength(4);
export const min10 = minLength(10);
export const min1 = minLength(1);

export const onlyNumbers = value => value && !/^[0-9]*$/i.test(value) ? translate('enterOnlyNumber') : undefined

export const lessThanSixDigit = value => value && (value - 100000)>=0 ? translate('enterOnlyFiveDigitNumber') : undefined

export const passwordsMatch = (value, allValues) =>
    value !== allValues.password ? translate('passwordNotMatch') : undefined;

export const alphaNumeric = value => value && !/^[a-z\d\-_\s]+$/i.test(value) ? translate('enterNumberOrAlphabets') : undefined

export const alphaNum_ = value => value && !/^[a-z\d\s]+$/i.test(value) ? translate('enterNumberOrAlphabets') : undefined

export const maxCurrentDate = value => value && new Date(value).getTime() > new Date().getTime() ? translate('dateNotGreaterThanCurrentDate') : undefined
