import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Table } from 'reactstrap';
import InputField from '../../../../components/shared/InputField';
import Button from '../../../../components/shared/ButtonField';
import moment from 'moment';
import { FaSortDown, FaSortUp, FaSort, FaPencilAlt, FaTimesCircle } from 'react-icons/fa'
import completedGreen from '../../../../assets/images/admin/completed-green.svg';
import NotStarted from "../../../../assets/images/admin/circle.png";
import Loaders from '../../../../messages/loaders/Loaders';
import { Alert } from 'reactstrap';
import './style.scss';
import Moment from "react-moment";
import {connect} from "react-redux";
import {setToastr} from "../../../../actions/Application";

/* A DataSheet component */
class DataSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceAlertData: [],
            actionRow: null,
            sequence: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'nineth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth', 'twentieth', 'twentyFirst', 'twentySecond', 'twentyThird', 'twentyFourth'],
            submitData: {
                "model": "",
                "equipmentType": "",
                "firstServiceByHour": 0,
                "secondServiceByHour": 0,
                "thirdServiceByHour": 0,
                "fourthServiceByHour": 0,
                "fifthServiceByHour": 0,
                "sixthServiceByHour": 0,
                "seventhServiceByHour": 0,
                "eighthServiceByHour": 0,
                "ninethServiceByHour": 0,
                "thenthServiceByHour": 0,
                "eleventhServiceByHour": 0,
                "twelfthServiceByHour": 0,
                "thirteenthServiceByHour": 0,
                "fourteenthServiceByHour": 0,
                "fifteenthServiceByHour": 0,
                "sixteenthServiceByHour": 0,
                "seventeenthServiceByHour": 0,
                "eighteenthServiceByHour": 0,
                "nineteenthServiceByHour": 0,
                "twentiethServiceByHour": 0,
                "twentyFirstServiceByHour": 0,
                "twentySecondServiceByHour": 0,
                "twentyThirdServiceByHour": 0,
                "twentyFourthServiceByHour": 0,


                "firstServiceByDays": 0,
                "secondServiceByDays": 0,
                "thirdServiceByDays": 0,
                "fourthServiceByDays": 0,
                "fifthServiceByDays": 0,
                "sixthServiceByDays": 0,
                "seventhServiceByDays": 0,
                "eighthServiceByDays": 0,
                "ninethServiceByDays": 0,
                "thenthServiceByDays": 0,
                "eleventhServiceByDays": 0,
                "twelfthServiceByDays": 0,
                "thirteenthServiceByDays": 0,
                "fourteenthServiceByDays": 0,
                "fifteenthServiceByDays": 0,
                "sixteenthServiceByDays": 0,
                "seventeenthServiceByDays": 0,
                "eighteenthServiceByDays": 0,
                "nineteenthServiceByDays": 0,
                "twentiethServiceByDays": 0,
                "twentyFirstServiceByDays": 0,
                "twentySecondServiceByDays": 0,
                "twentyThirdServiceByDays": 0,
                "twentyFourthServiceByDays": 0,
                "updatedDate": 0
            },
            notify: false,
            key: null,
            errorAPI: false,
            notifyType: 'success',
            click: true,
            notifyText: '',
            showLoader: true,
            updated: false,
            timeout: [],
            hasChanged: false,
            isChanged: false
        };
        this.isAnyServiceZero = this.isAnyServiceZero.bind(this)
    }

    componentWillUnmount() {
        this.setState({
            isChanged: false
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            showLoader: nextProps.serviceAlertData.isSending,
        });
        //if (this.props.serviceAlertData.SET_UPDATE_SCHEDULE_SERVICE_ALERT_DATA !== nextProps.serviceAlertData.SET_UPDATE_SCHEDULE_SERVICE_ALERT_DATA) {
        if (nextProps.serviceAlertData.SET_UPDATE_SCHEDULE_SERVICE_ALERT_DATA && this.state.isChanged) {
            this.setState({
                notify: true,
                notifyType: 'success',
                notifyText: <div className="callout"><p>Alert service has been scheduled for Equipment – Tractor {this.state.submitData.model} (Model).</p><p>Alert will be send it to the owners as per the equipment due date.</p></div>
            });
            clearTimeout(this.state.timeout)
            let timeout = setTimeout(() => { this.setState({ notify: false }) }, 5000)
            this.setState({ timeout: timeout })
        }
        //}
        if (this.props.serviceAlertData.SET_SCHEDULE_SERVICE_ALERT_DATA !== nextProps.serviceAlertData.SET_SCHEDULE_SERVICE_ALERT_DATA) {
            this.setState({
                serviceAlertData: nextProps.serviceAlertData.SET_SCHEDULE_SERVICE_ALERT_DATA,
                updated: false
            });
        }

        //if(this.props.apiError !== nextProps.apiError){
        if (nextProps.apiError !== false) {
            this.setState({
                serviceAlertData: nextProps.serviceAlertData.SET_SCHEDULE_SERVICE_ALERT_DATA,
                errorAPI: true,
                showLoader: false,
                notify: false
            })
        }
        //}
    }
    handleChange(index, value, e) {
        console.log('e.target.value', e.target.value)
        console.log('submitData', this.state.submitData[e.target.name])
        e.preventDefault();
        this.setState({
            hasChanged: true,
            isChanged: true,
            submitData: {
                ...this.state.submitData, [e.target.name]: e.target.value.trim()
            }
        });
    }

    handleSubmit(model, index, e) {
        let dataSubmiit = true;
        let submitData = JSON.parse(JSON.stringify(this.state.submitData)), { hasChanged } = this.state;
        let sequence = this.state.sequence;
        console.log("submitData" + JSON.stringify(submitData));
        var p = e.target.parentElement.parentElement
        var input = p.getElementsByTagName('input');
        /* 
        **********  Could be used in future **********
                sequence.map((element) => {
                    if (submitData[element + 'ServiceByHour'] === "" || submitData[element + 'ServiceByHour'] === 0) {
                        document.getElementById('boxServiceByHour' + index + element).className = document.getElementById('boxServiceByHour' + index + element).className + ' border-danger';
                        dataSubmiit = false;
        
                    }
                    if (submitData[element + 'ServiceByDays'] === "" || submitData[element + 'ServiceByDays'] === 0) {
                        document.getElementById('boxServiceByDays' + index + element).className = document.getElementById('boxServiceByDays' + index + element).className + ' border-danger';
                        dataSubmiit = false;
                    }
        
                }); */
        for (var i = 0; i <= 23; i++) {
            let inputfield = sequence[i];
            let Nextinputfield = sequence[i + 1];
            if (submitData[inputfield + 'ServiceByHour'] !== 0 && submitData[Nextinputfield + 'ServiceByHour'] !== 0 && parseInt(submitData[Nextinputfield + 'ServiceByHour']) < parseInt(submitData[inputfield + 'ServiceByHour'])) {
                 document.getElementById('boxServiceByHour' + index + inputfield).className = document.getElementById('boxServiceByHour' + index + inputfield).className + ' border-danger';
                document.getElementById('boxServiceByHour' + index + Nextinputfield).className = document.getElementById('boxServiceByHour' + index + Nextinputfield).className + ' border-danger';
               let msg= inputfield.toUpperCase() +' service can not be greater than ' +Nextinputfield.toUpperCase()+' service';
                this.props.setToastr({
                    toastr:{
                        type:'error' ,
                        message: msg
                    }
                });
                    
                dataSubmiit = false;
                break;
            }
        }
        if (dataSubmiit) {
            sequence.map((element) => {
                document.getElementById('boxServiceByHour' + index + element).className = 'edit form-control'
            });
            submitData.updatedDate = moment().format('D MMM YYYY HH:mm:ss');

            let data = this.state.serviceAlertData.map((value, loopndex) => {
                return (model === value.model) ? submitData : value;
            });
            this.setState({ actionRow: null, updated: true, serviceAlertData: data });
            delete this.state.submitData.updatedDate;

            if (hasChanged && submitData) {
                console.log(JSON.stringify(this.state.submitData))
                this.props.handleSubmit(this.state.submitData);
                this.setState({ hasChanged: false });
            }
        }






    }

    cancelEditRow(i,e) {
        console.log('i', i);

        var input = document.getElementsByTagName('input');


        let { sequence } = this.state;
        for (let c = 0; c <= 23; c++) {
            let box = document.getElementById("boxServiceByHour" + i + sequence[c]);

            box.disabled = true;
            box.classList.add('noneditable')
            let box2 = document.getElementById("boxServiceByDays" + i + sequence[c]);
            box2.disabled = true;
            box2.classList.add('noneditable')
        }
        this.setState({
            actionRow: null
        })
    }

    onDismiss() {
        this.setState({ notify: false });
    }
    render() {
        const { translate } = this.props;
        const { sequence } = this.state;

        return (
            <div className="ssa">
                { this.state.showLoader && <Loaders
                    loadingText={`Please Wait...`}
                    modalOpen={true}
                    className={`loading-contents`}
                    color={'#08a5e1'}
                />
                }
                { this.state.notify && <Alert color={this.state.notifyType} isOpen={this.state.notify} toggle={this.onDismiss.bind(this)}> {this.state.notifyText}</Alert>}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 noPadding" >
                            {this.equipmentDetailTableBody()}
                        </div>
                        <div className="col-md-3 noPadding">
                            {this.ServiceByHourTableBody()}
                        </div>
                        <div className="col-md-3 noPadding">
                            {this.ServiceByDaysTableBody()}
                        </div>
                        <div className="col-md-3 noPadding">
                            {this.equipmentRightTableBody()}
                        </div>
                    </div>
                </div>

                <div>
                    <img alt="completed" className="completed" src={completedGreen} /> Configured
                    <img alt="inprogress" className="inprogress" src={NotStarted} /> Not Configured
                </div>
            </div>
        );
    }

    editRow(index, value, i,e) {
        console.log('value', value)
        console.log('submitData', this.state.submitData)

        this.setState({
            actionRow: index,

        })
        this.setState({ submitData: value }, function () {
            console.log('submitData2', this.state.submitData)
        });

        let { sequence } = this.state;
        for (let c = 0; c <= 23; c++) {
            let box = document.getElementById("boxServiceByHour" + i + sequence[c]);
            
            box.disabled = false;
            box.classList.add("edit")
            let box2 = document.getElementById("boxServiceByDays" + i + sequence[c]);
            box2.disabled = false;
            box2.classList.add("edit")
        }

        /* e.target.parentElement.parentElement.childNodes.forEach((val)=>{
            console.log(val.firstChild.tagName)
            if(val.childElementCount > 0 && val.firstChild.tagName.toUpperCase() === "INPUT"){
                val.firstChild.className = 'edit';
                val.firstChild.disabled = false;
            }  
        }) */
    }

    compareBy(key) {
        if (!this.state.click) {
            return function (a, b) {
                if (a[key] > b[key]) return -1;
                if (a[key] < b[key]) return 1;
                return 0;
            };
        }
        return function (a, b) {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        };
    }

    sortBy(key) {
        let arrayCopy = [...this.state.serviceAlertData];
        arrayCopy = arrayCopy.map((val) => {
            val.status = this.isAnyServiceZero(val) ? 0 : 1;
            return val;
        })
        arrayCopy.sort(this.compareBy(key));
        this.setState({
            serviceAlertData: arrayCopy,
            key: key,
            click: !this.state.click
        });
    }
    isAnyServiceZero(value) {
        let result = true;
        const sequence = this.state.sequence;
        (sequence).forEach(element => {
            if (value[element + "ServiceByHour"] != 0 || value[element + "ServiceByDays"] != 0) {
                result = false;
            }
        });
        return result;
    }

    ServiceByHourTableBody = () => {
        let { translate } = this.props
        let data = this.state.serviceAlertData;
        let sequence = this.state.sequence;
        let sortType = (!this.state.click) ? <FaSortUp /> : <FaSortDown />
        return (
            <div style={{ width: "100%", overflow: "auto" }}>
                <Table className="table tabledashboard" >
                    <thead>
                        <tr><th colSpan='4' style={{ textAlign: "left", position: 'sticky', left: '50px' }}>Schedule Services (by Hours)</th>
                            <th colSpan='20' ></th></tr>
                        <tr>
                            {sequence && sequence.map((seq, index) => {
                                return (
                                    <th>{seq.toUpperCase()}</th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>

                        {(data && data.length > 0) && data.map((value, index) => {
                            const classInput = (this.state.actionRow === value.model) ? 'edit' : 'noneditable';
                            return (

                                <tr key={index}>

                                    {    (this.state.sequence.map(element => {

                                        return (
                                            <td className="width-edit">
                                                <InputField
                                                    type='number'
                                                    min='1'
                                                    value={(this.state.actionRow === value.model) ? this.state.submitData[element + 'ServiceByHour'] : value[element + 'ServiceByHour']}
                                                    name={element + "ServiceByHour"}
                                                    className={classInput}
                                                    handleChange={this.handleChange.bind(this, value.model, value)}
                                                    disabled={true}
                                                    id={"boxServiceByHour" + index + (element)}
                                                />
                                            </td>
                                        )
                                    }

                                    ))}

                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>

        )
    }
    ServiceByDaysTableBody = () => {
        let data = this.state.serviceAlertData;
        let { sequence, submitData } = this.state;

        console.log('submitData ', submitData)
        return (
            <div style={{ width: "100%", overflow: "auto" }}>
                <Table className="table tabledashboard" >
                    <thead>
                        <tr><th colSpan='4' style={{ textAlign: "left", position: 'sticky', left: '50px' }}>Schedule Services (by Days)</th>
                            <th colSpan='20' ></th></tr>
                        <tr>
                            {sequence && sequence.map((seq, index) => {
                                return (
                                    <th>{seq.toUpperCase()}</th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {(data && data.length > 0) && data.map((value, index) => {
                            const classInput = (this.state.actionRow === value.model) ? 'edit' : 'noneditable';
                            return (

                                <tr key={index}>

                                    {    (this.state.sequence.map(element => {

                                        return (
                                            <td className="width-edit">
                                                <InputField
                                                    type='number'
                                                    min='1'
                                                    value={(this.state.actionRow === value.model) ? this.state.submitData[element + 'ServiceByDays'] : value[element + 'ServiceByDays']}
                                                    name={element + "ServiceByDays"}
                                                    className={classInput}
                                                    handleChange={this.handleChange.bind(this, value.model, value)}
                                                    disabled={true}
                                                    id={"boxServiceByDays" + (index) + (element)}
                                                />
                                            </td>
                                        )

                                    }

                                    ))}

                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }
    equipmentRightTableBody = () => {
        let data = this.state.serviceAlertData;
        return (
            <Table className="table tabledashboard">
                <thead>
                    <tr><th colSpan='2'></th></tr>
                    <tr>
                        <th style={{ width: '57%' }}>Updated On</th>
                        <th style={{ width: '45%' }}>''</th></tr>
                </thead>
                <tbody>
                    {(data && data.length > 0) && data.map((value, index) => {
                        const isCombile = value.equipmentType.toLowerCase() == 'combine harvester' || value.equipmentType.toLowerCase() == 'excavator';
                        return (
                            <tr key={index}>
                                <td style={{ width: '57%' }}>{value.updatedDate || "NA"}</td>
                                <td style={{ width: '45%' }}>
                                    {
                                        (this.state.actionRow === value.model) ?
                                            <Fragment>
                                                <Button
                                                    buttonText="Set"
                                                    handleClick={this.handleSubmit.bind(this, value.model, index)}
                                                    clickEvent={true}
                                                    className="btn-primary p-0 pl-2 pr-2"
                                                />
                                        &nbsp;
                                        <button onClick={this.cancelEditRow.bind(this, index)} className="btn-dark p-0 pl-2 pr-2 btn btn-danger">
                                                    Cancel
                                        </button>
                                            </Fragment>
                                            :
                                            <FaPencilAlt onClick={this.editRow.bind(this, value.model, value, index)} />
                                    }
                                </td>

                            </tr>
                        );
                    })}
                </tbody>
            </Table>

        )
    }
    equipmentDetailTableBody = () => {
        let { translate } = this.props
        let data = this.state.serviceAlertData;
        let sortType = (!this.state.click) ? <FaSortUp /> : <FaSortDown />
        return (

            <Table className="table tabledashboard" >
                <thead>
                    <tr><th colspan='3' style={{ width: '100%' }}> </th></tr>
                    <tr>
                        <th style={{ width: '25%' }} onClick={() => this.sortBy('status')} className='first'>{translate('status')}{(this.state.key === 'status') ? sortType : <FaSort />}</th>
                        <th style={{ width: '25%' }} onClick={() => this.sortBy('equipmentType')} className='second'>{translate('Type')}{(this.state.key === 'equipmentType') ? sortType : <FaSort />}</th>
                        <th style={{ width: '70%' }} onClick={() => this.sortBy('model')} className='third'>{translate('model')}{(this.state.key === 'model') ? sortType : <FaSort />}</th>
                    </tr>
                </thead>
                <tbody>
                    {(data && data.length > 0) && data.map((value, index) => {
                        const classInput = (this.state.actionRow === value.model) ? 'edit' : 'noneditable';
                        const isAnyServiceZero = this.isAnyServiceZero(value);
                        const status = isAnyServiceZero ? <img alt="inprogress" className="ml-0 inprogress" src={NotStarted} /> : <img alt="completed" className="completed" src={completedGreen} />
                        return (

                            <tr key={index}>
                                <td style={{ width: '25%' }}>{status}</td>
                                {value.equipmentType.length && value.equipmentType.split(' ').length > 1 &&
                                    <td style={{ width: '25%' }}><div class="tooltip1">{value.equipmentType.split(' ')[0].toUpperCase()}...<span class="tooltiptext">{value.equipmentType.toUpperCase()}</span></div></td>
                                }
                                {value.equipmentType.length && value.equipmentType.split(' ').length == 1 &&
                                    <td style={{ width: '25%' }}>{value.equipmentType.toUpperCase()}</td>
                                }
                                <td style={{ width: '70%' }}>{value.model}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

        )
    }

}
let mapDispatchToProps = {  setToastr };

export default connect(null, mapDispatchToProps)(withRouter(DataSheet));

