import {GET_REPORTS_DATA,GET_WORK_REPORTS_DATA} from '../types'

/**
* Dispatch the activity listing action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/

export const getReports = (data) => {
	return {
		type : GET_REPORTS_DATA,
		data
	}
}

export const getWorkReports = (data) => {
	return {
		type : GET_WORK_REPORTS_DATA,
		data
	}
}