import { URL } from '../constants/Constants';

/** User Level */
export const __api_userResetPassword = URL + "User/ForgetPassword/updatePassword";
/** Members */
export const __api_getMembers = URL + "Member/getMembers";
export const __api_addMember = URL + "Member/add";
export const __api_saveMemeberFunction= URL+ "/Member/saveMemeberFunction";
export const __api_deleteMember = URL + "Member/delete";
export const __api_validateMember = URL + "User/exist";
export const __api_getMemberList = URL + "/Member/getMemberList";

/** Dailywork */
export const __api_getDailyWork = URL + "Equipment/getDailyWorkDetail";
export const __api_updateOperationInfo = URL + "Equipment/updateOperatingInfo";
export const __api_getDailyWorkYearOrMonth = URL + "getDailyWorkYearOrMonth";

export const __api_instantReport = URL + "Equipment/getInstantRoutDetail";

/** My Account */
export const __api_getProfile = URL + "User/getProfile";
export const __api_updateProfile = URL + "User/updateProfile";
export const __api_resetPassword = URL + "User/reset";
export const __api_changePassword = URL + "User/changePassword";

/** App Level */
export const __api_getTzLn = URL + "getCSLTList";

/* Field Informatioon*/
export const __api_saveArea = URL + "AgriculturalArea/saveArea";
export const __api_getArea = URL + "AgriculturalArea/getArea";

/* Alert Setting */
export const __api_getAlertSetting  = URL + "Equipment/getAlertSetting/";
export const __api_saveAlertSetting = URL + "Equipment/updateAlertSetting/";
export const __api_testNotification = URL + "Equipment/testNotification";

/* Location Information */
export const __api_getEquimentLocation = URL + "Equipment/locationDetails";

/*Area Measurement */
export const __api_saveFieldArea = URL + "map/mapArea";

/*Header*/

export const __api_getAlerts = URL + "AlertNotification/getAlerts";
//export const __api_getSysMentainInfo= URL + "SystemMaintenance/getInfo";
export const __api_getScheduleSysAlert = URL + "ScheduleServiceAlert/getAlerts";
export const __api_getHeaderNotificationCount = URL + "headerNotificationCount";


