import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { Row, Col, DropdownToggle, DropdownMenu, Input, Card, CardBody, CardText, Dropdown } from 'reactstrap';
import DataSheet from "./DataSheet";

/** importing actions */

import { setLoader, setToastr } from "../../../actions/Application";
import { getDealerEquipmentList } from '../../../actions/admin/dealerManagement';
import { moveEquipmentList } from '../../../api/admin/dealer-management';
import ButtonField from '../../shared/ButtonField';
import "./style.scss";

class DealerManagement extends Component {
    constructor(props) {
        super(props);
        /** decalre component state */
        this.state = {
            fromCompany: "",
            fromComapnyCode: "",
            toCompany: "",
            toCompanyCode: "",
            searchItems: [],
            dealerList: [],
            machineSaleId: [],
            resetCheckbox: false

        };
        /** function binding */
        this.getDealers = this.getDealers.bind(this);
        this.goToItem = this.goToItem.bind(this);
        this.selectedEquipments = this.selectedEquipments.bind(this);
        this.resetFields = this.resetFields.bind(this);
    }
    resetFields() {
        // ReactDOM.findDOMNode(document.getElementById('fromCompany')).value = '';
        ReactDOM.findDOMNode(document.getElementById('toCompany')).value = '';
        this.setState({
            toCompany: "",
            toCompanyCode: "",
            machineSaleId: [],
            resetCheckbox: true
        });

    }

    selectedEquipments(machineSaleId,resetCheckbox) {
        this.setState({
            machineSaleId: machineSaleId,
            resetCheckbox:resetCheckbox
        });
    }
    
    goToItem(companyName, companyCode, fieldType) {

        this.setState({
            keyword: ""
        });

        ReactDOM.findDOMNode(document.getElementById(fieldType)).value = companyName;
        if (fieldType === 'fromCompany') {
            this.setState({ fromCompany: undefined,fromCompanyCode: companyCode ,resetCheckbox:true});
            
            this.props.getDealerEquipmentList(companyName);
        }
        if (fieldType === 'toCompany') {
            this.setState({ toCompany: undefined });
            this.setState({ toCompanyCode: companyCode });
        }

    }

    getDealers(e) {
        var id = e.target.id
        this.setState({
            fromCompany: "",
            toCompany: ""
        });

        let { value } = ReactDOM.findDOMNode(document.getElementById(id)), { dealerList } = this.props, regex = new RegExp(value.trim(), 'i');
        if (value && value.length < 3) return false;
        if (dealerList && dealerList.data && dealerList.data.serviceProviders) {
            let filtered = dealerList.data.serviceProviders.filter(e => {
                if (regex.test(e.childCompanyName.toString())) {
                    return e;
                }
            })
            if (id === 'fromCompany')
                this.setState({ searchItems: filtered, fromCompany: value });
            if (id === 'toCompany')
                this.setState({ searchItems: filtered, toCompany: value });
        }

    }

    moveEquipment() {
        let { toCompanyCode, machineSaleId } = this.state;
        let selectedEquipmentList = [];
        let companyName = ReactDOM.findDOMNode(document.getElementById('fromCompany')).value
        console.log("toCompanyCode" + toCompanyCode);
        machineSaleId.forEach((e) => {
            selectedEquipmentList.push({ machineSaleID: e });
        }

        );
        if(toCompanyCode === ''){
            this.props.setToastr({ toastr: { message: "Company field should not be empty", type: "error" } });
            return;
        }
        let data = {
            companyCode: toCompanyCode,
            equipmentList: selectedEquipmentList
        }
        this.props.setLoader({ loader: { open: true } });

        moveEquipmentList(data).then(r => {
            if (r.data && r.data === true) {
                this.props.setToastr({ toastr: { message: "Equipment sucessfuly saved", type: "success" } });
            } else
                this.props.setToastr({ toastr: { message: "Equipment not saved", type: "error" } });

            this.props.setLoader({ loader: { open: false } });
            this.props.getDealerEquipmentList(companyName);
            this.resetFields();
        }).catch(e => {
            this.props.setToastr({ toastr: { message: e, type: "error" } });
            this.props.setLoader({ loader: { open: false } });
        })
    }
    render() {
        let { searchItems, fromCompany, toCompany, resetCheckbox } = this.state;
        let { eqList } = this.props
        return (<div>
            <div className="container">
                <div className="row">
                    <div className="col-5">

                        <Input type='text' id='fromCompany' placeholder="Search Company" className='form-control search-bar' onChange={this.getDealers} />
                        {fromCompany && searchItems && searchItems.length > 0 && <div className='search-area'>
                            <Card className='search-card'>
                                <CardBody>
                                    <ul className="list-group list-group-flush">
                                        {searchItems.map((item, i) => {
                                            return <Item key={i} attr={{ item, fieldName: 'fromCompany' }} searchfield='fromCompany' goToItem={this.goToItem} />;
                                        })}
                                    </ul>
                                </CardBody>
                            </Card>
                        </div>}
                    </div>
                    <div className="col-1">
                        To
                    </div>
                    <div className="col-5">
                        <Input type='text' id='toCompany' placeholder="Search Company" className='form-control search-bar' onChange={this.getDealers} />
                        {toCompany && searchItems && searchItems.length > 0 && <div className='search-area'>
                            <Card className='search-card'>
                                <CardBody>
                                    <ul className="list-group list-group-flush">
                                        {searchItems.map((item, i) => {
                                            return <Item key={i} attr={{ item, fieldName: 'toCompany' }} searchfield='toCompany' goToItem={this.goToItem} />;
                                        })}
                                    </ul>
                                </CardBody>
                            </Card>
                        </div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-5">
                        <DataSheet equipmentList={eqList.equipment} selectedEquipments={this.selectedEquipments} resetCheckbox={resetCheckbox} />
                    </div>

                    <div className="col-7 " >

                    </div></div>
                <div className="row">
                    <div className="col-5">

                    </div>

                    <div className="col-7 btn-move-setting" >
                        {eqList.equipment && eqList.equipment.length > 0 && <ButtonField
                            type="button"
                            name="Move"
                            id="Move"
                            className="btn-danger btn-move-setting"
                            buttonText="Move"
                            clickEvent={true}
                            handleClick={this.moveEquipment.bind(this)}
                        />}
                    </div></div>
            </div>
        </div>);
    }



    componentWillReceiveProps(nextProps) {
        if (this.props.dealerList !== nextProps.dealerList) {
            this.setState({ dealerList: nextProps.dealerList });
        }
    }
}

const Item = props => {
    let { attr, goToItem } = props;
    return (
        <li className="list-group-item pointer" onClick={() => goToItem(attr.item.childCompanyName, attr.item.childCompanyCode, attr.fieldName)}>
            <Row>

                <Col sm='12' className='no-padd-lr'>
                    <span className='highlighted'>Company Code</span> <span>{attr.item.childCompanyCode}</span> &nbsp;
                    <span className='highlighted'>Comapny Name</span> <span>{attr.item.childCompanyName}</span> &nbsp;
                   </Col>
            </Row>
        </li>
    );
}

const mapDispatchToProps = { setLoader, setToastr, getDealerEquipmentList /* , getDealersList */ }

const mapStateToProps = state => {

    return ({
        eqList: (state.dealerManagement && state.dealerManagement.dealerEquipment) ? state.dealerManagement.dealerEquipment.data : []

    });
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DealerManagement));