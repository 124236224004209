import { SET_SP_PROFILE } from '../../types';
import session from "../../services/session";
/** @type { Object } default state */
let defaultState = {
    profile: session.get("spprofile", "cookie") || undefined
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function spProfile(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_SP_PROFILE:
            return { ...state, profile: action.data};

        default: return state;
    }
}
