import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Table } from 'reactstrap';

import './style.scss';


/* A DataSheet component */
class DataSheet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            machineSaleId: [],
            resetFlag : true,
            checkAll :  false
        }

    }
    selectChange(selected, type) {
        let { selectedEquipments } = this.props;
        const { id } = selected.target, { machineSaleId } = this.state;
        console.log(JSON.stringify(id) + "  .. id")
        let mc = id;
        console.log(JSON.stringify(mc) + "  .. mc")
        if (selected.target.checked) {
            console.log("Checked")
            if (machineSaleId.indexOf(mc) < 0) machineSaleId.push(mc);
        } else {
            console.log("UnChecked")
            if (machineSaleId.indexOf(mc) >= 0) machineSaleId.splice(machineSaleId.indexOf(mc), 1);
        }

        this.setState({ machineSaleId });
        console.log(JSON.stringify(machineSaleId) + "**** Selected machineSaleId ***")

        selectedEquipments(this.state.machineSaleId,false)
    }
    handleAllChecked = (event) => {
        let { selectedEquipments, equipmentList } = this.props;
        let { machineSaleId,checkAll } = this.state;
        // let machineSaleIds = this.state.machineSaleId
        if (event.target.checked) {
            this.setState({ checkAll:true });
            equipmentList.forEach(equipment => machineSaleId.push(equipment.machineSaleID))
        } else {
            this.setState({ checkAll:false });
            machineSaleId = [];
        }

        this.setState({ machineSaleId });
        console.log(JSON.stringify(machineSaleId) + "**** Selected machineSaleId ***")
        selectedEquipments(this.state.machineSaleId,false)
    }

    render() {
        let equipmentList = this.props.equipmentList;
        let checkAll = this.state.checkAll;
        return (
            <div >
                {equipmentList && equipmentList.length > 0 &&
                    <table className="tabledashboard table table-striped table-advance table-hover m-top-30 auto-index scroll">
                        <thead>
                            <tr>
                                <th width="10%">S.No</th>
                                <th width="40%">Model</th>
                                <th width="40%">Machine Name</th>
                                <th > <input type="checkbox"  onClick={this.handleAllChecked} value="checkedall" checked={checkAll}/> </th>
                                
                            </tr>

                        </thead>
                        <tbody>

                            {equipmentList.map((row, index) => {

                                return (
                                    <tr key={index}>
                                        <td width="10%"></td>
                                        <td width="40%"  >{row.model || " "}</td>
                                        <td width="40%">{row.machineNum || " "}</td>
                                        <td width="10%"> 	<input type="checkbox" name={row.machineNum} id={row.machineSaleID} onChange={this.selectChange.bind(this)} checked={this.state.machineSaleId.indexOf(row.machineSaleID) >= 0 ? true : false} /></td>

                                    </tr>
                                )
                            })}

                        </tbody>



                    </table>


                }
            </div>
        );
    }
    
    componentDidUpdate(nextProps){
        let {resetCheckbox} = this.props;
        console.log("chckbox unchecked/checked ++++++++ " +resetCheckbox)
        if (this.props.resetCheckbox !== nextProps.resetCheckbox) {
            console.log("chckbox unchecked/checked ++++++++ INSIDE " +resetCheckbox)
         if(resetCheckbox){
           let machineSaleId = [];
            this.setState({machineSaleId,resetFlag:false })
            console.log("componen will recieve rops"+JSON.stringify(this.state.machineSaleId))
            this.setState({ checkAll:false });
        } }
    } 
    
}
export default withRouter(DataSheet);