import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FaEnvelope, FaMobile, FaEllipsisV,FaStar,FaRegStar} from 'react-icons/fa';

import { resetPassword } from "../../../../api/profile";
import { deleteMember } from "../../../../api/members";
import { setToastr, setConfirmBox, setLoader } from "../../../../actions/Application";
import { getMembers } from "../../../../actions/Member";
import Image from "../../../shared/Image";

class MemberCard extends Component {
    constructor(props){
        super(props);

        this.state = {
            dropdownOpen: false
        }

        this.deleteMemberCall = this.deleteMemberCall.bind(this);
        this.resetPwd = this.resetPwd.bind(this);
    }

    confirmnReset(user){
        let {translate}=this.props
          this.props.setConfirmBox({ modal: { open: true, data: user, title: translate('resetPassword'), content:translate('resetConfirmation') +`${user.firstName}`+translate('resetConfirmation2'), done: this.resetPwd }});
    }

    resetPwd(member) {
        let { setLoader } = this.props;
        /** show loader */
        setLoader({ loader: { open: true } });

        return new Promise((resolve, reject) => {
            resetPassword(member.id)
            .then(result => {
                /** close loader */
                setLoader({ loader: { open: false } });
                /** show success message */
                resolve({ toastr: { message: "We've sent a new password for " + [member.firstName, member.lastName].join(" ")+" on his registered email address and mobile number.", type: "success" } })
            })
            .catch(error => {
                /** close loader */
                setLoader({ loader: { open: false } });
                reject(false);
            });
        });
    }

    confirmnDelete(member){
        let {translate}= this.props;
        this.props.setConfirmBox({ modal: { open: true, data: member, title: translate('deleteMember'), content:translate('deleteMemberConfirmation'), done: this.deleteMemberCall }});
    }

    deleteMemberCall(member){
        let {translate,setLoader}= this.props;
        /** show loader */
        setLoader({loader:{open: true}});

        return new Promise((resolve, reject) => {
            deleteMember(member.id)
                .then(result => {
                    /** resolve promise */
                    /** close loader */
                    setLoader({ loader: { open: false } });
                    /** dispatch action to reload members */
                    this.props.getMembers();
                    resolve({ toastr: { message: [member.firstName, member.lastName].join(" ") + translate('hasRemoved'), type: "success" } });
                })
                .catch(error => {
                    /** close loader */
                    setLoader({ loader: { open: false } });
                    setToastr({ toastr: { message: translate('couldNotComplete'), type: "error" } });
                    /** reject promise */
                    reject(false);
                })
        });
    }

    render(){
        let { member, getFullName, loginUser ,translate} = this.props;
        return (
            <Col xs='12' sm='4' md='3' className="member">
                <div className="thumbnail">
                    <div className="member-image" align="center">
                        <Image src={member.photo} className="img-circle img-responsive" alt="Profile Image" />
                        {member.ownerFlag  && <FaStar className="on-profile" />}
                    </div>
                    <div className="caption">
                        <p className="member-name">{getFullName(member)}</p>
                        <p className="member-id">{member.loginId}</p>
                        <ul className="member-social">
                            <li><FaMobile /> <span className="member-phone">{member.phone || ' '}</span></li>
                            <li><FaEnvelope /> <span className="member-email">{member.email || ' '}</span></li>
                        </ul>
                    </div>
                </div>

                {loginUser.ownerFlag && <ButtonGroup className='member-options'>
                    <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })} >
                        <DropdownToggle>
                            <FaEllipsisV />
                        </DropdownToggle>
                        <DropdownMenu>
                            

                            <Link className='dropdown-item' to={"/customer/members/edit/"+member.id}>{translate('edit')}</Link>
                            <DropdownItem className='custom-link' onClick={() => this.confirmnReset(member)}>
                                <div className='pointer'>
                                {translate('resetPassword')} 
                                </div>
                            </DropdownItem>
                            
                            {!member.ownerFlag && 
                                <Fragment>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => this.confirmnDelete(member)} className='custom-link'>
                                        <div className='pointer' >
                                            {translate('deleteMember')}
                                        </div>
                                    </DropdownItem>
                                </Fragment>
                            }
                        </DropdownMenu>
                    </ButtonDropdown>
                </ButtonGroup>}
            </Col>
        );
    }
}

const mapDispatchToProps = { setToastr, setConfirmBox, getMembers, setLoader };

export default connect(null, mapDispatchToProps)(MemberCard);