import { ADMINURL } from '../../constants/Constants';

/** Admin Home */
export const __api_getRecentEvent = ADMINURL + "getRecentEvents";
/** Manage Service Company */
export const __api_getManageServiceCompanyData = ADMINURL + "getMSCompanies";
export const __api_saveManageServiceCompanyData = ADMINURL + "saveMSCompanies";
export const __api_getManageServiceCompanyEqList = ADMINURL + "getMscom";
/** Owner Module */
export const __api_getMachineInfo = ADMINURL + "getEquipmentAndCPCompanies";
export const __api_listOwner = ADMINURL + "getOwnerEquipments";
export const __api_addOwner = ADMINURL + "saveCustomer";
export const __api_getOwnerEquipments = ADMINURL + "getEquipmentsPCCompanies";
export const __api_updateOwner = ADMINURL + "updateOwnerProfile";
export const __api_checkOwner = ADMINURL + "checkOwnerLoginId";
export const __api_ResendEmailToRegisteredUser = ADMINURL + "ResendEmailToRegisteredUser";
export const __api_getOwnerDetail = ADMINURL + "getOwnerDetail";
export const __api_ownerDetailsUpdate = ADMINURL + "ownerDetailsUpdate";

/** Data Control Module */
export const __api_getDataControl = ADMINURL + "getDataControl";
export const __api_CRUDDataControl = ADMINURL + "saveOrUpdateOrDeleteDC";

/* Roles */
export const __api_getFeatures = ADMINURL + "getModuleFunctions";
export const __api_setNewRole = ADMINURL + "saveRoleFunction";
export const __api_updateNewRole = ADMINURL + "updateRFM";
export const __api_get_role_list = ADMINURL + "getMappedRFM";
export const __api_get_roles = ADMINURL + "getRoleFM";
export const __api_delete_role = ADMINURL + "deleteRFM";

/* custom map */
export const __api_saveCustomMap = ADMINURL + "saveMap";
export const __api_updateCustomMap = ADMINURL + "updateMap";
export const __api_getCustomMap = ADMINURL + "getMapHistory";
export const __api_deleteMap = ADMINURL + "deleteMap";
export const __api_getCustomAddedMap = ADMINURL + "mapAllRecord";
export const __api_getRegisteredMaps = ADMINURL + "uniqueMap";
export const __api_uploadMap = ADMINURL + "uploadFile";
export const __api_getMapEqList = ADMINURL +"getMapDefaultObjList";
export const __api_getSavedMapEqList = ADMINURL +"getSavedMapHistoryObjList"

/* FOTA */
export const __api_saveListFota = ADMINURL + "saveListFota";
export const __api_saveFota = ADMINURL + "saveFota";
export const __api_saveBulkFota = ADMINURL + "fotaBulkFile";
export const __api_deleteFota = ADMINURL + "deleteFota";
export const __api_deleteFotaVersion = ADMINURL + "deleteFotaVersion";
export const __api_getFotaVersion = ADMINURL + "fotaVersion";
export const __api_getFota = ADMINURL + "fotaAllRecord";
export const __api_updateFota = ADMINURL + "updateFota";
export const __api_getFotaEqList = ADMINURL + "getFotaObjList";

/** Dealer Management */

export const __api_getDealerEquipmentList = ADMINURL + "getDelearEquipment";
export const __api_moveDealerEquipmentList = ADMINURL + "moveDealer";

/** contract Management */
export const api_getContractCountDetail =ADMINURL+"getRecentContract";

