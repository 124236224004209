import { GET_EQUIPMENTS_SERVICE_DATA, GET_EQUIPMENTS_SERVICE_HISTORY } from '../types'


/**
* Dispatch the activity listing action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/

export const getEquipmentsServices = (data) => {
	return {
		type: GET_EQUIPMENTS_SERVICE_DATA,
		data
	}
}

export const getEquipmentServiceHistory = (data) => {
	return {
		type: GET_EQUIPMENTS_SERVICE_HISTORY,
		data
	}
}

