import {GET_EVENT_TOKEN_DATA} from '../types'

/**
* Dispatch the activity listing action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/

export const getEventTokenData = (data) => {
	return {
		type : GET_EVENT_TOKEN_DATA,
		data
	}
}