import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import EquipmentAlertDetailComponent from '../../components/admin/equipment-alert/EquipmentAlertDetailComponent';
import { translate } from '../../lang';
import { getEquipmentAlertData, getEquipmentSupportData, saveEquipmentSupportInfoData } from '../../actions/Equipment';

class EquipmentAlertsContainer extends Component {

    handleClick(data){
        this.props.saveEquipmentSupportInfoData(data);
    }

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount(){
        this.getSupportInfos();    
    }

    getSupportInfos(){
        const isService = (this.props.location.pathname.split('/')[1] === 'service');   
        this.props.getEquipmentSupportData({isService : isService , data : {eventId:this.props.match.params.alerId} })
    }

    getData(){
        const isService = (this.props.location.pathname.split('/')[1] === 'service');
        this.props.getEquipmentAlertData({isService : isService, data : false})
    }

    render() {  
        const isService = (this.props.location.pathname.split('/')[1] === 'service');      
        return (
            <EquipmentAlertDetailComponent translate={translate}
                isService = { isService }
                supportData = {this.props.equipmentSupportData}
                handleClick = { this.handleClick.bind(this) }
                getSupportInfos = { this.getSupportInfos.bind(this) }
                getData = { this.getData.bind(this) }
            />
        );
    }
}

/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    return {
        equipmentSupportData : state.equipment.data
      };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getEquipmentAlertData, getEquipmentSupportData, saveEquipmentSupportInfoData
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EquipmentAlertsContainer));
