import React, { Component } from 'react';
import { connect } from 'react-redux';
import EquipmentAlertsComponent from '../../components/admin/equipment-alert/EquipmentAlertsComponent';
import { translate } from '../../lang';
import { getEquipmentAlertData } from '../../actions/Equipment';

class EquipmentAlertsContainer extends Component {

    constructor(props) {
        super(props);
        console.log('admin alert')
        this.state={
            equipmentAlertData : [],
        };
    }

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount(){
        this.props.getEquipmentAlertData({isService : true, data : false})
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                equipmentAlertData : nextProps.equipmentAlertData
            })
        }
    }

    handlePageChange(data) {
        this.props.getEquipmentAlertData({isService : true, data : data})
    }
    
    render() {        
        const isService = true;    
        return (
            <EquipmentAlertsComponent 
                isService = { isService }
                translate={translate}
                handlePageChange={ this.handlePageChange.bind(this) } 
                apiError = { this.props.apiError }
                equipmentAlertData = {this.state.equipmentAlertData} 
            />
        );
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    return {
        equipmentAlertData : state.equipment.data,
        apiError : state.equipment.FETCH_DATA_FAIL || false
      };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getEquipmentAlertData
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentAlertsContainer);