import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import { routerReducer } from 'react-router-redux'

import user from './loginForm/loginForm';
import admin from './loginForm/adminLoginForm';
import equipment from './equipment/equipment';
import reports from './reports/reports';
import dailywork from "./dailywork/dailywork";
import app from "./application/app";
import members from "./members/members";
import profile from "./profile/profile";
import alertsNotifications from "./alerts/alerts";
import fieldInformation from "./field-information/field-information";
import alertSetting from "./alert-setting/alert-setting";
import forgetPassword from "./loginForm/forgetPassword";
import serviceHistory from "./serviceHistory/serviceHistory"
import locationInformation from "./location-information/location-information";
/** Admin Reducers */
import owner from "./admin/owner";
import scheduleService from './schedule-service/scheduleService.js';
import sysMaintenance from './sys-maintenance/sysMaintenance.js';
import dealerManagement from './admin/dealerManagement'
import customMap from './admin/customMap';
import fota from './admin/fota';
import manageService from './admin/manageService';
import dataControl from './admin/dataControl';

/** Service Provider Reducers */
import servprov from './loginForm/servprovLoginForm';
import sp from './service-provider/equipment';
import spProfile from "./service-provider/profile";

import RoleList from "./admin/roles";
import contractManagement from "./admin/contractManagement"
import hourmeterOffsetManagement from "./admin/hourmeterOffsetManagement"
/*
 * ALL your reducers must go into allReducers so they can be combined back together to update the 1 store. 
 * Don't forget to import them before adding them to JSON. 
 */
const allReducers = combineReducers({
    user,
    equipment,
    dailywork,
    members,
    profile,
    app,
    reports,
    alertsNotifications,
    fieldInformation,
    alertSetting,
    forgetPassword,
    admin,
    fota,
    manageService,
    customMap,
    servprov,
    locationInformation,
    owner,
    scheduleService,
    sysMaintenance,
    sp,
    dataControl,
    spProfile,
    RoleList,
    dealerManagement,
    form: formReducer,
    routing: routerReducer,
    serviceHistory,
    contractManagement,
    hourmeterOffsetManagement,
});

export default allReducers;
