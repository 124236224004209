import { SET_EQUIPMENTS_SERVICE_DATA,FETCH_DATA_FAIL,SET_EQUIPMENTS_SERVICE_HISTORY
} from '../../types';

/** @type { Object } default state */
let defaultState = {
	data: {
		data: '',
	},
	
	
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function serviceHistory(state = defaultState, action = {}) {
	switch (action.type) {
		case SET_EQUIPMENTS_SERVICE_DATA:
			let _data = {}
            _data[action. type] = action.data;
    		_data['resMsg'] = action.resMsg;
			_data['isSending'] = action.isSending;
			_data['error'] = action.error;
			return  Object.assign({}, state, {
				data: Object.assign({}, state.data, _data)
				})
	    case SET_EQUIPMENTS_SERVICE_HISTORY:
			let data = {}
            data[action. type] = action.data;
    		data['resMsg'] = action.resMsg;
			data['isSending'] = action.isSending;
			data['error'] = action.error;
			return  Object.assign({}, state, {
				data: Object.assign({}, state.data, data)
				});
				
		case FETCH_DATA_FAIL:
			const errors = {}
			errors[action.type] = action.payload.error;
			return Object.assign({}, state, errors)

		default: return state;
	}
}
