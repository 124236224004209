import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const types = ["info", "error", "warn", "success", "default"];

class Toastr extends Component {
    
    componentWillReceiveProps(newProps) {
        if (newProps.toastr) toast[types.includes(newProps.toastr.type || "default") ? newProps.toastr.type : "default"](newProps.toastr.message, {toastId: newProps.toastr.toastId || "unique"});
    }

    render(){
        let { toastr } = this.props;
        return(
            <Fragment>
                {toastr && <ToastContainer hideProgressBar closeOnClick={false} />}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({ toastr: state.app.toastr || undefined });

export default connect(mapStateToProps)(Toastr);