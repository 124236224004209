import React, { Component } from 'react';
import { connect } from "react-redux";
import NewOwnerComponent from '../../components/admin/owners/NewOwnerComponent';

import { getTimeZoneLanguage } from "../../actions/Application";


class NewOwnerContainer extends Component {
    /**
     * Dispatch the action to get the members data
     */
    componentDidMount() {
        /** get timezone and language */
        this.props.getTimeZoneLanguage();
    }

    render(){
        let { states, language, timezone } = this.props;
        return <NewOwnerComponent states={states} language={language} timezone={timezone} />;
    }
}

/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
const mapStateToProps = state => {  
    return { language: state.app.language || [], states: state.app.state || [], timezone: state.app.timezone || [] };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getTimeZoneLanguage
}


export default connect(mapStateToProps, mapDispatchToProps)(NewOwnerContainer);
