import { SET_EQUIPMENT_DATA, FETCH_DATA_FAIL, SENDING_EQUIPMENT_DATA,
	SENDING_EQUIPMENT_UPDATE_DATA, SET_EQUIPMENT_UPDATE_DATA,
	SENDING_EQUIPMENT_ALERT_SETTINGS_REQUEST, SET_EQUIPMENT_ALERT_SETTINGS_DATA,
	SET_EQUIPMENT_SINGLE_DATA, SENDING_EQUIPMENT_GET_DATA,
	SENDING_EQUIPMENT_ALERT_LIST_DATA, SET_EQUIPMENT_ALERT_LIST_DATA,
	SENDING_EQUIPMENTS_SUPPORT_DATA, SET_EQUIPMENTS_SUPPORT_DATA,
	SENDING_EQUIPMENT_EVENTS_LIST_DATA, SET_EQUIPMENT_EVENTS_LIST_DATA,
	SENDING_EQUIPMENTS_SUPPORT_INFO_DATA, SET_EQUIPMENTS_SUPPORT_INFO_DATA,
	SET_INSTANT_REPORT_DATA
} from '../../types';

/** @type { Object } default state */
let defaultState = {
	data: {
		data: '',
	},
	equipmentAlertCount: 0,
	InstantReportData: null,
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function equipment(state = defaultState, action = {}) {

	switch (action.type) {
		case SET_INSTANT_REPORT_DATA:
			let _data = {}
            _data[action. type] = action.data;
    		_data['resMsg'] = action.resMsg;
			_data['isSending'] = action.isSending;
			_data['error'] = action.error;
			return  Object.assign({}, state, {
				data: Object.assign({}, state.data, _data)
				});
				

		case SET_EQUIPMENT_DATA:
			const data = {}
			data[action.type] = action.data;
			data['resMsg'] = action.resMsg;
			data['isSending'] = action.isSending;
			data['error'] = action.error;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, data)
			});
		case SENDING_EQUIPMENT_DATA:
			const sendingRequest = {}
			sendingRequest[action.type] = action.data;
			sendingRequest['isSending'] = action.isSending;
			sendingRequest['error'] = action.error;
			sendingRequest['resMsg'] = action.resMsg;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, sendingRequest)
			})
		case SENDING_EQUIPMENT_UPDATE_DATA:
			const sendingUpdateRequest = {}
			sendingUpdateRequest[action.type] = action.data;
			sendingUpdateRequest['isSending'] = action.isSending;
			sendingUpdateRequest['error'] = action.error;
			sendingUpdateRequest['resMsg'] = action.resMsg;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, sendingUpdateRequest)
			})
		case SET_EQUIPMENT_UPDATE_DATA:
			const eqpDataUpdate = {}
			eqpDataUpdate[action.type] = action.data;
			eqpDataUpdate['isSending'] = false;
			eqpDataUpdate['resMsg'] = action.resMsg;
			eqpDataUpdate['error'] = action.error;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, eqpDataUpdate)
			})
		case SENDING_EQUIPMENT_ALERT_SETTINGS_REQUEST:
			const sendingGetAlertSettRequest = {}
			sendingGetAlertSettRequest[action.type] = action.data;
			sendingGetAlertSettRequest['isSending'] = action.isSending;
			sendingGetAlertSettRequest['error'] = action.error;
			sendingGetAlertSettRequest['resMsg'] = action.resMsg;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, sendingGetAlertSettRequest)
			})
		case SET_EQUIPMENT_ALERT_SETTINGS_DATA:
			const eqAlertSettingData = {}
			eqAlertSettingData[action.type] = action.data;
			eqAlertSettingData['isSending'] = action.isSending;
			eqAlertSettingData['resMsg'] = action.resMsg;
			eqAlertSettingData['error'] = action.error;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, eqAlertSettingData)
			})
		case SENDING_EQUIPMENT_GET_DATA:
			const sendingEquipmentGetRequest = {}
			sendingEquipmentGetRequest[action.type] = action.data;
			sendingEquipmentGetRequest['isSending'] = action.isSending;
			sendingEquipmentGetRequest['resMsg'] = action.resMsg;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, sendingEquipmentGetRequest)
			})
		case SET_EQUIPMENT_SINGLE_DATA:
			const eqSingleData = {}
			eqSingleData[action.type] = action.data;
			eqSingleData['isSending'] = action.isSending;
			eqSingleData['resMsg'] = action.resMsg;
			eqSingleData['error'] = action.error;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, eqSingleData)
			})
		/** Admin equipment details */
		case SENDING_EQUIPMENT_ALERT_LIST_DATA:
			const sendingEquipAlertGetRequest = {}
			sendingEquipAlertGetRequest[action.type] = action.data;
			sendingEquipAlertGetRequest['isSending'] = action.isSending;
			sendingEquipAlertGetRequest['resMsg'] = action.resMsg;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, sendingEquipAlertGetRequest)
			})
		case SET_EQUIPMENT_ALERT_LIST_DATA:
			const equipAlertListData = {}
			equipAlertListData[action.type] = action.data;
			equipAlertListData['isSending'] = action.isSending;
			equipAlertListData['resMsg'] = action.resMsg;
			equipAlertListData['error'] = action.error;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, equipAlertListData)
			})
		case SENDING_EQUIPMENT_EVENTS_LIST_DATA:
			const sendingEquipEventsGetRequest = {}
			sendingEquipEventsGetRequest[action.type] = action.data;
			sendingEquipEventsGetRequest['isSending'] = action.isSending;
			sendingEquipEventsGetRequest['resMsg'] = action.resMsg;
			
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, sendingEquipEventsGetRequest)
			})
			
		case SET_EQUIPMENT_EVENTS_LIST_DATA:
			const equipEventsListData = {}
			equipEventsListData[action.type] = action.data;
			equipEventsListData['isSending'] = action.isSending;
			equipEventsListData['resMsg'] = action.resMsg;
			equipEventsListData['error'] = action.error;
		
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, equipEventsListData),
				equipmentAlertCount: (action.data && action.data.constructor === Array) ? action.data.length : 0
			})
		case SENDING_EQUIPMENTS_SUPPORT_DATA:
			const sendingEquipSupGetRequest = {}
			sendingEquipSupGetRequest[action.type] = action.data;
			sendingEquipSupGetRequest['isSending'] = action.isSending;
			sendingEquipSupGetRequest['resMsg'] = action.resMsg;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, sendingEquipSupGetRequest)
			})
		case SET_EQUIPMENTS_SUPPORT_DATA:
			const equipSuppData = {}
			equipSuppData[action.type] = action.data;
			equipSuppData['isSending'] = action.isSending;
			equipSuppData['resMsg'] = action.resMsg;
			equipSuppData['error'] = action.error;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, equipSuppData)
			})
		case SENDING_EQUIPMENTS_SUPPORT_INFO_DATA:
			const sendingSupInfoRequest = {}
			sendingSupInfoRequest[action.type] = action.data;
			sendingSupInfoRequest['isSending'] = action.isSending;
			sendingSupInfoRequest['resMsg'] = action.resMsg;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, sendingSupInfoRequest)
			})
		case SET_EQUIPMENTS_SUPPORT_INFO_DATA:
			const equipSuppUpdate = {}
			equipSuppUpdate[action.type] = action.data;
			equipSuppUpdate['isSending'] = action.isSending;
			equipSuppUpdate['resMsg'] = action.resMsg;
			equipSuppUpdate['error'] = action.error;
			return Object.assign({}, state, {
				data: Object.assign({}, state.data, equipSuppUpdate)
			})
		case FETCH_DATA_FAIL:
			const errors = {}
			errors[action.type] = action.payload.error;
			return Object.assign({}, state, errors)

		default: return state;
	}
}
