import React from 'react';
import { connect } from "react-redux";
import './App.scss';
import Breadcrumbs from "./components/shared/Breadcrumbs";
import Header from './components/shared/Header';
import SideBar from './components/shared/SideBar';
import Footer from './components/shared/Footer';
import Toastr from "./components/shared/Toastr";
import ConfirmBox from "./components/shared/ConfirmBox";
import Search from "./components/shared/Search";
import Loader from "./messages/loaders/Loader";
import AlertIconButton from './components/admin/equipment-alert/AlertIconButton';
import { Row, Col} from 'reactstrap';

import { setBreadCrumbs } from "./actions/Application";


class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            databoardData: [],
            menuclicked: false
        };
    }

    componentWillMount(){
        if(this.props.component){
            this.props.setBreadCrumbs({ key: this.props.component.props.match.path });
        }
    }
 
    clickOnMenu(e) {
        this.setState({ menuclicked: !this.state.menuclicked });
    }

    render() {
        let isServiceProvider = undefined;
        let isService = undefined;
        if(this.props.component){
            isService = (this.props.component.props.location.pathname.split('/')[1] === 'service');
            isServiceProvider = (this.props.component.props.location.pathname.split('/')[1] === 'service-provider');
        }
        const classCenter = (isService || isServiceProvider)?'':'offset-sm-1 col-12 col-sm-11 col-lg-11';
        const classleft = (isService  || isServiceProvider)?'':'col-12 col-sm-12 col-lg-1';
        const isCustomer = (isService  || isServiceProvider)?false:true;
        let location = this.props.component.props.location.pathname;
        
        return (
            <div className="app-wrapper container-fluid">
                <Row>
                    <Header isService={isService} isServiceProvider={isService} handleClick = { this.clickOnMenu.bind(this) }/>
                    {
                        <div className={`sidemenubox ${classleft}`}>
                        <SideBar isService={isService} clicked = { this.state.menuclicked }/>
                    </div>
                    }
                    <Col className={`centerMain ${classCenter}`}>

                        <Row className='d-flex align-items-center'>
                            <Breadcrumbs/>          
                            <Search sp={isService} customer={isCustomer} service={isService} />
                        </Row>

                        <section className={location==='/customer/dashboard' ? 'db-section wrapper-container' : 'wrapper-container'}>
                            {this.props.children}
                            {this.props.component}
                        </section>
                        { (isService) ? <AlertIconButton /> : '' }
                    </Col>
                </Row>

                {/** for showing toast message / confirm box and loader */}
                <Toastr /> <ConfirmBox /> <Loader />

                <Footer/>
            </div>
        )
    }
}

const mapDispatchToProps = { setBreadCrumbs }

export default connect(null, mapDispatchToProps)(App);