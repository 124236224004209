import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Row, Col, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import MemberCard from "./elements/MemberCard";
import { FaPlusCircle } from "react-icons/fa";
import './style.scss';

import { setLoader} from "../../../actions/Application";

class Memberlist extends Component{

    componentWillMount(){
        this.props.setLoader({ loader: {open: true, loadingText:this.props.translate('loadingMember')}});
    }

    componentWillReceiveProps(newProps){
        if(newProps.members && newProps.members.constructor === Array){
            this.props.setLoader({ loader: {open: false} });       
        }
    }

    getFullName(member){   
        return [member.firstName || "", member.middleName || "", member.lastName || ""].join(" ");
    }

    render(){
        let { members, loginUser,translate } = this.props;
        return(
            <Fragment>
                    {loginUser && loginUser.ownerFlag && <Row className='no-gutters align-items-center'>
                        <Col className='text-right mb-20'>
                            <Link to={'/customer/members/add'} className='btn btn-primary float-right'><FaPlusCircle size='1em' />{translate('addNewMember')}</Link>
                        </Col>    
                    </Row>}

                    <Row className="members">    
                        {typeof members === 'object' && members.length > 0 && members.map((member, i) => <MemberCard key={i} loginUser={loginUser} member={member} getFullName={this.getFullName} translate={translate} />)}
                        {typeof members === 'object' && members.length === 0 && <Col><Alert color="primary">{translate('noMemberFound')} </Alert></Col> }
                    </Row>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return ({
        loginUser: state.profile || {ownerFlag: false}
    });
}

const mapDispatchToProps = {
    setLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(Memberlist);
