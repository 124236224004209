import {GET_EQUIPMENTS_LIST_DATA, UPDATE_EQUIPMENTS_DATA,GET_EQUIPMENTS_SETTINGS_ALERT,GET_EQUIPMENTS_SINGLE_DATA,
	GET_EQUIPMENTS_ALERT_DATA, GET_EQUIPMENTS_SUPPORT_DATA, GET_EQUIPMENTS_EVENTS_DATA, SAVE_EQUIPMENTS_SUPPORT_DATA, UPDATE_EQUIPMENTS_STATUS_DATA
,GET_INSTANT_REPORT_DATA,UPDATE_EQUIPMENTS_SERVICE_DATA
} from '../types'


/**
* Dispatch the activity listing action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/

export const getEquipmentList = (data) => {
	return {
		type : GET_EQUIPMENTS_LIST_DATA,
		data
	}
}

export const getInstantReport = (data) => {
	return {
		type : GET_INSTANT_REPORT_DATA,
		data
	}
}

export const updateEquipmentData = (data) => {
	return {
		type : UPDATE_EQUIPMENTS_DATA,
		data
	}
}


export const getEquipmentAlertSettingData = (data) => {
	return {
		type : GET_EQUIPMENTS_SETTINGS_ALERT,
		data
	}
}


export const getEquipmentSingleData = (data) => {
	return {
		type : GET_EQUIPMENTS_SINGLE_DATA,
		data
	}
}

export const getEquipmentAlertData = (data) => {
	return {
		type : GET_EQUIPMENTS_ALERT_DATA,
		data
	}
}


export const getEquipmentSupportData = (data) => {
	return {
		type : GET_EQUIPMENTS_SUPPORT_DATA,
		data
	}
}



export const getEquipmentEventsData = (data) => {
	return {
		type : GET_EQUIPMENTS_EVENTS_DATA,
		data
	}
}


export const saveEquipmentSupportInfoData = (data) => {
	return {
		type : SAVE_EQUIPMENTS_SUPPORT_DATA,
		data
	}
}



export const updateEquipmentStatusData = (data) => {
	return {
		type : UPDATE_EQUIPMENTS_STATUS_DATA,
		data
	}
}

export const updateEquipmentServiceInfo = (data) => {
	return {
		type : UPDATE_EQUIPMENTS_SERVICE_DATA,
		data
	}
}