import http from "../../services/http";
import { LIMIT } from "../../constants/Constants";
import { __api_getDataControl, __api_CRUDDataControl } from "./admin.endpoints";

/* export const getDataControl2 = (offset, limit=LIMIT, countFlag=true) => http.Request("GET", [__api_getDataControl, offset, limit, countFlag].join("/"));
 */
export const getDataControl = (req) => {
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    return http.Request("GET", [__api_getDataControl, offset, LIMIT, true].join("/"), req.data);
};

export const CRUDDataControl = (action, data) => http.Request("POST", [__api_CRUDDataControl, action].join("/"), data);
