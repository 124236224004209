import {GET_CUSTOM_MAP_EQUIPMENT_LIST,GET_SAVED_CUSTOM_MAP_EQUIPMENT_LIST} from '../../types'

/**
* Dispatch the activity listing action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/


export const getCustomMapEquipmentList = (data) => {
	console.log("getCustomMapEquipmentList")
	return {
		type : GET_CUSTOM_MAP_EQUIPMENT_LIST,
		data
	}
}

export const getSavedCustomMapEquipmentList = (data) => {
	console.log("getSavedCustomMapEquipmentList")
	return {
		type : GET_SAVED_CUSTOM_MAP_EQUIPMENT_LIST,
		data
	}
}

