import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import App from '../App';
/*
 * In App.Js we have the routing for the application. 
 * You can see here that given a different path you will get a different target component served up. 
 * Ex. { LoginPage } serves up the components/LoginPage directory.
 */

export const UserRouteContainer = ({ isAuthenticated, component: Component, ...rest }) => (
	<Route {...rest} render={ props => isAuthenticated ? <div className='wrpr'><App component={<Component { ...props } />} /></div> : <Redirect to="/" /> } />
 );

/** Required function and props with the proptypes */
/*UserRouteContainer.propTypes = {
  component: PropTypes.func.isRequired
}*/


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
	if (state.user && state.user.data && state.user.data.SET_LOGGED_IN_TOKEN) {		
		return {
			isAuthenticated: true,
			isExpired:false,
			ispending: false,
			mode: false
		}
	} else {
		return {
			isAuthenticated: false,
			isExpired: false,
			ispending: false,
			mode: false
		}
	}
}

export default connect(mapStateToProps)(UserRouteContainer);
