import React from 'react';
// import { Route, Switch, Redirect } from 'react-router-dom';
import {  Route,  Switch, Redirect } from 'react-router'
// import { syncHistoryWithStore, routerReducer } from 'react-router-redux'

import AdminRoutes from "./adminRoutes";
import SrvProvRoutes from "./serviceProviderRoutes";
import CustomerRoutes from "./customerRoutes";
import NoMatch from "../components/404";

import DemoTernimalComponent from "../components/DemoTernimalComponent";

const Routes = ({location, match}) => {
    return (
        <div>
            <Switch>
                {/* redirect to customer by default */}
                {match.url === '/' && match.isExact && <Redirect from="/" to='/customer' />}
                
                {/* Customer Routes */}
                <Route path='/customer' component={CustomerRoutes} />
    
                {/* Admin Routes */}
                <Route path="/service" component={AdminRoutes} />

                 {/* Service Provider Routes */}
                <Route path="/service-provider" component={SrvProvRoutes} />

                 {/* Service Provider Routes */}
                <Route path="/service" component={AdminRoutes} />

                {/* 404 */}
                <Route path='**' component={NoMatch} />


                <Route location={ location } path={`/terminal-data`} exact component={ DemoTernimalComponent } />
            </Switch>
        </div>
    );
};

export default Routes;