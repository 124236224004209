import http from "../../services/http";
import { __api_saveFota, __api_saveListFota, __api_getSavedMapEqList,__api_updateFota, __api_deleteFota, __api_getFota, __api_saveBulkFota, __api_getFotaVersion, __api_deleteFotaVersion ,__api_getFotaEqList} from "./admin.endpoints";
import { LIMIT } from "../../constants/Constants";

export const saveListFota = (data) => http.Request("POST", __api_saveListFota, data);

export const saveFota = (data) => http.Request("POST", __api_saveFota, data);

export const saveBulkFota = (data) => http.Request("POST", __api_saveBulkFota, data);

export const deleteFota = (id) => http.Request("DELETE", [__api_deleteFota, id].join("/"));

export const deleteBrowseFota = (id) => http.Request("DELETE", [__api_deleteFotaVersion, id].join("/"));

export const updateFota = (data, id) => http.Request("POST", __api_updateFota+"/"+id, data);

export const getFota = (req) => {
    if(req.qs){ req.data = req.qs};
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    return http.Request("GET", [__api_getFota, offset, LIMIT].join("/"), req.data);
};

export const getBrowseFota = (req) => {
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    return http.Request("GET", [__api_getFotaVersion, offset, LIMIT].join("/"), req.data);
};
export const getFotaEqList = (data) =>{
    return http.Request("GET",__api_getFotaEqList)
}
export const getAllFotaEqList = (data) =>{
    //need to change
    return http.Request("GET",__api_getSavedMapEqList)
}