import React, { Component, Fragment } from 'react';
//import { withGoogleMap, withScriptjs, GoogleMap, Marker/*, InfoWindow*/ } from "react-google-maps"

import { Map, GoogleApiWrapper, Polygon, InfoWindow, Polyline, Marker } from 'google-maps-react';
import { FaTimesCircle } from 'react-icons/fa';
import { MapKey } from "../../constants/Constants";
import Loadr from "../../assets/images/download.gif";
import _findKey from 'lodash/findKey';
import Moment from 'react-moment';

import start from "../../assets/images/map/location_pin3.png";
import offline from "../../assets/images/map/location_pin4.png";
import running from "../../assets/images/my-equipment-page-icons/tractorlocation.png";
//import warning from '../../assets/images/my-equipment-page-icons/warning.png';

const LoadingContainer = (props) => (
    <div style={{ top: 0, bottom: 0, left: 0, right: 0 }} className='d-flex justify-content-center align-items-center maploadr'><img src={Loadr} alt='loader' /></div>
)

class MapBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPlace: null,
            activeMarker: null,
            currentMarker: null,
            showingInfoWindow: false,
            center: null
        }
        this.onMarkerClick = this.onMarkerClick.bind(this);
    }
    closeMap() {
        this.props.showMap(false);
    }
    onMarkerClick(props, marker, e) {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            currentMarker: e,
           showingInfoWindow: props.data?true:false
        });
    }

    modifyData(data) {
        let icon = '';
        let obj = {};
        let markers = [];
        if (data && Object.entries(data).length > 0) {
            data.forEach((e) => {
                let drivingStatus = null;
                switch (e.drivingStatus) {
                    case 'EngIdel':
                        drivingStatus = 'Idling';
                        break;
                    case 'Driving':
                        drivingStatus = 'Running';
                        break;
                    case 'HighSpeed':
                        drivingStatus = 'High Speed Running';
                        break;
                    case 'PTO':
                         drivingStatus = 'PTO Running';
                         break;
                  
                }
                obj = {
                    lat: +(e.latitude),
                    lng: +(e.longitude),
                    instantId: e.instantId,
                    info: {
                        frameCode: e.frameCode,
                        drivingStatus: drivingStatus,
                        color: e.color,
                        trendAnalogValue: e.trendAnalogValue,
                        eventTimeStamp: e.eventTimeStamp
                    }
                };
                if (+(e.frameCode) === 1) {
                    obj = {
                        ...obj,
                        info: {
                            frameCode: e.frameCode,
                            drivingStatus: 'Start Point',
                            eventTimeStamp: e.eventTimeStamp
                           // color: 'yellow'
                        },
                        icon: 'start' 
                    };
                }
                if (+(e.frameCode) === 2) {
                    obj = {
                        ...obj,
                        info: {
                            frameCode: e.frameCode,
                            drivingStatus: 'Stop Point',
                            eventTimeStamp: e.eventTimeStamp
                            //color: 'black'
                        },
                        icon: 'offline'
                    };
                }
                if (e == data[(data.length - 1)]) {
                    //last iteration
                   // if ((e.frameCode) === 2) { obj = { ...obj, icon: 'offline' }; }
                    //else if ((e.frameCode) === 1) { obj = { ...obj, icon: 'start' }; }
                     if ((e.frameCode) === 8) { obj = { ...obj, icon: 'running' }; }
                }
                if(e.latitude !== null && e.longitude !== null){
                    markers.push(obj);
                }
                
            });
        } else {

            obj = {
                lat: this.props.lat,
                lng: this.props.lon,
                icon: 'offline',
            };
            markers.push(obj);
        }
        return markers;
    }
    render() {
        const { defaultZoom, lat, lon, isMarkerShown, icon, instantReportData } = this.props;
        const { selectedPlace, activeMarker, currentMarker, showingInfoWindow } = this.state
        // Marker Icon  square Start
        // Marker Icon  square End
        let markers = this.modifyData(instantReportData);
        let mapLegend = [];
        markers.forEach(e => {
            if (e && e.info) {
                if (e.info.drivingStatus) {
                    mapLegend.push({ color: e.info.color, drivingStatus: e.info.drivingStatus, frameCode:e.info.frameCode });
                }
            }
        });
        function removeDuplicates(myArr, prop) {
            return myArr.filter((obj, pos, arr) => {
                return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
            });
        }

        if (mapLegend && mapLegend.length > 0) {
            mapLegend = removeDuplicates(mapLegend, 'drivingStatus');
        }
        const markerIcon = (e) => {
            var goldStar = {
                // size 15 X 15
                path: 'M 14.5625 2.226562 L 0.4375 2.226562 C 0.195312 2.226562 0 2.421875 0 2.664062 L 0 12.335938 C 0 12.578125 0.195312 12.773438 0.4375 12.773438 L 14.5625 12.773438 C 14.804688 12.773438 15 12.578125 15 12.335938 L 15 2.664062 C 15 2.421875 14.804688 2.226562 14.5625 2.226562 Z M 14.5625 2.226562',
                fillColor: (e.info ? e.info.color : null),
                fillOpacity: 0.8,
                scale: 1,
                strokeColor: '#000099',
                strokeWeight: 1,
            };
            if (e.icon === "offline") { return offline }
            else if (e.icon === "start") { return start }
            else if (e.icon === "running") { return running }
            else return goldStar

        }


        let lines = [];
        let defaultCenter,initialCenter = {};
        if (markers && markers.length) {
            lines = markers.map(e => ({ lat: +(e.lat), lng: +(e.lng) }));
            defaultCenter = {
                lat: markers[0].lat,
                lng: markers[0].lng
            }
            initialCenter = {
                lat: markers[0].lat,
                lng: markers[0].lng
            }
        }
        let bounds = new this.props.google.maps.LatLngBounds();

        if (markers.length > 1) {
            for (var i = 0; i < markers.length; i++) {
                var mapLatLng = new this.props.google.maps.LatLng(markers[i].lat, markers[i].lng)
                bounds.extend(mapLatLng);
            }
        } else {
            let mapLatLngj = new this.props.google.maps.LatLng(23.63936, 68.14712);
            let mapLatLngj2 = new this.props.google.maps.LatLng(28.20453, 97.34466);

            bounds.extend(mapLatLngj);
            bounds.extend(mapLatLngj2);

        }
        //  let mapLatLngj = new this.props.google.maps.LatLng(23.63936, 68.14712);
        // let mapLatLngj2 = new this.props.google.maps.LatLng(28.20453, 97.34466);

        //	bounds.extend(mapLatLngj);
        //	bounds.extend(mapLatLngj2);


        let props = {
            google: this.props.google,
            className: 'map',
            zoom: 12,
         //   bounds
        }
        return (
            <Fragment>

                <div className="popupUI">
                    <div className='location-popup'>
                        <div className='ovrly'></div>
                        <div className="closeBtn cncl" onClick={this.closeMap.bind(this)}><FaTimesCircle /></div>
                        <div className="overlay-map">
                            {mapLegend && mapLegend.length > 0 &&
                                <div className="map-legend">
                                    {mapLegend.map((e, i) => (<div key={i}>
                                    {e.frameCode === 1 && <span><img src={start}/></span>} 
                                    {e.frameCode == 2 && <span><img  src={offline}/></span>} 
                                    {e.frameCode == 8 &&  <span style={{ backgroundColor: e.color }}></span>} 
                                    <small>{e.drivingStatus}</small>
                                    </div>))}
                                </div>
                            }
                            <Map  {...props}
                                defaultCenter={defaultCenter}
                               center={defaultCenter}
                                google={this.props.google}
                                zoom={12}
                                initialCenter={{
                                    lat: markers[0].lat,
                                    lng: markers[0].lng } 
                                }
                            >
                                {(markers && markers.map((e, i) => (
                                    <Marker
                                        key={i}
                                        data={e.info}
                                        name={'Marker-' + (i + 1)}
                                        onClick={this.onMarkerClick}
                                        icon={markerIcon(e)}
                                        position={{ lat: e.lat, lng: e.lng }} />
                                )))
                                }
                                <InfoWindow
                                    marker={activeMarker}
                                    visible={showingInfoWindow}>
                                    <div>
                                        {(selectedPlace && selectedPlace.data && selectedPlace.data.frameCode === 8) &&
                                            <div>
                                                <div> <strong className="driving-status"> {selectedPlace.data.drivingStatus}</strong> </div>
                                                <div className="info-windows"> <span>DATE TIME</span> :<strong>{<Moment format='DD/MM/YYYY hh:mm A'>{selectedPlace.data.eventTimeStamp}</Moment>}</strong> </div>
                                                <div>
                                                    {selectedPlace.data && Object.keys(selectedPlace.data.trendAnalogValue).map((key, i) => (
                                                        <Fragment key={i}>
                                                            <div className="info-windows"> <span>{key}</span> : <strong>{selectedPlace.data.trendAnalogValue[key]}</strong> </div>
                                                        </Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        }

                                        {(selectedPlace && selectedPlace.data && selectedPlace.data.frameCode !== 8) &&
                                            <div className="info-windows"> <span>Date Time</span> :<strong>{<Moment format='DD/MM/YYYY hh:mm A'>{selectedPlace.data.eventTimeStamp}</Moment>}</strong> </div>
                                        }

                                    </div>
                                </InfoWindow>

                                {(lines && lines.length > 0) &&
                                    <Polyline
                                        path={lines}
                                        strokeColor="#dd1e39"
                                        strokeOpacity={0.8}
                                        strokeWeight={2} />}
                            </Map>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default GoogleApiWrapper({ apiKey: (MapKey), LoadingContainer })(MapBox);