import React, { Fragment } from "react";

const MyAccountDetails = props => {
    let { profile, getFullName, getLanguage, getAlert ,translate} = props;
    
    return(
        
        <Fragment>
            <div className="row profile-asset">
                <div className="col-sm-2">{translate('userID')}</div>
                <div className="col-sm-10">{profile.loginId || " "}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">{translate('fullName')}<span className="required">*</span></div>
                <div className="col-sm-10">{getFullName(profile)}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">{translate('mobileNumber')} <span className="required">*</span></div>
                <div className="col-sm-10">{profile.phone || ' '}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">{translate('alternateMobileNumber')} <span className="required">*</span></div>
                <div className="col-sm-10">{profile.alternatePhone || ' '}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">{translate('emailAddress')} </div>
                <div className="col-sm-10">{profile.email || ' '}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">{translate('displayLanguage')} </div>
                <div className="col-sm-10">{getLanguage(profile.languageCode) || ' '}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">{translate('timeZone')} <span className="required">*</span></div>
                <div className="col-sm-10">{profile.timezone || ' '}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">{translate('alert')}  <span className="required">*</span></div>
                <div className="col-sm-10">{getAlert(profile.mobileFlag, profile.emailFlag) || ' '}</div>
            </div>

            <div className="row profile-asset">
                <div className="col-sm-2">{translate('unitDisplay')}  </div>
                <div className="col-sm-10">{translate('distance')}: {profile.distanceUnitDisplay || ' '} / {translate('volume')}: {profile.volumeUnitDisplay || ' '}</div>
            </div>
        </Fragment>
    );
}

export default MyAccountDetails;