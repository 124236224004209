import http from "../services/http";
import { __api_getAlertSetting, __api_saveAlertSetting, __api_testNotification } from "./endpoints";

export const getAlertSetting = machineSaleId => {
    return http.Request("GET", __api_getAlertSetting + `${machineSaleId}`);
}

export const saveAlertSetting = (machineSaleId, data) => {
    return http.Request("POST", __api_saveAlertSetting + `${machineSaleId}`, data);
}

export const testNotification = (data) => {
    return http.Request("POST", __api_testNotification, data);
}