import React, { Component } from 'react';
import { connect } from 'react-redux';
import EquipmentAlertsComponent from '../../components/admin/equipment-alert/EquipmentAlertsComponent';
import { translate } from '../../lang';
import { getEquipmentAlertData, updateEquipmentStatusData } from '../../actions/Equipment';

class EquipmentAlertsContainer extends Component {

    constructor(props) {
        super(props);
        console.log('EquipmentAlertsContainer');
        this.state={
            equipmentAlertData : [],
            isService : 1
        };
    }

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount(){
        const isService = (this.props.location.pathname.split('/')[1] === 'admin');
        this.setState({isService : false})
        this.props.getEquipmentAlertData({isService : false, data : false})
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                equipmentAlertData : nextProps.equipmentAlertData
            })
        }
    }

    handleStatusChange(data){
        this.props.updateEquipmentStatusData(data);
    }

    handlePageChange(data) {
        this.props.getEquipmentAlertData({isService : this.state.isService, data : data})
    }
    
    render() {  
        const isService = (this.props.location.pathname.split('/')[1] === 'admin');
        return (
            <EquipmentAlertsComponent 
                translate={translate}
                isService = {isService}
                handlePageChange={ this.handlePageChange.bind(this) } 
                apiError = { this.props.apiError }
                equipmentAlertData = {this.state.equipmentAlertData} 
                handleStatusChange = { this.handleStatusChange.bind(this) }
            />
        );
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    return {
        equipmentAlertData : state.equipment.data,
        apiError : state.equipment.FETCH_DATA_FAIL || false
      };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getEquipmentAlertData, updateEquipmentStatusData
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentAlertsContainer);