import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon, InfoWindow, Circle } from "react-google-maps";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import { compose, lifecycle, withStateHandlers } from "recompose";
import _ from "lodash";
import { getAreaData, getVerticesData } from '../../actions/FieldInformation';
import { resetBound } from '../../actions/LocationInformation';
import { connect } from 'react-redux';
// const FaAnchor = require("react-icons/lib/fa/anchor");
import Moment from 'react-moment';
import noImage from "../../assets/images/noimage.png";
import active from '../../assets/images/map/location_pin1.png';
import offlineCE from '../../assets/images/map/Excavator-Offline.png';
import offlineCH from '../../assets/images/map/CombineHarvester-Offline.png';
import offlineTE from '../../assets/images/map/Tractor-Offline.png';
import activeErrorCE from '../../assets/images/map/Excavator-ActiveError.png';
import activeErrorCH from '../../assets/images/map/CombineHarvester-ActiveError.png';
import activeErrorTE from '../../assets/images/map/Tractor-ActiveError.png';
import offlineErrorCE from '../../assets/images/map/Excavator-OfflineError.png';
import offlineErrorTE from '../../assets/images/map/Tractor-OfflineError.png';
import offlineErrorCH from '../../assets/images/map/CombineHarvester-OfflineError.png';
import activeTE from '../../assets/images/map/Tractor-Active.png';
import activeCE from '../../assets/images/map/Excavator-Active.png';
import activeCH from '../../assets/images/map/CombineHarvester-Active.png';
import maplocationPointer from '../../assets/images/my-equipment-page-icons/tractorlocation.png';


/*global google*/
const refs = {};
const mapDispatchToProps = {getAreaData, getVerticesData, resetBound};
const mapStateToProps = state => {
  return ({
    mapBoundData: state.locationInformation.boundData || null
  });
}

class MapComponent extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      radius:0,
      coords:[],
      area:0,
      mapTypeId:'roadmap',
      zoomLevel:10
    }
    this.onAreaChange = this.onAreaChange.bind(this);
    this.mapBound = this.mapBound.bind(this);
    this.setBoundMap = this.setBoundMap.bind(this);
  }

  onAreaChange(areaVal) {
    this.setState({ area: areaVal });
  }

  componentDidMount(){
    this.setState({ coords: this.props.coords, radius: this.props.radius })

    setTimeout(() => this.setBoundMap(this.props), 1000)
  }
  
  shouldComponentUpdate(nextProps){
    if(this.props.coords !== nextProps.coords || this.props.bound !== nextProps.bound || this.props.center !== nextProps.center || this.props.markrs !== nextProps.markrs || this.props.markers !== nextProps.markers  /* || (nextProps.markrs && nextProps.markrs.length >= 0) */ ) {
     console.log('update');
      return true;
    }else {
      console.log('no update');
      return false;
    }
  }

 mapBound (markers, bounds) {
  bounds = new google.maps.LatLngBounds();

  for (var i = 0; i < markers.length; i++) {
    var mapLatLng = new google.maps.LatLng(markers[i].lat, markers[i].lng)
    bounds.extend(mapLatLng);
  }
  return bounds;
}

setBoundMap(newProps) {
  /* used for alert settings */  

  if(newProps && newProps.coords && window.google && refs && refs.map){

    let bounds = new google.maps.LatLngBounds();

    if(newProps.radius){
      /* for circle */
      let circleOptions = {
        center: newProps.coords[0],
        map: refs.map,
        radius: newProps.radius /* 20 miles */
      }

      let myCircle = new google.maps.Circle(circleOptions);
      let b = myCircle.getBounds();

      if(newProps.markrs && newProps.markrs.length){
        for (let j = 0; j < newProps.markrs.length; j++) {
          if(newProps.markrs[j].lat && newProps.markrs[j].lng){
            let mapLatLngj = new google.maps.LatLng(newProps.markrs[j].lat, newProps.markrs[j].lng)
            b.extend(mapLatLngj);
          }
        }
      }

      refs.map.fitBounds(b);


    }else{
      /* for other shapes */
      for (let i = 0; i < newProps.coords.length; i++) {
        if(newProps.coords[i].lat && newProps.coords[i].lng){
          let mapLatLng = new google.maps.LatLng(JSON.parse(JSON.stringify(newProps.coords[i])).lat, JSON.parse(JSON.stringify(newProps.coords[i])).lng)
          bounds.extend(mapLatLng);
        }
      }

      if(newProps.markrs && newProps.markrs.length){
        for (let j = 0; j < newProps.markrs.length; j++) {
          if(newProps.markrs[j].lat && newProps.markrs[j].lng){
            let mapLatLngj = new google.maps.LatLng(newProps.markrs[j].lat, newProps.markrs[j].lng)
            bounds.extend(mapLatLngj);
          }
        }
      }

      refs.map.fitBounds(bounds);
    }

  }if(newProps && (newProps.t && newProps.t!=='location') && (!newProps.coords || newProps.coords.length ===0) && window.google && refs && refs.map){
    let bounds = new google.maps.LatLngBounds();
      //non-circle
      if(newProps.markrs && newProps.markrs.length){
        for (let j = 0; j < newProps.markrs.length; j++) {
          if(newProps.markrs[j].lat && newProps.markrs[j].lng){
            let mapLatLngj = new google.maps.LatLng(newProps.markrs[j].lat, newProps.markrs[j].lng)
            bounds.extend(mapLatLngj);
          }
        }
      }else{
        bounds.extend(new google.maps.LatLng(23.63936, 68.14712));
        bounds.extend(new google.maps.LatLng(28.20453, 97.34466));
      }
    refs.map.fitBounds(bounds);
  }else{
    this.props.resetBound();
  }

}
componentWillReceiveProps(newProps){ 

  setTimeout(() => this.setBoundMap(newProps), 1000)

  if(newProps.radius && newProps.radius !== this.state.radius){
    this.setState({radius: newProps.radius})
  }
  if(refs && this.props.showArea){
   this.setState({mapTypeId : refs.map.getMapTypeId() ,zoomLevel :refs.map.getZoom()})
   console.log(refs.map.getZoom(),"refs.map.getZoom()")
 }
 
  if(newProps.mapBoundData && refs && refs.map && refs.map.fitBounds){
    setTimeout(() => {
      refs.map.fitBounds(newProps.mapBoundData);
    }, 500);
  }
}
componentWillUnmount(){
  this.props.resetBound();
}
render(){ 
    let componentInstance = this;
    let {showArea} = this.props;
    console.log('showArea'+showArea);
    console.log('props.icon---->'+JSON.stringify(this.props));
    const MapWithADrawingManager = compose(
      connect(mapStateToProps, mapDispatchToProps),
      lifecycle({
        componentWillMount() {
          this.setState({
            onMapMounted: ref => {
              refs.map = ref;
            
            },
            onPolygonComplete: (polygon) => {
              let m, n, g, NE, SW, NW, SE;
              let vertices, center={}, radius=0;
              if(polygon.type === 'polygon') {
                vertices = polygon.overlay.getPath();
                m = vertices.getArray();
                n = google.maps.geometry.spherical.computeArea(m);
                if(showArea){
                  componentInstance.props.handleArea(n);
                }
                console.log('Area------>'+n.toFixed(4));
                n = Math.ceil(n);
               
               
                g = google.maps.geometry.spherical.computeLength(m);
              }else if(polygon.type === 'rectangle') {
                let bounds = polygon.overlay.getBounds();
                NE = bounds.getNorthEast();
                SW = bounds.getSouthWest();
                NW = new google.maps.LatLng(NE.lat(),SW.lng());
                SE = new google.maps.LatLng(SW.lat(),NE.lng());
                center = bounds.getCenter();
                vertices = [NE, NW, SW, SE];
                n = google.maps.geometry.spherical.computeArea(vertices);
                if(showArea){
                  componentInstance.props.handleArea(n);
                }
              }else if(polygon.type === 'circle') {
                //console.log("==================", polygon)
                let bounds = polygon.overlay.getBounds();

                radius = polygon.overlay.getRadius();

                /* console.log(polygon.overlay.getCenter())
                console.log(polygon.overlay.getCenter().lat())
                console.log(polygon.overlay.getCenter().lng()) */
                //console.log(radius)

                
                //return false;
                /* NE = bounds.getNorthEast();
                SW = bounds.getSouthWest();
                NW = new google.maps.LatLng(NE.lat(),SW.lng());
                SE = new google.maps.LatLng(SW.lat(),NE.lng()); */
                center = bounds.getCenter();
                vertices = [polygon.overlay.getCenter()];
                n = radius*radius*Math.PI;
                if(showArea){
                  componentInstance.props.handleArea(n);
                }
              }

              //console.log(vertices)

              this.props.getVerticesData(vertices);
              this.props.getAreaData(n);
              if(componentInstance.props.handleVertices){
                componentInstance.props.handleVertices(vertices, polygon, center, radius);
              }
            }
          })
        }
      }),
      withScriptjs,
      withStateHandlers(() => ({
        isOpen: {0:false},
        infoIndex: {0:0}
      }), {
        showInfo: ({ isOpen, infoIndex }) => (index) => ({
          isOpen: {[index]:infoIndex !== index || !isOpen},
          infoIndex: {[index]:index}
        })
      }),
      withGoogleMap
    )(props => {
      var icons = {
        activeCE: {
          icon: activeCE
        },
        activeCH: {
          icon: activeCH
        },
        activeTE: {
          icon: activeTE
        },
        offlineCE: {
          icon: offlineCE
        },
        offlineTE: {
          icon: offlineTE
        },
        offlineCH: {
          icon: offlineCH
        },
        activeErrorCE: {
          icon: activeErrorCE
        },
        activeErrorCH: {
          icon: activeErrorCH
        },
        activeErrorTE: {
          icon: activeErrorTE
        },
        offlineErrorTE: {
          icon: offlineErrorTE
        },
        offlineErrorCE: {
          icon: offlineErrorCE
        },
        offlineErrorCH: {
          icon: offlineErrorCH
        }
       /*, tractorActive: {
          icon: tractorActive
        },
        excavatorActive: {
          icon: excavatorActive
        },
        combineActive: {
          icon: combineActive
        }
        offlineErrorTractor: {
          icon: offlineErrorTractor
        },
        offlineErrorExcavator: {
          icon: offlineErrorExcavator
        },
        offlineErrorCombine: {
          icon: offlineErrorCombine
        },
        activeErrorTractor: {
          icon: activeErrorTractor
        },
        activeErrorExcavator: {
          icon: activeErrorExcavator
        },
        activeErrorCombine: {
          icon: activeErrorCombine
        },
        OfflineTractor: {
          icon: OfflineTractor
        },
        OfflineExcavator: {
          icon: OfflineExcavator
        },
        OfflineCombine: {
          icon: OfflineCombine
        }*/
      
      };


      return(
        <div>
          {/* { isLoaded ? 'true': 'fase'} */}

        <GoogleMap
          ref={props.onMapMounted}
          defaultZoom={10}
          zoom={this.state.zoomLevel}
          maxZoom = { 10}
          center={this.props.center}
          onBoundsChanged={props.onBoundsChanged}
          mapTypeId = {this.props.component === 'fieldInformation' ? google.maps.MapTypeId.SATELLITE:this.state.mapTypeId}
          area={props.area}
          drawingMode={props.drawingMode}
          drawingControl={props.drawingControl}
          bound={this.props.bound}
        >
          {props.markrs && props.markrs.map((marker, index) => {
              return <Marker id={index} key={index} position={marker} icon={active}>
                {/* <InfoWindow>
                <div className="tractor-info">
                    <div className='left'>
                      {/* <img width='100' height='100' src={marker.logo} /> *
                      <p>"RJ45SA6651"</p>
                    </div>
                    <div className='right'>
                      <ul>
                        <li><span>Types</span>{marker.equipmentType ? marker.equipmentType.toUpperCase() : "N/A"}</li>
                        <li><span>Model</span>{marker.model || "N/A"}</li>
                        <li><span>Machine Number</span>{marker.machineNum || "N/A"}</li>
                        <li><span>Address</span>{marker.address || "N/A"}</li>
                      </ul>
                    </div>
                  </div>
                </InfoWindow> */}
            </Marker>
          })}

          {props.markers.map((marker, index) =>
            <Marker id={index} key={index} position={marker} onClick={() => props.showInfo(index)} icon={icons[marker.icon].icon}>
              {(props.isOpen[index] && props.infoIndex[index] === index) && <InfoWindow onCloseClick={props.showInfo}>
                  <div className="tractor-info">
                    <div className='left'>
                      <img width='100' height='100' src={marker.logo || noImage} />
                      <p>{(marker.numberPlate ? marker.numberPlate.toUpperCase() : " ")}</p>
                    </div>
                    <div className='right'>
                      <ul>
                        <li><span>Type</span>{marker.equipmentType ? marker.equipmentType.toUpperCase() : " "}</li>
                        <li><span>Model</span>{marker.model}</li>
                        <li><span>Machine Number</span>{marker.machineNum}</li>
                        {marker.operatingStatus && <li><span>Last Operating Time</span><Moment format="DD MMM, YYYY hh:mm A">{marker.operatingStatus}</Moment></li>}
                        {marker.operatingStatus && <li><span>Hourmeter</span>{marker.hourmeter && marker.hourmeter.toFixed(1)}</li>}
                       {/*  <li><span>Address</span>{marker.address}</li> */}
                      </ul>
                    </div>
                  </div>
                </InfoWindow>}
            </Marker>
          )}

          {props.coords && <Polygon 
            path={props.coords}
            options={{
                fillColor: '#FF0000',
                fillOpacity: 0.5,
                strokeColor: '#FF0000',
                strokeOpacity: 1,
                strokeWeight: 2
              }}
            />
          }  

          {props.coords && props.coords.length===1 && <Circle 
            center={props.coords[0]}
            radius={this.state.radius}
            options={{
                fillColor: '#FF0000',
                fillOpacity: 0.5,
                strokeColor: '#FF0000',
                strokeOpacity: 1,
                strokeWeight: 2
              }}
            />
          }  

          <DrawingManager
            drawingMode={props.drawingMode}
            defaultOptions={{
              drawingControl: [props.drawingControl],
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                  google.maps.drawing.OverlayType.POLYGON,
                  google.maps.drawing.OverlayType.RECTANGLE,
                  google.maps.drawing.OverlayType.CIRCLE,
                ],
              },
              polygonOptions: {
                fillColor: '#FF0000',
                fillOpacity: 0.5,
                strokeColor: '#FF0000',
                strokeOpacity: 1,
                strokeWeight: 3,
                clickable: false,
                editable: false,
                zIndex: 1,
              },
              rectangleOptions: {
                fillColor: '#FF0000',
                fillOpacity: 0.5,
                strokeColor: '#FF0000',
                strokeOpacity: 1,
                strokeWeight: 3,
                clickable: false,
                editable: false,
                zIndex: 1,
              },
              circleOptions: {
                fillColor: '#FF0000',
                fillOpacity: 0.5,
                strokeColor: '#FF0000',
                strokeOpacity: 1,
                strokeWeight: 3,
                clickable: false,
                editable: false,
                zIndex: 1,
              }
            }}
            onOverlayComplete={props.onPolygonComplete}
          />
        </GoogleMap>
      </div>
      );
    }
      
    );
    return(
     
      <div className="map">
        <MapWithADrawingManager
          mapTypeId={this.props.mapTypeId}
          googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyC07gm8YtRyNMEd9uBsaLGKWQYHEwtG2-I&v=3.exp&libraries=geometry,drawing,places"}      
          loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
          containerElement={<div style={{ height: `550px`, width: `100%` }} />}
          mapElement={<div style={{ height: `100%`, width: `100%` }} />}
          drawingMode={this.props.drawingMode}
          area={this.props.area}
          updateArea={this.props.updateArea}
          getCoordinates={this.props.getCoordinates}
          getArea={this.props.getArea}
         // getMapTypeId={this.props.getMapTypeId}
          coords={this.props.coords}
          getBounds={this.props.getBounds}
          searchLocation={this.props.searchLocation}
          bound={this.props.bound} center={this.props.center}
          markers={this.props.markers}
          markrs={this.props.markrs}
          t={this.props.t}
          drawingControl={this.props.drawingControl}
          component={this.props.component}
          />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapComponent);