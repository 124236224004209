import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardComponent from '../components/customer/dashboard/DashboardComponent';
import { getEquipmentList } from '../actions/Equipment';
import { saveAlertReadStatus } from '../actions/AlertsNotifications';
import { translate } from '../lang';
import { getMembers } from "../actions/Member";

class DashboardContainer extends Component {

    constructor(props) {
        super(props);
        this.state={
            dashboardData : [],
        };
    }

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount(){
        let token = "";
        if(localStorage.getItem('TOKEN')){
            token = JSON.parse(localStorage.getItem('TOKEN'));
        }
        this.props.getEquipmentList({token:token.token});
        this.props.getMembers();
    }

    markAsRead(data) {
        this.props.saveAlertReadStatus( { alertId : data } );
    }
    render() {        
        return (
            <DashboardComponent 
                markAsRead = { this.markAsRead.bind(this) }
                translate={translate} 
                apiError = { this.props.apiError }
                members = { this.props.members }
                timezone = {this.props.timezone}
                dashdata={ this.props.equipmentData.SET_EQUIPMENT_DATA }/>
        );
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */


function mapStateToProps(state) {
    return {
        members: state.members.users || [],
        equipmentData: state.equipment.data,
        apiError : state.equipment.FETCH_DATA_FAIL || false,
        timezone: (state.profile && state.profile.timezone) || "Asia/Kolkata"
      };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getEquipmentList, saveAlertReadStatus, getMembers
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);