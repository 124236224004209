import http from "../../services/http";
import { __api_getManageServiceCompanyData, __api_saveManageServiceCompanyData,__api_getManageServiceCompanyEqList } from "./admin.endpoints";

export const getManageServiceCompanyData = (startIndex, numberOfRows, totalCounts, sorting, qs) => {
    let URL;
  
    URL= __api_getManageServiceCompanyData + `/${startIndex}/${numberOfRows}/${totalCounts}${(sorting === undefined ? '?' : (sorting === null) ? '?' : sorting )}ver=${Math.floor(Math.random() * 100)}`;
    if( qs && Object.keys(qs)) {
        let r = Object.keys(qs).map( key => `${key}=${qs[key]}`);
        console.log(URL)
        console.log(r)
        console.log(r.join('&'));
        URL = `${URL}&${r.join('&')}`;
        console.log(URL, "last");
        
    }
    return http.Request("GET", URL);
}

export const saveManageServiceCompanyData = (data) => {
    return http.Request("POST", __api_saveManageServiceCompanyData, data);
}

export const getManageServiceCompanyEqList = (data) =>{
    return http.Request("GET",__api_getManageServiceCompanyEqList)
}