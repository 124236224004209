import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContractManagementComponent from '../../components/admin/contract-management/ContractManagementComponent';
import {getStatusAndCompany,getContractListInfo} from '../../actions/admin/contractManagement';
class ContractManagementContainer extends Component {

    componentWillMount() {

        this.props.getStatusAndCompany();
    }
   

    render() {
        console.log("CompanyAndStatusList",this.props.CompanyAndStatusList)
        return(
            <ContractManagementComponent CompanyAndStatusList={this.props.CompanyAndStatusList}/>

        );
        
}
}
const mapDispatchToProps = {getStatusAndCompany }

const mapStateToProps = state => {
    let CompanyAndStatusList = state.contractManagement && state.contractManagement.CompanyAndStatusDetail && state.contractManagement.CompanyAndStatusDetail.data;
    return { CompanyAndStatusList};
}
export default connect(mapStateToProps, mapDispatchToProps)(ContractManagementContainer);