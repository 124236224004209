import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../../api/index';
import { GET_EQUIPMENTS_ALERT_DATA, SENDING_EQUIPMENT_ALERT_LIST_DATA, SET_EQUIPMENT_ALERT_LIST_DATA, 
  FETCH_DATA_FAIL } from '../../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* getEquipmentAlertListData(action) {
  const data = action.data;
  try {
    yield put({
      type: SENDING_EQUIPMENT_ALERT_LIST_DATA,
      data: '',
      resMsg: '',
      isSending: true,
      error: false
    })

    let response = {};
    if(data.isService){
      response = yield call(api.admin.equipmentData.alertList, data.data)
    }else{
      response = yield call(api.serviceProvider.equipment.getEquipmentEvents, data.data)
    }
    
    switch (response.statusCode) {
      case 200:
        yield put({
          type: SET_EQUIPMENT_ALERT_LIST_DATA,
          data: response.data,
          resMsg: response.message,
          isSending: false,
          error: false
        })
        let params = JSON.stringify({ 'equipmentAlertListData': response.data });
        localStorage.setItem('equipmentAlertListData', params);
        break;
      default:  
       break;
    }
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: 'Error while connecting server'
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchEquipmentAlertListData() {
  yield takeLatest(GET_EQUIPMENTS_ALERT_DATA, getEquipmentAlertListData)
}
