import {GET_MANAGE_SERVICE_DATA,GET_MANAGE_SERVICE_EQUIPMENT_LIST} from '../../types'

/**
* Dispatch the activity listing action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/

export const getManageServiceData = (data) => {
	return {
		type : GET_MANAGE_SERVICE_DATA,
		data
	}
}
export const getManageServiceEquipmentList = (data) => {
	return {
		type : GET_MANAGE_SERVICE_EQUIPMENT_LIST,
		data
	}
}

