import { SET_CONTRACT_DETAILS,SET_RECENT_CONTRACT,SET_COMPANY_STATUS_LIST} from '../../types';

/** @type { Object } default state */
let defaultState = {
    data: {
    }
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function contractManagement(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_CONTRACT_DETAILS:
            return { ...state, contractDetails : action.data};
      

        case SET_RECENT_CONTRACT:
            return  { ...state, contractCountDetail : action.data};
        case SET_COMPANY_STATUS_LIST:
            return  { ...state, CompanyAndStatusDetail : action.data};

       
    
            default: return state;
        }
        
       
    
}
