import React, { Component } from 'react';
import { connect } from 'react-redux';
import MemberlistComponent from '../components/customer/members/MemberlistComponent';
import { translate } from '../lang';

import { getMembers } from "../actions/Member";

class MembersContainer extends Component {

    /**
     * Dispatch the action to get the members data
     */
    componentDidMount() {
        this.props.getMembers();
    }


    render() {
        let { members } = this.props;
        return <MemberlistComponent members={members} translate={translate}/>;
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
const mapStateToProps = state => {
    return { members: state.members.users };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getMembers
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersContainer);