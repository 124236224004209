import React, { Component, Fragment } from "react";
import { Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { connect } from "react-redux";
import { setToastr, setLoader } from "../../../actions/Application";
import { Field, reduxForm, change } from "redux-form";
import moment from "moment";
import { renderTextField } from "../../shared/renderField";
import { updateContract } from "../../../api/admin/contract-management";
import { FaCalendarAlt } from 'react-icons/fa'
import { withRouter } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import "./style.scss";


const form = "contractRenewForm";


class ContractRenew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            machineSaleId: undefined,
            data: {
                startDate:'',
                endDate:null,
                currentDate: moment()
            },

        };
        //this.getMachineInfo = this.getMachineInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renewContact = this.renewContact.bind(this);
        this.endDate = this.endDate.bind(this);
        this.startDate=this.startDate.bind(this);

    }



    componentWillReceiveProps(props) {

        let { contractDetails, dispatch } = props;

        if (contractDetails) {
            dispatch(change(form, "contractNumber", contractDetails.contractNumber));
            dispatch(change(form, "machineSaleId", contractDetails.machineSaleId));
        }

    }


    renewContact(values) {
        let { setLoader, setToastr } = this.props;
        let { machineId, userId } = this.props.match.params
        const date = new Date(this.state.data.endDate);
        const localTime = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

       
        let contractDetails = {
            ...values,
            applicationDate: this.props.contractDetails.applicationDate,
            //contractDate: this.props.contractDetails.contractDate,
            contractPeriod: localTime,
            contractDate: this.state.data.startDate,
        }
           
        setLoader({ loader: { open: true } });
       
        updateContract(contractDetails)
            .then(result => {
                /** hide loader */
                setLoader({ loader: { open: false } });
                console.log('userId',result)

                /** display success message */
                if(userId)
                this.props.history.push(`/service/equipment-detail/${userId}/${machineId}`)
                else{
                    if (result.data==true) {
                        this.props.history.push(`/service/contract-management`)
                        setToastr({ toastr: { message: "Contract has been updated successfully", type: "success" } })
                        setLoader({ loader: {open:false}});
                    }
                    else{
                        this.props.setToastr({ toastr: { message:  "This request couldn't be completed. Try again later.", type: "error" } });
                        this.props.setLoader({ loader: { open: false } });
                    }   
                //this.props.history.push(`/service/contract-management`)
                //setToastr({ toastr: { message: "Contract has been updated successfully", type: "success" } })
                }
            })
            .catch(error => {

                this.props.setToastr({ toastr: { message: error || "This request couldn't be completed. Try again later.", type: "error" } });

                this.props.setLoader({ loader: { open: false } });
            });

    }
    endDate(e) {
        e.preventDefault();
        this.endcomponent.setOpen(true);
    }
    startDate(e) {
        e.preventDefault();
        this.startcomponent.setOpen(true);
    }


    handleChange(type, data) {

        if (data === undefined) {
            data = type.target.value;
            type = type.target.name;
        }
        this.setState(
            {
                data: {
                    ...this.state.data, [type]: data
                }
            });

    }
    render() {
        const { handleSubmit, contractDetails } = this.props;
    

        return (
            <Fragment>

                {/* table data here */}
                <form onSubmit={handleSubmit(this.renewContact)} autoComplete='off' className='form-horizontal' id={form}>

                    {/* Contract information*/}

                    {contractDetails && <section className='member-contract-info margin-up'>
                        <Row className='no-gutters'>
                            {/* Hidden fileds */}
                            <Field component={renderTextField} type='hidden' name='machineSaleId' />

                            <Col md='12' sm='12' xs='12' className='machine-asset'>
                                <Col md='2' className='float-left'>
                                    Application Date <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='d-flex justify-content-start align-items-center float-left'>
                                    <div className="float-left mr-5 position-relative">
                                        <DatePicker
                                            selected={moment(contractDetails.applicationDate)}
                                            autoComplete='off'
                                            dateFormat="DD MMM YYYY"
                                            id="applicationDate"
                                            className="form-control pointer-event"
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                            </Col>

                            <Col md='12' sm='12' xs='12' className='machine-asset'>
                                <Col md='2' className='float-left'>
                                    Contract Number <span className='required'>*</span>
                                </Col>

                                <Col md='4' className='float-left'>
                                    <Field name="contractNumber" component={renderTextField} className='form-control' type="text" disabled />
                                </Col>
                            </Col>

                            <Col md='12' sm='12' xs='12' className='machine-asset'>
                                <Col md='2' className='float-left'>
                                    Contract Period<span className='required'>*</span>
                                </Col>
                                {/*<div className='float-left col-md-4'>*/}
                                    {/*<Label className="inputLabel float-left">Contract Period</Label>*/}
                                <Col md='8' className='d-flex justify-content-start align-items-center float-left'>
                                <div className="float-left mr-4 position-relative" >
                                    <DatePicker
                                        selected={this.state.data.startDate}
                                        //minDate={moment()}
                                        ref={(r) => {
                                            this.startcomponent = r;
                                        }}
                                        autoComplete='off'
                                        onChange={this.handleChange.bind(this, 'startDate')}
                                        dateFormat="DD MMM YYYY"
                                        id="startDate"
                                        className="form-control pointer-event"
                                        required="true"
                                    />
                                    <button className="calLogoContract" onClick={this.startDate}><FaCalendarAlt /></button>
                                </div>
                                <div className="float-left mr-1 position-relative" style={{left: '6px'}} >
                                    <DatePicker
                                        selected={this.state.data.endDate}
                                        //minDate={moment(new Date())}
                                        minDate={moment(this.state.data.startDate)}
                                        ref={(r) => {
                                            this.endcomponent = r;
                                        }}
                                        autoComplete='off'
                                        onChange={this.handleChange.bind(this, 'endDate')}
                                        dateFormat="DD MMM YYYY"
                                        id="endDate"
                                        className="form-control pointer-event"
                                        required="true"
                                    />
                                    <button className="calLogoContract" onClick={this.endDate}><FaCalendarAlt /></button>
                                </div>
                            </Col>
                                {/*</div>*/}
                            </Col>
                            <Col md='12' sm='12' xs='12' className='machine-asset'>
                                <Col md='2' className='float-left' >
                                    Remarks
                                </Col>

                                <Col md='4' className='float-left'>
                                    <Field name="remark" id="remark" component={renderTextField} className='form-control' type="text" />
                                </Col>
                            </Col>
                            <div className="col-sm-2">
                                <button type="button" className="btn btn-primary srch-btn" onClick={() => this.props.history.goBack()}>Back</button>
                            </div>
                            <div className="col-sm-2">
                               
                                <button type="submit" className="btn btn-primary srch-btn">Renew</button>
                                
                            </div>
                            

                        </Row>

                    </section>}
                </form>
            </Fragment>
        );
    }

}
const mapDispatchToProps = { setToastr, setLoader };
let ContractRenewForm = (reduxForm({ form: form })(withRouter(ContractRenew)));

export default connect(null, mapDispatchToProps)(ContractRenewForm);
