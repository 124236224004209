import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { getMembers } from "../../../../api/members";

/** importing sub-components */
import OperationalInfo from "./dataset/OperationalInfo";
import Chart from "../../../shared/Chart";
import Map from "../../../shared/Map";
import HeaderText from "../../../shared/HeaderText";

/** import action */
import { setEquipmentOperatingInfo } from "../../../../actions/Dailywork";
import { setConfirmBox } from "../../../../actions/Application";

class ReportComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: []
        };

        /** function binding... */
        this.updateOperatingInfo = this.updateOperatingInfo.bind(this);
    }

    async componentDidMount() {
        let {isService} = this.props;
        let members = !isService && await getMembers();
        /** Set into state */
        this.setState({ members: members.data || [] });
    }

    updateOperatingInfo(i, row) {
        let { operatingInfo } = this.props;

        if (i >= 0 && operatingInfo && operatingInfo.length) {
            /** dispatch action */
            /** update info here */
            operatingInfo[i].oilQuantity = parseFloat(row.oilQuantity);
            operatingInfo[i].memo = row.memo;
            operatingInfo[i].id = parseInt(row.id);
            operatingInfo[i].workingWidth = parseFloat(row.workingWidth);
            this.props.setEquipmentOperatingInfo({ operatingInfo });
        }
    }

    render() {
        let { operatingInfo, isEquipment, routeDetail, translate, group,operatingInformation,isService } = this.props;
        let { members } = this.state;

        if (!Object.keys(operatingInfo).length || !isEquipment) return false;
        let routeDetailLength = 0;
        if (routeDetail && routeDetail.length) {
            routeDetail.forEach((marker, i) => {
                for (let i in marker) {
                routeDetailLength++;
                }
            })}

        return (
            <div className="report-area">

                <div className="work-chart-area">
                    <Col md="12">
                        <Row>
                            <Col md='4' sm='4' xs='12' className='align-self-center'>
                                <HeaderText headerText={translate('equipmentOperatingInfo')} />
                            </Col>

                          { !isService && <Col md='8' sm='8' xs='12' className='align-self-center text-right'>
                                <span className="route-map-btn"
                                    onClick={() => this.props.setConfirmBox({
                                        modal: {
                                            open: true, header: false, size: "modal-lg",
                                            style: { "height": "400px" },
                                            content:  routeDetailLength > 0 ? <Map drivercoords={routeDetail} isMarker={true} height="380px" width="96.5%" isRouteMap="yes" group={this.props.group} /> :<Map linecords={operatingInfo} isMarker={true} height="380px" width="96.5%"/>
                                        }
                                    })}>{translate('viewRouteMapDetail')}</span>
                            </Col> }
                        </Row>
                    </Col>
                </div>

                <div className="work-chart-area">
                    <Col md="12">
                    <Chart translate={translate} isService={isService} />
                    </Col>
                </div>

                <div className="work-report-area o-auto">
                    <Col md="12">
                        <Row>
                            <table className="table table-respons2ive">
                                <thead>
                                    <tr>
                                        <th width="5%">{translate('sNo')}</th>
                                        <th width="9%">{translate('startTime')}</th>
                                        <th width="9%">{translate('endTime')}</th>
                                        <th width="9%">{translate('operatingHour')}</th>
                                        {group == 'TE' && <React.Fragment><th width="9%">{translate('ptoRunningHour')}</th>
                                            <th width="8%">{translate('idlingHour')}</th>
                                            <th width="8%">{translate('runningTime')}</th>
                                            <th width="8%">{translate('highSpeed')}</th>
                                            <th width="7%">{translate('workDetail')}</th>
                                            <th width="7%">{translate('refuelQty')}</th>
                                            <th width="7%">{translate('workingWidth')}</th>
                                            <th width="7%">{translate('acreageArea')}</th>
                                            <th width="7%">{translate('member')}</th></React.Fragment>}
                                        {group == 'CE' && <React.Fragment>
                                            <th width="8%">{isService ? translate('hydraulicTempAvg'):translate('hydraulicTemp')}</th>
                                            <th width="8%">{isService ? translate('coolingTempAvg'):translate('coolingTemp')}</th>
                                        </React.Fragment>}
                                        {group == 'CH' && <div>
                                            <th width="8%">{translate('alarmInfo')}</th>

                                        </div>}
                                        <th><span className="pull-right">
                                            &nbsp;
                                        </span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {!isService && operatingInfo.length && operatingInfo.map((row, i) => {
                                         return <OperationalInfo i={i} row={row} members={members} key={i} updateOperatingInfo={this.updateOperatingInfo} group={group} />;
                                     })}
 
                                {isService && operatingInformation.length && operatingInformation.map((row, i) => {
	                                       
                                        return <OperationalInfo i={i} row={row} members={members} key={i} updateOperatingInfo={this.updateOperatingInfo} group={group} />;
                                    })}
                                </tbody>
                            </table>
                        </Row>
                    </Col>
                </div>
            </div>
        );
    }
};



const mapStateToProps = state => {
    return ({
        routeDetail: state.dailywork.routeDetail || {},
        operatingInfo: state.dailywork.operatingInfo || {},
        operatingInformation: state.dailywork.equipOperatingInfo && state.dailywork.equipOperatingInfo.operatingInfoResponse || {},
        equipmentType: state.dailywork.equipmentType && state.dailywork.equipmentType.toUpperCase() || {},
        group:state.dailywork.status && state.dailywork.status.toUpperCase() || {}
    });
};

const mapDispatchToProps = { setEquipmentOperatingInfo, setConfirmBox };

export default connect(mapStateToProps, mapDispatchToProps)(ReportComponent);