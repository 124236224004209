import { GET_OWNER_EQUIPMENTS, RESET_OWNER_DETAILS, GET_OWNERS, SET_OWNER_DATA_ORDER ,GET_OWNER_DETAIL} from '../../types';

/**
 * Dispatch the login action to the middleware
 * @param  { Object } data Date to be processed
 * @return { Object }      Dispatch the action type
 */
export const getOwnerEquipments = data => {
    return { type: GET_OWNER_EQUIPMENTS, data }
}

export const resetOwnerDetails = data => {
    return { type: RESET_OWNER_DETAILS, data }
}

export const getOwners = (data) => {
    return { type: GET_OWNERS, data }
}

export const setOrder = (data) => {
    return { type: SET_OWNER_DATA_ORDER, data }
}

export const getOwnerDetail = data => {
    return { type: GET_OWNER_DETAIL, data }
}
