import React, { Component, Fragment } from "react";
import { Field, reduxForm, change, clearFields } from "redux-form";
import { required, maxCurrentDate } from "../../shared/Validation";
import { renderTextField, renderDatepicker } from "../../shared/renderField";
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ServiceCheckComponent from './ServiceCheckComponent';
import "./style.scss";
import Pagination from "../../shared/Pagination";
import { LIMIT } from "../../../constants/Constants";
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa'
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'react-moment';
import moment from 'moment';

class EquipmentServiceDetailComponent extends Component {

    constructor(props) {
        super(props);
        console.log("+++++++EquipmentServiceDetail++++++++++");

        this.startDate = this.startDate.bind(this);
        this.toggle = this.toggle.bind(this);
        this.saveData = this.saveData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.viewService = this.viewService.bind(this);
        this.addNewService = this.addNewService.bind(this);
        this.confirmToggle = this.confirmToggle.bind(this);
        this.finalSave = this.finalSave.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.state = {
            serviceDate: moment(),
            activePage: 1,
            total: 0,
            modal: false,
            confirmToggle:false,
            serviceCheckList: [],
            viewServiceList: [],
            readonly: false,
            values : null
        }
    }



    handleChange(type, data) {
        this.setState({ serviceDate: data });
    }

    saveData(data) {
        this.setState({ serviceCheckList: data });
        this.toggle();
    }
    handlePageChange(page) {
        let { machineInfo } = this.props;
        let data = {
            model: machineInfo.model,
            machineNum: machineInfo.machineNum
        }

        this.setState({ activePage: page || 1 }, () => {
            this.props.getEquipmentServiceInfo(page, data);
        });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }
    confirmToggle() {
        this.setState({
            confirmModal: !this.state.confirmModal
        });
    }

    addNewService() {
        this.toggle();
        let array = Array.from(this.state.serviceCheckList);
        this.setState({
            viewServiceList: array,
            readonly: false
        })
    }

    viewService(rList) {
        this.toggle();
        this.setState({serviceCheckList:[]});
        let array = Array.from(JSON.parse(rList));
        this.setState({
            viewServiceList: array,
            readonly: true
        })
    }
    startDate() {
        this.startcomponent.setOpen(true);
    }
     finalSave(){
         this.confirmToggle();
         let values = this.state.values;
        
         let serviceDetail = JSON.parse(values.serviceDetail);
        
        if(serviceDetail.length !== 0 || typeof(values.otherServiceDetail) !== 'undefined'){
            this.props.saveServiceData(values);
        // clear pop up window data
        this.clearFields();
        }else{
            this.props.setToastr({ toastr: { type: "error", message:  "Please select atleast one service or mention service details" } });
             
        }
      
    } 
    onSubmit(values) {
        let { serviceDate, serviceCheckList } = this.state;
        let { machineInfo,data } = this.props;
        let isScheduledService = document.getElementById('scheduleService').checked;
        values = {
            ...values,
            serviceDate: serviceDate,
            serviceDetail: JSON.stringify(serviceCheckList),
            model: data.model || machineInfo.model,
            machineNum: data.machineNum || machineInfo.machineNum,
            scheduleServiceFlag  : isScheduledService
        }
        
           console.log("values",values,"machineInfo",machineInfo)
        if(!isScheduledService){
            this.setState ({values})
            this.setState({confirmModal:true});
        }else{
            
         
        
            if(serviceCheckList.length !== 0 || typeof(values.otherServiceDetail) !== 'undefined'){
                this.props.saveServiceData(values);
            // clear pop up window data
            this.clearFields();
            }else{
                this.props.setToastr({ toastr: { type: "error", message:  "Please select atleast one service or mention service details" } });
                 
            }
        }
       
               
       
       
    }
    clearFields(){
        this.setState({ serviceCheckList: [] })
            document.getElementById('scheduleService').checked = false;
            this.props.dispatch(change('ServiceInfoForm', 'otherServiceDetail', ''));
            this.setState({serviceDate : moment()});
    }
    render() {
        let { handleSubmit, data, dispatch, serviceDate } = this.props;
        let { total, activePage, serviceCheckList, readonly } = this.state;
        dispatch(change("ServiceInfoForm", "ownerName", data.ownerName));
        dispatch(change("ServiceInfoForm", "hourMeter", data.hourMeter && data.hourMeter.toFixed(1)));
         let group =data && data.status;
  
        // end
        return (
            <Fragment>
                <form name="ServiceInfoForm" id="ServiceInfoForm" autoComplete='off' onSubmit={handleSubmit(this.onSubmit)} >
                    <Row className="members align-items-center ">
                        <Col sm="12">
                            <div className="row profile-asset">
                                <div className="col-sm-2 "> Owner Name
                                    <Field name="ownerName" component={renderTextField} className='form-control' disabled type="text" value={data.ownerName} />
                                </div>
                                <div className="col-sm-1"> HourMeter
                                    <Field name="hourMeter" component={renderTextField} className='form-control' disabled type="text" value={data.hourMeter} />
                                </div>

                                <div className="col-sm-3">
                                    <div className="Row">Service Date <span className='required'>*</span></div>
                                    <div className="Row">
                                        <div className="float-left mr-5">
                                            <DatePicker
                                                selected={this.state.serviceDate}
                                                maxDate={new Date()}
                                                ref={(r) => {
                                                    this.startcomponent = r;
                                                }}
                                                autoComplete='off'
                                                onChange={this.handleChange.bind(this, 'serviceDate')}
                                                timeIntervals={15}
                                                dateFormat="DD MMM YYYY HH:mm:00"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                id="serviceDate"
                                                className="form-control pointer-event"
                                            />
                                            <FaCalendarAlt className="calLogo" onClick={() => this.startDate()} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2 align-middle" style={{
                                    display: "flex", justifyContent: "center", alignItems: "center"
                                }}>

                                    <div className="checkbox-group ">
                                        <label>
                                            <input type="checkbox" id='scheduleService' className='form-control' />
                                            <span className='checkmark'></span></label>
                                    </div> Scheduled Service

                                </div>
                                <div className="col-sm-2 btn-group-vertical">
                                    <a href="#" className="serviceDetail" onClick={this.addNewService}  >
                                        {serviceCheckList.length > 0 ? <span> View Service Detail </span> : <span> Select Service Detail </span>}
                                    </a></div>
                                <div className="col-sm-2 "> Other Service Detail
                                    <Field name="otherServiceDetail" component={renderTextField} className='form-control' type="text" />
                                </div>


                            </div>

                        </Col>
                    </Row>
                    <Row className="members align-items-center ">
                        
                        <div className="col-sm-1 save">
                                    <button type="submit" className="btn btn-primary srch-btn" /* disabled={submitting} */>Save</button>
                                </div>
                                
                        </Row>
                </form>

                <div >
                <Modal size='lg' isOpen={this.state.confirmModal} toggle={this.confirmToggle} className={this.props.className}>
                    <ModalHeader toggle={this.confirmToggle}>Confirmation</ModalHeader>
                    <ModalBody >
                    <p> If it is a scheduled service, select the Scheduled Service Box, else proceed.
                    </p> </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.confirmToggle}>Cancel</Button>
                        <Button color="primary"   onClick={this.finalSave}>Save Anyway</Button>
                        
                    </ModalFooter>
                </Modal>
            </div>

                {data.inEquipmentServiceInfo && <table className="tabledashboard table table-striped table-advance table-hover m-top-30 eqService">
                    <thead>
                        <tr>
                            {/* <th>Sr. No</th> */}
                            <th>Model</th>
                            <th>Machine Number</th>
                            {group == 'TE' &&<th>Number Plate</th>}
                            <th>HourMeter</th>
                            <th>Service Detail</th>
                            <th>Other Service Detail</th>
                            <th>Registered By</th>
                            <th>Service Company</th>
                            <th>Service Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(data.inEquipmentServiceInfo).length > 0 && (data.inEquipmentServiceInfo).map((item, i) => (
                            <tr key={item.model + i + 1}>
                                <td>{item.model}</td>
                                <td>{item.machineNum}</td>
                                {group =='TE' &&<td>{(item.numberPlate ? item.numberPlate.toUpperCase() : " ")}</td>}
                                <td>{item.hourMeter && item.hourMeter.toFixed(1)}</td>
                                <td>{JSON.parse(item.serviceDetail).length > 0 ? <a href="#" className="serviceDetail" onClick={() => this.viewService(item.serviceDetail)}  >
                                    <span> View Detail </span>
                                </a> : ""}</td>
                                <td>{item.otherServiceDetail}</td>
                                <td>{item.serviceEngineerName }</td>
                                <td>{item.serviceCompanyName }</td>
                                <td><Moment format="DD-MMM-YYYY HH:mm:ss" tz="Asia/Kolkata">{item.serviceDate}</Moment></td>
                            </tr>
                        ))}

                        {!(data.inEquipmentServiceInfo).length && <tr><td align="center" colSpan="12">No Service history Found</td></tr>}

                    </tbody>
                </table>}
                <Pagination
                    limit={LIMIT}
                    total={data.countResult.totalCounts}
                    activePage={activePage}
                    onChange={this.handlePageChange.bind(this)}
                    data={data.inEquipmentServiceInfo || []}
                />

                {/*   ******** model window****** */}

                <ServiceCheckComponent readonly={readonly} data={data.inSreviceItem} modal={this.state.modal} toggle={this.toggle} saveData={this.saveData} serviceCheckList={this.state.viewServiceList} group={group}></ServiceCheckComponent>
            </Fragment>)
    }

    componentDidUpdate(nextProps) {
        console.log("componentDidUpdate",nextProps);
        if (nextProps.saveData) {
           /* nextProps.getEquipmentServiceInfo({ page: 1 }, nextProps.machineInfo); */
           nextProps.setLoader({ loader: { open: false } });
           
        }
    } 

}
export default reduxForm({ form: "ServiceInfoForm" })(EquipmentServiceDetailComponent);


