import Polyglot from 'node-polyglot';
import hin from './lang.hin.js';
import en from './lang.en.js';


/**
 * Setting Up the language for the application
 */

let locale =  localStorage.getItem('locale')  || 'en' ;

const allLang = {
    en: en,
    hin:hin
  };
     
const polyglot = new Polyglot({
    locale,
    phrases: allLang[locale],
});

export const translate = polyglot.t.bind(polyglot);
export const locale1 = polyglot.locale.bind(polyglot) 

