import { takeLatest, call, put } from 'redux-saga/effects';
import { getOwnerEquipments, listOwners ,getOwnerDetail} from '../../api/admin/owner';
import { GET_OWNER_EQUIPMENTS, SET_LOADER, SET_OWNER_EQUIPMENTS, SET_TOASTR, GET_OWNERS, SET_OWNERS, GET_OWNER_DETAIL,SET_OWNER_DETAIL } from '../../types';


export function* getOwnerEquipmentCall(action) {
    try {
        const ownerData = yield call(getOwnerEquipments, action.data);
        yield put({ type: SET_OWNER_EQUIPMENTS, data: {user: ownerData.data.user || undefined, equipments: ownerData.data.eqpPCCompanies || []} });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });
        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}

export function* getOwners(action) {
    try {
        const owners = yield call(listOwners, action.data);

        yield put({ type: SET_OWNERS, data: {owners: owners.data.ownerDetailList || [], countResult: owners.data.countResult} });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "This URL does not valid.", 
                    type:"info"
                }
            }
        });
    }
}

export function* getOwnerDetailCall(action) {
    try {
        const ownerData = yield call(getOwnerDetail, action.data);
        yield put({ type: SET_OWNER_DETAIL, data: {ownerDetail: ownerData.data} });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });
        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchOwnerEquipments() {
    yield takeLatest(GET_OWNER_EQUIPMENTS, getOwnerEquipmentCall)
    yield takeLatest(GET_OWNERS, getOwners)
    yield takeLatest(GET_OWNER_DETAIL, getOwnerDetailCall)
}
