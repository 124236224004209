import { GET_CONTRACT_DETAILS,GET_RECENT_CONTRACT,GET_COMPANY_STATUS_LIST} from '../../types';

export const getContractDetail = (data) => {
    console.log('inside action ');
    return { type: GET_CONTRACT_DETAILS, data }
}

export const getRecentContract =(data) => {
    console.log('inside getRecentContract ');
    return { type: GET_RECENT_CONTRACT, data }
}

export const getStatusAndCompany =(data) => {
    console.log('inside getStatusAndCompany ');
    return { type: GET_COMPANY_STATUS_LIST, data }
}



