import { ADD_SYS_MAINTAIN_DATA,UPDATE_SYS_MAINTAIN_DATA, UNSET_ADD_MAINTAIN_DATA } from '../types'

/**
* Dispatch the Schedule Service action to the middleware
* @param  { Object } data Date to be processed
* @return { Object }      Dispatch the action type
*/

export const addSystemMaintenance = (data) => {
	return {
		type : ADD_SYS_MAINTAIN_DATA,
		data
	}
}

export const updateSystemMaintenance = (data) => {
	return {
		type : UPDATE_SYS_MAINTAIN_DATA,
		data
	}
}


export const unsetSystemMaintenance = (data) => {
	return {
		type : UNSET_ADD_MAINTAIN_DATA,
		data
	}
}
