import React from 'react';
import { equipments, members, daily_work, filed_information, location_information, alert_settings, work_report, my_account, owners, dataControl, service_history, member_management, area_measurement } from "../constants/Startpoints";
import equipment from '../assets/images/menu/Equipment1.svg';
import serviceHistory from '../assets/images/menu/ServiceHistory2.svg';
import serviceHistory1 from '../assets/images/menu/ServiceHistory1.svg';
import areaMeasurement from '../assets/images/menu/AreaMeasurement2.svg';
import areaMeasurement1 from '../assets/images/menu/AreaMeasurement1.svg';
import equipment1 from '../assets/images/menu/Equipment2.svg';
import dailyWork from '../assets/images/menu/Dailywork1.svg';
import dailyWork1 from '../assets/images/menu/Dailywork2.svg';
import memberManagementIcon from '../assets/images/menu/memberManagement1.svg';
import memberManagementIcon1 from '../assets/images/menu/memberManagement2.svg';
import locationInfo from '../assets/images/menu/Location1.svg';
import locationInfo1 from '../assets/images/menu/Location2.svg';
import alertSett from '../assets/images/menu/Alertsettings1.svg';
import alertSett1 from '../assets/images/menu/Alertsettings2.svg';
import reports from '../assets/images/menu/Managereport1.svg';
import reports1 from '../assets/images/menu/Managereport2.svg';
import membersIcon from '../assets/images/menu/Mymembers1.svg';
import membersIcon1 from '../assets/images/menu/Mymembers2.svg';
import myacc from '../assets/images/menu/Myaccount1.svg';
import myacc1 from '../assets/images/menu/Myaccount2.svg';

export const navItem = [
    { menu: true, functionName: "My Equipments", uuid: equipments, icon: <img src={equipment} alt="menu" title="My Equipments"/>, icon1: <img src={equipment1} alt="menu" title="My Equipments"/> },
    { menu: true, functionName: "Manage Daily Work", uuid: daily_work, icon: <img src={dailyWork} alt="menu" title="Manage Daily Work"/>, icon1: <img src={dailyWork1} alt="menu" title="Manage Daily Work"/> },
    { menu: true, functionName: "Location Information", uuid: location_information, icon: <img src={locationInfo} alt="menu" title="Location Information"/>, icon1: <img src={locationInfo1} alt="menu" title="Location Information"/> },
    { menu: true, functionName: "Alert Settings", uuid: alert_settings, icon: <img src={alertSett} alt="menu" title="Alert Settings"/>, icon1: <img src={alertSett1} alt="menu" title="Alert Settings"/> },
    { menu: true, functionName: "Manage Reports", uuid: work_report, icon: <img src={reports} alt="menu" title="Manage Reports"/>, icon1: <img src={reports1} alt="menu" title="Manage Reports"/> },
    { menu: true, functionName: "Equipment Service History", uuid: service_history, icon: <img src={serviceHistory} alt="menu" title="Equipment Service History"/>, icon1: <img src={serviceHistory1} alt="menu" title="Equipment Service History"/> },
    { menu: true, functionName: "Area Measurement", uuid: area_measurement, icon: <img src={areaMeasurement1} alt="menu" title="Area Measurement"/>, icon1: <img src={areaMeasurement} alt="menu" title="Area Measurement"/> },
    { menu: true, functionName: "My Members", uuid: members, icon: <img src={membersIcon} alt="menu" title="My Members"/>, icon1: <img src={membersIcon1} alt="menu" title="My Members"/> },
    { menu: true, functionName: "Member Management", uuid: member_management, icon: <img src={memberManagementIcon} alt="menu" title="Member Management"/>, icon1: <img src={memberManagementIcon1} alt="menu" title="Member Management"/> },
    { menu: true, functionName: "My Account", uuid: my_account, icon: <img src={myacc} alt="menu" title="My Account"/>, icon1: <img src={myacc1} alt="menu" title="My Account"/> }
];
// dynamic menu base on user role

export const navAdminItem2 = [
    { functionName: "Dashboard", uuid: "/service/dashboard" },
    { functionName: "Owner Management", uuid: owners },
    { functionName: "Manage Service Companies", uuid: "/service/manage-service-companies" },
    { functionName: "Equipment Alerts", uuid: "/service/equipment-alerts" },
    { functionName: "Schedule Service Alerts", uuid: "/service/schedule-service-alert" },
    { functionName: "System Maintenance", uuid: "/service/system-maintenance" },
    { functionName: "Data Control", uuid: dataControl },
    { functionName: "Fota", uuid: "/service/fota" },
    { functionName: "Custom Map", uuid: "/service/custom-map" },
    { functionName: "Dealer Management", uuid: "/service/dealer-management" },
    { functionName: "Equipment Location Info", uuid: "/service/equipment-location-info" },
    { functionName: "Email Information", uuid: "/service/email-information" },
    { functionName: "Contract Management", uuid: "/service/contract-management" },
    { functionName: "Hourmeter Offset Management", uuid: "/service/hourmeter-offset-management" }

];

export const navAdminItem = [
    { functionName: "Dashboard", uuid: "/service/dashboard" }
];