import { SET_EQUIPMENT_DAILYWORK_DETAILS, SET_EQUIPMENT_OPERATINGINFO_DETAILS } from '../../types';

/** @type { Object } default state */
let defaultState = { equipmentDailyWork: null };


/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function dailywork(state = defaultState, action = {}) {


    //console.log(action);
    
    switch (action.type) {
        case SET_EQUIPMENT_DAILYWORK_DETAILS:
            return { ...state, ...action.data };

        case SET_EQUIPMENT_OPERATINGINFO_DETAILS:
            return { ...state, ...action.data };

        default: return state;
    }
}
    