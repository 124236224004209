
import http from "../../services/http";
import { __api_getCompanyEmail, __api_updateCompanyEmail,__api_deleteCompanyEmail,__api_addCompanyEmail,__api_companySearch } from "./sp.endpoints";

/*export const getCompanyEmail = (data) => {
    return http.Request("GET", __api_getCompanyEmail, data);
}*/

export const getCompanyEmail = (startIndex,numberOfRows,countFlag,ord,email,companyName) => {
    return http.Request("GET", __api_getCompanyEmail + `/${startIndex}/${numberOfRows}/${countFlag}/${ord}/${email}/${companyName}`);
}

export const getCompanyEmailByComapnyCode = (data,startIndex,numberOfRows,countFlag,ord,email,companyName) => {

    return data ? http.Request("GET", __api_getCompanyEmail + `/${startIndex}/${numberOfRows}/${countFlag}/${ord}/${email}/${companyName}`+ '?companyCode=' + data) : http.Request("GET", __api_getCompanyEmail + `/${startIndex}/${numberOfRows}/${countFlag}/${ord}/${email}/${companyName}`, data);
}

export const updateCompanyEmail = (email,data) => {
    return http.Request("POST", __api_updateCompanyEmail + '/' + `${email}`, data);
}

export const deleteCompanyEmail = (email) => {
    return http.Request("DELETE", __api_deleteCompanyEmail + '/' + `${email}`);
}

export const addCompanyEmail = (data) => {
    return http.Request("POST", __api_addCompanyEmail, data);
}

export const companySearch = (data) => {
    return http.Request("GET", __api_companySearch, data);
}