import React, { Component } from 'react';
import { connect } from "react-redux";
import { getEquipmentsServices } from "../actions/ServiceHistory";
import ServiceHistoryComponent from '../components/customer/service-history/ServiceHistoryComponent';
import { translate } from '../lang';


class ServiceHistoryContainer extends Component {

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount(){
        let userId = localStorage.getItem('LOGINID');
        this.props.getEquipmentsServices({userId});
    }
    render() {
        let {equipments} = this.props;
        return (
           <ServiceHistoryComponent translate={translate} equipments={equipments}/>
        );
    }
   
}
const mapDispatchToProps = {
    getEquipmentsServices
    
}
const mapStateToProps = state => {
    return({
        equipments: (state.serviceHistory && state.serviceHistory.data && state.serviceHistory.data.SET_EQUIPMENTS_SERVICE_DATA) ? state.serviceHistory.data.SET_EQUIPMENTS_SERVICE_DATA : []
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceHistoryContainer);

