import { GET_USER_LOGGED_IN, GET_USER_LOGOUT, GET_FORGET_PASSWORD, GET_ADMIN_LOGGED_IN, GET_ADMIN_FORGET_PASSWORD, GET_SRVPROV_LOGGED_IN } from '../types';

/**
 * Dispatch the login action to the middleware
 * @param  { Object } data Date to be processed
 * @return { Object }      Dispatch the action type
 */
export const getUserLogin = (data) => {
	return {
		type: GET_USER_LOGGED_IN,
		data
	}
}

export const logoutUser = (data) => {
	return {
		type: GET_USER_LOGOUT,
		data
	}
}

export const handleForgetPassword = (data) => {
	return {
		type: GET_FORGET_PASSWORD,
		data
	}
}

/** Admin Login auth actions */

export const getAdminLogin = (data) => {
	return {
		type: GET_ADMIN_LOGGED_IN,
		data
	}
}

export const handleAdminForgetPassword = (data) => {
	return {
		type: GET_ADMIN_FORGET_PASSWORD,
		data
	}
}

/** Service provider Login auth actions */

export const getSrvprovLogin = (data) => {
	return {
		type: GET_SRVPROV_LOGGED_IN,
		data
	}
}
