import React, { Fragment } from 'react';

/** It is common class that provide the header texts */
const HeaderText = props => {
    const { headerText, caption } = props;
    return (
        <Fragment>
            <h3 className="header-text" id="header-text">{headerText}</h3>
            {caption && <span><em>{caption}</em></span>}
        </Fragment>
    );
}

export default HeaderText;
