import http from "../../services/http";
import { __api_getHourMeterGap, __api_saveHourMeterOffsetValue, __api_getHourMeterGapHistory } from "../service-provider/sp.endpoints";
import { LIMIT } from "../../constants/Constants";

export const getHourMeterGap = (req) => {
    let offset = (req.page && req.page > 1) ? ((req.page - 1) * LIMIT) : 0;
    return http.Request("GET", [[__api_getHourMeterGap, offset, LIMIT, true].join("/"), req.qs].join("?"));
};

export const saveHourMeterOffsetValue = data => {
    return http.Request("POST", __api_saveHourMeterOffsetValue, data);
}

export const getHourMeterGapHistory = (req) => {
    return http.Request("GET", __api_getHourMeterGapHistory + "/" + req.model + "/" + req.machineNum);
};
