const customer = "/customer/", admin = "/service/", serviceProvider = "/service-provider/";

/** Members */
export const members = customer + "members";
export const teamMembers = customer + "teamMembers";
export const add_member = customer + "members/add";
export const edit_member = customer + "members/edit/:memberId";
export const member_management = customer + "member_management";

/** Equipments */
export const dashboard = customer + "dashboard"
export const equipments = customer + "equipment"

export const equipments_detail = customer + "equipment/equipmentDetail/:equipmentid"


/** Daily Work */
export const daily_work = customer + "manage-daily-work"

/** Reports */
export const work_report = customer + "reports"

/** Equipments */
export const my_account = customer + "my-account"

/** Area Measurement */
export const area_measurement = customer + "area-measurement"

/** Location information*/
export const location_information = customer + "location-information"

/** Equipment Service History*/
export const service_history = customer + "service-history"

/** Alert setting */
export const alert_settings = customer + "alert-setting"
export const alert_settings_byid = customer + "alert-setting/:machineSaleId"

/** Field information */
export const filed_information = customer + "field-information"

/** Admin Equipments alerts section */
export const equipmentAlerts = admin + "equipment-alerts"


/** Admin Equipments alerts details section */
export const equipmentAlertDetails = admin + "equipment-alert-detail/:alerId"


/** Admin schedule Service Alert section */
export const scheduleServiceAlert = admin + "schedule-service-alert"


/** Admin system maintenance section */
export const systemMaintenance = admin + "system-maintenance"


/** Admin add system maintenance section */
export const addSystemMaintenance = admin + "add-system-maintenance"

/** Admin Manage Service Company */
export const manageServiceCompany = admin + "manage-service-companies"

/** Owner Management */
export const owners = admin + "owners";
export const addOwner = admin + "owners/add";
export const ownerDetails = admin + "owners/detail/:userId";
export const editOwnerDetails = admin + "edit-owner-details/:userId/:machineSaleId/true";

/** Data Control */
export const dataControl = admin + "data-control";

/**Equipment Location Info */
export const equipmentLocationInfo = admin + "equipment-location-info";

/**Email Information  */
export const emailInformation = admin + "email-information";

/**Contract management */
export const contractManagement = admin + "contract-management";
export const contractManagementStatus = admin + "contract-management/:status"

/*Hourmeter offset management*/
export const hourmeterOffsetManagement = admin + "hourmeter-offset-management";
export const hourmeterOffsetDetail = admin + "hourmeter-offset-detail";

/** Admin Home */
export const home = admin + 'dashboard';
export const CustomMap = admin + 'custom-map';
export const FOTA = admin + 'fota';
export const roles = admin + 'roles';
export const compareRoles = admin + 'roles/compareRoles';
export const addRole = admin + 'roles/add';
export const editRole = admin + 'roles/edit/:roleid';
export const dealerMangement = admin + 'dealer-management'

/** Service Provider equipment alert section */
export const SVEquipemtAlert = admin + "equipment-alerts";
export const SVEquipemtAlertDetail = admin + "sp_equipment-alert-detail/:alerId";


/** Service Provider equipment operating info */
export const SPEquipmentOperatingInfo = admin + "equipment-operating-info";
export const SPEquipmentDetailOperatingInfo = admin + "equipment-detail/:userId/:machineId";

/* equipment operating info contract renew*/
export const SPEquipmentDetailOperatingInfoContractRenew = admin + "contract-renew/:userId/:machineId";
export const SPContractManagementContractRenew = admin + "contract-renew/:machineId";

export const editDetailsContractRenew = admin + "edit-owner-details/:userId/:machineSaleId";

/** Service Provider Profile */
export const SPProfile = admin + "profile";

export const SPEquipmentServiceInfo = admin + "equipment-service-info";
