import { SET_DC } from '../../types';

/** @type { Object } default state */
let defaultState = {
    data: {
        msCompaniesPageList: [],
        msCompaniesTotalList: []
    }
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function dcSettings(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_DC:
            return { ...state, ...action.data};

        default: return state;
    }
}
