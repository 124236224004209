import React, {Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from "reactstrap";
import Image from "../../../shared/Image";
import noImage from "../../../../assets/images/noimage.png";
import moment from "moment";
import SelectField from '../../../../components/shared/SelectField';
import { getDailyWorkYearOrMonth } from "../../../../api/dailywork";
import { setToastr, setLoader } from "../../../../actions/Application";


class EquipmentDetails extends Component {
    constructor(props) {
        super(props);

        /** define component state */
        this.state = {
            years: [],
            months: [],
            data: {
                year: "",
                month: '',
            },
        };

        /** function binding */
        this.getYearOrMonth = this.getYearOrMonth.bind(this);
        
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.years && nextProps.years && this.props.years !== nextProps.years) {
            this.setState({years : nextProps.years });
            this.setState(
                {
                    data: {
                        ...this.state.data, month: '', year: ''
                    }
                });
        }
    }

    getYear() {
        let options = [];
        let years = this.state.years;
        for (let i = 0; i < years.length; i++) {
            options.push(<option key={years[i]} value={years[i]}> {years[i]} </option>);
        }
        return { year: options }
        
    }

    getMonth() {
        let options = [];
        let months = this.state.months;
        let monthMap = {
            "1": "JAN",
            "2": "FEB",
            "3": "MAR",
            "4": "APR",
            "5": "MAY",
            "6": "JUN",
            "7": "JUL",
            "8": "AUG",
            "9": "SEP",
            "10": "OCT",
            "11": "NOV",
            "12": "DEC"
        }
        for (let i = 0; i < months.length; i++) {
            options.push(<option key={months[i]} value={months[i]}> {monthMap[months[i]]} </option>);
        }
        return { month: options }
    }
    getYearOrMonth(year) {
        let { equipment } = this.props;
        let machineSaleId = equipment.machineSaleId
        this.props.setLoader({ loader: { open: true } });
        let req = {
            "machineSaleId": machineSaleId
        }
        if (year) {
            req["qs"] = `year=${year}`;
        }
        getDailyWorkYearOrMonth(req)
            .then(result => {
                if (result.data && result.data.length > 0) {
                    let yearMonth = result.data;
                    let extractedMonths = yearMonth.map(i => i.month);
                    this.setState({ months: extractedMonths });
                }
                else{
                    this.setState({ months: [] });
                }
               this.props.setLoader({ loader: { open: false } });
            })
            .catch(error => {
               this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
               this.props.setLoader({ loader: { open: false } });
            })
    }

    handleChange(type, data) {
        if (data === undefined) {
            data = type.target.value;
            type = type.target.name;
        }
        this.setState(
            {
                data: {
                    ...this.state.data, [type]: data
                }
            });
        if (type == 'year' && !isNaN(data)) {
            this.getYearOrMonth(data);
            let openToDate = moment().year(data).format("YYYY-MM-DD");
            this.props.onYearMonthChange({
                openToDate
            });
            this.setState({ openToDate: openToDate })
        } else if (type == 'year' && isNaN(data)) {
            const d = new Date();
            let month = this.state.data.month;
            let currentYear = d.getFullYear();
            let openToDate = moment().year(currentYear).format("YYYY-MM-DD");
            this.props.onYearMonthChange({
                openToDate
            });
            this.setState({ openToDate: openToDate });
            this.setState(
                {
                    data: {
                        ...this.state.data, month: '', year: ''
                    }
                });
        }
        if (type == 'month' && !isNaN(data)) {
            let selectedMonth = data - 1;
            let openToDate = moment().year(this.state.data.year).month(selectedMonth).format("YYYY-MM-DD");
            this.props.onYearMonthChange({
                openToDate
            });
            this.setState({ openToDate: openToDate })
        }
        else if (type == 'month' && isNaN(data)) {
            const d = new Date();
            let year = this.state.data.year;
            let currentMonth = d.getMonth();
            let openToDate =  moment().year(this.state.data.year).month(currentMonth).format("YYYY-MM-DD");
            this.props.onYearMonthChange({
                openToDate
            });
            this.setState({ openToDate: openToDate });
            this.setState(
                {
                    data: {
                        ...this.state.data, month: '', year: this.state.data.year
                    }
                });
        }
    }

    render() {
    let { equipment, performance,translate, isInitial,currentDate,years } = this.props; 
    let contractExpiry = equipment.contractExpiry;
    let hidden = moment(new Date()).isAfter(equipment.contractExpiry);
    let formattedDate = moment(this.props.currentDate).format('DD MMM YYYY')
    if(performance && performance.operatingHours && performance){
        hidden = false
    }
    /** Render only if we have equipment details */
    if (!equipment || !Object.keys(equipment).length) return false;
   
    return (
        <Fragment>
            {isInitial ?
                <div className="tracker-details">
                    <Row className='no-gutters'>
                        <Col sm='12' md='12' lg={5} className="area-bordered">
                            <Row className='no-gutters'>
                                <Col sm='2' className="tracker-img-pos text-center">
                                    <Image src={equipment.logo || noImage} alt="tracker" className="img-responsive img-circle" />
                                </Col>
                                <Col sm='10' className="tracker-info-pos">
                                    <Row className='no-gutters'>

                                        <Col sm='6'>
                                            <span className="highlighted">{translate('model')}</span> {equipment.model || " "}
                                        </Col>
                                        <Col sm='6'>
                                            <span className="highlighted">{translate('machineno')}</span> {equipment.machineNum || " "}
                                        </Col>
                                        <div className="w-100"></div>
                                        {equipment.status == 'TE' && <Col sm='6'>
                                            <span className="highlighted">{translate('numberPlate')}</span> {(equipment.numberPlate ? equipment.numberPlate.toUpperCase() : " ")}
                                        </Col>}
                                        <Col >
                                            <span className="highlighted">{translate('type')}</span> {equipment.equipmentType ? equipment.equipmentType.toUpperCase() : " "}
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="7" sm='12' md='12' className="area-filled">

                            <Row className='no-gutters' style={{ marginTop: '-5px' }}>
                                {hidden &&
                                    <div>
                                        <Col xs="12" className="errorMessage" align='center'>{translate('The_Contract_has_been_expired_for_the_selected_machine')}.
                                        </Col>
                                    </div>}
                                {!hidden &&
                                    <Col sm="2">
                                        <div className="tracker-perftxt-pos">
                                            <span className="highlighted">
                                                {translate('equipment')} <br /> {translate('performanceInfo')}
                                            </span>
                                        </div>
                                    </Col>
                                }

                                <Col className="col-sm-10 tracker-perf-pos">
                                    <Row className='no-gutters'>
                                        {!hidden &&
                                            <Col sm='6'>
                                                <span className="highlighted">{translate('operatingHourMinutes')}</span>
                                                <span className="pull-right">{(!isInitial && performance && performance.operatingHours) ? performance.operatingHours.substring(0, 5) : "0.0"}</span>
                                            </Col>}

                                        {equipment.status == 'TE' && !hidden && <Col sm='6'>
                                            <span className="highlighted">{translate('ptoHour')}</span>
                                            <span className="pull-right">{(!isInitial && performance && performance.ptoHr) ? performance.ptoHr : "0.0"}</span>
                                        </Col>}
                                        {equipment.status == 'TE' && !hidden && <Col sm='6'>
                                            <span className="highlighted">{translate('idleHour')}</span>
                                            <span className="pull-right">{(!isInitial && performance && performance.idleHr) ? performance.idleHr : "0.0"}</span>
                                        </Col>}
                                        {equipment.status == 'TE' && !hidden && <Col sm='6'>
                                            <span className="highlighted">{translate('runningHour')}</span>
                                            <span className="pull-right">{(!isInitial && performance && performance.runningTime) ? performance.runningTime : "0.0"}</span>
                                        </Col>}
                                        {equipment.status == 'TE' && !hidden && <Col sm='6'>
                                            <span className="highlighted">{translate('highSpeedHour')}</span>
                                            <span className="pull-right">{(!isInitial && performance && performance.highSpeed) ? performance.highSpeed : "0.0"}</span>
                                        </Col>}
                                        {!hidden && <Col sm='6'>
                                            {!isInitial ? <span className="highlighted">{translate('selectedDate')}</span> : <span className="highlighted">{translate('updatedOn')}</span>}
                                            <span className="pull-right">{formattedDate}</span>
                                        </Col>}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div> :
                <section className='sp-equipment-detail '>
                    <p class="ridge">
                        {/*  <h3 className='sub-heading u_l'>Equipment Operating Info - {equipment.numberPlate}</h3> */}
                        <p className='grey-title'>{translate('performanceInfo')}</p>
                        <div className="toprow row" style={{ marginBottom: '10px', marginLeft: '0px' }}>
                            <div className="text-center col-sm-4 col-lg-2">
                                <Image className="imagetractor fix-h" alt="logo" src={equipment.logo || noImage} style={{ height: '100px' }} />
                            </div>
                            {hidden &&
                                <div>
                                    <Col xs="12" className="errorMessage" align='center'>{translate('The_Contract_has_been_expired_for_the_selected_machine')}.
                                    </Col>
                                </div>
                            }

                            <div className="col-sm-8 col-lg-10 eqdetails" style={{paddingLeft:'0px'}}>
                                <div className="row">
                                    <div className="eqdetailbox col-sm-4">
                                        {!hidden && <div className="detailcover">
                                            <div>
                                                <span className='highlighted col-sm-8' style={{padding:'0px'}}>{translate('operatingHourMinutes')}</span>
                                                <span className='float-right col-sm-3'>{(!isInitial && performance && performance.operatingHours) ? performance.operatingHours.substring(0, 5) : "0.0"}</span>
                                            </div>
                                        </div>
                                        }
                                        {equipment.status == 'TE' && !hidden &&
                                            <div className="detailcover">
                                                <div>
                                                    <span className='highlighted col-sm-8' style={{padding:'0px'}}>{translate('highSpeedHour')}</span>
                                                    <span className='float-right col-sm-3'>{(!isInitial && performance && performance.highSpeed) ? performance.highSpeed : "0.0"}</span>
                                                </div>
                                            </div>
                                        }
                                        
                                        {equipment.status == 'TE' && !hidden && <div className="detailcover">
                                            <div>
                                                <span className='highlighted col-sm-8' style={{padding:'0px'}}>{translate('idleHour')}</span>
                                                <span className='float-right col-sm-3'>{(!isInitial && performance && performance.idleHr) ? performance.idleHr : "0.0"}</span>
                                            </div>
                                        </div>}
                                    </div>

                                    <div className="eqdetailbox col-sm-4">
                                        {equipment.status == 'TE' && !hidden && <div className="detailcover">
                                            <div >
                                                <span className='highlighted col-sm-7'style={{padding:'0px'}}>{translate('ptoHour')}</span>
                                                <span className='float-right col-sm-5'>{(!isInitial && performance && performance.ptoHr) ? performance.ptoHr : "0.0"}</span>
                                            </div>
                                        </div>}
                                        {equipment.status == 'TE' && !hidden && <div className="detailcover">
                                            <div>
                                                <span className='highlighted col-sm-7' style={{padding:'0px'}}>{translate('runningHour')}</span>
                                                <span className='float-right col-sm-5'>{(!isInitial && performance && performance.runningTime) ? performance.runningTime : "0.0"}</span>
                                            </div>
                                        </div>}
                                        {!hidden &&
                                            <div className="detailcover">
                                                <div>
                                                    {!isInitial ? <span className='highlighted col-sm-7' style={{padding:'0px'}}>{translate('selectedDate')}</span> : <span className='highlighted col-sm-7'>{translate('updatedOn')}</span>}
                                                    <span className='float-right col-sm-5'>{formattedDate}</span>
                                                </div>
                                            </div>}

                                    </div>
                                    {!hidden && 
                                    <div className="eqdetailbox col-sm-4">
                                        <div className="detailcover">
                                            <div class='row'>
                                                <span className='highlighted col-sm-7' style={{padding:'0px'}}>{translate('workingHistoryYear')}</span>
                                                <span className='col-sm-3' style={{padding:'0px'}}>
                                                {(this.state.years.length) > 0 ?
                                                    <SelectField
                                                        divClass="selectType" 
                                                        type="select"
                                                        options={this.getYear().year}
                                                        value={(this.state.data.year.length) > 0 ? this.state.data.year : 'No working history'}
                                                        name="year"
                                                        //className={(this.state.yearError) ? 'border-danger' : ''}
                                                        handleChange={this.handleChange.bind(this)}
                                                        defaultOption="Select Year"
                                                    />: translate('noHistoryAvailable')}
                                                </span>
                                            </div>
                                        </div>
                                            <div className="detailcover">
                                                <div class='row'>
                                                    {this.state.data.year && !isNaN(this.state.data.year) &&
                                                        <span className='highlighted col-sm-7' style={{padding:'0px'}}>{translate('workingHistoryMonth')}</span>
                                                    }
                                                    {this.state.data.year && !isNaN(this.state.data.year) &&
                                                        <span className='col-sm-3' style={{ padding: '0px' }}>
                                                            <SelectField
                                                                divClass="selectType"
                                                                type="select"
                                                                options={this.getMonth().month}
                                                                value={this.state.data.month}
                                                                name="month"
                                                                //className={(this.state.yearError) ? 'border-danger' : ''}
                                                                handleChange={this.handleChange.bind(this)}
                                                                defaultOption="Select Month"
                                                            />
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                    </p>
                </section>}
        </Fragment>
        

    );
}
}

const mapStateToProps = state => {
    return({
        performance: state.dailywork.performanceParams || {}
    });
};
const mapDispatchToProps = {setToastr, setLoader }

export default connect(mapStateToProps,mapDispatchToProps)(EquipmentDetails);