import { SET_OWNER_EQUIPMENTS, RESET_OWNER_DETAILS, SET_OWNERS, SET_OWNER_DATA_ORDER } from '../../types';

/** @type { Object } default state */
let defaultState = {
    user: undefined,
    equipments: [],
    order: undefined,
    orderBy: undefined
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function ownerSettings(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_OWNER_EQUIPMENTS:
            return { ...state, ...action.data};

        case RESET_OWNER_DETAILS:
            return { ...state, user: undefined, equipments: []};
        
        case SET_OWNERS:
            return { ...state, ...action.data};
        
        case SET_OWNER_DATA_ORDER:
            return { ...state, ...action.data};

        default: return state;
    }
}
