import { SET_TOASTR, SET_TZ_LN, SET_CONFIRM_BOX, SET_LOADER, SET_BREADCRUMBS, SHOW_REALTIME_ALERT, BELL_TOGGLE, SET_PERMISSIONS } from '../../types';
import session from "../../services/session";

let functions = session.get("prev", "cookie");
let showBell = session.get("bell", "cookie");

const getPermissions = functions => {
    let p = {};
    if(functions && functions.length){
        functions.map(func => {
            p[func.uuid] = func.access;
        })
    }
    return p;
}

let permissions = getPermissions(functions);

/** @type { Object } default state */
let defaultState = { 
    breadcrumbs: "dashboard",
    toastr: false, 
    loader:{
        open: false
    }, 
    modal: {  
        open: false,
        footer: true,
        header: true,
        title: "Please Confirm",
        content: "Do you really want to perform this action?"
    },
    showBell: (showBell) ? true : (showBell===false) ? false : true,
    functions: functions || [],
    permissions: permissions || {}
};


/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function app(state = defaultState, action = {}) {

    switch (action.type) {
        case SET_TOASTR:
            return { ...state, ...action.data };

        case SET_LOADER:
            return { ...state, ...action.data };

        case SET_CONFIRM_BOX:
            return { ...state, ...action.data };

        case SET_TZ_LN:
            return { ...state, ...action.data };
        
        case SET_BREADCRUMBS:
            return { ...state, breadcrumbs: action.data.key };
        
        case SHOW_REALTIME_ALERT:
            return {...state, modal: {open:true, ...action.data }}

        case SET_PERMISSIONS:
            return {...state, functions: action.functions, permissions: action.permissions }
        
        case BELL_TOGGLE:
            return {...state, showBell: action.data }
            
        default: return state;
    }
}
