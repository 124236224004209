import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { GET_EQUIPMENTS_SERVICE_DATA,SET_EQUIPMENTS_SERVICE_DATA,FETCH_DATA_FAIL,SET_EQUIPMENTS_SERVICE_HISTORY,GET_EQUIPMENTS_SERVICE_HISTORY,ET_EQUIPMENTS_SERVICE_HISTORY} from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */

export function* getServiceEquipmentData(action) {
  const data = action.data;
  
  try {
   let response = yield call(api.serviceHistory.getEquipmentsServices, data)   
   switch (response.statusCode) {
        case 200 :
          yield put({ type: SET_EQUIPMENTS_SERVICE_DATA, data: response.data, resMsg: response.message, isSending: false, error: false })
          break;
       default:  
        break;
    }
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: 'Error while connecting server'
      }
    })
  }
}

export function* getEquipmentServiceHistory(action) {
  const data = action.data
  console.log("data",data);
  try {
   let response = yield call(api.serviceHistory.getEquipmentServiceHistory, data)   
   switch (response.statusCode) {
        case 200 :
          console.log('response.data',response.data);
          yield put({ type: SET_EQUIPMENTS_SERVICE_HISTORY, data: response.data, resMsg: response.message, isSending: false, error: false })
          break;
       default:  
        break;
    }
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: 'Error while connecting server'
      }
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchServiceEquipmentData() {
  yield takeLatest(GET_EQUIPMENTS_SERVICE_DATA, getServiceEquipmentData)
  yield takeLatest(GET_EQUIPMENTS_SERVICE_HISTORY,getEquipmentServiceHistory)
}

