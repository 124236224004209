import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ReactLoading from 'react-loading';
import './style.scss';

/** A dump loader message component */
class Loaders extends Component {

	render() {
		const { modalOpen, loadingText, className, color } = this.props;
		return (
			<div>
				<Modal isOpen={modalOpen} className="loader-process" >
					<ModalBody>
						<div className="processing">
							<ReactLoading
								type={`spinningBubbles`}
								color={color || '#08a5e1'}
								className={className || 'loading-contents'}
							/>
							<h5>{loadingText || 'Please Wait...'}</h5>
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

export default Loaders;