import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Row, Col ,Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import Moment from 'react-moment';
import moment from 'moment';
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { FormGroup, Label } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { Field, reduxForm, change } from "redux-form";
import { FaSortDown, FaSortUp, FaSort } from 'react-icons/fa';
import { FileInput } from "../../shared/renderField";
import sorting from "../../../services/sorting";
/** importing sub-components */
import { renderTextField, renderSelectField, renderCustomField, renderDatepicker } from "../../shared/renderField";
import { required, min1, max10, min6, min10, max6, email, onlyNumbers, alphaNumeric, maxCurrentDate, alphaNum_ } from "../../shared/Validation";
/** importing APIs */
import { updateOwner, getMachineInfo, addOwner ,getSendEmail} from "../../../api/admin/owner";
/** importing actions */
import { getOwnerEquipments } from "../../../actions/admin/Owner";
import { setToastr, setLoader, setConfirmBox } from "../../../actions/Application";
const form = "EditOwnerForm";


class EditOwner extends Component {
    constructor(props) {
        super(props);

        /** component state */
        this.state = {
            equipments: [],
            isEdit: false,
            newEquipment: false,
            machineSaleId: undefined,
            preview: false,
            providers: [],
            company: [],
            selectedCompany: [],
            isEmergency: true,
            user: {},
            orderField: undefined,
            order: true, //true=asc, false=desc
            data: {
                //fromDate: moment(),
                startDate: moment(),
                tractorSaleDate: moment(),
                applicationDate: moment(),
                //tractorSaleDate: moment().subtract(1, "d"),
                //toDate: moment().subtract(1, "d"),
                //toDate: '',
                endDate:null
            },
            confirmToggle:false,
            confirmationMsg:"Are you sure you want to resend user login details?",
            smsConfirmation: false,
        };

        /** Function binding */
        this.toggleEdit = this.toggleEdit.bind(this);
        this.editOwnerDetails = this.editOwnerDetails.bind(this);
        this.getMachineInfo = this.getMachineInfo.bind(this);
        this.getServiceCompany = this.getServiceCompany.bind(this);
        this.getParentCompany = this.getParentCompany.bind(this);
        this.addEquipment = this.addEquipment.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.tractorSDate=this.tractorSDate.bind(this);
        this.endDate=this.endDate.bind(this);
        this.startDate=this.startDate.bind(this);
        this.applicationDate=this.applicationDate.bind(this);
        this.resendRegistrationEmail=this.resendRegistrationEmail.bind(this);
        this.confirmToggle = this.confirmToggle.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        this.toggleEdit(true);
        this.props.setLoader({ loader: { open: true } });
    }


    componentWillReceiveProps(newProps) {
        let { initialize, user, setLoader } = this.props;

        if (newProps.user && typeof newProps.user !== typeof user) {
            /** initialize react form */

            let headerText = document.getElementById("header-text").innerText;
            document.getElementById('header-text').innerText = (headerText.split(" -")[0] + " - " + newProps.user.loginId);

            this.setState({ isEmergency: newProps.user.emergencyCallFlag, user: newProps.user, equipments: newProps.equipments });

            initialize(newProps.user);
            setLoader({ loader: { open: false } });
        }

        if (newProps.equipments) this.setState({ equipments: newProps.equipments });
    }

    editable(e) {
        e.preventDefault();
        this.toggleEdit();
    }
    confirmToggle() {
        this.setState({
            confirmModal: !this.state.confirmModal,
            confirmationMsg:"Are you sure you want to resend user login details?",
            smsConfirmation:false,

        });
    }

    toggleEdit(type = false) {
        this.setState({ isEdit: !type, preview: type });

        let filledForm = document.forms[form];

        for (let i = 0; i < filledForm.elements.length; i++) {
            if (filledForm.elements[i].className && filledForm.elements[i].className.indexOf("ignore") < 0) filledForm.elements[i].disabled = type;
        }
    }
    handleChange(type, data) {
        if (data === undefined) {
            data = type.target.value;
            type = type.target.name;
        }
        this.setState(
            {
                data: {
                    ...this.state.data, [type]: data
                }
            });
    }

    startDate(e) {
        e.preventDefault();
        this.startcomponent.setOpen(true);
    }

    endDate(e) {
        e.preventDefault();
        this.endcomponent.setOpen(true);
    }
    tractorSDate(e) {
        e.preventDefault();
        this.tractorsaledatecomponent.setOpen(true);
    }
    applicationDate(e) {
        e.preventDefault();
        this.applicationDatecomponent.setOpen(true);
    }

    getMachineInfo() {
        let machineNum = ReactDOM.findDOMNode(document.getElementById("machineNum")).value,
            model = ReactDOM.findDOMNode(document.getElementById("model")).value,
            { dispatch, setToastr } = this.props;

        if (machineNum && model) {
            setLoader({ loader: { open: true, loadingText: "Fetching Machine Information" } });
            /** get machine infomation */
            getMachineInfo({ machineNum, model })
                .then(response => {
                    setLoader({ loader: { open: false } });

                    let machineInfo = {
                        equipmentType: (response.data.equipmentDetails && response.data.equipmentDetails.equipmentType) ? response.data.equipmentDetails.equipmentType.toUpperCase() : "",
                        terminalId: (response.data.equipmentDetails && response.data.equipmentDetails.terminalId) ? response.data.equipmentDetails.terminalId : "",
                        machineSaleId: (response.data.equipmentDetails && response.data.equipmentDetails.machineSaleId) ? response.data.equipmentDetails.machineSaleId : 0,
                        machineNum,
                        model
                    };

                    this.setState({ machineSaleId: (response.data.equipmentDetails && response.data.equipmentDetails.hasOwnProperty("machineSaleId")) ? response.data.equipmentDetails.machineSaleId : undefined });

                    // use `change` method to set specific field value
                    dispatch(change(form, "equipmentType", machineInfo.equipmentType));
                    dispatch(change(form, "terminalId", machineInfo.terminalId));
                    dispatch(change(form, "machineSaleId", machineInfo.machineSaleId));
                    // set providers
                    this.setState({ providers: response.data.serviceProviders || [] });

                    if (response.data.equipmentExitFlag) {
                        setToastr({ toastr: { message: "This equipment already exists", type: "info" } });
                    } else if (!response.data.equipmentDetails) {
                        setToastr({ toastr: { message: "Invalid Machine Number or Model", type: "info" } });
                    }
                })
                .catch(error => {
                    setToastr({ toastr: { message: "Invalid Machine Number or Model", type: "error" } });
                })
        }
    }

    getServiceCompany() {

        let serviceProvider = ReactDOM.findDOMNode(document.getElementById("serviceProvider")).value,
            company = [], selectedCompany = [], { dispatch } = this.props;

        if (serviceProvider) {
            getMachineInfo({ serviceProvider })
                .then(response => {
                    company.push({ options: response.data.serviceProviders || [] });
                    /** set selected options */
                    selectedCompany.splice(0, 0, { companyCode: serviceProvider });

                    if (response.data.serviceProviders && response.data.serviceProviders.length === 1) {
                        dispatch(change(form, ["company0"].join(""), response.data.serviceProviders[0].parentCompanyName));

                        selectedCompany.push({ companyCode: response.data.serviceProviders[0].parentCompanyCode });
                    }

                    this.setState({ company, selectedCompany });
                })
        } else {
            dispatch(change(form, "company0", ""));
            this.setState({ company, selectedCompany });
        }
    }

    getParentCompany($event) {
        let serviceProvider = $event.target.value, { company, selectedCompany } = this.state, { tagid } = $event.target.dataset, { dispatch } = this.props;

        if (serviceProvider) {
            getMachineInfo({ serviceProvider })
                .then(response => {
                    if (company[tagid]) {
                        /**set parent company options */
                        company[+tagid + 1] = { options: response.data.serviceProviders || [] };
                        company.splice(+tagid + 2, company.length);
                        /**set selected company value */
                        selectedCompany[+tagid + 1] = { companyCode: serviceProvider };
                        selectedCompany.splice(+tagid + 2, selectedCompany.length);

                        let defaultSelectValue = "";

                        if (response.data.serviceProviders && response.data.serviceProviders.length === 1) {
                            defaultSelectValue = response.data.serviceProviders[0].parentCompanyName;

                            if (defaultSelectValue) selectedCompany.push({ companyCode: response.data.serviceProviders[0].parentCompanyCode });
                        }

                        dispatch(change(form, ["company", +tagid + 1].join(""), defaultSelectValue))
                    }
                    this.setState({ company, selectedCompany });
                })
        } else {
            /** remove all feilds which are child of last selected parent */
            company.splice(+tagid + 1, company.length);
            selectedCompany.splice(+tagid + 1, selectedCompany.length);
            this.setState({ company });
        }
    }

    editOwnerDetails(values) {

        let { setLoader, setToastr, setConfirmBox } = this.props, { userId } = this.props.match.params, { newEquipment, user } = this.state, data;

        if (newEquipment) {
            /** Adding new equipment */
            const endDate = new Date(this.state.data.endDate);
            const localEndTime = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60 * 1000);
            
            data = {
                equipmentDetails: {
                    machineSaleId: values.machineSaleId,
                    machineNum: values.machineNum,
                    model: values.model,
                    numberPlate: values.numberPlate,
                    equipmentType: values.equipmentType,
                    tractorSaleDate: this.state.data.tractorSaleDate,
                    applicationForm: values.applicationForm,
                    applicateDate: this.state.data.applicationDate,
                    contractNumber: values.contractNum,
                    contractDate: this.state.data.startDate,
                    contractPeriod: localEndTime,
                    remark: values.remarks,
                },
                companyCodes: this.state.selectedCompany.reverse(),
            };

            /** register user here */
            setConfirmBox({ modal: { open: true, data, title: "Save Equipment", content: "Do you want to register these details?", done: this.addEquipment } });

        } else {
            setLoader({ loader: { open: true } });
            /** Updating Customer Details */

            data = {
                id: userId,
                firstName: values.firstName,
                lastName: values.lastName,
                address: values.address,
                city: values.city,
                zipCode: values.zipCode,
                stateCode: values.stateCode,
                phone: values.phone,
                alternatePhone: values.alternatePhone,
                email: values.email && values.email.toLowerCase(),
                emergencyCallFlag: (values.emergencyCallFlag === 'true') ? true : false,
                emergencyCallNumber: values.emergencyCallNumber,
                languageCode: values.languageCode,
                timezone: values.timezone,
                middleName: ""
            };

            updateOwner(data)
                .then(response => {
                    if (response.data && response.data !== 'false') {
                        setToastr({ toastr: { message: "Owner details have been updated.", type: "success" } });
                    } else {
                        setToastr({ toastr: { message: "Failed to add details. Please try again later.", type: "error" } });
                    }
                    /** close editing & loader*/
                    setLoader({ loader: { open: false }, user: this.updateUserState(data) });
                    this.toggleEdit(true);

                    user.emergencyCallFlag = data.emergencyCallFlag;

                    this.setState({ isEmergency: data.emergencyCallFlag, user });
                })
                .catch(error => {
                    setToastr({ toastr: { message: error || "Failed to add details. Please try again later.", type: "error" } });
                    setLoader({ loader: { open: false } });
                })

        }

    }

    updateUserState(updatedData) {
        let keys = Object.keys(updatedData), { user } = this.state;
        keys.forEach(key => user[key] = updatedData[key]);
        return user;
    }

    addEquipment(data) {
        let { setLoader, setToastr, getOwnerEquipments, reset, dispatch } = this.props, { userId } = this.props.match.params;

        /** show loader */
        setLoader({ loader: { open: true } });
        return new Promise((resolve, reject) => {
            addOwner(data, "?userId=" + userId)
                .then(response => {
                    setLoader({ loader: { open: false } });

                    this.setState({ newEquipment: false });

                    /* reset form  */
                    dispatch(reset(form));

                    /** load equipments */
                    getOwnerEquipments({ userId });

                    resolve({ toastr: { message: "New Equipment has been added successfully.", type: "success" } });
                })
                .catch(error => {
                    dispatch(reset(form));
                    setLoader({ loader: { open: false } });
                    setToastr({ toastr: { message: error || "This request couldn't be completed. Try again later.", type: "error" } });
                    reject(false);
                })
        });
    }

    resetForm(isEdit = true) {
        if (!isEdit) {
            this.props.history.goBack();
        } else {
            this.toggleEdit(true);
            this.props.initialize(this.state.user);

            if (this.state.user.emergencyCallFlag !== this.state.isEmergency) {
                this.setState({ isEmergency: !this.state.isEmergency });
            }
        }
    }


    sortBy(key) {
        let arrayCopy = [...this.props.equipments];
        arrayCopy.sort(sorting.compareByChar(key, this.state.order));
        this.setState({
            equipments: arrayCopy,
            orderField: key,
            order: !this.state.order
        });
    }
    sortByAlphaNumeric(key){
        let arrayCopy = [...this.props.equipments];
        arrayCopy.sort(sorting.compareAlpaNumeric(key, this.state.order));
        this.setState({
            equipments: arrayCopy,
            orderField: key,
            order: !this.state.order
        });
        

    }

    sortByNumber(key) {
        let arrayCopy = [...this.props.equipments];
        arrayCopy.sort(sorting.compareByNumber(key, this.state.order));
        this.setState({
            equipments: arrayCopy,
            orderField: key,
            order: !this.state.order
        });
    }
    /* method to send registration email*/
    resendRegistrationEmail(loginId) {

        this.props.setLoader({ loader: { open: true } });
        if (this.props.user && (!this.props.user.email || this.props.user.email == "") && !this.state.smsConfirmation) {
            this.setState({ confirmationMsg: "Email ID was not provided during registration.\n Do you want to resend the details by SMS?" })
            this.setState({ smsConfirmation: true })
            this.props.setLoader({ loader: { open: false } });
            return;
        } else {
            this.setState({ smsConfirmation: false })
        }
        this.confirmToggle();
        getSendEmail(loginId).then(result => {
            //this.props.setLoader({ loader: { open: false } });
            if (result.data) {
                this.props.setToastr({ toastr: { message: "Registration details sent successfully.", type: "success" } });
            } else {
                this.props.setToastr({ toastr: { message: "Failed to send registration details. Please try again later.", type: "error" } });
            }
            this.props.setLoader({ loader: { open: false } });
        })
    }

    handleResendClick=()=>{
        this.confirmToggle();
        this.setState({confirmToggle:true});

    }

    handleKeyDown =(event) =>{
        if(event.key ==='Enter'){
            event.preventDefault();
        }
    }


    render() {
        let { handleSubmit, language, states, timezone, loading, user, submitting } = this.props, { userId } = this.props.match.params, { isEdit, newEquipment, machineSaleId, providers, company, preview, isEmergency, order, orderField, equipments } = this.state;
        let sortType = (!this.state.order) ? <FaSortUp /> : <FaSortDown />;
        let {push} = this.props.history;

        return (
            <Fragment>

                <Row className='no-gutters align-items-center'>
                    <Col className='text-right'>
                        {/* <span className='btn btn-primary float-right' onClick={() => this.setState({ newEquipment: !newEquipment})}>{newEquipment ? 'Cancel' : 'Add New Equipment'}</span> */}
                         
                        {!newEquipment && <span className='btn btn-primary float-right' onClick={() => this.setState({ newEquipment: !newEquipment })}>{'Add New Equipment'}</span>}
                        {!newEquipment && <span className='btn btn-primary float-right' style={{marginRight:'5px'}} onClick={this.handleResendClick}>{'Resend Login Details'}</span>}
                    </Col>
                </Row>
                <div >
                    <Modal size='lg' isOpen={this.state.confirmModal} toggle={this.confirmToggle} className={this.props.className}>
                        <ModalHeader toggle={this.confirmToggle}>Confirmation</ModalHeader>
                        <ModalBody >
                            <p> {this.state.confirmationMsg}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={this.confirmToggle}>Cancel</Button>
                            <Button color="primary" onClick={() => this.resendRegistrationEmail(this.props.user.loginId)}>Yes</Button>

                        </ModalFooter>
                    </Modal>
                </div>

                {/* table data here */}
                <form onSubmit={handleSubmit(this.editOwnerDetails)} autoComplete='off' className='form-horizontal' id={form} onKeyDown={this.handleKeyDown}>

                    {!newEquipment && <section className='member-equipments'>
                        <span className='sub-heading'>Owner Equipments</span>

                        <table className="tabledashboard table table-striped table-advance table-hover m-top-30">
                            <thead>
                                <tr>
                                    <th onClick={() => this.sortBy('model')}>
                                        Model
                                        {orderField === 'model' ? sortType : <FaSort />}
                                    </th>
                                    <th onClick={() => this.sortByAlphaNumeric('machineNum')}>
                                        Machine Number
                                        {orderField === 'machineNum' ? sortType : <FaSort />}
                                    </th>

                                    <th onClick={() => this.sortBy('equipmentType')}>
                                        Equipment Type
                                        {orderField === 'type' ? sortType : <FaSort />}
                                    </th>
                                    <th onClick={() => this.sortByNumber('terminalId')}>
                                        SA-R-ID
                                        {orderField === 'terminalId' ? sortType : <FaSort />}
                                    </th>
                                    <th onClick={() => this.sortBy('company')}>
                                        Service Company
                                        {/* { orderField === 'company' ? sortType : <FaSort/> } */}
                                    </th>
                                    <th onClick={() => this.sortBy('provider')}>
                                        Service Provider
                                        {/* { orderField === 'provider' ? sortType : <FaSort/> } */}
                                    </th>
                                    <th onClick={() => this.sortBy('contractDate')}>
                                        Contract Date
                                        {/* { orderField === 'provider' ? sortType : <FaSort/> } */}
                                    </th>
                                    <th onClick={() => this.sortBy('contractExpiry')}>
                                        Contract Expiry
                                        {/* { orderField === 'provider' ? sortType : <FaSort/> } */}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {equipments.length > 0 && equipments.map((equipment, i) => <Tr push={push} equipment={equipment} key={i} userId={userId} machineSaleId = {equipment.machineSaleId}/>)}

                                {!loading && equipments.length === 0 && <tr><td colSpan='9' align='center'>No Equipment Found</td></tr>}
                            </tbody>
                        </table>
                    </section>}

                    {newEquipment && <section className='member-machine-info'>
                        <Row className='no-gutters'>
                            {/* <p className='section-headline float-left'><span className='highlighted'>Sales Equipment Information</span></p> */}

                            {/* Hidden fileds */}
                            <Field component={renderTextField} type='hidden' name='machineSaleId' id='machineSaleId' value={machineSaleId} />
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Model <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="model" id="model" component={renderTextField} className='form-control' type="text" validate={[required]} onBlur={this.getMachineInfo} />
                                </Col>
                            </Col>


                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    SA-R ID <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="terminalId" component={renderTextField} className='form-control ignore' type="text" disabled validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Machine Number <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="machineNum" id="machineNum" component={renderTextField} className='form-control' type="text" validate={[required, min1, max10]} onBlur={this.getMachineInfo} />
                                </Col>
                            </Col>
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Equipment Type <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="equipmentType" component={renderTextField} className='form-control ignore' type="text" disabled validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Number Plate {/* <span className='required'>*</span> */}
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="numberPlate" component={renderTextField} className='form-control' type="text" validate={[alphaNum_]} />
                                </Col>
                            </Col>
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Tractor Sale Date <span className='required'>*</span>
                                </Col>
                                <Col md='8' className='d-flex justify-content-start align-items-center float-left'>
                                    {/*<Field name="tractorSaleDate"  className='form-control' component={renderDatepicker} validate={[required,maxCurrentDate]}></Field></Col>*/}
                                    <div className="float-left mr-5 position-relative">
                                        <DatePicker
                                            selected={this.state.data.tractorSaleDate}
                                            maxDate={moment()}
                                            ref={(r) => {
                                                this.tractorsaledatecomponent = r;
                                            }}
                                            autoComplete='off'
                                            onChange={this.handleChange.bind(this, 'tractorSaleDate')}
                                            dateFormat="Do MMM YY"
                                            id="tractorSaleDate"
                                            className="form-control pointer-event"
                                        />
                                        <button className="calLogoContract" onClick={this.tractorSDate}><FaCalendarAlt /></button>
                                    </div>
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Service Provider <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="serviceProvider" id="serviceProvider" component={renderSelectField} validate={[required]} onChange={this.getServiceCompany}>
                                        <option value="">Select Provider</option>
                                        {providers && providers.map((provider, index) => <option key={index} value={provider.childCompanyCode}>{provider.childCompanyName}</option>)}
                                    </Field>
                                </Col>
                            </Col>

                            {company.map((field, index) => {
                                return (
                                    <Col key={index} md='6' sm='6' xs='12' className='machine-asset'>
                                        {field.options && field.options.length >= 1 && <Col md='4' className='float-left'>
                                            {index === 0 ? 'Service Company' : ''}  {!index && <span className='required'>*</span>}
                                        </Col>}

                                        {field.options && field.options.length >= 1 && <Col md='8' className='float-left'>
                                            {field.options && field.options.length > 1 ? <Field name={'company' + index} id={'company' + index} data-tagid={index} component={renderSelectField} validate={[required]} onChange={this.getParentCompany}>
                                                <option value="">Select Company</option>

                                                {field.options.map((option, i) => {
                                                    return <option key={i} value={option.parentCompanyCode}>{option.parentCompanyName}</option>;
                                                })}
                                            </Field> :
                                                <Fragment>
                                                    {field.options && field.options.length === 1
                                                        ? <Field name={"company" + index} id={"company" + index} component={renderTextField} className='form-control ignore' disabled type="text" validate={[required]} readOnly />
                                                        : null}
                                                </Fragment>
                                            }
                                        </Col>}
                                    </Col>
                                );
                            })}

                        </Row>

                    </section>}
                    {newEquipment && <section className='member-contract-info margin-up'>
                        <Row className='no-gutters'>
                            {<span className='sub-heading'>Contract Information</span>}


                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Application Date <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='d-flex justify-content-start align-items-center float-left'>
                                    <div className="float-left mr-5 position-relative">
                                        <DatePicker
                                            selected={this.state.data.applicationDate}
                                            maxDate={moment()}
                                            ref={(r) => {
                                                this.applicationDatecomponent = r;
                                            }}
                                            autoComplete='off'
                                            onChange={this.handleChange.bind(this, 'applicationDate')}
                                            dateFormat="Do MMM YY"
                                            id="applicationDate"
                                            className="form-control pointer-event"
                                        />
                                        <button className="calLogoContract" onClick={this.applicationDate} ><FaCalendarAlt /></button>
                                    </div>
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Contract Number <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="contractNum" id="contractNum" component={renderTextField} className='form-control' type="text" validate={[required]} onBlur={this.getMachineInfo} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Contract Period<span className='required'>*</span>
                                </Col>
                                {/*<div className='float-left col-md-8'>*/}
                                    {/*<Label className="inputLabel float-left">Contract Period</Label>*/}
                                    <Col md='8' className='d-flex justify-content-start align-items-center float-left'>
                                    <div className="float-left mr-4 position-relative" >
                                        <DatePicker
                                            selected={this.state.data.startDate}
                                            maxDate={moment()}
                                            ref={(r) => {
                                                this.startcomponent = r;
                                            }}
                                            autoComplete='off'
                                            onChange={this.handleChange.bind(this, 'startDate')}
                                            dateFormat="Do MMM YY"
                                            id="startDate"
                                            className="form-control pointer-event"
                                        />
                                        <button className="calLogoContract" onClick={this.startDate}><FaCalendarAlt /></button>
                                    </div>
                                    <div className="float-left mr-1 position-relative" style={{left: '6px'}}>
                                        <DatePicker
                                            selected={this.state.data.endDate}
                                            minDate={this.state.data.startDate}
                                            ref={(r) => {
                                                this.endcomponent = r;
                                            }}
                                            autoComplete='off'
                                            onChange={this.handleChange.bind(this, 'endDate')}
                                            dateFormat="Do MMM YY"
                                            id="endDate"
                                            className="form-control pointer-event"
                                        />
                                        <button className="calLogoContract" onClick={this.endDate}><FaCalendarAlt /></button>
                                    </div>
                                    </Col>
                                {/*</div>*/}




                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Application form
                                </Col>

                                <Col md='8' className='float-left'>

                                    <div className="float-left col-sm-8 px-0">
                                        <Field name="file" component={FileInput} className='form-control' type="file"/>
                                    </div>

                                    <div className="float-left col-sm-4">
                                        <button type="submit" className="btn btn-primary" disabled={submitting}>Upload</button>
                                    </div>

                                </Col>
                            </Col>
                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left' >
                                    Remarks
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="remarks" id="remarks" component={renderTextField} className='form-control' type="text" />
                                </Col>
                            </Col>

                        </Row>

                    </section>}


                    <section className='member-personal-info margin-up'>
                        <Row className='no-gutters'>
                            {<span className='sub-heading'>Owner Personal Information</span>}

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    User ID <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    {user && user.loginId ? user.loginId : ""}
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    First Name <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="firstName" component={renderTextField} className='form-control' type="text" validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Last Name <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="lastName" component={renderTextField} className='form-control' type="text" validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Address <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="address" component={renderTextField} className='form-control' type="text" validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Pin Code <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="zipCode" component={renderTextField} className='form-control' type="text" validate={[required, min6, max6, onlyNumbers]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    City <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="city" component={renderTextField} className='form-control' type="text" validate={[required]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    State <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="stateCode" component={renderSelectField} validate={[required]}>
                                        <option value="">Select State</option>
                                        {states && states.map(state => <option key={state.stateCode} value={state.stateCode}>{state.state}</option>)}
                                    </Field>
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Country <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="country" component={renderSelectField}>
                                        <option value="99">India</option>
                                    </Field>
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Mobile Number <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="phone" component={renderTextField} className='form-control' type="text" validate={[required, min10, max10, onlyNumbers]} />
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Alternate Mobile Number<span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="alternatePhone" component={renderTextField} className='form-control' type="text" validate={[required, min10, max10, onlyNumbers]} />
                                </Col>
                            </Col>


                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Email Address 
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="email" component={renderTextField} className='form-control' type="text" validate={[email]}/>
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Emergency Contact
                                </Col>

                                <Col md='8' className='d-flex justify-content-start align-items-center float-left'>
                                    <Field name="emergencyCallFlag" id='emergencyCallFalg_yes' component={renderCustomField} className='form-control custom-reset' type="radio" value={"true"} checked={isEmergency} onClick={() => this.setState({ isEmergency: true })} /> Yes &nbsp;

                                    <Field name="emergencyCallFlag" id='emergencyCallFalg_no' component={renderCustomField} className='form-control custom-reset' type="radio" value={"false"} checked={!isEmergency} onClick={() => this.setState({ isEmergency: false })} /> No
                                </Col>
                            </Col>

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Default Language <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="languageCode" component={renderSelectField} validate={[required]}>
                                        <option value="">Select Language</option>
                                        {language && language.map(lang => <option key={lang.languageCode} value={lang.languageCode}>{lang.language}</option>)}
                                    </Field>
                                </Col>
                            </Col>

                            {isEmergency && <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Emergency Contact No. <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="emergencyCallNumber" component={renderTextField} className='form-control' type="text" validate={[min10, max10, onlyNumbers, required]} />
                                </Col>
                            </Col>}

                            <Col md='6' sm='6' xs='12' className='machine-asset'>
                                <Col md='4' className='float-left'>
                                    Default Timezone <span className='required'>*</span>
                                </Col>

                                <Col md='8' className='float-left'>
                                    <Field name="timezone" component={renderSelectField} validate={[required]}>
                                        <option value="">Select Timezone</option>
                                        {timezone.map(tz => <option key={tz.timezone} value={tz.timezone}>{tz.timezone}</option>)}
                                    </Field>
                                </Col>
                            </Col>

                            {!newEquipment && <Col className='machine-button' md='12'>
                                <Col md='8' className='float-left'>
                                {isEdit && <button type="button" className="btn btn-danger ignore"  onClick={() => this.resetForm(isEdit)}>Cancel</button>}
                                {isEdit && <button type="submit" className="btn btn-success ignore" style={{marginLeft:'130px'}}>Update</button> }
                                </Col>
                                <Col md='8' className='float-left'>
                                    
                                    {!isEdit &&<button type="button" className="btn btn-primary ignore" onClick={this.editable.bind(this)}>Edit Details</button>}
                                </Col>
                            </Col>}

                            {newEquipment && <Col className='machine-button' md='12'>
                                <Col md='4' className='float-left'>
                                    {preview ? <button type="submit" className="btn btn-primary ignore">Save Equipment</button> : <button type="submit" className="btn btn-primary ignore">Preview</button>}
                                </Col>

                                <Col md='8' className='float-left'>
                                    <button type="button" className="btn btn-danger float-right ignore" onClick={() => this.setState({ newEquipment: false })}>Cancel</button>
                                </Col>
                            </Col>}

                        </Row>

                    </section>

                </form>
            </Fragment>

        );
    }
}


const Tr = ({ equipment,push,userId,machineSaleId}) => <tr onClick={() =>push(`/service/edit-owner-details/${userId}/${machineSaleId}/true`)}>
        <td>{equipment.model || " "}</td>
        <td>{equipment.machineNum || " "}</td>
        <td>{(equipment.equipmentType) ? equipment.equipmentType.toUpperCase() : " "}</td>
        <td>{equipment.terminalId || " "}</td>
        <td>{equipment.parentCompany || " "}</td>
        <td>{(equipment.companyNames && equipment.companyNames.length) ? equipment.companyNames[0].companyName : " "}</td>
        <td>{(equipment.contractDate)? <Moment format="DD-MMM-YYYY">{equipment.contractDate }</Moment>:""}</td>
        <td>{(equipment.contractPeriod)? <Moment format="DD-MMM-YYYY">{equipment.contractPeriod }</Moment>:""}</td>
    </tr>

const mapStateToProps = state => {
    return ({
        user: state.owner.user,
        equipments: state.owner.equipments
    });
}
const mapDispatchToProps = {
    setToastr, setLoader, setConfirmBox, getOwnerEquipments
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: form })(withRouter(EditOwner)));