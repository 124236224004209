import { takeLatest, call, put } from 'redux-saga/effects';
import { getProfile } from '../../api/profile';
import { GET_PROFILE, SET_PROFILE } from '../../types';


export function* getProfileCall(action) {
    try {
        const profile = yield call(getProfile, action);
        yield put({ type: SET_PROFILE, data: profile.data || undefined });
    }
    catch (e) {
        //action.error(e);
    }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchProfileData() {
    yield takeLatest(GET_PROFILE, getProfileCall)
}
