import React, { Component } from "react";
import ReactDOM from "react-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import { FaPencilAlt, FaTimesCircle, FaCheckCircle } from 'react-icons/fa';

import { updateOperationInfo } from "../../../../../api/dailywork";
import { setToastr, setLoader } from "../../../../../actions/Application";

/*Convert Minutes into Hours*/
const convertMinute = (time) => {
    if (time) {
        let m1 = time.split(":");
        let h = +m1[0], m = +m1[1], s = +m1[2];
        let d = h + (m / 60) + (s / 3600);
        return +(d.toFixed(2));
    }
}

class OperationalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false
        };

        /** function binding */
        this.editInfo = this.editInfo.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.saveInfo = this.saveInfo.bind(this);
        this.getMemberName = this.getMemberName.bind(this);
        this.checkOilQty = this.checkOilQty.bind(this);
        this.checkWorkingWidth = this.checkWorkingWidth.bind(this);
    }

    editInfo() {
        this.setState({ isEdit: true });
    }

    cancelEdit(i, row) {

        /** Reset fields if changed */
        ReactDOM.findDOMNode(document.getElementById("memo" + i)).value = row.memo;
        ReactDOM.findDOMNode(document.getElementById("oiling" + i)).value = row.oilQuantity;
        ReactDOM.findDOMNode(document.getElementById("workingwidth" + i)).value = row.workingWidth;
        ReactDOM.findDOMNode(document.getElementById("member" + i)).value = row.id;

        this.setState({ isEdit: false });
    }

    async saveInfo(i, row) {
        /** show toastr */
        let { setLoader, setToastr } = this.props;

        var isIE = /*@cc_on!@*/false || !!document.documentMode;


        if (!isIE) setLoader({ loader: { open: true } });

        /** Update operational info */
        let data = {
            ...row,
            memo: ReactDOM.findDOMNode(document.getElementById("memo" + i)).value,
            oilQuantity: ReactDOM.findDOMNode(document.getElementById("oiling" + i)).value,
            workingWidth: ReactDOM.findDOMNode(document.getElementById("workingwidth" + i)).value,
            id: ReactDOM.findDOMNode(document.getElementById("member" + i)).value
        };



        await updateOperationInfo(data).then(response => {
            let { i, updateOperatingInfo } = this.props;

            /** update store */
            if (updateOperatingInfo) updateOperatingInfo(i, data);

            /** show toastr message*/
            setToastr({ toastr: { message: "Operating Info has been updated.", type: "success" } });
            /** change edit state */
            this.setState({ isEdit: false });
            /** hide loader */
            if (!isIE) setLoader({ loader: { open: false } });
        })
            .catch(error => {
                /** show toastr message*/
                setToastr({ toastr: { message: "Operating Info can't be edited at this moment.", type: "error" } });
                /** hide loader */
                if (!isIE) setLoader({ loader: { open: false } });
            });

    }

    getMemberName(members, memberId) {
        let m = members.filter(member => member.id === memberId)[0];
        return m ? (m.firstName + " " + m.lastName) : " ";
    }

    checkOilQty(e) {
        if (e.target.value <= 0) e.target.value = Math.abs(e.target.value);
    }

    checkWorkingWidth(e) {
        if (e.target.value <= 0) e.target.value = Math.abs(e.target.value);
    }

    render() {
        let { members, i, row, group } = this.props;
        let { isEdit } = this.state;


     
        return (
            <tr>
                {/* S.No */}
                <td>{i + 1}</td>

                {/* Start Time */}
                <td>
                    {row.bootStartDate &&
                        <Moment format="HH:mm:ss">
                            {row.bootStartDate}
                        </Moment> || " "}
                    {/* {moment(row.bootStartDate).format("h:mm:ss A")} */}
                </td>

                {/* End time */}
                <td>
                    {row.bootStopDate &&
                        <Moment format="HH:mm:ss">
                            {row.bootStopDate}
                        </Moment> || " "}
                    {/* {moment(row.bootStopDate).format("h:mm:ss A")} */}
                </td>


                {/* Operating Hours */}
                <td>{(row && row.operatingHour) ? row.operatingHour : '0'}</td>
                {group == 'TE' && <React.Fragment>
                    {/* PTO running Time */}
                    <td>{(row && row.ptoHr) ? row.ptoHr.toFixed(4) : '0'}</td>

                    {/* Idleing Time */}
                    <td>{(row && row.idleHr) ? row.idleHr.toFixed(4) : '0'}</td>

                    {/* Running Time */}
                    <td>{(row && row.runningTime) ? (row.runningTime.toFixed(4)) : '0'}</td>

                    {/* HighSpeed (Hrs) */}
                    <td>{(row && row.highSpeed) ? (row.highSpeed.toFixed(4)) : '0'}</td>

                    {/* <td>{ (row && row.memo)  ? row.memo: ''}</td> */}
                    {/* <td>
                    <input 
                        type = 'text' 
                        name = 'memo' 
                        id = {'memo' + i} 
                        className = {(!isEdit ? 'no-edit ' : '') + 'form-control'} 
                        disabled = {!isEdit} 
                        defaultValue = {row.memo} />
                </td> */}

                    {/* Work Details (Memo) */}
                    <td>
                        {!isEdit ? row.memo :
                            <input
                                type='text'
                                name='memo'
                                id={'memo' + i}
                                className="form-control"
                                defaultValue={row.memo}
                            />}
                    </td>

                    {/* Oiling Qty(L) */}
                    {/* <td>{ (row && row.oilQuantity)  ? row.oilQuantity: '0'}</td> */}
                    <td>
                        {!isEdit ? row.oilQuantity :
                            <input
                                type='number'
                                step='0.1'
                                min='0'
                                name='oiling'
                                id={'oiling' + i}
                                className='form-control'
                                defaultValue={row.oilQuantity}
                                onChange={this.checkOilQty}
                            />}
                    </td>

                    {/* Working width(m) 
                {/* <td>{ (row && row.workingWidh)  ? row.workingWidh: 'N/A'}</td> */}
                    <td>
                        {!isEdit ? row.workingWidth :
                            <input
                                type='number'
                                step='0.1'
                                min='0'
                                name='workingwidth'
                                id={'workingwidth' + i}
                                className='form-control'
                                defaultValue={row.workingWidth}
                                onChange={this.checkWorkingWidth}
                            />}
                    </td>

                    {/* Acreage Report */}
                    <td>{(row && row.acreageReport) ? row.acreageReport : '0'}</td>

                    {/* Member */}
                    {/* <td>{ (row && row.member)  ? row.member: 'N/A'}</td> */}
                    <td>
                        {isEdit ? <select name='member' id={'member' + i} className="form-control no-padded" defaultValue={row.id} >
                            <option value=''>Select</option>
                            {members && members.map((member, i) => {
                                return <option key={i} value={member.id}>{member.firstName + " " + member.lastName}</option>;
                            })}
                        </select> : this.getMemberName(members, row.id)}

                    </td>

                    {/* Edit Button*/}
                    <td>
                        {!isEdit ? <FaPencilAlt onClick={this.editInfo} /> :
                            <React.Fragment>
                                <FaTimesCircle onClick={() => this.cancelEdit(i, { memo: row.memo, oilQuantity: row.oilQuantity, workingWidth: row.workingWidth, id: row.id, i })} />  &nbsp;

                                <FaCheckCircle onClick={() => this.saveInfo(i, { machineSaleId: row.machineSaleId.toString(), startStopId: row.startStopId })} />
                            </React.Fragment>}
                    </td>
                </React.Fragment>}
                {group =='CE' && <React.Fragment>
                    <td>{((row && row.hydraulicOilAvg)&&(row && (row.hydraulicOilAvg<0))) ? ' ' : row.hydraulicOilAvg.toFixed(4) }</td>
                    <td>{((row && row.coolingTemperatureAvg) && (row && (row.coolingTemperatureAvg<0)))? ' ' : row.coolingTemperatureAvg.toFixed(4) }</td></React.Fragment>
                }
                {group =='CH' &&
                    <td>{(row && row.waterTempAlarm) ? row.waterTempAlarm.toFixed(4) : '0'}</td>
                }
            </tr>
        );
    };
}


const mapDispatchToProps = { setToastr, setLoader };

const mapStateToProps = state => {
    return ({
        timezone: state.profile.timezone || "Asia/Kolkata"
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationalInfo);