import { takeLatest, call, put } from 'redux-saga/effects';
import { getMembers } from '../../api/members';
import { GET_FIELDINFORMATION_DATA, SET_MAP_DATA } from '../../types';


export function* getMembersCall(action) {
    try {
        const members = yield call(getMembers, action);
        console.log(members, "------------------ fi", action);
        yield put({ type: SET_MAP_DATA, data: action.data || [],  });
    }
    catch (e) {
        //action.error(e);
    }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchFIData() {
    yield takeLatest(GET_FIELDINFORMATION_DATA, getMembersCall)
}
