import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import App from '../App';
/*
 * In App.Js we have the routing for the application. 
 * You can see here that given a different path you will get a different target component served up. 
 * Ex. { LoginPage } serves up the components/LoginPage directory.
 */

export const SrvprovRouteContainer = ({ isSrvprovAuthenticated, component: Component, ...rest }) =>(
	<Route {...rest} render={ props => isSrvprovAuthenticated ? <div><App component={<Component { ...props } />} /></div> : <Redirect to="/service-provider" /> } />
 );

/** Required function and props with the proptypes */
/*UserRouteContainer.propTypes = {
  component: PropTypes.func.isRequired
}*/


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
	console.log("container",state.servprov.data);
	if (state.servprov && state.servprov.data && state.servprov.data.SET_SRVPROV_LOGGED_IN_TOKEN) {		
		return {
			isSrvprovAuthenticated: true,
			isExpired:false,
			ispending: false,
			mode: false
		}
	} else {
		return {
			isSrvprovAuthenticated: false,
			isExpired: false,
			ispending: false,
			mode: false
		}
	}
}

export default connect(mapStateToProps)(SrvprovRouteContainer);
