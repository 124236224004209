import React, { Component } from 'react';
import { connect } from 'react-redux';
import OwnersComponent from '../../components/admin/owners/OwnersComponent';

class OwnersContainer extends Component {

    /**
     * Dispatch the action to get the members data
     */
    componentDidMount() {
        //this.props.getMembers();
    }


    render() {
        let { members } = this.props;
        return <OwnersComponent members={members} />;
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
const mapStateToProps = state => {
    return { owners: [] };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnersContainer);