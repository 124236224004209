import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../../api/index';
import { GET_EQUIPMENTS_SUPPORT_DATA, SENDING_EQUIPMENTS_SUPPORT_DATA, SET_EQUIPMENTS_SUPPORT_DATA, 
  FETCH_DATA_FAIL } from '../../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* getEquipmentSupportData(action) {
  const data = action.data;
  try {
    yield put({
      type: SENDING_EQUIPMENTS_SUPPORT_DATA,
      data: '',
      resMsg: '',
      isSending: true,
      error: false
    })
    let response = {};
    if(data.isService){
      response = yield call(api.admin.equipmentData.supportInfo, data.data)
    }else{
      response = yield call(api.serviceProvider.equipment.supportInfo, data.data)
    }

    switch (response.statusCode) {
      case 200:
        yield put({
          type: SET_EQUIPMENTS_SUPPORT_DATA,
          data: response.data,
          resMsg: response.message,
          isSending: false,
          error: false
        })
        break;
      default:
        break;
    }
  } catch (e) {
    yield put({
      type: FETCH_DATA_FAIL,
      payload: {
        error: 'Error while connecting server'
      }
    })
    yield put({
        type: SET_EQUIPMENTS_SUPPORT_DATA,
        data: '',
        resMsg: e,
        isSending: false,
        error: true
    })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchGetEquipmentSupportData() {
  yield takeLatest(GET_EQUIPMENTS_SUPPORT_DATA, getEquipmentSupportData)
}

