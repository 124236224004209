import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocationInformationComponent from '../components/customer/location-information/LocationInformationComponent';
import { getLocationData } from '../actions/LocationInformation';

class LocationInformationContainer extends Component {

    constructor(props) {
        super(props);
        this.state={
            
        };
    }

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount(){
        let token = "";
        if(localStorage.getItem('TOKEN')){
            token = JSON.parse(localStorage.getItem('TOKEN'));
        }
        this.props.getLocationData({token:token.token});
    }

    render() {        
        return (
            <LocationInformationComponent />
        );
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    return {};
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getLocationData
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationInformationContainer);