import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, DropdownToggle, DropdownMenu, Input, Card, CardBody, CardText, Dropdown,DropdownItem,UncontrolledDropdown } from 'reactstrap';
import { FaChevronDown, FaFilter, FaSearch, FaTimes, FaArrowLeft,FaAngleDown } from "react-icons/fa";
import { getEquipmentList } from "../../actions/Equipment";
import { getOwners } from "../../actions/admin/Owner";
import { getSPEquipments } from "../../actions/service-provider/equipment";
import { getAllEquipments } from "../../api/service-provider/equipments";
import { listOwners } from "../../api/admin/owner";
import { getDC } from "../../actions/admin/dataControl";
import { setLoader,setToastr } from "../../actions/Application";
import Image from "../shared/Image";
import {translate} from "../../lang";
import defaultImg from "../../assets/images/noimage.png";

class Search extends Component{
    constructor(props){
        super(props);

        /** decalre component state */
        this.state = {
            dropdownOpen: false,
            searchItems: [],
            keyword: "",
            owners: "",
            speq: "",
            dcsrch:"",
            filterBy: [],
            searchItemsOwner: [],
            searchByMachineNum : false,
            searchByModel : false,
            searchByNumberPlate : false,
            searchByPhone : false,
            searchByOwnerId : false,
            searchByOwnerName : false

        };

        /** function binding */
        this.getEquipments = this.getEquipments.bind(this);
        this.goToItem = this.goToItem.bind(this);
        this.setFilterBy = this.setFilterBy.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.searchEquipments = this.searchEquipments.bind(this);
        this.resetSearchEquipments = this.resetSearchEquipments.bind(this);
        this.searchDC = this.searchDC.bind(this);
        this.resetSearchDC = this.resetSearchDC.bind(this);
        this.getOwnersList = this.getOwnersList.bind(this);
        this.goToItemOwner = this.goToItemOwner.bind(this);  
        this.getEquipmentsCustomer = this.getEquipmentsCustomer.bind(this);
    }

    componentDidMount(){
        if(this.props.customer) this.props.getEquipmentList();
       // if(this.props.sp)    this.props.getSPEquipments({page: 1,machineNum:true,model:true,numberPlate:true});
    }

    componentWillReceiveProps(newProps){
        if(newProps.customer || (newProps.sp && newProps.location.pathname === '/service/equipment-operating-info')){
            this.setState({ keyword: ""});
        /** empty search field */
            if (document.getElementById("keyword")) {
                ReactDOM.findDOMNode(document.getElementById("keyword")).value = '';
            }
        }
    }

    getEquipmentsCustomer(){
        
        let { value } = ReactDOM.findDOMNode(document.getElementById("keyword")), { equipments,spEquipments } = this.props, {filterBy} = this.state, regex = new RegExp(value.trim(), 'i');

      if(value && value.length < 3) return false;
      if(this.props.sp){
        equipments = spEquipments
      } 

        let filtered = equipments.filter(e => {

            if(filterBy.includes("all")){
                if(regex.test(e.machineNum.toString()) || (e.numberPlate && regex.test(e.numberPlate.toString()))|| regex.test(e.model.toString())){
                    return e;
                }
            }else if(filterBy.includes("machineNum") && filterBy.includes("numberPlate")){
                if(regex.test(e.machineNum.toString()) || (e.numberPlate && regex.test(e.numberPlate.toString()))){
                    return e;
                }
            }else if(filterBy.includes("machineNum") && filterBy.includes("model")){
                if(regex.test(e.machineNum.toString()) || regex.test(e.model.toString())){
                    return e;
                }
            }else if(filterBy.includes("model") && filterBy.includes("numberPlate")){
                if(regex.test(e.model.toString()) || (e.numberPlate && regex.test(e.numberPlate.toString()))){
                    return e;
                }
            }else if(filterBy.includes("numberPlate")){
                if(e.numberPlate && regex.test(e.numberPlate.toString())){
                    return e;
                }
            }else if(filterBy.includes("machineNum")){
                if(regex.test(e.machineNum.toString())){
                    return e;
                }
            }else if(filterBy.includes("model")){
                if(regex.test(e.model.toString())){
                    return e;
                }
            }else{
                e.numberPlate = e.numberPlate? e.numberPlate: '';
                if(regex.test(e.machineNum.toString()) || regex.test(e.numberPlate.toString()) || regex.test(e.model.toString())){
                    return e;
                }
            }
        });
        this.setState({ searchItems: filtered.slice(0, 5), keyword: value });
    }

    getEquipments() {
        let { value } = ReactDOM.findDOMNode(document.getElementById("keyword")), { filterBy } = this.state, regex = new RegExp(value.replaceAll("\\","").trim(), 'i');
        let equipments = [];
        if (value && value.length < 3) return false;
        let { searchByMachineNum, searchByModel, searchByNumberPlate } = this.state;
        if(!searchByMachineNum && !searchByModel && !searchByNumberPlate){
            searchByMachineNum=true;
            searchByModel = true;
            searchByNumberPlate = true;
        }
        /*
		CHG-00020710(INC-01093637)
		search functionality not working in pagination (on prod too)-OperatingInfoPage
		*/
        getAllEquipments({ page: 1, machineNum: searchByMachineNum, model: searchByModel, numberPlate: searchByNumberPlate, qs: `searchField=${value}` })
            .then(result => {
                if (result.data) {
                    equipments = result.data.ownerEquipmentDetailList;
                    let filtered = equipments.filter(e => {

                        if (filterBy.includes("all")) {
                            if (regex.test(e.machineNum.toString()) || (e.numberPlate && regex.test(e.numberPlate.toString())) || regex.test(e.model.toString())) {
                                return e;
                            }
                        } else if (filterBy.includes("machineNum") && filterBy.includes("numberPlate")) {
                            if (regex.test(e.machineNum.toString()) || (e.numberPlate && regex.test(e.numberPlate.toString()))) {
                                return e;
                            }
                        } else if (filterBy.includes("machineNum") && filterBy.includes("model")) {
                            if (regex.test(e.machineNum.toString()) || regex.test(e.model.toString())) {
                                return e;
                            }
                        } else if (filterBy.includes("model") && filterBy.includes("numberPlate")) {
                            if (regex.test(e.model.toString()) || (e.numberPlate && regex.test(e.numberPlate.toString()))) {
                                return e;
                            }
                        } else if (filterBy.includes("numberPlate")) {
                            if (e.numberPlate && regex.test(e.numberPlate.toString())) {
                                return e;
                            }
                        } else if (filterBy.includes("machineNum")) {
                            if (regex.test(e.machineNum.toString())) {
                                return e;
                            }
                        } else if (filterBy.includes("model")) {
                            if (regex.test(e.model.toString())) {
                                return e;
                            }
                        } else {
                            e.numberPlate = e.numberPlate ? e.numberPlate : '';
                            if (regex.test(e.machineNum.toString()) || regex.test(e.numberPlate.toString()) || regex.test(e.model.toString())) {
                                return e;
                            }
                        }
                    });
                    this.setState({ searchItems: filtered, keyword: value });
                }
                else {
                    this.props.setToastr({ toastr: { message: "Something went wrong", type: "error" } });
                    
                }
            })

            .catch(error => {
                this.props.setToastr({ toastr: { message: error, type: "error" } });
            })
    }

    getOwnersList() {
        let { value } = ReactDOM.findDOMNode(document.getElementById("keyword")), { filterBy } = this.state, regex = new RegExp(value.trim(), 'i');
        if (value && value.length < 3) return false;
        let { searchByPhone, searchByOwnerId, searchByOwnerName } = this.state;
        let owners = [];
        if (!searchByPhone && !searchByOwnerId && !searchByOwnerName) {
            searchByPhone = true;
            searchByOwnerId = true;
            searchByOwnerName = true;
        }
        /*
		CHG-00020710(INC-01093638)
		search functionality not working in pagination (on prod too)-OwnerManagement
		*/ 
        listOwners({ page: 1, phone: searchByPhone, ownerId: searchByOwnerId, ownerName: searchByOwnerName, qs: `searchField=${value}` })
            .then(result => {
                if (result.data) {
                    owners = result.data.ownerDetailList;
                    let filtered = owners.filter(e => {

                        if (filterBy.includes("all")) {
                            if (regex.test(e.owner.toString()) || (regex.test(e.phone.toString())) || regex.test(e.loginId.toString())) {
                                return e;
                            }
                        } else if (filterBy.includes("ownerName") && filterBy.includes("phone")) {
                            if (regex.test(e.owner.toString()) || (regex.test(e.phone.toString()))) {
                                return e;
                            }
                        } else if (filterBy.includes("ownerName") && filterBy.includes("ownerId")) {
                            if (regex.test(e.owner.toString()) || regex.test(e.loginId.toString())) {
                                return e;
                            }
                        } else if (filterBy.includes("phone") && filterBy.includes("ownerId")) {
                            if (regex.test(e.phone.toString()) || (regex.test(e.loginId.toString()))) {
                                return e;
                            }
                        } else if (filterBy.includes("ownerName")) {
                            if (regex.test(e.owner.toString())) {
                                return e;
                            }
                        } else if (filterBy.includes("phone")) {
                            if (regex.test(e.phone.toString())) {
                                return e;
                            }
                        } else if (filterBy.includes("ownerId")) {
                            if (regex.test(e.loginId.toString())) {
                                return e;
                            }
                        } else {
                            if (regex.test(e.owner.toString()) || regex.test(e.phone.toString()) || regex.test(e.loginId.toString())) {
                                return e;
                            }
                        }
                    });
                    this.setState({ searchItemsOwner: filtered, keyword: value });
                }
                else {
                    this.props.setToastr({ toastr: { message: "Something went wrong", type: "error" } });

                }
            })

            .catch(error => {
                this.props.setToastr({ toastr: { message: error, type: "error" } });
            })

        /*let filtered = owners.filter(e => {

            if (filterBy.includes("all")) {
                if (regex.test(e.owner.toString()) || (regex.test(e.phone.toString())) || regex.test(e.loginId.toString())) {
                    return e;
                }
            } else if (filterBy.includes("ownerName") && filterBy.includes("phone")) {
                if (regex.test(e.owner.toString()) || (regex.test(e.phone.toString()))) {
                    return e;
                }
            } else if (filterBy.includes("ownerName") && filterBy.includes("ownerId")) {
                if (regex.test(e.owner.toString()) || regex.test(e.loginId.toString())) {
                    return e;
                }
            } else if (filterBy.includes("phone") && filterBy.includes("ownerId")) {
                if (regex.test(e.phone.toString()) || (regex.test(e.loginId.toString()))) {
                    return e;
                }
            } else if (filterBy.includes("ownerName")) {
                if (regex.test(e.owner.toString())) {
                    return e;
                }
            } else if (filterBy.includes("phone")) {
                if (regex.test(e.phone.toString())) {
                    return e;
                }
            } else if (filterBy.includes("ownerId")) {
                if (regex.test(e.loginId.toString())) {
                    return e;
                }
            } else {
                if (regex.test(e.owner.toString()) || regex.test(e.phone.toString()) || regex.test(e.loginId.toString())) {
                    return e;
                }
            }
        });
        this.setState({ searchItemsOwner: filtered, keyword: value });*/
    }

    goToItem(machineSaleId, id){
        this.setState({ keyword: ""});
        /** empty search field */
        ReactDOM.findDOMNode(document.getElementById("keyword")).value = '';
        /** redirect to detail page */
       this.props.customer && this.props.history.push("/customer/equipment/equipmentDetail/" + machineSaleId);
       this.props.sp &&  this.props.history.push("/service/equipment-detail/"+id+"/"+machineSaleId);
    }

    goToItemOwner(id){
        this.setState({ keyword: ""});
        /** empty search field */
        ReactDOM.findDOMNode(document.getElementById("keyword")).value = '';
        /** redirect to detail page */
       this.props.history.push("/service/owners/detail/"+id);

    }

    setFilterBy($e){
        let {filterBy} = this.state;

        /** remove {all} value from array */
        if(filterBy.includes("all")) filterBy.splice(filterBy.indexOf("all"), 1);

        if($e.target.checked){
            /** insert into filter array */
            if(!filterBy.includes($e.target.value)) filterBy.push($e.target.value);
        }else{
            filterBy.splice(filterBy.indexOf($e.target.value), 1);
        }

        if(!filterBy.includes("all") && filterBy.length === 3) {
            if(!filterBy.includes("all")) filterBy.push("all");
            document.getElementById("all").checked = true;
        }else{
            document.getElementById("all").checked = false;
        }
        this.setState({ filterBy });
        let searchByMachineNum = false;
        let searchByModel = false;
        let searchByNumberPlate = false;
        if(filterBy.length == 0){
            searchByMachineNum = true;
            searchByModel = true;
            searchByNumberPlate = true;
        }
        if(filterBy.includes("numberPlate")){
            searchByNumberPlate = true;
        }else{
            searchByNumberPlate = false;
        }

        if(filterBy.includes("machineNum")){
            searchByMachineNum = true;
        }else{
            searchByMachineNum = false;
        }

        if(filterBy.includes("model")){
            searchByModel = true;
        }else{
            searchByModel = false;
        }
        this.setState({searchByMachineNum : searchByMachineNum, searchByModel:searchByModel ,searchByNumberPlate:searchByNumberPlate})

        let searchByPhone = false;
        let searchByOwnerId = false;
        let searchByOwnerName = false;
        if(filterBy.length == 0){
            searchByPhone = true;
            searchByOwnerId = true;
            searchByOwnerName = true;
        }
        if(filterBy.includes("ownerName")){
            searchByOwnerName = true;
        }else{
            searchByOwnerName = false;
        }

        if(filterBy.includes("phone")){
            searchByPhone = true;
        }else{
            searchByPhone = false;
        }

        if(filterBy.includes("ownerId")){
            searchByOwnerId = true;
        }else{
            searchByOwnerId = false;
        }
        this.setState({searchByPhone : searchByPhone, searchByOwnerId:searchByOwnerId ,searchByOwnerName:searchByOwnerName})
    
    
    }

    selectAll($e){

        let options = document.getElementsByClassName("filtererBy"), {filterBy} = this.state;

        for(let i=0; i<options.length; i++){
            options[i].checked = $e.target.checked;

            if($e.target.checked){
                if(!filterBy.includes(options[i].value)) filterBy.push(options[i].value);
                this.setState({searchByMachineNum : true, searchByModel:true ,searchByNumberPlate:true, searchByOwnerId:true,searchByOwnerName:true,searchByPhone:true})
            }else{
                filterBy.splice(filterBy.indexOf(options[i].value), 1);
                this.setState({searchByMachineNum : false, searchByModel:false ,searchByNumberPlate:false, searchByOwnerId:false,searchByOwnerName:false,searchByPhone:false})
            }
        }

        if(!filterBy.includes("all")) filterBy.push("all");
        this.setState({filterBy});
    }

    searchEquipments(){
        let { value } = document.getElementById("keyword");
        if(value !== ''){
            this.props.setLoader({ loader: {open:true}});
            
            /* when we have value */
            this.setState({ speq: value });
           // this.props.getSPEquipments({page: 1,machineNum:true, data:{searchField: value}});
        }
    }

    resetSearchEquipments(){
        this.setState({ speq: undefined });
        this.props.setLoader({ loader: {open:true}});


        document.getElementById("keyword").value = '';
        /* when we have value */
       
    }

    searchDC(){
        let { value } = document.getElementById("keyword");
        if(value !== ''){
            this.props.setLoader({ loader: {open:true}});

            /* when we have value */
            this.setState({ dcsrch: value });
            this.props.getDC({page: 1, data:{searchField: value}});
        }
    }

    resetSearchDC(){
        this.setState({ dcsrch: undefined });
        this.props.setLoader({ loader: {open:true}});


        document.getElementById("keyword").value = '';
        /* when we have value */
        this.props.getDC({page: 1});
    }

    render(){
        let {searchItems, keyword, filterBy,owners, speq, dcsrch,searchItemsOwner} = this.state, {service, location, customer, sp} = this.props; 
        return(
        
                <Col sm='4' className='search-area'>
                    {location.pathname !== '/service/dashboard' && <span className='float-right btn btn-danger btn-xs backbtn' onClick={() => this.props.history.goBack()}> <FaArrowLeft className='backicon'/> {translate('back')}</span>}
                    {((sp && location.pathname === ('/service/equipment-operating-info'))) && <Input type='text' id='keyword' placeholder={translate('searchEquipments')} className='form-control search-bar' onChange={this.getEquipments} /> }
                    {customer && location.pathname === ('/customer/equipment') && <Input type='text' id='keyword' placeholder={translate('searchEquipments')} className='form-control search-bar' onChange={this.getEquipmentsCustomer} /> }
                    {service && location.pathname === '/service/owners' && <div className='member-options carat-position'>
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })} >
                            {!owners && <DropdownToggle>
                                <FaChevronDown/>
                            </DropdownToggle> }
                            <DropdownMenu>
                                <div className='custom-link'>
                                    <ul className="list-group list-group-flush flexy-width">
                                        <li className='list-group-item'><FaFilter /> <span className='highlighted'>{translate('filters')}</span></li>

                                        <li className='list-group-item'>
                                            <input type='checkbox' name='filterBy' onClick={this.selectAll} value='all' id='all' defaultChecked={filterBy.includes("all")}/> <span className='highlighted'>{translate('all')}</span>
                                        </li>

                                        <li className='list-group-item'>
                                            <input type='checkbox' name='filterBy' className='filtererBy' onChange={this.setFilterBy} value='ownerName' defaultChecked={filterBy.includes("ownerName")}/> <span className='highlighted'>Owner Name</span>
                                        </li>

                                        <li className='list-group-item'>
                                            <input type='checkbox' name='filterBy' className='filtererBy' onChange={this.setFilterBy} value='phone' defaultChecked={filterBy.includes("phone")}/> <span className='highlighted'>Mobile Number</span>
                                        </li>

                                        <li className='list-group-item'>
                                            <input type='checkbox' name='filterBy' className='filtererBy' onChange={this.setFilterBy} value='ownerId' defaultChecked={filterBy.includes("ownerId")}/> <span className='highlighted'>User Id</span>
                                        </li>
                                    </ul>
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                    </div>}
                    {service && location.pathname === '/service/owners' && 
                        <Fragment>
                            <Input data-name={0} type='text' id='keyword' placeholder={"Search Owner"} className='form-control search-bar' onChange={this.getOwnersList} autoComplete='off' style={{width:'60%', marginLeft:'auto'}}/>
                        </Fragment>
                    }

                    {service && location.pathname === '/service/data-control' && 
                        <Fragment>
                            <Input type='text' id='keyword' placeholder="Search Data By Company Name" className='form-control search-bar' autoComplete='off' style={{width: "96%"}} />

                            <button onClick={this.searchDC} data-name={0} className="common-srch-btn float-left"><FaSearch size='1.4em'/></button>
                        
                            {dcsrch && <button onClick={this.resetSearchDC} data-name={0} className="common-x-btn float-left"><FaTimes size='1em'/></button>}
                        </Fragment>
                    }

                  
                    {(customer && location.pathname === ('/customer/equipment') || (sp && location.pathname === '/service/equipment-operating-info')) && <div className='member-options carat-position'>
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })} >
                            <DropdownToggle>
                                <FaChevronDown />
                            </DropdownToggle> 
                            <DropdownMenu>
                                <div className='custom-link'>
                                    <ul className="list-group list-group-flush flexy-width">
                                        <li className='list-group-item'><FaFilter /> <span className='highlighted'>{translate('filters')}</span></li>

                                        <li className='list-group-item'>
                                            <input type='checkbox' name='filterBy' onClick={this.selectAll} value='all' id='all' defaultChecked={filterBy.includes("all")}/> <span className='highlighted'>{translate('all')}</span>
                                        </li>

                                        <li className='list-group-item'>
                                            <input type='checkbox' name='filterBy' className='filtererBy' onChange={this.setFilterBy} value='numberPlate'  defaultChecked={filterBy.includes("numberPlate")} /> <span className='highlighted'>{translate('numberPlate')}</span>
                                        </li>

                                        <li className='list-group-item'>
                                            <input type='checkbox' name='filterBy' className='filtererBy' onChange={this.setFilterBy} value='machineNum'  defaultChecked={filterBy.includes("machineNum")} /> <span className='highlighted'>{translate('machineNum')}</span>
                                        </li>

                                        <li className='list-group-item'>
                                            <input type='checkbox' name='filterBy' className='filtererBy' onChange={this.setFilterBy} value='model'  defaultChecked={filterBy.includes("model")} /> <span className='highlighted'>{translate('model')}</span>
                                        </li>
                                    </ul>
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                    </div>}


                    {keyword && searchItems && searchItems.length > 0 && <div>
                        <Card className='search-card' >
                            <CardBody style={{overflowY:"auto" , maxHeight:"400px"}}>
                            <ul className="list-group list-group-flush">
                                {searchItems.map((item, i) => {
                                    return <Item key={i} attr={item} goToItem={this.goToItem} />;
                                })}
                            </ul>
                            </CardBody>
                        </Card>
                    </div>}

                    {keyword && searchItems && searchItems.length === 0 && <div>
                        <Card className='search-card'>
                            <CardBody>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item pointer"><CardText>{translate('noEquipmentFound')}</CardText></li>
                                </ul>
                            </CardBody>
                        </Card>
                    </div>}

                    {/** card for owner search*/ }
                    {keyword && searchItemsOwner && searchItemsOwner.length > 0 && <div>
                        <Card className='search-card'>
                            <CardBody style={{overflowY:"auto" , maxHeight:"400px"}}>
                            <ul className="list-group list-group-flush">
                                {searchItemsOwner.map((item, i) => {
                                    return <ItemOwner key={i} attr={item} goToItemOwner={this.goToItemOwner} />;
                                })}
                            </ul>
                            </CardBody>
                        </Card>
                    </div>}

                    {keyword && service && location.pathname === '/service/owners' && searchItemsOwner && searchItemsOwner.length === 0 && <div>
                        <Card className='search-card'>
                            <CardBody>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item pointer"><CardText>No owner found</CardText></li>
                                </ul>
                            </CardBody>
                        </Card>
                    </div>}
                </Col>
        );
    }
}

const Item = props => {
    let {attr, goToItem} = props;
    let containsAgriEquip = attr.equipmentType == 'tractor' || attr.equipmentType == 'GMS';
    return(
        <li className="list-group-item pointer" onClick={() => goToItem(attr.machineSaleId, attr.id)}>
            <Row>
                <Col sm='2'>
                    <Image className='rounded-circle searched-img' src={attr.logo || defaultImg} />
                </Col>
                <Col sm='10' className='no-padd-lr'>
                    {containsAgriEquip &&
                        <div>
                            <span className='highlighted'>Number Plate</span> <span>{attr.numberPlate && attr.numberPlate.toUpperCase()}</span>
                        </div>
                    } 
                    <span className='highlighted'>Machine No.</span> <span>{attr.machineNum}</span> &nbsp;
                    <span className='highlighted'>Model</span> <span>{attr.model}</span>
                </Col>
            </Row>
        </li>
    );
}

const ItemOwner = props => {
    let {attr, goToItemOwner} = props;
    return(
        <li className="list-group-item pointer" onClick={() => goToItemOwner(attr.id)}>
            <Row>
                <Col sm='10' className='no-padd-lr'>
                    <span className='highlighted'>Owner Name</span> <span>{attr.owner}</span> &nbsp;
                    <span className='highlighted'>Mobile Number</span> <span>{attr.phone}</span> &nbsp;
                    <span className='highlighted'>User Id</span> <span>{attr.loginId}</span>
                </Col>
            </Row>
        </li>
    );
}

const mapStateToProps = state => {
    return ({
        equipments: (state.equipment.data && state.equipment.data.SET_EQUIPMENT_DATA) ? state.equipment.data.SET_EQUIPMENT_DATA : [],
        orderBy: state.owner.orderBy,
        order: state.owner.order,
        spEquipments: (state.sp.equipments)?state.sp.equipments : [],
        owners:(state.owner && state.owner.owners) ? state.owner.owners:[]
    });
}

const mapDispatchToProps = { getEquipmentList, getOwners, getSPEquipments, getDC, setLoader,setToastr };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));