import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import { getEquipmentServiceHistory } from "../../../actions/ServiceHistory";


/** Importing Sub Components */
import SearchEquipmentComponent from "../daily-work/elements/SearchEquipmentComponent";
import EquipmentServiceDetail from "./EquipmentServiceDetail";
import ServiceHistoryDetail from "./ServiceHistoryDetail";

/** importings actions */

import { setToastr } from "../../../actions/Application";

/** Importing Sub Components */


class ServiceHistoryComponent extends Component {

    constructor(props) {
        super(props);

        /** Setting State */
        this.state = {
            selectedEquipment: {},
        };
        /** Function Binding */
        this.getSelectedEquipmentAttr = this.getSelectedEquipmentAttr.bind(this);
        this.getEquipmentServiceHistory = this.getEquipmentServiceHistory.bind(this);
    }
    async getSelectedEquipmentAttr(selectedEquipment = undefined) {
        if (selectedEquipment) {
            await this.setState({
                selectedEquipment: this.getSelectedEquipmentDetails(selectedEquipment),
            });


            this.getEquipmentServiceHistory();
        }
    }
    async getEquipmentServiceHistory(page = 1) {
        console.log("getEquipmentServiceHistory page ",page);
        console.log('getEquipmentServiceHistory');
        let { selectedEquipment } = this.state;
        if (!selectedEquipment || !selectedEquipment.machineSaleId) return false;
        try {
            this.props.getEquipmentServiceHistory({selectedEquipment,page});
        } catch (error) {
            this.props.setToastr({ toastr: { message: "This operation can't be performed. Please try again later.", type: "error" } });
        }
    }

    getSelectedEquipmentDetails(selectedEquipment) {
        /** if no selection, don't allow */
        if (!selectedEquipment.value) return false;

        let { equipments } = this.props;

        /** search equipment from list based on model and plate */
        return equipments.filter(equipment => {
            return selectedEquipment.searchBy === 'model' ? (equipment[selectedEquipment.searchBy] === selectedEquipment.value.split("/")[0] && equipment["machineNum"] === selectedEquipment.value.split("/")[1]) : (equipment[selectedEquipment.searchBy] === selectedEquipment.value);
        })[0] || undefined;
    }
    componentWillUnmount() {
        this.setState({ selectedEquipment: undefined });
    }
    render() {
        let { equipments, translate } = this.props;
        let { selectedEquipment } = this.state;

        return (
            <Fragment>

                {/* Search Equipment Form Component */}
                <SearchEquipmentComponent translate={translate} equipments={equipments} onEquipmentSelect={this.getSelectedEquipmentAttr} />

                {/* Equipment Component */}
                <EquipmentServiceDetail translate={translate} equipment={selectedEquipment} />
                {/*  Service History Detail */}

                {(Object.keys(selectedEquipment).length > 0) && <ServiceHistoryDetail translate={translate} selectedEquipment={selectedEquipment} getEquipmentServiceHistory={this.getEquipmentServiceHistory } />}
            </Fragment>
        );
    }
}




const mapDispatchToProps = {
    getEquipmentServiceHistory
}

export default connect(null, mapDispatchToProps)(ServiceHistoryComponent);