import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactDOM from 'react-dom';
import Moment from "react-moment";
import { connect } from 'react-redux';
import moment from "moment";
import { Link, withRouter } from 'react-router-dom';
import MapBox from '../../../components/shared/MapBox';
import { MapKey } from '../../../constants/Constants'
import ButtonField from '../../../components/shared/ButtonField';
import InputField from '../../../components/shared/InputField';
import FuelIndicator from '../../../components/shared/FuelIndicator';
import Alertsettings2 from '../../../assets/images/menu/Alertsettings2.svg';
import Location from '../../../assets/images/menu/small_unitility_icons/Location.svg';
import locationPointer from '../../../assets/images/my-equipment-page-icons/location-pointer.png';
import maplocationPointer from '../../../assets/images/my-equipment-page-icons/tractorlocation.png';
import { FaClock, FaPencilAlt, FaExpand } from 'react-icons/fa';
import Loaders from '../../../messages/loaders/Loaders';
import warning from '../../../assets/images/my-equipment-page-icons/warning.png';
import bellIcon from '../../../assets/images/my-equipment-page-icons/bell-black.png';
import noimage from '../../../assets/images/noimage.png';
import { Alert } from 'reactstrap';
import { getInstantReport } from '../../../actions/Equipment';

class EquipmentsDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            equipmentData: [],
            showCtrl: false,
            clickedImageEdit: false,
            data: {
                machineSaleId: '',
                logo: '',
                numberPlate: ''
            },
            notify: false,
            notifyType: 'success',
            notifyText: 'Hey! Its Equipemnt Page',
            updated: false,
            isMarkerShown: false,
            eqpImage: '',
            showLoader: true,
            modal: false,
            inputerror: false,
            country: '',
        };
        this.onDismiss = this.onDismiss.bind(this);
        this.fileUpload = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        this.setState({ showLoader: true });
    }

    /**
    * Get the new props and set into state if they are not same.
    * @param  { Object } nextProps Contains the next props
    */
    componentWillReceiveProps(nextProps) {
        //if (this.props !== nextProps) {
        const { updateSuccess } = nextProps;
        if ((updateSuccess && updateSuccess.data === true) && this.state.updated === true) {
            this.setState({ notify: true, notifyType: 'success', notifyText: 'Details Updated!!' })
            this.setState({ updated: false });
            this.setState({ showLoader: nextProps.integrationData.isSending });
            this.props.refresh(nextProps.integrationData.SET_EQUIPMENT_SINGLE_DATA[0].machineSaleId);
        }
        if ((updateSuccess && updateSuccess.data !== true) && this.state.updated === true) {
            this.props.setToastr({ toastr: { message: updateSuccess, type: "error" } });
            this.setState({ updated: false });
            this.setState({ showLoader: false });
            //  this.props.refresh(nextProps.integrationData.SET_EQUIPMENT_SINGLE_DATA[0].machineSaleId);
        }

        if (nextProps.integrationData.SET_EQUIPMENT_SINGLE_DATA) {
            if (nextProps.integrationData.SET_EQUIPMENT_SINGLE_DATA.length > 0) {
                this.setState({ showLoader: nextProps.integrationData.isSending });
                let logo;
                if (this.state.data.logo !== "") {
                    logo = `data:image/jpeg;base64,${this.state.data.logo}`
                }
                console.log("this.state.data.numberPlate",this.state.data.numberPlate)
                this.setState({
                    data: {
                        ...this.state.data,
                        machineSaleId: nextProps.integrationData.SET_EQUIPMENT_SINGLE_DATA[0].machineSaleId,
                        numberPlate: nextProps.integrationData.SET_EQUIPMENT_SINGLE_DATA[0].numberPlate 
                    },
                    equipmentData: nextProps.integrationData.SET_EQUIPMENT_SINGLE_DATA[0],
                    eqpImage: logo || nextProps.integrationData.SET_EQUIPMENT_SINGLE_DATA[0].logo + '?time=' + new Date().getTime()
                });
                setTimeout(() => {
                    document.getElementsByClassName('header-text')[0].textContent = `Equipment`
                }, 200);
                if (nextProps.app.state) {
                    let country, stateId;
                    if (nextProps.app.state.length > 0) {
                        stateId = nextProps.app.state.filter((val) => {
                            return (val.state === nextProps.integrationData.SET_EQUIPMENT_SINGLE_DATA[0].state)
                        })
                    }
                    if (stateId.length > 0) {
                        country = nextProps.app.country.filter((value) => {
                            return (value.countryCode === stateId[0].countryCode)
                        })
                    }
                    if (country.length > 0) {
                        this.setState({ country: `/ ${country[0].countryName}` })
                    }
                }
            } else {
                this.props.history.push('/customer/equipment')
            }
        }
        //}
    }


    handleInlineEditClick(e) {
        e.preventDefault();
        if (e.target.tagName === 'svg' || e.target.tagName === 'path') {
            this.setState({ clickedInlineEdit: true, showCtrl: true });
        } else {
            document.getElementById('equpimage').value = "";
            this.setState({
                clickedInlineEdit: false,
                showCtrl: false,
                data: {
                    ...this.state.data, logo: '', numberPlate: this.state.equipmentData.numberPlate ? this.state.equipmentData.numberPlate.trim() : ""
                }
            });
        }
        this.setState({ /* eqpImage: this.state.equipmentData.logo, */ inputerror: false })
    }

    handleEquipmentEdit(e) {
        e.preventDefault();
        
        let data = {
            "machineSaleId": this.state.data.machineSaleId,
            "numberPlate": this.state.equipmentData.status == 'TE' && this.state.data.numberPlate ? this.state.data.numberPlate.trim() : "",
            "logo": this.state.data.logo || ''
        }
        if (this.state.equipmentData.status == 'TE' && this.state.data.numberPlate && this.state.data.numberPlate.trim() === "") {
            this.setState({ inputerror: true })
        } else {
            this.props.handleClick(data);
            this.setState({ showCtrl: false, clickedInlineEdit: false, updated: true, inputerror: false });
        }
    }
    validEntry(s) {
        if (s.length !== 0) {
            return s.match(/^[0-9a-zA-Z]+$/)
        } else {
            return true
        }
    }
    handleChange(e) {
        e.preventDefault();
        if (e.target.type === "file" && e.target.files.length > 0) {

            if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                var reader = new FileReader();
                let componentInstance = this;
                reader.onload = (function (theFile) {


                    return function (e) {
                        var i = new Image();
                        i.src = e.target.result;
                        i.onload = () => {
                            
                            if (i.width >= 200 && i.height >= 200) {
                                var canvas = document.createElement("canvas");
                                if(i.width<=i.height){
                                    canvas.width = 200;
                                    canvas.height = 200/i.width * i.height;
                                }
                                else{
                                    canvas.height = 200;
                                    canvas.width = 200/i.height * i.width;
                                }
                                var ctx = canvas.getContext("2d");
                                ctx.drawImage(i,0,0,canvas.width,canvas.height);
                                let new_image_url = ctx.canvas.toDataURL("image/jpeg","90");
                                componentInstance.setState({
                                    data: {
                                        ...componentInstance.state.data, logo: new_image_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                                    },
                                    eqpImage: new_image_url
                                });
                            } else {
                                componentInstance.props.setToastr({ toastr: { message: "Width & Height must be greater than or equal to 200px", type: "info" } });
                            }

                        }
                    };
                })(e.target.files[0]);
                reader.readAsDataURL(e.target.files[0]);
            }
        } else {

            if (!(this.validEntry(e.target.value) === null)) {
                this.setState({
                    data: {
                        ...this.state.data, [e.target.name]: e.target.value
                    }
                });
            }
        }
    }

    handleImageUpload(e) {
        e.preventDefault();
        const uploadElement = ReactDOM.findDOMNode(this.fileUpload);
        uploadElement.click()
        this.setState({ showCtrl: true });
    }
    onDismiss() {
        this.setState({ notify: false });
    }
    HideMap() {
        this.setState({ isMarkerShown: false })
    }
    loadMap(e) {
        e.preventDefault();
        // disptach for call API 
        let inDate = moment(new Date()).format("YYYY-MM-DD");
        this.props.getInstantReport({ machineSaleId: this.props.match.params.equipmentid, inDate });

        let lat = parseFloat(e.target.parentElement.getAttribute('lat'));
        let lon = parseFloat(e.target.parentElement.getAttribute('lon'));
        this.setState({ isMarkerShown: true, lat: lat, lon: lon })
    }
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }
    render() {
        let opStartDate, opEndDate, wan, viewAll, selectedWarn;
        const { alertSettings, translate } = this.props;
        let { instantReportData } = this.props;
        const integrationData = this.state.equipmentData;
        let geo = translate('off');
        let hidden = moment(new Date()).isAfter(integrationData.contractExpiry)

        if (typeof (alertSettings) !== "undefined") {
            opStartDate = (alertSettings.operationStartTime === null) ? undefined : alertSettings.operationStartTime;
            opEndDate = (alertSettings.operationEndTime === null) ? undefined : alertSettings.operationEndTime;
            if (alertSettings.geofenceCordinate && alertSettings.geofenceCordinate.trim() !== 'null') {
                geo = (JSON.parse(alertSettings.geofenceCordinate.trim()).coords === null) ? translate('off') : translate('on');
            }
            if (alertSettings.geofenceCordinate) {
                let c = JSON.parse(alertSettings.geofenceCordinate);
                if (c && c.coords && !c.coords.length) {
                    geo = translate('off');
                }
            }
        }
        if (integrationData && integrationData.equipmentEvents && integrationData.equipmentEvents.length > 0) {
            wan = integrationData.equipmentEvents.map((val, index) => {
                return (val.eventType === "Technical Error" && val.currentStatus !== "Completed") ?
                    <Alert color='warning' key={index} isOpen={true}><img src={warning} alt="warning" className="exclamationt" />  {val.eventDescription} <p className='float-right'> <span className='ico-fix'><FaClock className='clocker' /></span>
                        {(val.eventDate &&
                            <Moment format="DD MMM YYYY HH:mm:ss" >
                                {val.eventDate}
                            </Moment>) || " "}
                    </p></Alert> :
                    <Alert color='dark' key={index} isOpen={true}><img src={bellIcon} alt="bell" className="exclamationt" />  {val.eventDescription} <p className='float-right'> <span className='ico-fix'><FaClock className='clocker' /></span>
                        {(val.eventDate &&
                            <Moment format="DD MMM YYYY HH:mm:ss" >
                                {val.eventDate}
                            </Moment>) || " "}
                    </p></Alert>;
            })

            selectedWarn = wan.map((val, index) => {
                if (index < 3) {
                    return val
                }
            })

            if (wan.length > 3) {
                viewAll = <div className="btn btn-primary viewall btn-sm pull-right clearfix" onClick={this.toggle}><FaExpand />view all</div>
            }
        }

        let error = (this.state.inputerror) ? 'border-danger inlineeditableInput' : 'inlineeditableInput';

        return (
            <div>
                {
                    (this.state.isMarkerShown) ?
                        <MapBox
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MapKey}`}
                            defaultZoom={15}
                            isMarkerShown
                            instantReportData={instantReportData}
                            lat={this.state.lat}
                            lon={this.state.lon}
                            icon={maplocationPointer}
                            showMap={this.HideMap.bind(this)}
                            containerElement={<div className="popupUI" />}
                            loadingElement={<div className="popupUI" />}
                            mapElement={<div style={{ height: `100%` }} />}
                        /> :
                        ''
                }
                {this.state.showLoader && <Loaders
                    loadingText={`Please Wait...`}
                    modalOpen={true}
                    className={`loading-contents`}
                    color={'#08a5e1'}
                />}
                <Col className="colorwhite equipmentdetail">
                    <Row className="toprow">
                        
                            <Col lg="12">
                                {this.state.notify && <Alert color={this.state.notifyType} isOpen={this.state.notify} toggle={this.onDismiss}> {this.state.notifyText}</Alert>}
                                {selectedWarn}
                                {viewAll}
                            </Col>
                        
                        <div className="clearfix"></div>
                        <Col lg="3" sm="12" className="text-center">
                            <img className="imagetractor" alt="tractor img" src={this.state.eqpImage || noimage} />
                            <InputField
                                className="d-none"
                                handleChange={this.handleChange.bind(this)}
                                name="equpimage"
                                type="file"
                                id="equpimage"
                                elementRef={input => this.fileUpload = input}
                            />
                            {
                                integrationData.equipmentType && (integrationData.status == 'TE')
                                &&
                                <div className="tractorDetail" style={{marginTop:'-4px', width:'221px'}}>{(this.state.data.numberPlate ? this.state.data.numberPlate.toUpperCase(): " ")}</div>
                            }
                            <div><ButtonField className="btn-primary" handleClick={this.handleImageUpload.bind(this)} clickEvent={true} buttonText={translate('editPicture')} /></div>
                        </Col>
                        <Col lg="9" sm="12">
                            <Row>
                                <Col sm="6" className="eqdetailbox">
                                    {
                                        integrationData.equipmentType && (integrationData.status == 'TE')
                                        &&
                                        <div className="detailcover">
                                            <div>{translate('numberPlate')}</div>
                                            <div className="editableDiv">
                                                <div className={(this.state.clickedInlineEdit) ? 'd-none' : ''}>{this.state.data.numberPlate}</div>
                                                <InputField
                                                    className={(!this.state.clickedInlineEdit) ? 'd-none' : error}
                                                    value={this.state.data.numberPlate && this.state.data.numberPlate.toUpperCase().trim()}
                                                    name="numberPlate"
                                                    max={10}
                                                    id="numberPlate"
                                                    handleChange={this.handleChange.bind(this)}
                                                />
                                                <FaPencilAlt onClick={this.handleInlineEditClick.bind(this)} />
                                            </div>
                                        </div>
                                    }
                                    <div className="detailcover">
                                        <div>{translate('equipmentType')}</div><div>{integrationData.equipmentType ? integrationData.equipmentType.toUpperCase() : " "}</div>
                                    </div>
                                    <div className="detailcover">
                                        <div>{translate('model')}</div><div>{integrationData.model}</div>
                                    </div>
                                    <div className="detailcover">
                                        <div>{translate('machineno')}</div><div>{integrationData.machineNum}</div>
                                    </div>
                                    <div className="detailcover">
                                        <div>{translate('contractDate')}</div><div>{integrationData.contractDate &&
                                            <Moment format="DD MMM YYYY " >
                                                {integrationData.contractDate}
                                            </Moment> || " "}</div>
                                    </div>
                                    <div className="detailcover">
                                        <div>{translate('ContractExpiry')}</div><div>{integrationData.contractExpiry &&
                                            <Moment format="DD MMM YYYY " >
                                                {integrationData.contractExpiry}
                                            </Moment> || " "}</div>
                                    </div>

                                </Col>
                                
                                    <Col sm="6" className="eqdetailbox">
                                        <div className="detailcover">
                                            <div>{translate('currentLocation')}</div>
                                            <div>
                                                {
                                                    (integrationData.sar && integrationData.sar.latitude !== null && integrationData.sar.longitude !== null) ?
                                                        <a href="#" className="mappointer" onClick={this.loadMap.bind(this)} lat={integrationData.sar && integrationData.sar.latitude} lon={integrationData.sar && integrationData.sar.longitude}>
                                                            <img alt="location" src={locationPointer} /> <span>{translate('view')}</span>
                                                        </a>
                                                        :
                                                        ' '
                                                }
                                            </div>
                                        </div>
                                        {!hidden &&
                                        <div className="detailcover">
                                            <div>{translate('fuel')}</div>
                                            <div>
                                                {
                                                    integrationData.equipmentType && (integrationData.status == 'TE') ?

                                                        <FuelIndicator
                                                            fuelRemaning={integrationData.sar && integrationData.sar.fuelRemaning}
                                                            margin="-8px"
                                                        /> : "NA"}
                                            </div>
                                        </div>}
                                        {!hidden &&
                                        <div className="detailcover">
                                            <div>{translate('hourmeter')} </div><div>{(integrationData.sar && integrationData.sar.hourMeter) ? parseFloat(integrationData.sar.hourMeter).toFixed(1) : " "}</div>
                                        </div>}

                                        <div className="detailcover">
                                            <div>{translate('updatedOn')}</div><div>
                                                {integrationData.sar && integrationData.sar.updatedOn &&
                                                    <Moment format="DD MMM YYYY HH:mm:ss" >
                                                        {integrationData.sar.updatedOn}
                                                    </Moment> || " "}

                                            </div>
                                        </div>
                                    </Col>
                                
                                <div className="clearfix"></div>
                                <Col className={(!this.state.showCtrl) ? 'd-none' : 'pt-3'}>
                                    <ButtonField
                                        className="mr-5 btn-primary"
                                        buttonText={translate('save')}
                                        type="submit"
                                        name="updateequipment"
                                        clickEvent={true}
                                        handleClick={this.handleEquipmentEdit.bind(this)}
                                    />
                                    <ButtonField
                                        className="btn-danger"
                                        buttonText={translate('cancel')}
                                        clickEvent={true}
                                        handleClick={this.handleInlineEditClick.bind(this)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {!hidden &&
                        <Row>
                            <Col lg={12}>
                                <Row>
                                    <div className="alertsettingbox">
                                        <div className="text"><img src={Alertsettings2} alt="alert" /> <div>{translate('alertSettings')}</div></div>
                                        <div className="detailcover">
                                            <img src={Location} alt="location" />
                                            <span className="inlinehead">{translate('geoFencing')} </span>
                                            <span> {translate('status')} : {geo}</span>
                                        </div>
                                        <div className="detailcover float-left">
                                            <FaClock />
                                            <span className="optime inlinehead"> {translate('OperatingTime')} </span><span className="optime"> {translate('status')} : {(opStartDate !== undefined && opEndDate !== undefined) ? translate('on') : translate('off')} </span>
                                        </div>
                                        {
                                            (opStartDate !== undefined && opEndDate !== undefined) ?
                                                <div className="detailcover time float-left"><div>{translate('time')} {opStartDate} - {opEndDate}</div></div>
                                                :
                                                ''
                                        }
                                        <Link to={`/customer/alert-setting/${this.state.data.machineSaleId}`}>
                                            <ButtonField className="float-right mt-1 btn-primary" buttonText={translate('editSettings')} />
                                        </Link>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Col>
                {hidden && 
                    <div>
                        <Col xs="12" className="errorMessage" align='center'> {translate('The_Contract_has_been_expired_for_the_selected_machine')}.
                        </Col>
                    </div>}
                <div>
                    <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                        <ModalHeader toggle={this.toggle}>Alerts</ModalHeader>
                        <ModalBody className="equipment-detail-modal-body">
                            {wan}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={this.toggle}>{translate('close')}</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }

    componentDidMount() {
        // call for get data 
        /*  let inDate = moment(new Date()).format("YYYY-MM-DD");
         this.props.getInstantReport({machineSaleId: this.props.match.params.equipmentid,  inDate});
  */
        let img = document.getElementsByTagName('img')
        for (let d in img) {
            if (!isNaN(d)) {
                img[d].addEventListener('error', function () {
                    this.src = noimage;
                })
            }
        }
    }
}

const mapDispatchToProps = { getInstantReport };

const mapStateToProps = state => {
    return ({
        instantReportData: (state.equipment && state.equipment.data && state.equipment.data.SET_INSTANT_REPORT_DATA) ? state.equipment.data.SET_INSTANT_REPORT_DATA : []

    });
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EquipmentsDetails));