import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Col, Row } from 'reactstrap';
import ButtonField from '../../../../components/shared/ButtonField';
import SearchReportForm from '../../../../forms/SearchReportForm';
import Loaders from '../../../../messages/loaders/Loaders';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { FaSortDown } from 'react-icons/fa'
import './style.scss';
import { setToastr } from "../../../../actions/Application";

const list = [
  { name: 'Jan' },
  { name: 'Feb' },
  { name: 'Mar' },
  { name: 'Apr' },
  { name: 'May' },
  { name: 'Jun' },
  { name: 'Jul' },
  { name: 'Aug' },
  { name: 'Sep' },
  { name: 'Oct' },
  { name: 'Nov' },
  { name: 'Dec' }
];

const MenuItem = ({ text, selected }) => {
  return (
    <div
      className={`menu-item ${selected ? 'active' : ''}`}
    >
      {text}
    </div>
  );
};

export const Menu = (list) => list.map(el => {
  const { name } = el;

  return (
    <MenuItem
      text={name}
      key={name}
    />
  );
});

const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};

export const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
export const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

/* A Work Report component */
class WorkReportComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: {
        fromDate: moment(),
        toDate: moment(),
        machineSaleId: ''
      },
      selectError: false,
      results: false,
      numberPlateOption: {}
    };
  }



  handleChange(type, data) {
    if (data === undefined) {
      data = type.target.value;
      type = type.target.name;
    }

    this.setState(
      {
        data: {
          ...this.state.data, [type]: data
        }
      }
    );
  }

  handleClick() {

    const isafter = moment(this.state.data.toDate).isAfter(this.state.data.fromDate);
    if (!isafter) {

      this.props.setToastr({ toastr: { message: "End Date should be greater than Start Date.", type: "info" } });
      return false;

    }


    if (this.state.data.machineSaleId === "" || this.state.data.machineSaleId === "null") {
      this.setState({ selectError: true })
    } else {
      this.props.handleChange(this.state.data, 1);
      this.setState({ results: true, showLoader: true, selectError: false })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({ showLoader: false })
    }
    if (this.props.showLoader !== nextProps.showLoader) {
      this.setState({ showLoader: nextProps.showLoader })
    }
  }

  csvExportData() {
    const TractorHeaders = [
      { label: this.props.langtranslate('workdate'), key: 'workDate' },
      { label: this.props.langtranslate('operatingHour'), key: 'totalOperatingHour' },
      { label: this.props.langtranslate('workerDetails'), key: 'member' },
      { label: this.props.langtranslate('memo'), key: 'memo' },
      { label: this.props.langtranslate('averageSpeed'), key: 'averageSpeed' },
      { label: this.props.langtranslate('fuelConsumption'), key: 'averageFuelConsumption' },
      { label: this.props.langtranslate('Refuel Quantity'), key: 'averageOilQuantity' },
      { label: this.props.langtranslate('totalEngHr'), key: 'totalEngineHr' },
      { label: this.props.langtranslate('totalPtoHr'), key: 'totalPtoHr' },
      { label: this.props.langtranslate('totalIdlingHr'), key: 'totalIdlingHr' },
      { label: this.props.langtranslate('totalRunningHr'), key: 'totalRunningHr' },
      { label: this.props.langtranslate('totalAcregeArea'), key: 'totalAcregeArea' }
    ];

    const ConstrcuctionHeaders = [
      { label: this.props.langtranslate('workdate'), key: 'workDate' },
      { label: this.props.langtranslate('operatingHour'), key: 'totalOperatingHour' },
      { label: this.props.langtranslate('coolingTemp'), key: 'coolingTemperatureAvg' },
      { label: this.props.langtranslate('hydraulicTemp'), key: 'hydraulicOilAvg' }

    ];

    const combineHeader = [
      { label: this.props.langtranslate('workdate'), key: 'workDate' },
      { label: this.props.langtranslate('operatingHour'), key: 'totalOperatingHour' },
      { label: this.props.langtranslate('alarmInfo'), key: 'waterTempAlarm' }
    ];
    let data,header = [];
    let group;
    if(this.props.data && this.props.data.length > 0){
      data = this.props.data ;
      group = this.props.data[0].status;
      header = (group == 'CE') ? ConstrcuctionHeaders : (group == 'CH')?combineHeader : TractorHeaders
    }
    return [header, data];
  }

  render() {
    const { langtranslate, numberPlateOption, data, translate } = this.props;
    let group;
    if(data && data.length>0){
      group = data[0].status;
    }
     

    return (
      <div className="workreport">
        {this.state.showLoader && <Loaders
          loadingText={`Please Wait...`}
          modalOpen={true}
          className={`loading-contents`}
          color={'#08a5e1'}
        />}
        <Row>
          <Col lg={12}>
            <SearchReportForm
              module='work'
              translate={langtranslate}
              numberPlateOption={numberPlateOption}
              handleChange={this.handleChange.bind(this)}
              fromDate={this.state.data.fromDate}
              toDate={this.state.data.toDate}
              report={true}
              selectError={this.state.selectError}
            />
            {(data && data.length > 0) ?
              <CSVLink
                className="float-right btn-success text-uppercase m-2 btn btn-secondary"
                data={this.csvExportData()[1]}
                headers={this.csvExportData()[0]}
                filename={'Workreportdata.csv'}
              >
                {translate('downloadCSV')}
              </CSVLink>
              :
              ''
            }
            <ButtonField className="float-right btn-success text-uppercase m-2"
              clickEvent={true}
              buttonText={translate('search')}
              handleClick={this.handleClick.bind(this)}
            />
          </Col>
        </Row>
        {this.state.results &&
          <Row>
            <Col lg={12}>
              <Table className="tabledashboard table table-striped table-advance table-hover table-responsive">
                <thead>
                  <tr >
                    <th>{langtranslate('workdate')}</th>
                    <th>{langtranslate('operatingHour')}</th>
                    {(group == 'TE') ? <React.Fragment>
                      <th>{langtranslate('workerDetails')}({langtranslate('memo')})</th>
                      <th>{langtranslate('averageSpeed')}</th>
                      <th>{langtranslate('fuelConsumption')}</th>
                      <th>{langtranslate('refuelQty')}</th>
                      <th>{langtranslate('totalEngHr')}</th>
                      <th>{langtranslate('totalPtoHr')}</th>
                      <th>{langtranslate('totalIdlingHr')}</th>
                      <th>{langtranslate('totalRunningHr')}</th>
                      <th>{langtranslate('totalAcregeArea')}</th></React.Fragment>
                      : group == 'CE' ?
                        <React.Fragment><th>{langtranslate('hydraulicTemp')}</th>
                          <th>{langtranslate('coolingTemp')}</th> </React.Fragment>
                        :
                        <th>{langtranslate('alarmInfo')}</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {this.getTableBody()}
                </tbody>
              </Table>
            </Col>
          </Row>
        }
      </div>
    );
  }

  getTableBody() {
    const { data } = this.props;
    
    let reportsData = '';
    if (data) {
      if (data.length > 0) {
        let group = data[0].status;
        reportsData = data.map((value, index) => {
          return (
            <tr key={index}>
              <td>{value.workDate}</td>
              <td>{value.totalOperatingHour}</td>
              {group == 'TE' ? <React.Fragment>
                <td className='text-wrap'>

                  {value.member && value.memo &&
                    <>
                      <b>{value.member}</b>
                      [{value.memo}]
                    </>
                  }

                  {(!value.member || !value.memo) && " "}
                </td>
                <td>{value.averageSpeed && value.averageSpeed.toFixed(4)}</td>
                <td>{value.averageFuelConsumption && value.averageFuelConsumption.toFixed(4)}</td>
                <td>{value.averageOilQuantity && value.averageOilQuantity.toFixed(4)}</td>
                <td>{value.totalEngineHr && value.totalEngineHr.toFixed(4)}</td>
                <td>{value.totalPtoHr && value.totalPtoHr.toFixed(4)}</td>
                <td>{value.totalIdlingHr && value.totalIdlingHr.toFixed(4)}</td>
                <td>{value.totalRunningHr && value.totalRunningHr.toFixed(4)}</td>
                <td>{value.totalAcregeAre && value.totalAcregeAre.toFixed(4)}</td>
              </React.Fragment>
                : group == 'CE' ? <React.Fragment>
                  <td>{(value.hydraulicOilAvg && (value.hydraulicOilAvg<0)) ?  ' ':(value.hydraulicOilAvg.toFixed(4)) }</td>
                  <td>{(value.coolingTemperatureAvg && (value.coolingTemperatureAvg<0)) ?  ' ':(value.coolingTemperatureAvg.toFixed(4)) }</td>
                </React.Fragment> : <td>{(value.waterTempAlarm) ? (value.waterTempAlarm.toFixed(4)) : '0'}</td>
              }
            </tr>
          );
        });
      } else {
        reportsData = <tr className="custom-no-data"><td className="text-center" colSpan={13}> No Data Found</td></tr>;
      }
    } else {
      reportsData = <tr className="custom-no-data"><td className="text-center" colSpan={13}> Loading Reports...</td></tr>;
    }
    return reportsData
  }
}

const mapDispatchToProps = {
  setToastr
}
export default connect(null, mapDispatchToProps)(WorkReportComponent);
