import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Form, FormGroup, Col, Row, CustomInput, Input } from "reactstrap";


/** Importing Sub Components */
import EquipmentDetailsComponent from "../elements/EquipmentDetailsComponent";
import EquipmentServiceDetail from "../../service-history/EquipmentServiceDetail"

class SearchEquipment extends Component {
    constructor(props) {
        super(props);

        /** define component state */
        this.state = {
            searchBy: "model",
            label: "byModelMachineNo",
            options: [],
            equipments: [],
            invoke: false,
            selectedValue: null
        };

        /** function binding */
        this.searchBy = this.searchBy.bind(this);
        this.onEquipmentSelect = this.onEquipmentSelect.bind(this);
        this.onEquipmentRowSelect = this.onEquipmentRowSelect.bind(this);
    }

    componentWillReceiveProps(newProps) {
        let { invoke } = this.state;
        if (newProps.equipments && newProps.equipments.length) {
            this.setState({ equipments: newProps.equipments, invoke: true }, () => {
                if (!invoke) this.updateSelection(this.state.searchBy);
            });
        }
    }

    searchBy(value, label) {
        //let { value } = $event.target, { label } = $event.target.dataset;

        if (this.state.searchBy && this.state.searchBy === value) return false;

        this.setState({ searchBy: value, label });

        /** update selection values */
        this.updateSelection(value);
    }

    updateSelection(value = 'numberPlate') {
        /** */
        let { equipments } = this.state;


        let options;
        if (value === 'model') {
            options = equipments.map(equipment => {
                return (equipment[value] + "/" + equipment["machineNum"])
            });
        } else {
            options = equipments.map(equipment => {
                if (equipment.status == 'TE')
                    return equipment[value]
            });
        }


        this.setState({ options });

        /** Reset Options  */
        ReactDOM.findDOMNode(document.getElementById('q')).value = '';

        if (this.state.options.length) this.props.onEquipmentSelect({
            undefined, searchBy: this.state.searchBy
        });
    }

    onEquipmentSelect($event) {
        /** Select Equipment by NumberPlate/Model/Machine */
        let { value } = $event.target;
        
        let selectedMachineSaleId ;
        if(this.state.searchBy == 'model'){
            let selectedMachine = value.split('/')[1];
            let filtered = this.state.equipments.filter(a => a.machineNum == selectedMachine)
            selectedMachineSaleId = filtered.length > 0 ? filtered[0].machineSaleId : '' ;
        }
        else{
            let selectedNumberPlate = value;
            let filtered = this.state.equipments.filter(a => a.numberPlate == selectedNumberPlate)
            selectedMachineSaleId = filtered.length > 0 ? filtered[0].machineSaleId : '' ;
        }
        /** Let parent component know about equipment selection */
        this.props.onEquipmentSelect({
            value, searchBy: this.state.searchBy,machineSaleId:selectedMachineSaleId
        });
        
        this.setState({ selectedValue: value });
    }

    onEquipmentRowSelect(model, machineNum, numberPlate,machineSaleId) {
        /** Select Equipment by NumberPlate/Model/Machine */
        /** Let parent component know about equipment selection */
        var searchBy = this.state.searchBy;
        if (searchBy == 'numberPlate' && numberPlate == null) {
            searchBy = 'model';
        }
        let value = searchBy == 'numberPlate' ? numberPlate : model + "/" + machineNum;
        this.props.onEquipmentSelect({
            value, searchBy: searchBy,machineSaleId
        });
        this.setState({ selectedValue: value });
    }

    render() {
        let { searchBy, options, label, equipments, selectedValue } = this.state;
        let { translate, selectedEquipment } = this.props;
        const url = window.location.pathname;
        const isInitial = true;
        const containsAgriEquip = !!equipments.find(equipment => {
            return (equipment.status == 'TE' || equipment.status == 'CH')
        })
        const isTractor = selectedEquipment && (selectedEquipment.status =='CH' || selectedEquipment.status =='CE');
        return (
            <div className="daily-work-area">
                <div className="filter-form-area">
                    <Form className="form-horizontal row">
                        <Col sm="6">
                            <FormGroup>
                                <Row>
                                    <Col md="4" sm="3"><span className="highlighted">{translate('selectEquipment')}</span></Col>
                                    <Col md="7" sm="9" className='d-flex justify-content-start'>
                                        <CustomInput type="radio" id="by_model" name="sby" value="model" label={translate('byModelMachineNo')} data-label="Model/Machine Number" onClick={() => this.searchBy('model', 'byModelMachineNo')} defaultChecked={this.state.searchBy === 'model' ? true : false} />
                                        &nbsp;
                                        {containsAgriEquip &&
                                            <CustomInput type="radio" id="by_number_plate" name="sby" value="numberPlate" label={translate('byNumberPlate')} data-label="Number Plate" onClick={() => this.searchBy('numberPlate', 'byNumberPlate')} defaultChecked={this.state.searchBy === 'numberPlate' ? true : false} disabled={isTractor}/>
                                        }

                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>

                        {selectedEquipment && selectedEquipment.machineSaleId &&
                            <Col sm='6' className="col-sm-6 tracker-perf-pos">
                                <Row className='no-gutters'>

                                    <Col sm='6'>
                                        <span className="highlighted">{translate('model')}</span> 
                                        <span className="pull-right" style={{marginRight:'30px'}}>{selectedEquipment.model || " "}</span>
                                    </Col>
                                    <Col sm='6'>
                                        <span className="highlighted">{translate('machineno')}</span> 
                                        <span className="pull-right" style={{marginRight:'30px'}}>{selectedEquipment.machineNum || " "}</span>
                                    </Col>
                                    <Col sm='6'>
                                        <span className="highlighted">{translate('type')}</span> 
                                        <span className="pull-right" style={{marginRight:'30px'}}>{selectedEquipment.equipmentType ? selectedEquipment.equipmentType.toUpperCase() : " "}</span>
                                    </Col>
                                    {selectedEquipment.status == 'TE' && <Col sm='6'>
                                        <span className="highlighted">{translate('numberPlate')}</span>
                                        <span className="pull-right" style={{marginRight:'30px'}}> {(selectedEquipment.numberPlate ? selectedEquipment.numberPlate.toUpperCase() : " ")}</span>
                                    </Col>}
                                </Row>
                            </Col>
                        }

                        <Col sm="12">
                            <FormGroup>
                                <Row>
                                    <Col sm="2"><span className="highlighted">{translate(label)}</span></Col>
                                    <Col sm="4">
                                        <Input type="select" name="q" id='q' className="no-padded" disabled={searchBy && equipments.length ? false : true} onChange={this.onEquipmentSelect}
                                            onMonthChange={this.onEquipmentSelect}
                                        >
                                            <option value=''>{translate('selectEquipment')}</option>
                                            {options.length && options.map((opt, i) => {
                                                if (opt)
                                                    return (
                                                        <option key={i} value={opt}>{opt.toUpperCase()}</option>
                                                    );
                                            })}
                                        </Input>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>

                        <Col sm="12">
                            {/* Equipment Component */}
                            {!selectedValue && <>
                                {equipments && equipments.map((e, i) => (<div key={i} onClick={() => this.onEquipmentRowSelect(e.model, e.machineNum, e.numberPlate,e.machineSaleId)}>
                                    {url && url.indexOf('service-history') > 0 ?

                                        <EquipmentServiceDetail key={i} translate={translate} equipment={e} />

                                        :
                                        <EquipmentDetailsComponent key={i} equipment={e} translate={translate} isInitial={isInitial} />

                                    }
                                </div>
                                ))}
                            </>}
                        </Col>

                    </Form>
                </div>
            </div>
        );
    }
}

export default SearchEquipment;