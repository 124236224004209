import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import SrvprovRouteContainer from '../containers/ServProvRouteContainer';
import ServProvLoginContainer from '../containers/service-provider/ServProvLoginContainer';
import ServiceProviderEquipmentAlertsContainer from '../containers/service-provider/EquipmentAlertsContainer';
import ServiceProviderEquipmentAlertDetailAleridContainer from '../containers/admin/EquipmentAlertDetailContainer';
import EquipmentServiceInfo from '../components/service-provider/equipments/EquipmentServiceInfo'
import session from "../services/session";
import _find from "lodash/find";

import ServiceProviderEquipmentOperatingInfoContainer from "../containers/service-provider/EquipmentOperatingInfoContainer";
import ServiceContractRenewContainer from "../containers/admin/ContractRenewContainer";
import ServiceHourmeterOffsetDetailContainer from "../containers/admin/HourmeterOffsetDetailContainer";
//import ServiceEditOwnerDetailContainer from "../containers/admin/EditOwnerDetailContainer";

import ServiceProviderProfileContainer from "../containers/service-provider/MyProfileContainer";

const ContainerList = {
    ServiceProviderEquipmentAlertsContainer,
    ServiceProviderEquipmentAlertDetailAleridContainer,
    ServiceProviderEquipmentOperatingInfoContainer,
    ServiceProviderProfileContainer,
    ServiceContractRenewContainer,
    ServiceHourmeterOffsetDetailContainer
    //ServiceEditOwnerDetailContainer
};


const pascalConverter = str => (str.replace(/(\w)(\w*)/g,
    function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); })).replace(/[^a-zA-Z ]/g, "") + "Container";

export default class SrvProvRoutes extends Component {

    render() {
        let { location, match, translate } = this.props;
        let routeList = (session && session.get("spprev", "cookie")) || [];

        let hasEqInfo = _find(routeList, { uuid: "/service-provider/equipment-operating-info" }),
            hasEqAlert = _find(routeList, { uuid: "/service-provider/equipment-alerts" }),
            hasEqServiceInfo = _find(routeList, { uuid: "/service-provider/equipment-service-info" }),
            hasHourInfo= _find(routeList, { uuid: "/service/hourmeter-offset-management" });
        console.log("hasEqServiceInfo" + hasEqServiceInfo);
        return (
            <>
                <Route location={location} translate={translate} path={`${match.url}/`} exact component={ServProvLoginContainer} />

                {routeList && routeList.map((route, i) => {
                    let comp = pascalConverter(route.uuid);
                    if (ContainerList[comp]) {
                        return <SrvprovRouteContainer key={route.uuid} location={location} translate={translate} path={route.uuid} exact component={ContainerList[comp]} />
                    } else {
                        return false;
                    }
                })}

                {hasEqInfo && <SrvprovRouteContainer location={ location } translate={ translate } path={`${match.url}/equipment-detail/:userId/:machineId`} exact component={ ServiceProviderEquipmentOperatingInfoContainer } />}
                {hasEqInfo && <SrvprovRouteContainer location={ location } translate={ translate } path={`${match.url}/contract-renew`} exact component={ ServiceContractRenewContainer } />}
                {hasHourInfo && <SrvprovRouteContainer location={location} translate={translate} path={`${match.url}/hourmeter-offset-detail`} exact component={ServiceHourmeterOffsetDetailContainer} />}
                {/*{hasEqInfo && <SrvprovRouteContainer location={ location } translate={ translate } path={`${match.url}/edit-owner-details`} exact component={ ServiceEditOwnerDetailContainer } />}*/}

                {hasEqAlert && <SrvprovRouteContainer location={ location } translate={ translate } path={`${match.url}/equipment-alert-detail/:alerId`} exact component={ ServiceProviderEquipmentAlertDetailAleridContainer } />}
                {hasEqServiceInfo && <SrvprovRouteContainer location={ location } translate={ translate } path={`${match.url}/equipment-service-info`} exact component={ EquipmentServiceInfo } />}
                {/* <SrvprovRouteContainer location={ location } translate={ translate } path={`${match.url}/equipment-alerts`} exact component={EquipmentAlertsContainer} />
                <SrvprovRouteContainer location={ location } translate={ translate } path={`${match.url}/equipment-alert-detail/:alerId`} exact component={EquipmentAlertDetailContainer} />
                <SrvprovRouteContainer location={ location } translate={ translate } path={`${match.url}/equipment-operating-info`} exact component={EquipmentOperatingInfoContainer} />
                <SrvprovRouteContainer location={ location } translate={ translate } path={`${match.url}/equipment-operating-info/:userId/:machineId`} exact component={EquipmentOperatingInfoContainer} /> 
                <SrvprovRouteContainer location={ location } translate={ translate } path={`${match.url}/profile`} exact component={ServiceProviderMyProfileContainer} />
                */}
            </>
        );
    }
}