import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyAccountComponent from "../components/customer/my-account/MyAccountComponent";

import { getProfile } from "../actions/Profile";
import { getTimeZoneLanguage } from "../actions/Application";

class ProfileContainer extends Component {

    /**
     * Dispatch the action to get the members data
     */
    componentDidMount() {
        /** get profile data */
        this.props.getProfile();
        /** get timezone and language */
        this.props.getTimeZoneLanguage();
    }


    render() {
        let { profile } = this.props;
        return <MyAccountComponent profile={profile}/>;
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
const mapStateToProps = state => {  
    return { profile: state.profile || undefined };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getProfile,
    getTimeZoneLanguage
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);