import { SET_SP_EQUIPMENTS ,SET_EQUIPMENT_SERVICE_INFO,SET_ALL_EQUIPMENTS,SET_ALL_EQUIPMENTS_LOCATION} from '../../types';

/** @type { Object } default state */
let defaultState = {
    equipments: [],
    countResult: undefined
}

/**
 * dispatched the user data to the store
 * @param  { Object } state  default state
 * @param  { Object } action dispacthed action from middleware
 */
export default function spSettings(state = defaultState, action = {}) {

    switch (action.type) {
        case SET_SP_EQUIPMENTS:
            return { ...state, ...action.data };
        case SET_ALL_EQUIPMENTS:
            const eqpList = {}
            eqpList[action.type] = action.data;
            eqpList['isSending'] = false;
            eqpList['resMsg'] = action.resMsg;
            eqpList['error'] = action.error;
            return Object.assign({}, state, {
                eqpList: Object.assign({}, state.data, eqpList)
            })
        case SET_EQUIPMENT_SERVICE_INFO:
            const eqpDataUpdate = {}
            eqpDataUpdate[action.type] = action.data;
            eqpDataUpdate['isSending'] = false;
            eqpDataUpdate['resMsg'] = action.resMsg;
            eqpDataUpdate['error'] = action.error;
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, eqpDataUpdate)
            })
        case SET_ALL_EQUIPMENTS_LOCATION:
            const eqpLocationUpdate = {}
            eqpLocationUpdate[action.type] = action.data;
            eqpLocationUpdate['isSending'] = false;
            eqpLocationUpdate['resMsg'] = action.resMsg;
            eqpLocationUpdate['error'] = action.error;
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, eqpLocationUpdate)
            })
        default: return state;
    }
}
