import { takeLatest, call, put } from 'redux-saga/effects';
import { getDataControl } from '../../api/admin/data-control';
import { SET_TOASTR, SET_LOADER, GET_DC, SET_DC } from '../../types';



export function* getDC(action) {
    try {
        const response = yield call(getDataControl, action.data);

        let msCompaniesPageList = response.data.msCompaniesPageList || [], 
            childParentCompanies = response.data.childParentCompanies || [],
            data = (msCompaniesPageList && msCompaniesPageList.length) ?  msCompaniesPageList : childParentCompanies;

        yield put({ type: SET_DC, data: { data: {msCompaniesPageList: data, msCompaniesTotalList: response.data.msCompaniesTotalList || [] } , countResult: response.data.countResult} });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchDC() {
    yield takeLatest(GET_DC, getDC)
}
