import React, { Component } from 'react';
import InputField from '../components/shared/InputField';
import SelectField from '../components/shared/SelectField';
import { FormGroup, Label } from 'reactstrap';
import './style.scss';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";

/* A Search Report Form component */
class SearchReportFormAreaMeasurement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            optionType: '1',
        };
    }


    handleChange(type, data) {
       this.props.handleChange(type, data);
    }


    render() {
        const { numberPlateOption, selectError, translate } = this.props;
        let opt = (this.state.optionType === '1') ? false : true;
        
        

        return (
            <div>
                <FormGroup tag="fieldset">
                    <FormGroup>
                        <Label for="exampleSelect" className="inputLabel">{translate('modelMachine')}</Label>
                        <SelectField
                            divClass="selectwrapper"
                            handleChange={this.handleChange.bind(this)}
                            options={numberPlateOption[this.state.optionType]}
                            type="select"
                            name="machineSaleId"
                            className={(selectError) ? 'border-danger' : ''}
                            defaultOption={(opt) ? "---" + translate('selectNumberPlate') + "---" : "---" + translate('selectModelMachineNum') + "---"}
                        />
                    </FormGroup>
                </FormGroup>
            </div>
        );
    }
}

export default SearchReportFormAreaMeasurement;
