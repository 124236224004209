import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import _find from "lodash/find";
import { connect } from 'react-redux';
import {FaSortDown, FaSortUp, FaSort} from 'react-icons/fa';
import session from "../../../services/session";

/** importing sub-components */
import Pagination from "../../shared/Pagination";
/** importnig constants */
import { LIMIT } from "../../../constants/Constants";

import { getOwners, setOrder } from "../../../actions/admin/Owner";
import { setLoader } from "../../../actions/Application";

class Owners extends Component{
    constructor(props){
        super(props);

        this.state = {
            owners: [],
            routeList : (session && session.get("prev","cookie")) || [],
            activePage: 1,
            total: 0,
            loading: true,
            ownerField: undefined,
            order: undefined //true=asc, false=desc
        }

        /** funciton binding */
        this.listOwnersData = this.listOwnersData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    componentDidMount(){
        this.props.setLoader({ loader: {open:true}});

        this.props.getOwners({page:1});
    }

    componentWillReceiveProps(newProps){
        if(newProps.countResult){
            this.props.setLoader({ loader: {open:false}});
            this.setState({
                loading: false, 
                owners: newProps.owners, 
                activePage: this.state.activePage || 1,
                total: (newProps.countResult && newProps.countResult.totalCounts) ? newProps.countResult.totalCounts : this.state.total 
            });
        }
    }

    listOwnersData(page){
        this.props.setLoader({ loader: {open:true}});
        this.setState({loading: true, activePage: page || 1});
        this.props.getOwners({page});
    }

    onPageChange(page){
        let { order, ownerField } = this.state;
        let q = document.getElementById("keyword");
        this.props.setLoader({ loader: {open:true}});
        this.setState({loading: true, activePage: page || 1}, () => {
            this.props.getOwners({page, data: {phone: (q && q.value) || undefined, ownerField, order: order === true ? "asc":"desc"}});
        });  
    }

    sortBy(ownerField) {
        let { order, activePage } = this.state;
        let q = document.getElementById("keyword").value;

        this.setState({ ownerField, order: typeof order === undefined ? true : !order }, () => {
            this.props.setOrder({orderBy: ownerField, order: this.state.order === true ? "asc":"desc"})
            this.props.getOwners({page:activePage, data: {phone: q || undefined, ownerField, order: this.state.order === true ? "asc":"desc"}});
            this.props.setLoader({ loader: {open:true}});
        });
    }

    /*
        let offset = (page && page > 1) ? ((page - 1) * LIMIT) : 0;

        /** start loader *
        this.setState({loading: true});

        listOwners(offset, LIMIT, !page ? true : false)
        .then(response => {
            this.setState({
                loading: false, 
                owners: response.data.ownerDetailList || [], 
                activePage: page || 1,
                total: (response.data.countResult && response.data.countResult.totalCounts) ? response.data.countResult.totalCounts : this.state.total 
            });
        })
        .catch(error => this.setState({loading: false}));
    } */

    

    render(){

        let {total, activePage, loading, owners, ownerField} = this.state, {push} = this.props.history;
        let sortType = (this.state.order) ? <FaSortUp/> : <FaSortDown/>;

        let routeList = (session && session.get("prev","cookie")) || [];

        let modules = {
            hasOwnersPerm: "/service/owners",
            newRegistration: "/service/owners/add",
            updateOwner: "/service/owners/detail/:userId"
        };

        let hasNewRegistration = _find(routeList, {uuid: modules.newRegistration}),
        hasUpdateOwner = _find(routeList, {uuid: modules.hasOwnersPerm});
        return(
            <div className ="owner-mgmt" >
                
                <div >{hasNewRegistration && <Row className='no-gutters align-items-center'>
                    <Col className='text-right'>
                        <Link to={modules.newRegistration} className='btn btn-primary float-right' >Add New Owner</Link>
                    </Col>    
                </Row>}
                </div>

                <div style={{overflowY:"auto" , maxHeight:"100%", height:"400px" , marginTop:"15px" , marginBottom:"1rem"}}>
                <table className="tabledashboard table table-striped table-advance table-hover " >
                    <thead>
                        <tr>
                            <th onClick={() => this.sortBy('login_id')}>
                                User ID
                                { ownerField === 'login_id' ? sortType : <FaSort/> }
                            </th>
                            <th onClick={() => this.sortBy('owner')}>
                                Owner Name
                                { ownerField === 'owner' ? sortType : <FaSort/> }
                            </th>
                            <th onClick={() => this.sortBy('email')}>
                                Email
                                { ownerField === 'email' ? sortType : <FaSort/> }
                            </th>
                            <th onClick={() => this.sortBy('phone')}>
                                Mobile Number
                                { ownerField === 'phone' ? sortType : <FaSort/> }
                            </th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {owners.length > 0 && owners.map((owner, i) => <Tr owner={owner} key={i} push={push} hasPermission={hasUpdateOwner ? true : false}/>)}

                        {loading && <tr><td colSpan='9' align='center' className='blinking'>Loading...</td></tr>}

                        {!loading && owners.length===0 && <tr><td colSpan='9' align='center'>No Record Found</td></tr>}
                    </tbody>
                </table>
                </div>

                

                 <Pagination
                    limit={LIMIT}
                    total={total}
                    activePage={activePage}
                    onChange={this.onPageChange}
                    data={owners}
                />

            </div>
            
        );
    }
}

const Tr = ({owner, push, hasPermission}) => <tr onClick={() => hasPermission ? push(`/service/owners/detail/${owner.id}`) : "#"}>
    <td>{owner.loginId || " "}</td>
    <td>{owner.owner || " "}</td>
    <td>{owner.email || " "}</td>
    <td>{owner.phone || " "}</td>
    <td>{owner.address || " "}</td>
</tr>

const mapDispatchToProps = { getOwners, setLoader, setOrder }

const mapStateToProps = state => {
    return({
        owners: state.owner.owners || [],
        countResult: state.owner.countResult || undefined
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Owners));