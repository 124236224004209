import React, { Component } from 'react';
import { connect } from 'react-redux';
import HybridLoginComponent from '../../components/customer/login/HybridLoginComponent';
import MaintenanceBar from '../../components/shared/MaintenanceBar';
import { getSrvprovLogin } from '../../actions/Auth';
import { getAlertNotifications } from '../../actions/AlertsNotifications';
import { translate } from '../../lang';
import { Redirect } from 'react-router-dom';
import session from "../../services/session";

/** An active login component */
export class ServProvLoginContainer extends Component {

	/**
	 * submit the form by dispatching the action
	 * @param  {Object} data This will dispatch the action.
	 */
	handleLogin = (data) => {
		data.module = 'SERVICE PROVIDER';
		this.props.getSrvprovLogin(data);
    }

	componentWillMount() {
		this.props.getAlertNotifications({
			data: {
				type: 1
			},
			numberOfRows: 200,
			startIndex: null,
			totalEventsFlag: null,
			sortingParams: null,
			smFilterParam : '?smFilterParam=SMARTASSIST SERVICE'
		})
	}

	render() {
		let routeList = session.get("spprev", "cookie");

		if (this.props.isSrvprovAuthenticated) {
			return <Redirect to={routeList[0].uuid} />
		} else {
			return (
				<div>
					<HybridLoginComponent
						login={ this.handleLogin.bind(this) }
						translate = { translate }
						msg = { this.props.msg }
						loggedInData = { this.props.loggedInData }
						/>
					<MaintenanceBar maintainSysData={ this.props.maintainSysData }/>
				</div>
			);
		}

	}
}

/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 **/

function mapStateToProps(state) {
	let msg = 'Username or Password is wrong';

	let isSrvprovAuthenticated = false;
	if (state.servprov && state.servprov.data && state.servprov.data.SET_SRVPROV_LOGGED_IN_TOKEN) {
		isSrvprovAuthenticated = true
	}

	if (state.servprov && state.servprov.data && state.servprov.data.error === false && state.servprov.data.isLoggedIn) {
		isSrvprovAuthenticated = true;
	}

	if (state.servprov && state.servprov.data && state.servprov.data.resMsg) {
		msg = state.servprov.data.resMsg;
	}

	return {
		loggedInData: state.servprov && state.servprov.data,
		isSrvprovAuthenticated: isSrvprovAuthenticated,
		msg,
		maintainSysData: state.alertsNotifications.data,
	}
}

/** @type { Object } Connect the action to be dispatched **/
const mapDispatchToProps = {
    getSrvprovLogin, getAlertNotifications
}


export default connect(mapStateToProps, mapDispatchToProps)(ServProvLoginContainer);
