import React, { Component, Fragment } from "react";
import { required } from "../../shared/Validation";
import { withRouter } from "react-router-dom";
import { Row, Col, CustomInput, FormGroup } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { renderTextField, renderSelectField } from "../../shared/renderField";
import { getContractListInfo } from "../../../api/admin/contract-management";
import { setLoader, setToastr } from "../../../actions/Application";
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";
import ReactDOM from "react-dom";
import ContractManagementDetailComponent from "./ContractManagementDetail";


/*global google*/

/* A Contract Management component */
class ContractManagementComponent extends Component {
    constructor(props) {
        super(props);
        this.searchBy = this.searchBy.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.goToItem = this.goToItem.bind(this);
        this.getContractList = this.getContractList.bind(this);
        this.getContractStatus = this.getContractStatus.bind(this);
        this.getEquipmentContractList = this.getEquipmentContractList.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.state = {
            searchBy: 'companyName',
            fieldType: "",
            keyword: "",
            searchFields: [],
            qs: null,
        }
    }
    /*onSubmit(values) {
        console.log(values, "values")
        this.setState({ searchFields: values })
        this.getEquipmentContractList({ page: 1 }, values, null);
    }*/
    onSubmit(values) {
        if ('companyName' in values) {
            values = {
                ...values,
                companyName: encodeURIComponent(values['companyName'])

            }
        } else {
            values = {
                ...values,
                status: encodeURIComponent(values['status'])
            }
        }

        let startIndex = 0;
        this.setState({ qs: values, startIndex: 0, sortingParams: null, totalCounts: true, searchFields: values });
        if (!Object.keys(values).length) return false;

        this.props.setLoader({ loader: { open: true } });
        this.getEquipmentContractList(startIndex, values);
    }
    getEquipmentContractList = (page, data, sortingParam) => {
        let isStatus = false;
        let isCompanyName = false;
        let searchField, queryString;
        if (data) {
            if (Object.keys(data) == 'status') {
                isStatus = true;

                searchField = data['status']
            } else if (Object.keys(data) == 'companyName') {
                isCompanyName = true;
                searchField = data['companyName']
            }
            queryString = `searchField=` + searchField;
        }
        queryString = sortingParam ? (searchField === undefined ? sortingParam : sortingParam + `&` + queryString) : queryString;
        this.props.setLoader({ loader: { open: true } });

        getContractListInfo({ page: page || 1, isStatus: isStatus, isCompanyName: isCompanyName, qs: queryString })
            .then(result => {

                if (result.data) {
                    console.log(result.data)
                    this.setState({ data: result.data });
                }
                this.props.setLoader({ loader: { open: false } });
            })
            .catch(error => {
                this.props.setToastr({ toastr: { type: "error", message: error || "Something went wrong." } });
                this.props.setLoader({ loader: { open: false } });
            })
    }
    clearSearch() {
        this.getEquipmentContractList();
        this.setState({ qs: null 
        ,searchFields: []});
    }
    searchBy(value) {
        this.setState({ searchBy: value })

        this.props.dispatch(change('SearchForm', 'companyName', ''));
        this.props.dispatch(change('SearchForm', 'status', ''));
    }
    render() {

        let { handleSubmit, dispatch, reset, submitting } = this.props;
        let { getServiceData, data, searchFields, fieldType, searchItems, keyword, qs } = this.state;
        let statusValueList = this.props.CompanyAndStatusList;

        return (
            <Fragment>

                <form className="equipmentInfo" name="SearchForm" id="SearchForm" autoComplete='off' onSubmit={handleSubmit(this.onSubmit)} >

                    <Row className="members align-items-center form-srch">
                        <Col sm="12">
                            <FormGroup>
                                <Row>
                                    <Col md="2" sm="3"><span className="highlighted">Select Equipment</span></Col>
                                    <Col md="10" sm="9" className='d-flex justify-content-start'>
                                        <CustomInput type="radio" id="by_service_company" name="sby" value="companyName" label="By Service Company" data-label="Service Company" onClick={() => this.searchBy('companyName')} defaultChecked={this.state.searchBy === 'companyName' ? true : false} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        <CustomInput type="radio" id="by_status" name="sby" value="status" label="By Status" data-label="Status" onClick={() => this.searchBy('status')} defaultChecked={this.state.searchBy === 'status' ? true : false} />


                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col sm="12">

                            <div className="row profile-asset">
                                {this.state.searchBy === 'companyName' && <div className="col-sm-3">

                                    <div >Service Company <span className='required'>*</span>
                                        <Field name="companyName" id="companyName" component={renderTextField} className='form-control' type="text" validate={[required]} onChange={this.getContractList} />
                                        {keyword.length > 0 && searchItems.length > 0 && fieldType === 'companyName' &&
                                            <div className='search-area'>
                                                <Card className='search-card'>
                                                    <CardBody>
                                                        <ul className="list-group list-group-flush">
                                                            {searchItems.map((item, i) => {
                                                                return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'companyName'} />;
                                                            })}
                                                        </ul>
                                                    </CardBody>
                                                </Card>
                                            </div>}
                                        {keyword.length > 0 && searchItems.length == 0 && fieldType === 'companyName' &&
                                            <div className='search-area'>
                                                <Card className='search-card'>
                                                    <CardBody>
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item pointer" >
                                                                <Row>
                                                                    <Col sm='12' className='no-padd-lr'>
                                                                        <span>No record found</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                        </ul>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        }
                                    </div>

                                </div>
                                }
                                {this.state.searchBy === 'status' && <div className="col-sm-3">
                                    <div >Status <span className='required'>*</span>
                                        <Field name="status" id='status' component={renderSelectField} className='form-control' type="text" validate={[required]} onChange={this.getContractStatus} >
                                            <option value="">Select Status</option>
                                            {statusValueList && statusValueList.status.map(status => <option key={status} value={status}>{status}</option>)}
                                        </Field>
                                        {/*{keyword && searchItems && fieldType === 'status' && <div className='search-area'>

                                            <Card className='search-card'>
                                                <CardBody>
                                                    <ul className="list-group list-group-flush">
                                                        {searchItems.map((item, i) => {
                                                            return <Item key={i} attr={{ item }} goToItem={this.goToItem} fieldType={'status'} />;
                                                        })}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                                    </div>}*/}
                                    </div>
                                </div>}


                                <div className="col-sm-4">
                                    <button type="submit" className="btn btn-primary srch-btn" /* disabled={submitting} */>Search</button>
                                    {qs && Object.keys(qs).length > 0 &&
                                        <> &nbsp;&nbsp;&nbsp;
                                            <button type="button" className="btn btn-danger srch-btn" style={{ paddingLeft: '15px', paddingRight: '15px' }}
                                                onClick={() => { dispatch(reset()); this.clearSearch(); }}
                                                disabled={submitting}>Clear Search</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                </form>
                {<ContractManagementDetailComponent data={this.state.data} getEquipmentContractList={this.getEquipmentContractList} searchFields={searchFields}>
                </ContractManagementDetailComponent>}

            </Fragment>
        );
    }
    getContractList(e) {
        let companyList = this.props.CompanyAndStatusList && this.props.CompanyAndStatusList.getContractList;
        let fieldType = e.target.id
        let serviceCompanies = []
        var invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
        let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), regex = new RegExp(value.replace(invalid, "").trim(), 'i');

        let filtered = companyList && companyList.filter(e => {
            if (e.companyName != null && !serviceCompanies.includes(e.companyName.toString()) && fieldType === 'companyName') {
                if (regex.test(e.companyName.toString())) {
                    serviceCompanies.push(e.companyName.toString());
                    return e;
                }
            }
        });



        console.log(filtered, "filtered")
        this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
    }

    getContractStatus(e) {
        let fieldType = e.target.id
        let statuses = [];
        let statusValueList = this.props.CompanyAndStatusList
        let { value } = ReactDOM.findDOMNode(document.getElementById(e.target.id)), regex = new RegExp(value.trim(), 'i');
        let filtered = statusValueList && statusValueList.status.filter(e => {
            if (e != null && !statuses.includes(e.toString()) && fieldType === 'status') {
                if (regex.test(e)) {
                    statuses.push(e.toString())
                    return e;
                }
            }
        });
        console.log(filtered, "filtered")
        this.setState({ searchItems: filtered, keyword: value, fieldType: fieldType });
    }

    componentDidMount() {
        const { status } = (this.props.match && this.props.match.params )|| null;
        let searchFiled = {
            status: status
        }
        if (status) {
            this.getEquipmentContractList({ page: 1 }, searchFiled, null);
        } else {
            this.getEquipmentContractList({ page: 1 }, null, null);
        }


    }
    goToItem(attr, fieldType) {
        //setting value

        this.props.dispatch(change('SearchForm', fieldType, attr));
        this.setState({ keyword: "", searchItems: [], fieldType: "" });
    }
}
const Item = props => {
    let { attr, goToItem, fieldType } = props;
    var listOption;
    if (fieldType == 'companyName')
        listOption = attr.item[fieldType];
    else
        listOption = attr.item;
    return (
        <li className="list-group-item pointer" onClick={() => goToItem(listOption, fieldType)} >
            <Row>
                <Col sm='12' className='no-padd-lr'>
                    <span>{listOption}</span> &nbsp;
                </Col>
            </Row>
        </li>
    );
}

function mapStateToProps(state) {
    return;
}
let formComponent = reduxForm({ form: "SearchForm" })(ContractManagementComponent);
let mapDispatchToProps = { setLoader, setToastr };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(formComponent));
