import { takeLatest, call, put } from 'redux-saga/effects';
import api from '../../api/index';
import { GET_FORGET_PASSWORD, SENDING_FORGET_PASSWORD_REQUEST, SET_FORGET_PASSWORD_FAIL, SET_FORGET_PASSWORD } from '../../types';

export function* forgetPassword(action) {
  const data = action.data;
  try {
    yield put({ type: SENDING_FORGET_PASSWORD_REQUEST, 
                forgetPasswordData: '', 
                isSending: true, 
                isSuccess: false, 
                error: false })
    const response = yield call(api.user.forgotPassword, data)

    switch (response.statusCode) {
      case 200 :
        yield put({ type: SET_FORGET_PASSWORD, 
                    forgetPasswordData: response.data, 
                    resMsg: response.message, 
                    isSending: false, 
                    isSuccess: true, 
                    error: false })
        break;
      case 422 :
        yield put({ type: SET_FORGET_PASSWORD, 
                    forgetPasswordData: response.data, 
                    resMsg: response.message, 
                    isSending: false, 
                    isSuccess: false, 
                    error: true })
        break;
      default:
        yield put({ type: SET_FORGET_PASSWORD_FAIL, 
                    forgetPasswordData: response.data, 
                    resMsg: response.message, 
                    isSending: false, 
                    isSuccess: false, 
                    error: true });
        break;    
    }
  } catch (e) {
    yield put({
      type: SET_FORGET_PASSWORD_FAIL,
      payload: {
        error: e.message, isSuccess: false, errorCheck: true, isSending: false
      }
    })
  }
}

export default function* watchForgetPassword() {
  yield takeLatest(GET_FORGET_PASSWORD, forgetPassword)
}
