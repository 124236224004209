import React, { Component } from 'react';
import { connect } from 'react-redux';
import ForgetPassComponent from '../components/customer/forget/ForgetPassComponent';
import { handleForgetPassword } from '../actions/Auth';
import { setLoader } from "../actions/Application";
import {translate} from "../lang"

/** An active Forget Password component */
class ForgetPassContainer extends Component {


	/**
	 * Submit the data of the forget password form
	 * @param  { Object } data Object of the user inputs
	 */
	handleForgetPass = (data) => {
		this.props.handleForgetPassword(data)
	}

	render() {

		this.props.setLoader({loader: {open: true}})

		return (
				<ForgetPassComponent
					data = { this.props.forgetPassword }
					loader = { this.props.setLoader }
					submit={ this.handleForgetPass.bind(this) }
					translate={translate}
				/>	
			);
	}
}

/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {

	return {
			forgetPassword: state.forgetPassword && state.forgetPassword.data,
		}
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
	handleForgetPassword,
	setLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassContainer);