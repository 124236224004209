import { takeLatest, call, put } from 'redux-saga/effects';
import { getMembers,getMemberList } from '../../api/members';
import { GET_MEMBERS, SET_MEMBERS_DATA, SET_TOASTR, SET_LOADER,GET_MEMBERS_LIST,SET_MEMBERS_LIST } from '../../types';


export function* getMembersCall(action) {
    try {  
        const members = yield call(getMembers, action);
        console.log('members',members);
        yield put({ type: SET_MEMBERS_DATA, data: members.data || [] });
    }
    catch (e) {
        //action.error(e);
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find members for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });

    }
}

export function* getMemberPermissions(action) {
    try {  
       const members = yield call(getMemberList, action);
        
       yield put({ type: SET_MEMBERS_LIST, data: members.data || [] });
    }
    catch (e) {
        //action.error(e);
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find members for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });

    }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchMembersData() {
    yield takeLatest(GET_MEMBERS, getMembersCall)
    yield takeLatest(GET_MEMBERS_LIST, getMemberPermissions)
}
