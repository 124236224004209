import React, { Component } from 'react';
import { connect } from "react-redux";
import EditOwnerComponent from '../../components/admin/owners/EditOwnerComponent';
import { getTimeZoneLanguage } from "../../actions/Application";
import { getOwnerEquipments, resetOwnerDetails } from "../../actions/admin/Owner";

class EditOwnerContainer extends Component {
    /**
     * Dispatch the action to get the members data
     */
    componentDidMount() {
        let { userId } = this.props.match.params;

        if(userId) this.props.getOwnerEquipments({ userId })
        /** get timezone and language */
        this.props.getTimeZoneLanguage();
    }

    componentWillUnmount(){
        this.props.resetOwnerDetails();
    }

    render(){
        let { states, language, timezone } = this.props;
        return <EditOwnerComponent states={states} language={language} timezone={timezone} />;
    }
}

/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
const mapStateToProps = state => {  
    return { language: state.app.language || [], states: state.app.state || [], timezone: state.app.timezone || [] };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getTimeZoneLanguage, getOwnerEquipments, resetOwnerDetails
}


export default connect(mapStateToProps, mapDispatchToProps)(EditOwnerContainer);
