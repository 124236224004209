import React, { Component } from 'react';
import EmailInformationComponent  from '../../components/admin/email-information/EmailInformationComponent';
import { setLoader, setToastr } from "../../actions/Application";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";


class EmailInformationContainer extends Component {
    constructor(props) {
        super(props);
        
    }

    render() {
        return (
            <EmailInformationComponent  />
        );
    }
}
function mapStateToProps(state) {
    return;
}
let mapDispatchToProps = { setLoader, setToastr };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmailInformationContainer));