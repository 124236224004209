import React, { Component } from 'react';
import DailyworkComponent from '../components/customer/daily-work/DailyworkComponent';
import { translate } from '../lang';

export default class DailyworkContainer extends Component {
    render() {
        return (
            <DailyworkComponent translate={translate} />
        );
    }
}
