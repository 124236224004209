import React, { Component } from 'react';
import { connect } from 'react-redux';
import ManageServiceCompanyComponent from '../../components/admin/manage-service-company/ManageServiceCompanyComponent';
import { getEventTokenData } from '../../actions/RecentEvents';
import session from "../../services/session"
class ManageServiceCompanyContainer extends Component {

    /**
     * Dispatch the action to get the members data
     */
    componentWillMount(){
        let token = '';
        if(session.get('ADMINTOKEN', "cookie")){
            token = session.get('ADMINTOKEN', 'cookie');
        }
        this.props.getEventTokenData({token:token.token})
    }

    render() {
        return <ManageServiceCompanyComponent />;
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
const mapStateToProps = state => {
    return {};
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getEventTokenData
}

export default connect(mapStateToProps, mapDispatchToProps) (ManageServiceCompanyContainer);