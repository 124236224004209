import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditMemberComponent from '../components/customer/members/EditMemberComponent';
import { getTimeZoneLanguage } from "../actions/Application";
import { translate } from '../lang';

class EditMemberContainer extends Component {
    componentDidMount(){
        this.props.getTimeZoneLanguage();
    }

    render() {
        let { states } = this.props;
        return <EditMemberComponent states={states} translate={translate}/>;
    }
}

const mapStateToProps = state => {
    return({states: state.app.state || []});
}

const mapDispatchToProps = {
    getTimeZoneLanguage
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMemberContainer);