import React, { Component } from 'react';
import {Input} from 'reactstrap'

class InputField extends Component {
    
    handleChange(e) {
        this.props.handleChange(e)
    }

    render() {
         const{ elementRef, type, name, placeholder, id, value, disabled, autoComplete, className, max,
                checked, title, min, step  } = this.props;
        return (
                <Input 
                    title={ title }
                    value={ value }
                    id={ id }
                    type={ type }
                    maxLength = {max}
                    name={ name }
                    placeholder={ placeholder }
                    disabled={ disabled }
                    ref = { elementRef }
                    onChange={ this.handleChange.bind(this) }
                    autoComplete={ autoComplete ? autoComplete.toString() : undefined }
                    className={ className }
                    checked={ checked }
                    min = {min}
                    step = {step}
                />
        );
    }
}

export default InputField;
