import React from 'react';
import { Route } from 'react-router-dom';
import UserRouteContainer from '../containers/UserRouteContainer';
import EquipmentContainer from '../containers/EquipmentContainer';
import EquipmentDetailsContainer from '../containers/EquipmentDetailsContainer';
import DashboardContainer from '../containers/DashboardContainer';
import ReportsContainer from '../containers/ReportsContainer';
import LoginContainer from '../containers/LoginContainer';
import ResetPasswordContainer from "../containers/ResetPasswordContainer";
import DailyworkContainer from "../containers/DailyworkContainer";
import MembersContainer from "../containers/MembersContainer";
import AddMemberContainer from "../containers/AddMemberContainer";
import EditMemberContainer from "../containers/EditMemberContainer";
import AlertSettingContainer from "../containers/AlertSettingContainer";
import LocationInformationContainer from "../containers/LocationInformationContainer";
import ForgetPassContainer from "../containers/ForgetPassContainer";
import AreaMeasurementContainer from "../containers/AreaMeasurementContainer";

import ProfileContainer from "../containers/ProfileContainer";
import ServiceHistoryContainer from "../containers/ServiceHistoryContainer"
import MemberManagementContainer from "../containers/MemberManagementContainer"

const CustomerRoutes = ({ location, translate, match }) => {
    return (
        <>
            <Route location={ location } translate={ translate } path={`${match.url}/`} exact component={ LoginContainer } />
                <Route location={ location } translate={ translate } path={`${match.url}/reset-password/:token`} exact component={ ResetPasswordContainer } />
                <Route location={ location } translate={ translate } path={`${match.url}/forget-password`} exact component={ ForgetPassContainer } />

                
                    <UserRouteContainer location={ location } translate={ translate } path={`${match.url}/equipment`} exact component={ EquipmentContainer } />
                    <UserRouteContainer location={ location } translate={ translate } path={`${match.url}/equipment/equipmentDetail/:equipmentid`} exact component={ EquipmentDetailsContainer } />
                    <UserRouteContainer location={ location } translate={ translate } path={`${match.url}/dashboard`} exact component={ DashboardContainer } />
                    <UserRouteContainer location={ location } translate={ translate } path={`${match.url}/reports`} exact component={ ReportsContainer } />
                    <UserRouteContainer location={ location } translate={ translate } path={`${match.url}/location-information`} exact component={ LocationInformationContainer } />
                    <UserRouteContainer location={ location } translate={ translate } path={`${match.url}/alert-setting/:machineSaleId`} exact component={ AlertSettingContainer } />
                    <UserRouteContainer location={ location } translate={ translate } path={`${match.url}/alert-setting`} exact component={ AlertSettingContainer } />
                    <UserRouteContainer location={location} path={`${match.url}/manage-daily-work`} exact component={DailyworkContainer } />
                    <UserRouteContainer location={location} path={`${match.url}/members`} exact component={MembersContainer} />
                    <UserRouteContainer location={location} path={`${match.url}/teamMembers`} exact component={MembersContainer} />
                    <UserRouteContainer location={location} path={`${match.url}/members/add`} exact component={AddMemberContainer} />
                    <UserRouteContainer location={location} path={`${match.url}/members/edit/:memberId`} exact component={EditMemberContainer} />
                    <UserRouteContainer location={location} path={`${match.url}/my-account`} exact component={ProfileContainer} />
                    <UserRouteContainer location={location} path={`${match.url}/service-history`} exact component={ServiceHistoryContainer} />
                    <UserRouteContainer location={location} path={`${match.url}/member_management`} exact component={MemberManagementContainer}/>
                    <UserRouteContainer location={location} path={`${match.url}/area-measurement`} exact component={AreaMeasurementContainer}/>
                
        </>
    )
};

export default CustomerRoutes;