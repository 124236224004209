import React, { Component } from "react";
import { connect } from "react-redux";
import { setToastr, setLoader, setConfirmBox } from "../../../../actions/Application";
import { Field, reduxForm, reset } from "redux-form";
import { required, passwordsMatch, min6 } from "../../../shared/Validation";
import { renderTextField  } from "../../../shared/renderField";
import { changePassword } from "../../../../api/profile";
import { Row, Col } from "reactstrap";
import { translate } from "../../../../lang";

const form = "changePasswordForm";

class ChangePassword extends Component{
    constructor(props){
        super(props);

        this.state = { disablePwdBtn: false }

    }

    onSubmit = (values) => {
        const { setLoader, setToastr, dispatch } = this.props;
        setLoader({loader:{open:true}});

        let data = {userID: this.props.profile.id, newPassword: values.password, oldPassword: values.oldpassword};
        changePassword(data)
        .then(result => {
            console.log(result)
            setLoader({loader:{open:false}});
            setToastr({toastr:{message: this.props.translate('passwordChangeSuccess'), type:"success"}});
            dispatch(reset(form));
        })
        .catch(err => {
            setLoader({loader:{open:false}});
            setToastr({ toastr: { message: err || this.props.translate('passwordChangeError'), type: "error" } });
        })
    }

    render(){
        let { disablePwdBtn } = this.state, { profile, submitting, handleSubmit,translate } = this.props;
        return (
            <div className="tab-pane fade active in show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div className="user-profile">
                    <form onSubmit={handleSubmit(this.onSubmit)} autoComplete='off'>
                        <Row className="members align-items-center">
                            <Col sm='8'>
                            

                                <div className="row profile-asset">
                                    <div className="col-sm-4">{translate('oldPassword')} <span className='required'>*</span>
                                        <Field name="oldpassword" component={renderTextField} className='form-control' type="password" validate={[required, min6]}/>
                                    </div>
                                    <div className="col-sm-4">{translate('newPassword')} <span className='required'>*</span>
                                        <Field name="password" component={renderTextField} className='form-control' type="password" validate={[required, min6]}/>
                                    </div>
                                    <div className="col-sm-4">{translate('confirmPassword')} <span className='required'>*</span>
                                        <Field name="confpassword" component={renderTextField} className='form-control' type="password" validate={[required, passwordsMatch, min6]}/>
                                    </div>
                                </div>

                                <div className="row profile-asset">
                                    <div className="col-sm-4">&nbsp;</div>
                                    <div className="col-sm-8">
                                        <button type="submit" className="btn btn-primary" disabled={submitting}>{translate('changePassword')}</button> 
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        )
    }
}

const changePasswordForm = reduxForm({ form })(ChangePassword);

const mapDispatchToProps = { setLoader, setConfirmBox, setToastr }

export default connect(null, mapDispatchToProps)(changePasswordForm);