import { takeLatest, call, put } from 'redux-saga/effects';
import { getAllEquipments,getAllMachineDetail } from '../../api/service-provider/equipments';
import { GET_SP_EQUIPMENTS, SET_LOADER, SET_TOASTR, SET_SP_EQUIPMENTS,SET_ALL_EQUIPMENTS,GET_ALL_EQUIPMENTS } from '../../types';


export function* getSPEquipments(action) {

    try {

        const equipments = yield call(getAllEquipments, action.data);

        yield put({ type: SET_SP_EQUIPMENTS, data: {equipments: equipments.data.ownerEquipmentDetailList || [], countResult: equipments.data.countResult} });
        let params = JSON.stringify({ 'equipmentOperatingInfo':  equipments.data.ownerEquipmentDetailList});
            localStorage.setItem('equipmentOperatingInfo', params);
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}

/**
 * map the dispatched action to the above function (Returning with pagination)
 */
export default function* watchSPEquipments() {
    yield takeLatest(GET_SP_EQUIPMENTS, getSPEquipments)
}

   
