import { takeLatest, call, put } from 'redux-saga/effects';
import { getDealerEquipmentList} from '../../api/admin/dealer-management';
import { getDealersList} from '../../api/admin/dealer-management';
import { SET_TOASTR, SET_LOADER, GET_DEALER_EQUIPMENT_LIST, SET_DEALER_EQUIPMENT_LIST,GET_DEALERS, SET_DEALERS } from '../../types';



export function* getDealerEquipment(action) {
    const data = action.data
    try {
        const eqList = yield call(getDealerEquipmentList, action.data);
       
        yield put({ type: SET_DEALER_EQUIPMENT_LIST, data: eqList });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}

export function* getDealers(action) {
    
    try {
        const dList = yield call(getDealersList, action.data);
       
        yield put({ type: SET_DEALERS, data: dList });
    }
    catch (e) {
        yield put({
            type: SET_TOASTR,
            data: {
                toastr: {
                    message: "We could't find search result for you.", 
                    type:"info"
                }
            }
        });

        yield put({
            type: SET_LOADER,
            data: {
                loader: {
                    open: false
                }
            }
        });
    }
}






/**
 * map the dispatched action to the above function
 */
export default function* watchDealerEquipment() {
    yield takeLatest(GET_DEALER_EQUIPMENT_LIST, getDealerEquipment)
    yield takeLatest(GET_DEALERS, getDealers)
}
