import http from "../services/http";
import { __api_getMembers, __api_addMember, __api_saveMemeberFunction,__api_deleteMember, __api_validateMember,__api_getMemberList } from "./endpoints";

export const getMembers = () => {
    return http.Request("GET", __api_getMembers);
}

export const getMemberList = () => {
    return http.Request("GET", __api_getMemberList);
}

export const addMember = data => {
    return http.Request("POST", __api_addMember, data);
}
export const saveMemeberFunction = data => {
    return http.Request("POST", __api_saveMemeberFunction, data);
}
export const deleteMember = id => {
    return http.Request("DELETE", __api_deleteMember + "/" + id);
}

export const validateMember = loginId => http.Request("GET", __api_validateMember + "/" + loginId);