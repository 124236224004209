import React, { Component } from 'react';
import { connect } from 'react-redux';
import HourmeterOffsetDetail from '../../components/service-provider/equipments/HourmeterOffsetDetail';

class HourmeterOffsetDetailContainer extends Component {
    render() {
        return (
            <HourmeterOffsetDetail />

        );

    }
}
const mapStateToProps = state => {

}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(HourmeterOffsetDetailContainer);