import { takeLatest, call, put } from 'redux-saga/effects';
import {GetInstantReport } from '../../api/getInstantReport';
import {SET_INSTANT_REPORT_DATA, SENDING_EQUIPMENT_GET_DATA, GET_INSTANT_REPORT_DATA } from '../../types';

/**
 * Call the api and dispatch data to the reducer
 * @param { Object } action Contained object data to be send to API call
 */
export function* getInstantReportData(action) {
  const data = action.data;
  
  try {
    yield put({ type: SENDING_EQUIPMENT_GET_DATA, 
                data: '', 
                resMsg: '',
                isSending: true
              });
   let response = yield call(GetInstantReport, data);

   switch (response.statusCode) {
        case 200 :
          yield put({
             type: SET_INSTANT_REPORT_DATA,
             data: response.data? response.data.instantRoute: null,
             resMsg: response.message,
             isSending: false,
             error: false 
            })
            break;
          default:
            break;
    }
  } catch (e) {
    // yield put({
    //   type: FETCH_DATA_FAIL,
    //   payload: {
    //     error: e.message
    //   }
    // })
  }
}

/**
 * map the dispatched action to the above function
 */
export default function* watchInstantReportData() {
  yield takeLatest(GET_INSTANT_REPORT_DATA, getInstantReportData)
}
