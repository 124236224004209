import React, { Component, Fragment } from "react";
import { Field, reduxForm, reset } from "redux-form";
import { required } from "../../shared/Validation";
import { FileInput  } from "../../shared/renderField";
import { Row, Col, Alert } from "reactstrap";
import {connect} from "react-redux";
import {uploadMap} from "../../../api/admin/custom-map";
import { setLoader, setToastr } from "../../../actions/Application";

const form = "CustomMapImportForm";

class ImportCustomMapComponent extends Component{

    constructor(props){
        super(props);

        this.state = {
            mapRecords: []
        }
    }

    onSubmit = (values) =>{
        this.props.setLoader({ loader: {open: true}});
        uploadMap(values)
        .then(response => {
            if(response.data){

                if(response.data.mapRecords && Array.isArray(response.data.mapRecords)){
                    this.setState({mapRecords: response.data.mapRecords}); 
                }
                this.props.setToastr({ toastr: { message:"Custom Map has been uploaded successfully.", type:"success" }});
                this.props.dispatch(reset(form));
            }else{
                throw new Error();
            }
            this.props.setLoader({ loader: {open: false}});
        })
        .catch(error => {
            this.props.setToastr({toastr:{type: "error", message:error || "Something went wrong."}});
            this.props.setLoader({ loader: {open: false}});            
        })
    }
    
    render(){
        const { handleSubmit, submitting } = this.props, {mapRecords} = this.state;

        return(
            <Fragment>
                <form name={form} id={form} onSubmit={handleSubmit(this.onSubmit)} autoComplete='off'>
                    <Row className="members align-items-center">
                        <Col sm="6">
                            <Alert type="info">
                                <i>Note: Please upload csv file to import data in bulk.</i>
                            </Alert>
                            <div className="row profile-asset">
                                <div className="col-sm-6">&nbsp;
                                    <Field name="file" component={FileInput} className='form-control' type="file" validate={[required]} onChange={() => this.setState({mapRecords: []})}/>
                                </div>

                                <div className="col-sm-6">&nbsp;  <br/>
                                <button type="submit" className="btn btn-primary" disabled={submitting}>Upload</button> &nbsp;
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>

                {mapRecords.length > 0 && <table className="tabledashboard table table-striped table-advance table-hover m-top-30">
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>Machine Number</th>
                            <th>Map1 ID</th>
                            <th>Map2 ID</th>
                            <th>Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(mapRecords) && mapRecords.length>0 && mapRecords.map((item,i) => (
                            <tr key={item.model+i+1}>
                                <td className={item.errorMessage ? "errbg": "sucbg"}>{item.model || "NA"}</td>
                                <td className={item.errorMessage ? "errbg": "sucbg"}>{item.machineNum || "NA"}</td>
                                <td className={item.errorMessage ? "errbg": "sucbg"}>{item.map1Id || "NA"}</td>
                                <td className={item.errorMessage ? "errbg": "sucbg"}>{item.map1Id || "NA"}</td>
                                <td className={item.errorMessage ? "errbg": "sucbg"}>{item.errorMessage || "NA"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </Fragment>
        );
    }
}


const CustomMapForm = reduxForm({
    form: "CustomMapImportForm"
})(ImportCustomMapComponent);

let mapDispatchToProps = { setLoader, setToastr };
export default connect(null, mapDispatchToProps)(CustomMapForm);