import { GET_SP_EQUIPMENTS,GET_ALL_EQUIPMENTS,GET_ALL_EQUIPMENTS_LOCATION } from '../../types';

export const getSPEquipments = (data) => {
    return { type: GET_SP_EQUIPMENTS, data }
}

export const getSPMachineDetails = (data) => {
    return { type: GET_ALL_EQUIPMENTS, data }
}

export const getSPMachineLocation = (data) =>{
    return {type : GET_ALL_EQUIPMENTS_LOCATION, data}
}
