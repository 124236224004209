import React, { Fragment, Component } from "react";
import { Col, Row, Button } from "reactstrap";
import { FaBell, FaExclamationCircle, FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default class RealTimeAlert extends Component{
    constructor(props){
        super(props);

        this.state = {
            showEvent: 0
        }

        this.switchEvent = this.switchEvent.bind(this);
    }

    switchEvent(type='next'){
        let {showEvent} = this.state;
        showEvent = (type === 'next') ? showEvent+1 : showEvent-1;
        this.setState({ showEvent });
    }


    render(){
        let { showEvent } = this.state, { events, push } = this.props, event = events[showEvent];

        return(
            <Fragment>

                {showEvent>0 && <FaChevronLeft size='3em' className='arrow prev' onClick={() => this.switchEvent("prev")} />}
                
                {showEvent < (events.length-1) && <FaChevronRight size='3em' className='arrow next' onClick={() => this.switchEvent("next")} />}

                <Row>
                    <Col sm="2" className='text-center offset-sm-1'>
                        <FaBell size="7em"/>
                    </Col>
                    <Col>
                        <Row>
                            <Col sm="3">Number Plate</Col>
                            <Col>{(event && event.numberPlate) || " "}</Col>
                        </Row>

                        <Row>
                            <Col sm="3">Machine Number</Col>
                            <Col>{(event && event.machineNum) || " "}</Col>
                        </Row>

                        <Row>
                            <Col sm="3">Owner</Col>
                            <Col>{(event && event.owner) || " "}</Col>
                        </Row>

                        <Row>
                            <Col sm="3">Details</Col>
                            <Col>{(event && event.eventDescription) || " "}</Col>
                        </Row>

                        <Row>
                            <Col className='offset-sm-3 margin-top-15'>
                                <Button className='btn btn-default' onClick={() => push(`/service/equipment-alert-detail/${event.eventId}`)}>View Details</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col className='offset-sm-2 margin-top-15'>
                                <span className={'urgency-bar type' + ((event && event.eventUrgency) || "3") }>
                                    <span className='urgency-title'>Urgency</span>
                                    <span className='urgency-type'><FaExclamationCircle /> {(event && event.eventUrgency) ==="1"? "HIGH" : ((event && event.eventUrgency) === '2' ? "MEDIUM" : "LOW")}</span>
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}