import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScheduleServiceAlertComponent from '../../components/admin/schedule-service-alert/ScheduleServiceAlertComponent';
import { translate } from '../../lang';
import { getScheduleServiceAlert, updateScheduleServiceAlert } from '../../actions/ScheduleService';

class ScheduleServiceAlertContainer extends Component {

    /**
     * Dispatch the action to get the get user list data
     */
    componentWillMount(){
        this.props.getScheduleServiceAlert()
    }

    handleSubmit(data){
        this.props.updateScheduleServiceAlert(data)
    }

    render() {      
        return (
            <ScheduleServiceAlertComponent translate={translate} apiError = { this.props.apiError } handleSubmit={this.handleSubmit.bind(this)} serviceAlertData = {this.props.serviceAlertData} />
        );
    }
}


/**
 * Get the data from store and provide as props to the
 * current component
 * @param  { Object } state Global states
 * @return { Object }       return the required data from store.
 */
function mapStateToProps(state) {
    return {
        serviceAlertData : state.scheduleService.data,
        apiError : state.scheduleService.FETCH_DATA_FAIL || false
      };
}

/** @type { Object } Connect the action to be dispatched */
const mapDispatchToProps = {
    getScheduleServiceAlert, updateScheduleServiceAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleServiceAlertContainer);